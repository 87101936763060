import styled from "styled-components";

export const HomeSearch = styled.section`
  width: 100%;
  height: 100%;

  #simple-search{
    display: flex;
    height: 44px;
    padding: 0px 16px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 16px;
    text-indent: 2.5rem;
    color: var(--Grey-Grey-40, #9E9E9E);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.021px;

    background: var(--Grey-Grey-10, #F7F7F7); 
    ::placeholder {
      color: var(--Grey-Grey-40, #9E9E9E);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.021px; 
    }
  }

  .filter-title{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    text-align: center;
    /* Headline/Heading 5 - medium */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */ 
  }

  .filter-name{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Poppin/Body/Body Large - regular */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.024px; 
  }

  .filter-btn {
    display: flex;
    height: 40px;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
    border-radius: 8px;
    background: #06C270; 

    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.024px; 
  }

`;
