import { homeImg } from "helpers/ExportImg";
import GameCard from "components/Card/GameCard";
import useWindowSize from "hooks/useWindowSize";
import appContext from "appContext";
import { useContext, useEffect } from "react";
import PaginationGame from "components/Pagination";
import { getListGamesOpenSource } from "pages/services/api";
import { async } from "@firebase/util";
import { setDetailOpen, setListGameOpenSource } from "slices/gameOpenSource";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useRef } from "react";
import LoadingIcon from "components/LoadingIcon";
import { footerImg, slidebarImg } from "helpers/ExportImg";
import InfiniteScroll from "react-infinite-scroller";
import LoadingVerTwo from "components/loadingVerTwo/LoadingVerTwo";

import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { PlayToEarnStyled } from "./styled";
import GameCardNew from "components/Card/GameCardNew";
import { Link } from "react-router-dom";

const OpenSourceceAll = ({ openList }: any) => {
  const [page, setPage] = useState(1);
  const [limitGame, setLimitGame] = useState(21);
  const [loading, setLoading] = useState<boolean>(true);
  const handleChagePage = (value: number) => {
    rootRef?.current.scrollIntoView({ behavior: "smooth" });
    setPage(value);
  };
  const rootRef = useRef<any>();
  const { listGame } = useSelector((state: any) => state.gameOpenSource);

  const { user } = useSelector((state: any) => state.auth);
  const count = Math.ceil(listGame?.total / 10);
  const { controlMobile } = footerImg;

  const totalCount = listGame?.total;
  const totalGame = listGame?.data?.length;

  const { bgSymbol } = homeImg;
  const width: any = useWindowSize();
  useEffect(() => {
    if (listGame) {
      setLoading(false);
    }
  }, [listGame]);
  const dispatch = useDispatch();
  const fetchApi = async () => {
    try {
      setLoading(true);
      // const limit = 10;
      const params = { page, limit: limitGame, id: user?.id || 0 };
      const data = await getListGamesOpenSource(params);
      setLoading(false);
      dispatch(setListGameOpenSource(data));
    } catch (err) {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    try {
      setLoading(true);
      const newLimit = limitGame + 20;

      const params = { page, limit: newLimit, id: user?.id || 0 };
      const data = await getListGamesOpenSource(params);
      setLoading(false);
      setLimitGame(newLimit);
      dispatch(setListGameOpenSource(data));
    } catch (err) {
      console.log(err);

      setLoading(false);
    }
  };

  const [dataGame, setDataGame] = useState([]);

  const dataGameMobile = async () => {
    try {
      setLoading(true);
      const limit = totalCount;
      const params = { page, limit, id: user?.id || 0 };
      const data = await getListGamesOpenSource(params);
      setLoading(false);
      setDataGame(data.data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchApi();
  }, [page, user]);


  const payToEarnRef = useRef<any>(null)

  // const ClickScroll = (x:number)=>{
  //     myRef.current.scrollBy(x, 0);
  // }
  // useEffect(() => {
  //   dataGameMobile();
  // }, []);

  // useEffect(() => {
  //   rootRef?.current.scrollIntoView({ behavior: "smooth" });

  // }, [page])

  return (
    <PlayToEarnStyled className="relative">

    <div
      className=""
      style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
      ref={rootRef}
    >
      <div
        className=""
        style={{ margin: 0 }}
      >
        <div className="flex justify-between w-full  ">
          <div className="pte-header-left">
            <span>Play to earn</span>
          </div>
          <div className="pte-header-right">
            <Link to={"/all-game-pta?iconHeader=2"}>See all</Link>
          </div>
        </div>

        <>
          {loading ? (
            <img
              style={{ padding: "5rem 0" }}
              src={controlMobile}
              alt="img"
              className="logo-loading"
            />
          ) : (

            <div ref={payToEarnRef} className="mt-5 grid grid-rows-3 grid-flow-col gap-10 overflow-x-scroll scroll-p-0 w-[90vw] max-w-[1200px] pb-10 hide-scroll-bar justify-between">
            {listGame?.data?.map((game: any) => (
                <div
                  className={`${
                    width > 1000 ? "card-all-games" : "card-genre-game"
                  }`}
                  key={game?.id}
                >
                  {/* <div className="card-genre-game game-open" key={game?.id}> */}
                  <GameCardNew game={game} />
                </div>
              ))}
              {/* <div style={{ marginTop: "1rem", width: "100%" }}>
                <PaginationGame
                  count={count}
                  page={page}
                  setPage={handleChagePage}
                />
              </div> */}
            </div>
            // <>
            //   {width > 1000 ? (
            //     <div className="flex-center wrapper-cards cards-free-game  card-genre-game-custom">
            //       {listGame?.data?.map((game: any) => (
            //         <div className="card-all-games" key={game?.id}>
            //           <GameCard game={game} />
            //         </div>
            //       ))}
            //       <div style={{ marginTop: "1rem", width: "100%" }}>
            //         <PaginationGame
            //           count={count}
            //           page={page}
            //           setPage={handleChagePage}
            //         />
            //       </div>
            //     </div>
            //   ) : (
            //     <div className="flex-center wrapper-cards cards-free-game  card-genre-game-custom">
            //       {dataGame?.map((game: any) => (
            //         <div className="card-genre-game" key={game?.id}>
            //           <GameCard game={game} />
            //         </div>
            //       ))}
            //     </div>
            //   )}
            // </>
          )}
        </>
      </div>
    </div>
    </PlayToEarnStyled>

  );
};

export default OpenSourceceAll;