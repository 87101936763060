import React, { FC } from "react";
import { footerImg, homeImg, slidebarImg } from "helpers/ExportImg";
type LoadingProps = {
  title?: string;
  closeLoading?: () => void;
};
const LoadingIcon: FC<LoadingProps> = ({ title, closeLoading }) => {
  const { controlMobile } = footerImg;

  return (
    <div className="flex-center loading-component ">
      <div className="flex flex-col items-center">
        <div className="flex justify-center">
          <img src={controlMobile} alt="img" className="logo-loading" />
        </div>

        <div className="mt-6 text-center text-[20px] text-[#fff] font-bold">
          {title}
        </div>
        {title && (
          <div className="flex justify-center mt-4">
            <div
              className="btn text-[12px] cursor-pointer"
              onClick={closeLoading}
            >
              Close
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadingIcon;
