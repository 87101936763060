import { getListGenre } from "pages/services/api";
import React, { useEffect, useState, useRef, useContext } from "react";

import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import appContext from "appContext";

import imgSoft from "../asset/game-free/soft.png";
import useClickOutSide from "hooks/useClickOutside";

const FilterGame = () => {
  const [genres, setGenres] = useState([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [render, setRender] = useState<boolean>(true);
  const [genreCurrent, setGenreCurrent] = useState("");

  const { setCategories, categories, setPage, genre } = useContext(appContext);

  const formFilterRef = useRef(null);

  const closeFormFilterHandler = () => {
    if (isOpen) {
      setIsOpen(false);
      return;
    }
  };

  useClickOutSide(closeFormFilterHandler, formFilterRef);

  const filterRef = useRef<any>();
  const boxRef = useRef<any>();

  useEffect(() => {
    getListGenre().then((response: any) =>
      setGenres(response.filter((el: any) => el.name !== "Driving & Racing"))
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      boxRef.current?.classList.add("filter-down");
      boxRef.current?.classList.remove("filter-up");
    } else if (!render) {
      boxRef.current?.classList.add("filter-up");
      boxRef.current?.classList.remove("filter-down");
    }
  }, [isOpen]);

  const openFilter = (event: any) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    setRender(false);
  };

  const chooseGenre = (event: any, genre: any) => {
    // HARD DATA GENRE DRIVING AND RACING
    event.preventDefault();
    event.stopPropagation();
    if (genre?.id === 129) return setCategories(2);
    setCategories(genre?.id);
    setPage(1);
    setGenreCurrent(genre?.name);
  };

  return (
    <div
      className="wrapper-filter"
      // ref={filterRef}
      onClick={openFilter}
      ref={formFilterRef}
    >
      <div className="filter-game">
        <div className="img-soft">
          <img src={imgSoft} alt="img soft" />
        </div>
        <span style={{ marginLeft: "5px" }}>
          {genreCurrent ? genreCurrent : "Soft"}
        </span>
        <ArrowDropDownIcon fontSize="large" style={{ fontSize: "30px" }} />
      </div>

      {isOpen && (
        <div
          className="multi-genre animate__animated animate__bounceInDown animate__faster"
          ref={boxRef}
        >
          {genres?.map((genre: any) => (
            <div
              key={genre?.id}
              className="genre-item"
              onClick={(event) => chooseGenre(event, genre)}
              style={{
                background:
                  genre?.id === categories
                    ? "rgba(0, 201, 162, 1)"
                    : "transparent",
              }}
            >
              {genre?.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterGame;
