import { shopImg } from "helpers/ExportImg";
import styled from "styled-components";

const { NFTgame } = shopImg;

export const NavBar = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  ${'' /* box-shadow: 2px 0px 8px rgba(34, 158, 151, 0.25); */}
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 700;
  .wrapper-notify {
    img {
      pointer-events: ${({ status }) => status && "none"};
    }
  }

  .noti-shop {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.5rem;
  }

  .logo-search {
    display: flex;
    align-items: center;
    column-gap: 3rem;
  }

  .nft-game-btn {
    width: 22rem;
    height: 6.5rem;
    cursor: pointer;

    background-image: url(${NFTgame});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 23rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5rem;
  }

  .nft-games {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 130%;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }

  .playgame-img {
    width: 11.4rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .score{
    color: var(--Neutral-Color-Black, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 183.333% */
    letter-spacing: 0.018px;

    display: flex;
    padding: 2px 8px 2px 4px;
    align-items: center;
    gap: 4px; 
    border-radius: 24px;
    background: linear-gradient(104deg, #B9943B 0.73%, #D0B15F 52.43%, #F6EAA2 65.88%, #E3CC7F 79.84%, #C9A551 100%), #E1E1E1;
  }
  .nav-title{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    text-align: center;
    /* Headline/Heading 5 - medium */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */ 
  }

  @media (max-width: 1566px) {
    .nft-games {
      font-size: 2rem;
    }
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    .playgame-img {
      width: 7rem;
    }
  }

  @media (max-width: 767px) {
    .nft-game-btn {
      display: none;
    }

    .playgame-img {
      width: 7rem;
    }

    .logo-search {
      column-gap: unset;
    }
  }
`;

export const NavBarMobile = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;

  .navbar-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }

  .notify-mobile {
    max-width: 8rem;
  }

  .shop-img {
    max-width: 7rem;
  }
`;
