import Hero from "components/home/hero";
import { slideNFT } from "helpers/ExportImg";
import SliceNFTGame from "./slideNFT";
import { GameNFTTag } from "./styled";

function GameNFTPageNew() {
  const { banner } = slideNFT;

  return (
    <GameNFTTag>
      {/* <Hero /> */}
      <div className="img-banner">
        <img src={banner} alt="img" />
      </div>
      <SliceNFTGame />
    </GameNFTTag>
  );
}

export default GameNFTPageNew;
