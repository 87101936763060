
import { Link } from "react-router-dom";
import { CardSpecialHistoryWrapper } from "./styled";

const CardSpecialHistory: any = ({ game }: any) => {
  const { totalReward, participant, startedAt, id } = game;
  return (
    <>
      <CardSpecialHistoryWrapper>
      <Link to={`/history-special/${id}`}>
        <div className="flex flex-col mt-5 overflow-x-auto w-full">
          <div className="flex w-full history-row pb-5" >
            <div className="flex-none w-5/12">
              <div className="space-y-2">
                <div className="history-lable">
                  Prize
                </div>
                <div className="history-lable">
                  Total Participants
                </div>
              </div>
            </div>
            <div className="flex-1 justify-center items-center space-y-2">
              <div className="value-prize">
                {totalReward}
              </div>
              <div className="value-total">
                {participant}
              </div>
            </div>
            <div className="flex-1 ">
              <div className="flex justify-end items-end value-total">{startedAt?.slice(0, 10).replaceAll('-','/')}</div>
            </div>
          </div>
        </div>
        </Link>
      </CardSpecialHistoryWrapper>
    </>
  );
};

export default CardSpecialHistory;