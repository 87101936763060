import { slideNFT } from "helpers/ExportImg";
import styled from "styled-components";

const { gamebo } = slideNFT;

export const GameNFTTag = styled.section``;

export const SliceNFTTag = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .content-nft {
    display: flex;
    justify-content: center;
  }

  .container-wrapper {
    width: 90%;
  }

  .prevEl_nft_game {
    position: absolute;
    top: 50%;
    left: 29%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 10rem;
    transition: all 0.3s ease-in-out;
  }

  .nextEl_nft_game {
    position: absolute;
    top: 50%;
    right: 23.5%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 10rem;
    transition: all 0.3s ease-in-out;

    :active {
      transform: translate(-50%, -50%) scale(0.98);
    }
  }

  .click-btn {
    transition: all 0.3s ease-in-out;
  }

  .btn-click {
    animation: topsideAnimation 0.4s ease;
  }

  .game-tect {
    position: absolute;
    top: 48.8%;
    transform: translate(-50%, -50%);
  }

  .game-tect img {
    height: 46rem;
  }

  .game-tect--left {
    left: 27.9%;
    top: 48.5%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .game-tect--center {
    left: 49.5%;
    top: 48.5%;
    transform: translate(-50%, -50%);
  }

  .game-tect--right {
    left: 71.1%;
    top: 48.5%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .nft-game-slide {
    width: 85%;
    height: 55rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .mySwiper {
      padding-top: 5rem;
      padding-right: 1.5rem;

      .swiper-slide.swiper-slide-active .nft-game-img {
        width: 51rem;
        height: 39rem;
      }

      .swiper-slide .nft-game-img {
        width: 20rem;
        height: 15rem;
      }

      .swiper-wrapper {
        align-items: center;
      }

      .swiper-slide.swiper-slide-next {
        justify-content: end;
      }

      .nft-game-img {
        /* width: 26rem; */
        /* height: 20rem; */

        img {
          width: 100%;
          height: 100%;
          /* border-radius: 10px; */
          object-fit: cover;
        }
      }
    }
  }

  .un_active {
    -moz-box-shadow: 15px 15px 3px #cecece;
    -webkit-box-shadow: 15px 15px 3px #cecece;
    box-shadow: 15px 15px 3px #cecece;
    border-radius: 8px;

    img {
      border: 5px solid #008369;
      border-radius: 8px;
    }
  }

  .desc-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .nft-game-content {
    margin-top: 6rem;
    .nft-content-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 3.5rem;
      margin-bottom: 6.2rem;

      .nft-content-whitepaper {
        display: flex;
        align-items: center;
        column-gap: 2.4rem;
        cursor: pointer;

        background: #00896f;
        box-shadow: 0px 6px 0px #00c9a2;
        border-radius: 10px;
        padding: 1.6rem 4rem;

        .img-whitepaper {
          max-width: 5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .nft-content-downlod {
        display: flex;
        align-items: center;
        column-gap: 2.4rem;
        cursor: pointer;

        background: #ff6700;
        box-shadow: 0px 6px 0px #ffaf10;
        border-radius: 10px;
        padding: 1.6rem 10rem;
      }

      .nft-text {
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 37px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }

    .btn-hover:active {
      transform: scale(0.98);
      box-shadow: unset;
    }

    .nft-content-bot {
      margin-bottom: 10rem;
      width: 80%;

      .nft-content-bot-title {
        display: flex;
        flex-direction: column;

        column-gap: 1rem;

        .title-game {
          font-style: normal;
          font-weight: 700;
          font-size: 3.2rem;
          line-height: 49px;
          text-transform: uppercase;
          color: #008369;
        }

        .category-game {
          font-style: normal;
          font-weight: 400;
          font-size: 2rem;
          line-height: 29px;
          text-transform: uppercase;
          color: #1a1a1a;
        }
      }

      .nft-content-bot-desc {
        width: 50%;
        .nft-content-bot-desc-title {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          text-transform: uppercase;
          color: #1a1a1a;
          margin: 0.8rem 0 1.2rem;
        }

        .nft-content-ver {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 130%;
          text-transform: capitalize;
          color: #2b2b2b;
          margin-bottom: 1.5rem;
        }

        .nft-content-bot-desc-text {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 130%;
          text-transform: capitalize;
          color: #2b2b2b;
        }
      }

      .nft-content-bot-info {
        .nft-content-bot-desc-title {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          text-transform: uppercase;
          color: #1a1a1a;
          margin: 0.8rem 0 1.2rem;
        }

        .nft-content-bot-info-social {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;

          .nft-content-bot-info-social-web {
            display: flex;
            align-items: center;
            column-gap: 2rem;

            .nft-content-bot-info-img {
              width: 3rem;
              height: 3rem;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .nft-content-bot-info-info {
            .nft-content-bot-info-title {
              font-style: normal;
              font-weight: 700;
              font-size: 2rem;
              line-height: 31px;
              display: flex;
              align-items: center;
              color: #000000;
            }

            .nft-content-bot-info-link {
              font-style: normal;
              font-weight: 400;
              font-size: 2rem;
              line-height: 130%;
              display: flex;
              align-items: center;
              letter-spacing: 0.0025em;
              color: #6f6f6f;
            }
          }
        }
      }
    }
  }

  @keyframes topsideAnimation {
    0% {
      transform: translateY(0%);
      z-index: 0;
    }

    33% {
      transform: translateY(30%);
      /* z-index: 10; */
    }

    100% {
      transform: translateY(0%);
      z-index: 0;
    }
  }

  @media (max-width: 1850px) {
    .game-tect--right {
      left: 72.5%;
    }

    .game-tect--left {
      left: 26.9%;
    }
  }

  @media (max-width: 1750px) {
    .game-tect--right {
      left: 74.5%;
    }

    .game-tect--left {
      left: 26.9%;
    }

    .game-tect--center {
      left: 50.5%;
    }

    .nextEl_nft_game {
      right: 20.5%;
    }
  }

  @media (max-width: 1650px) {
    .game-tect--right {
      left: 72.5%;
    }

    .game-tect--left {
      left: 26.4%;
    }

    .game-tect--center {
      left: 50.5%;
    }

    .nextEl_nft_game {
      right: 20.5%;
    }
    .game-tect--center {
      left: 52.5%;
    }
  }

  @media (max-width: 1566px) {
    .game-tect--right {
      left: 73.1%;
    }

    .game-tect--left {
      left: 25.8%;
    }

    .game-tect--center img {
      width: 84%;
    }

    .nextEl_nft_game {
      right: 20.5%;
    }

    .prevEl_nft_game {
      left: 27%;
    }
  }

  @media (max-width: 1500px) {
    .game-tect--right {
      left: 72.35%;
      top: 47.5%;
    }

    .game-tect--left {
      left: 27%;
      top: 47.5%;
    }

    .game-tect--center img {
      width: 95%;
    }

    .game-tect--center {
      left: 50.5%;
      top: 47.5%;
    }

    .nextEl_nft_game {
      right: 22.5%;
    }

    .prevEl_nft_game {
      left: 29%;
    }

    .game-tect img {
      height: 47rem;
    }
  }

  @media (max-width: 1366px) {
    .game-tect--right {
      left: 72.35%;
    }
    .game-tect--center {
      left: 51.5%;
    }
    .nextEl_nft_game {
      right: 22.5%;
    }
    .game-tect--center img {
      width: 89%;
    }
  }

  @media (max-width: 1000px) {
    .swiper-slide.swiper-slide-active {
      display: flex;
      justify-content: center;
    }

    .game-tect--center {
      left: 51.6%;
      top: 45.5%;
    }

    .game-tect--right {
      left: 79%;
      top: 45.5%;
    }

    .game-tect--left {
      left: 24.8%;
      top: 45.5%;
    }

    .nextEl_nft_game {
      right: 14.5%;
      top: 47%;
    }

    .prevEl_nft_game {
      left: 26%;
      top: 47%;
    }
  }

  @media (max-width: 768px) {
    .bg_back_mobile {
      height: 42rem;
      width: 15rem;
      img {
        height: 100%;
        width: 100%;
      }
    }

    .nft-game-content .nft-content-top {
      flex-wrap: wrap;
      row-gap: 6rem;
    }

    .nft-game-content .nft-content-bot {
      width: 100%;
    }

    .img-whitepaper,
    .img-token,
    .img-download {
      max-width: unset;
      width: 5.5rem;
    }

    .desc-info {
      flex-direction: column;
      row-gap: 2rem;
    }

    .nft-game-content .nft-content-bot .nft-content-bot-desc {
      width: 100%;
    }

    .nft-game-slide {
      width: 100%;
    }

    .game-tect--center {
      left: 50%;
      top: 44.5%;
    }

    .game-tect--right {
      left: 71.5%;
      top: 46.5%;
    }

    .game-tect--left {
      left: 28.5%;
      top: 46.5%;
    }

    .nextEl_nft_game {
      right: 22.5%;
      top: 46.5%;
    }

    .prevEl_nft_game {
      left: 29%;
      top: 45%;
    }

    .game-tect--center img {
      width: 100%;
    }
  }

  @media (max-width: 650px) {
    .game-tect--right {
      left: 78.5%;
    }

    .game-tect--left {
      left: 21.5%;
    }

    .nextEl_nft_game {
      right: 14.5%;
    }

    .prevEl_nft_game {
      left: 22%;
    }
  }

  @media (max-width: 550px) {
    .game-tect--right {
      left: 81%;
    }

    .game-tect--left {
      left: 19%;
    }

    .nextEl_nft_game {
      right: 10.5%;
    }

    .prevEl_nft_game {
      left: 19%;
    }

    .nft-game-content {
      .nft-content-top {
        margin-bottom: 1.2rem;
        .nft-content-whitepaper,
        .nft-content-downlod {
          padding: 0.5rem 1.5rem;
        }
      }
    }

    .nft-game-content {
      .nft-content-bot {
        .nft-content-bot-title {
          .title-game {
            font-size: 5.2rem;
          }
          .category-game {
            font-size: 3rem;
          }
        }
      }
    }

    .nft-game-content {
      .nft-content-bot {
        .nft-content-bot-info {
          .nft-content-bot-info-social {
            .nft-content-bot-info-info {
              .nft-content-bot-info-title,
              .nft-content-bot-info-link {
                font-size: 3rem;
              }
            }
          }
        }
      }
    }

    .swiper-slide.swiper-slide-active .nft-game-img {
      height: 38rem;
    }
  }

  @media (max-width: 480px) {
    .game-tect--center {
      left: 50%;
      top: 43.5%;
      width: 52rem;
    }

    .game-tect--right {
      left: 85%;
      top: 45.5%;
    }

    .game-tect--left {
      left: 13.5%;
      top: 45.5%;
    }

    .nextEl_nft_game {
      right: 5.5%;
      top: 43%;
    }

    .prevEl_nft_game {
      left: 13%;
      top: 43%;
    }
  }

  @media (max-width: 420px) {
    .game-tect--center {
      left: 50%;
      top: 43.5%;
      width: 49rem;
    }

    .game-tect--right {
      left: 88%;
      top: 45.5%;
    }

    .game-tect--left {
      left: 11.5%;
      top: 45.5%;
    }

    .nextEl_nft_game {
      right: 0.5%;
      top: 43%;
    }

    .prevEl_nft_game {
      left: 13%;
      top: 43%;
    }
  }

  @media (max-width: 410px) {
    .game-tect--center {
      width: 45rem;
    }
  }
`;
