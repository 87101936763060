import { getSignature } from "pages/services/api";
import { useEffect, useState } from "react";
import { wealthyAbi } from "web3/contract/wealthyAbi";
import { PopupTag } from "./styled";


import { prepareWriteContract, writeContract } from '@wagmi/core';
import { toast } from "react-toastify";
import { slidebarImg } from "helpers/ExportImg";
import { config } from "app/environment";



const PopupVote = ({ show, setShow, eventId, type }: any) => {
  const [query, setQuery] = useState('')
  const { pools } = slidebarImg;

  const [loading, setLoading] = useState(false)

  const handleChangeComment = (event: any) => {
    setQuery(event.target.value)
  };
  const handlerClosePopup = () => {
    setShow(false)
  };

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === 'Enter') {
    }
  }


  const handlerVote = async (eventId: number | undefined, pool: number | undefined, type: number | undefined) => {
    if (!query || parseFloat(query) < 1) {
      toast.error("Need to enter valid so pools and must be greater than 1")
      return
    }
    setLoading(true)
    try {
      const sigData = await getSignature(eventId, parseFloat(query), type)
      const configVote = await prepareWriteContract({
        address: config.CONTRAC_VOTE as `0x${string}`,
        abi: wealthyAbi,
        functionName: 'deposit',
        args: [eventId, sigData.pool, type, sigData.expireBlock, sigData.signature,],
        overrides: {
          value: sigData.pool
        },
      })
      const { hash } = await writeContract(configVote)
      console.log(hash)
      setQuery('')
      setLoading(false)
      handlerClosePopup()
      toast.success("Vote successful")
    } catch (error) {
      console.log("vote error", error)
      setLoading(false)
      handlerClosePopup()
      setQuery('')
      toast.error("Vote failed! Insufficient Pool amount or unusual issue")
    }
  }

  useEffect(() => {

  }, []);

  return (
    <PopupTag>
      <>
        {show && (
          <>
            <div onClick={handlerClosePopup} className={`backdrop`}></div>
            <div className="">
              <div className={`popup img-mobile w-10/12 max-w-[500px] min-w-[80%]`}>
                <>
                  <div className="bg-[#FFF] rounded-3xl p-14 w-full">
                    <div className="p-5">
                      <div className="popup-title">
                        Input POOLS
                      </div>
                      <div className="text-center mt-5 text-[10px] text-red-400" >
                        Once you have completed your vote, you will not be able to get a refund. This is to ensure the fairness and transparency of the voting process.
                      </div>
                      <div></div>
                      <div className="popup-text mt-5" >
                        <input type="number" className=" bg-[#F5F5F5] vote-input w-full rounded-3xl ps-10 p-5 " value={query} onChange={handleChangeComment}
                          onKeyDown={handleKeyDown} placeholder="Please input number" required />
                      </div>
                    </div>
                    <div className="flex items-center justify-center gap-6 mb-[12px] mt-20">
                      <div

                        className="w-full text-3xl text-center hover:text-blue-400 cursor-pointer"
                      >
                        <button
                          className="popup-btn  w-full"
                          onClick={async () => { handlerClosePopup(); }}
                          style={{ borderRadius: "10px !important" }}
                        >
                          Cancel
                        </button>
                      </div>
                      <button onClick={async () => { handlerVote(eventId, parseFloat(query), type) }} className="w-full ">
                        <div className="text-3xl text-center hover:text-blue-400 cursor-pointer">
                          <button
                            className="popup-btn  w-full"
                            disabled={loading}
                          >
                            {loading ? 'Loading...' : 'Submit'}
                            <img className="w-[24px] h-[24px]" src={pools}
                              alt="img" />
                          </button>
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupVote;


