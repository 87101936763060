
import { eventsImg, logos, slidebarImg } from "helpers/ExportImg";
import { EventCardWrapper } from "./styled";

const CardTopPrizeDay: any = ({ eventData }: any) => {

  const { joinCounter, pointCondition, eventDetail, userPoint  } = eventData;
  const { pools } = slidebarImg;

  let cache: any[] = []

  const getDetailByRank = (rank: number) => {
    if (cache[rank]) return cache[rank];
    cache[rank] = eventDetail?.find((a: { rank: number; }) => a.rank === rank);
    return cache[rank];
  }

  return (
    <>
      <EventCardWrapper>
        <div className="inline-block w-full rounded-xl ">
          <div className="flex flex-col m-auto overflow-hidden rounded-xl " >
            <div className="flex   m-auto mt-5">
              {getDetailByRank(2) && (<div className="flex flex-col justify-end ">
                <div className="mb-10" >
                  <img className="m-auto" src={eventsImg.iconTop2}
                    alt="img" />
                </div>
                <div className="flex justify-center mb-2">
                  <div className=" top-rize-number flex">{getDetailByRank(2).reward}</div>
                  <div className="ml-2 items-center" >
                    <img className="w-[24px] h-[24px]" src={pools}
                      alt="img" />
                  </div>
                </div>
                <div className="flex event-info">
                  <div className="" >
                    <img className="" src={eventsImg.top2}
                      alt="img" />
                  </div>
                </div>
              </div>)}
              {getDetailByRank(1) && (<div className="flex flex-col  justify-end ">
                <div className="mb-10" >
                  <img className="m-auto" src={eventsImg.iconTop1}
                    alt="img" />
                </div>
                <div className="flex justify-center">
                  <div className="flex items-center mb-2">
                    <div className=" top-rize-number flex">{getDetailByRank(1).reward}</div>
                    <div className="ml-2 items-center" >
                      <img className="w-[24px] h-[24px]" src={pools}
                        alt="img" />
                    </div>
                  </div>
                </div>
                <div className="flex event-info">
                  <div className="" >
                    <img className="" src={eventsImg.top1}
                      alt="img" />
                  </div>
                </div>

              </div>)}
              {getDetailByRank(1) && (<div className="flex flex-col  justify-end ">
                <div className="mb-10" >
                  <img className="m-auto" src={eventsImg.iconTop3}
                    alt="img" />
                </div>
                <div className="flex justify-center">
                  <div className="flex items-center  mb-2">
                    <div className=" top-rize-number flex">{getDetailByRank(3).reward}</div>
                    <div className="ml-2 items-center" >
                      <img className="w-[24px] h-[24px]" src={pools}
                        alt="img" />
                    </div>
                  </div>
                </div>
                <div className="flex event-info">
                  <div className="" >
                    <img className="" src={eventsImg.top3}
                      alt="img" />
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
     
      </EventCardWrapper>
    </>
  );
};

export default CardTopPrizeDay;