import { ButtonNotice } from "components/button";
import { NoticeStyle } from "./styled";
import icon from "../../asset/notice/icon.png";

import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Notice() {
  return (
    <NoticeStyle>
      <div className="notice">
        <div className="notice-header">
          <ButtonNotice name={"Announment"} />
          <div className="notice-search">
            <SearchIcon className="notice-search_icon" />
            <input type="text" placeholder="Search" />
          </div>
        </div>
        <div className="notice-content">
          <h4 className="notice-content_title">Notice to all</h4>
          <div className="notice-content_info">
            <div className="notice-content_info__main">
              <div className="notice-content_info__img">
                <img src={icon} alt="icon notice" />
              </div>
              <div className="notice-content_info__desc">
                [Update] Portfolio main screen, function improvement
                (2023.01.05)
              </div>
            </div>
            <div className="notice-content_info__main">
              <div className="notice-content_info__img">
                <img src={icon} alt="icon notice" />
              </div>
              <div className="notice-content_info__desc">
                Announcement of customer center operating hours in January
              </div>
            </div>
            <div className="notice-content_info__main">
              <div className="notice-content_info__img">
                <img src={icon} alt="icon notice" />
              </div>
              <div className="notice-content_info__desc">
                Personal information processing policy revision notice
                (2022.08.26)
              </div>
            </div>
            <div className="notice-content_info__main">
              <div className="notice-content_info__img">
                <img src={icon} alt="icon notice" />
              </div>
              <div className="notice-content_info__desc">
                Notice of penalty policy (Effective date: July 4)
              </div>
            </div>
            <div className="notice-content_info__main">
              <div className="notice-content_info__img">
                <img src={icon} alt="icon notice" />
              </div>
              <div className="notice-content_info__desc">
                Personal information processing policy revision notice
                (2022.01.07)
              </div>
            </div>
            <div className="notice-content_info__main">
              <div className="notice-content_info__img">
                <img src={icon} alt="icon notice" />
              </div>
              <div className="notice-content_info__desc">
                Message impersonating Game Center, beware!
              </div>
            </div>
            <div className="notice-content_info__viewall">
              <span>View all 20 documents</span>
              <ExpandMoreIcon className="arrow_down" />
            </div>
          </div>
        </div>
      </div>
    </NoticeStyle>
  );
}

export default Notice;
