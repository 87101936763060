import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { popupInfoState } from "interfaces";


const initialState: popupInfoState = {
  show: false,
  title: '',
  description: ''
};

const popupInfoSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPopupInfoState: (state: popupInfoState, action: PayloadAction<popupInfoState>) => {
      return {
        ...state,
        description: action.payload.description,
        show: action.payload.show,
        title: action.payload.title
      };
    },
    setPopupInfoShowState: (state: popupInfoState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        show: action.payload,
      };
    },
  },
});

export const {
  setPopupInfoState,
  setPopupInfoShowState
} = popupInfoSlice.actions;

export const popupInfoReducer = popupInfoSlice.reducer;
