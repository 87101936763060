import styled from "styled-components";

export const Result = styled.section`
  width: 70%;
  margin: 10px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .result-table-container {
    border-radius: 8px;
    overflow-x: auto;
  }

  .result-table-head {
    background: #00896f;
    border-radius: 10px;
  }

  .result-table-row-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 49px;
    color: #ffffff;
  }

  .result-table-title {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 53px;
    color: #161616;
  }

  .result-table-bot {
    border: 3px solid #00896f;
  }

  .result-bot {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 85%;
    column-gap: 100px;
    row-gap: 50px;
    margin-top: 50px;
  }

  .result-bot-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    max-width: 227px;

    background: #00896f;
    border-radius: 10px 10px 0px 0px;
    margin-left: 35px;
  }

  .result-bot-item {
    width: calc(100% / 2 - 60px);

    position: relative;
  }

  .result-bot-score {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    max-width: 448px;

    background: #ffffff;
    border: 2.97616px solid #00896f;
    box-shadow: 0px 4.46424px 0px #00896f;
    border-radius: 7.44041px;
  }

  .result-bot-score-num {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 61px;
    color: #00896f;
  }

  .result-bot-score-tit {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 55px;
    color: #00896f;
  }

  .result-bot-img {
    position: absolute;
    left: 0;
    top: 60%;
    transform: translate(-50%, -50%);
  }

  .result-bot-img {
    max-width: 125px;
    max-height: 95px;
  }

  .result-bot-img img {
    width: 100%;
    height: 100%;
  }

  .wrapper {
    width: 100%;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
  }

  .cart-chead {
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.6);
  }

  .cart-ctr {
    grid-template-columns: 2.5fr 2fr 2fr;
    display: grid;
    align-items: center;
    background-color: var(--white-color);
    padding: 10px 15px;
    border-radius: 5px;
    gap: 20px;
    text-align: center;
  }

  .cart-ctxt {
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cart-cbody {
    border-radius: 3px;
    font-size: 14.5px;
    color: rgba(0, 0, 0, 0.6);
  }

  .qty-change {
    display: flex;
    align-items: center;

    .qty-decrease,
    .qty-increase {
      font-size: 13px;
      width: 28px;
      height: 28px;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .qty-value {
      width: 36px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .result-top {
    background: #00896f;
    border-radius: 10px;
    width: 100%;
  }

  .title-top {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 49px;
    color: #ffffff;
  }

  .title-bot {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 53px;
    color: #161616;
  }

  .result-bot-1 {
    border: 2px solid #00896f;
    border-top: none;
    border-radius: 5px;
    width: 95%;
    margin:auto;
  }

  @media (max-width: 1700px) {
    .container {
      width: 1200px;
    }
  }

  @media (max-width: 1566px) {
    .result-bot-item {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    .cart-cth {
      span{
        font-size:20px;
        line-height: 100%;
      }
    }
  }
 

  @media (max-width: 600px) {
    width: 80%;
    .result-bot-score-num {
      font-size: 25px;
    }

    .result-bot-score-tit {
      font-size: 20px;
    }

    .result-bot-score {
      min-width: 290px;
    }

    .container {
      width: 690px;
    }

    .title-top {
      font-size: 18px;
    }

    .title-bot {
      font-size: 15px;
    }
    .cart-cth {
      span{
        font-size:12px;
        line-height: 100%;
      }
    }
  }

  /* @media screen and (min-width: 320px) and (max-width: 767px) {
    transform: translateY(55%);
  }
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    transform: translateY(55%);
  } */
`;
