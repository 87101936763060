import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HistoryIcon from "@mui/icons-material/History";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Rating } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import avatar from "asset/account/avatar.png";
import bgAccount from "asset/account/bg-account.png";
import control from "asset/account/control.png";
import fire from "asset/account/fire.png";
import like from "asset/account/like.png";
import note from "asset/account/note.png";

import LoadingIcon from "components/LoadingIcon";
import PaginationGame from "components/Pagination";
import { discover, openSourcePath } from "constants/path";
import useWindowSize from "hooks/useWindowSize";
import moment from "moment";
import {
  getHistoryDownload,
  getHistoryGame,
  getRatedUser,
} from "pages/services/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./account.css";

const AccountPage = () => {
  const [page, setPage] = useState(1);
  const handleChagePage = (value: number) => {
    setPage(value);
  };
  const { wallet, user, accessToken } = useSelector((state: any) => state.auth);

  const width: any = useWindowSize();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentTag, setCurrentTag] = useState("recently");
  const [count, setCount] = useState(1);

  const [recentlyGames, setRecentlyGames] = useState<any>();
  const [ratedGames, setRatedGames] = useState<any>();
  const [downloadGames, setDownloadGames] = useState<any>();

  const account = user?.email;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    const params = {
      limit: 12,
      page: page,
    };

    if (!account) {
      setRatedGames(null);
      setRecentlyGames(null);
      setDownloadGames(null);
    } else {
      getRatedUser(params, accessToken).then((response: any) =>
        setRatedGames(response)
      );
      getHistoryGame(params).then((response: any) =>
        setRecentlyGames(response)
      );
      getHistoryDownload(accessToken).then((response: any) =>
        setDownloadGames(response)
      );
    }

    if (currentTag === "recently") {
      setCount(Math.ceil(recentlyGames?.total / params.limit));
    } else if (currentTag === "rated") {
      setCount(Math.ceil(ratedGames?.total / params.limit));
    } else if (currentTag === "download") {
      setCount(Math.ceil(downloadGames?.total / params.limit));
    }
  }, [
    account,
    page,
    count,
    currentTag,
    recentlyGames?.total,
    ratedGames?.total,
    downloadGames?.total,
    accessToken,
  ]);

  const timeStart = moment(user?.createdAt, "YYYYMMDD").fromNow();
  const onlineTime = moment(user?.onlineDate, "YYYYMMDD").fromNow();

  //load more
  const PER_PAGE = 12;
  const [recentlyPlayed, setRecentlyPlayed] = useState<any>([]);
  const [ratedGamesLoadMore, setRateGamesLoadMore] = useState<any>([]);

  const [flagCount, setFlagCount] = useState<number>(0);
  const [flagCountRated, setFlagCountRated] = useState<number>(0);

  const [loading, setLoading] = useState<any>(false);
  const [loadingRated, setLoadingRated] = useState<any>(false);

  const [hasMore, setHasMore] = useState<any>(true);
  const [hasMoreRated, setHasMoreRated] = useState<any>(true);

  const [offset, setOffset] = useState<any>(0);
  const [offsetRated, setOffsetRated] = useState<any>(0);

  const totalCountRecently = recentlyGames?.total;
  const totalCountRated = ratedGames?.total;

  const isEmptyRecentlyPlayed = !recentlyPlayed || recentlyPlayed.length === 0;
  const isEmptyRated = !ratedGamesLoadMore || ratedGamesLoadMore.length === 0;

  const fetchRecentlyPlayed = async () => {
    if (loading) return;
    setLoading(true);
    const newOffset = offset + 1;

    const params = { page: newOffset, limit: PER_PAGE };

    try {
      const response = await getHistoryGame(params);
      if (response && response.data) {
        const newListGame = [...recentlyPlayed, ...response.data];
        setFlagCount(flagCount + newListGame.length);

        if (flagCount >= totalCountRecently) {
          setHasMore(false);
        }

        setRecentlyPlayed(newListGame);

        setOffset(newOffset);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const fetchRated = async () => {
    if (loadingRated) return;
    setLoadingRated(true);
    const newOffset = offsetRated + 1;

    const params = { page: newOffset, limit: PER_PAGE };

    try {
      const response = await getRatedUser(params, accessToken);
      if (response && response.data) {
        const newListGame = [...ratedGamesLoadMore, ...response.data];
        setFlagCountRated(flagCountRated + newListGame.length);

        if (flagCountRated >= totalCountRated) {
          setHasMoreRated(false);
        }

        setRateGamesLoadMore(newListGame);

        setOffsetRated(newOffset);
      }
    } catch (error) {
      console.log(error);
    }

    setLoadingRated(false);
  };

  useEffect(() => {
    // fetchRecentlyPlayed();
    // fetchRated();
  }, []);

  const HandleRate = ({ game }: any) => {
    const rateCount = game?.nftGame
      ? game?.nftGame?.rateCount
      : game?.miniGame?.rateCount;
    const rateSum = game?.nftGame
      ? game?.nftGame?.rateSum
      : game?.miniGame?.rateSum;

    const result = Number(rateSum / rateCount);

    return (
      <Rating
        name="read-only"
        value={result ? result : 5}
        className="rating"
        readOnly
      />
    );
  };

  const Info = () => {
    const navigate = useNavigate();
    return (
      <div className="wrapper-info">
        <div className="account-info">
          <img
            src={account?.avatar != null ? account.avatar : avatar}
            alt="avatar"
            className="avatar-account"
          />
          <div className="info-account">
            <div className="name-login">
              {/* {account ? <span>{account?.displayName}</span> : <GoogleButton />} */}
              {account?.displayName}
            </div>
            <div
              // onClick={() => navigate("/point-history")}
              className="info-point"
            >
              {user?.pointPool?.point?.toLocaleString("en-US") || 0} points
            </div>
          </div>
        </div>
        <div className="wrapper-status">
          <div className="status-content">
            <div className="item-status">
              <img src={note} alt="note" />
              <div className="content-stt">
                <span>Member for</span>
                <span className="text-primary">{account ? timeStart : 0}</span>
              </div>
            </div>
            <div className="item-status">
              <img src={control} alt="note" />
              <div className="content-stt">
                <span>Games played</span>
                <span className="text-primary">
                  {account ? recentlyGames?.data?.length : 0}
                </span>
              </div>
            </div>
            <div className="item-status">
              <img src={like} alt="note" />
              <div className="content-stt">
                <span>Game rated</span>
                <span className="text-primary">
                  {account ? ratedGames?.data?.length : 0}
                </span>
              </div>
            </div>
            <div className="item-status" style={{ border: "none" }}>
              <img src={fire} alt="note" />
              <div className="content-stt">
                <span>Playstreak</span>
                <span className="text-primary">{account ? onlineTime : 0}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Status = () => {
    return (
      <div className="wrapper-status">
        <h1>Status</h1>
        <div className="status-content">
          <div className="item-status">
            <img src={note} alt="note" />
            <div className="content-stt">
              <span>Member for</span>
              <span className="text-primary">{account ? timeStart : 0}</span>
            </div>
          </div>
          <div className="item-status">
            <img src={control} alt="note" />
            <div className="content-stt">
              <span>Games played</span>
              <span className="text-primary">
                {account ? recentlyGames?.data?.length : 0}
              </span>
            </div>
          </div>
          <div className="item-status">
            <img src={like} alt="note" />
            <div className="content-stt">
              <span>Game rated</span>
              <span className="text-primary">
                {account ? ratedGames?.data?.length : 0}
              </span>
            </div>
          </div>
          <div className="item-status">
            <img src={fire} alt="note" />
            <div className="content-stt">
              <span>Playstreak</span>
              <span className="text-primary">{account ? onlineTime : 0}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Tags = () => {
    const switchTag = (tag: any) => {
      setCurrentTag(tag);
      setPage(1);
    };

    return (
      <div className="wrapper-tags">
        <div className="tags">
          <div
            className={
              currentTag === "recently"
                ? "tag-item tag-item-active"
                : "tag-item"
            }
            onClick={() => switchTag("recently")}
          >
            Recently Played
          </div>
          {/* <div
            className={
              currentTag === "rated" ? "tag-item tag-item-active" : "tag-item"
            }
            onClick={() => switchTag("rated")}
          >
            Rated Games
          </div>
          <div
            className={
              currentTag === "download"
                ? "tag-item tag-item-active"
                : "tag-item"
            }
            onClick={() => switchTag("download")}
          >
            Downloads
          </div> */}
        </div>
        {currentTag === "recently" ? (
          recentlyGames?.total > 0 ? (
            <div className="wrapper-card-account">
              {width > 1000 ? (
                <>
                  {recentlyGames?.data?.map((game: any) => (
                    <div
                      onClick={() => {
                        if (game.gameType === "game") {
                          const nameRep = game?.game?.name
                            .replaceAll(" ", "-")
                            .toLowerCase();
                          navigate(
                            `${openSourcePath}/${nameRep}/${game?.game?.id}`
                          );
                        } else {
                          navigate(`${discover}/${game?.id}`);
                        }
                      }}
                      key={game?.id}
                      className="card-game-account card-pointer w-full"
                    >
                      <img
                        src={
                          game.gameType === "game"
                            ? game.game.thumbnail
                            : game?.nftGame
                            ? game?.nftGame.images[1]
                            : game.miniGame?.image
                        }
                        alt="game"
                      />
                      <div className="content-card-account">
                        <span className="content-card-account-title">
                          {game.gameType === "game"
                            ? game.game.name
                            : game?.nftGame
                            ? game?.nftGame?.name
                            : game.miniGame?.name}
                        </span>
                        {/* <span>Playground</span> */}
                        <span className="genre-account">
                          {game?.nftGame
                            ? "NFT Game"
                            : game?.miniGame?.categories[0]?.name}
                        </span>
                        <HandleRate game={game} />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {loading && <LoadingIcon />}
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={fetchRecentlyPlayed}
                    hasMore={hasMore}
                    loader={<div className="loader"> Loading... </div>}
                    // useWindow={false}
                    threshold={350}
                    // initialLoad={true}
                  >
                    <div
                      className="flex-center cards-free-game wrapper-cards m-auto"
                      style={{ margin: "auto" }}
                    >
                      {!isEmptyRecentlyPlayed &&
                        recentlyPlayed?.map((game: any, id: any) => (
                          <div
                            onClick={() => {
                              if (game.gameType === "game") {
                                const nameRep = game?.game?.name
                                  .replaceAll(" ", "-")
                                  .toLowerCase();
                                navigate(
                                  `${openSourcePath}/${nameRep}/${game?.game?.id}`
                                );
                              } else {
                                navigate(`${discover}/${game?.id}`);
                              }
                            }}
                            key={id}
                            className="card-game-account card-pointer w-full"
                          >
                            <img
                              src={
                                game.gameType === "game"
                                  ? game.game.thumbnail
                                  : game?.nftGame
                                  ? game?.nftGame.images[1]
                                  : game.miniGame?.image
                              }
                              alt="game"
                            />
                            <div className="content-card-account">
                              <span className="content-card-account-title">
                                {game.gameType === "game"
                                  ? game.game.name
                                  : game?.nftGame
                                  ? game?.nftGame?.name
                                  : game.miniGame?.name}
                              </span>
                              {/* <span>Playground</span> */}
                              <span className="genre-account">
                                {game?.nftGame
                                  ? "NFT Game"
                                  : game?.miniGame?.categories[0]?.name}
                              </span>
                              <HandleRate game={game} />
                            </div>
                          </div>
                        ))}
                    </div>
                  </InfiniteScroll>
                </>
              )}
            </div>
          ) : (
            <div className="empty-content">
              <HistoryIcon className="icon-empty" />
              <span className="title-empty">
                Your recently played games will appear here
              </span>
              <span>
                Play some games if you like them, gives them a thumb up!
              </span>
            </div>
          )
        ) : currentTag === "rated" ? (
          ratedGames?.total > 0 ? (
            <div className="wrapper-card-account">
              {width > 1000 ? (
                <>
                  {ratedGames?.data?.map((game: any) => (
                    <div key={game?.id} className="card-game-account">
                      <img
                        src={
                          game?.nftGame
                            ? game?.nftGame?.images[1]
                            : game?.miniGame?.image
                        }
                        alt="game"
                      />
                      <div className="content-card-account">
                        <span className="content-card-account-title">
                          {game?.nftGame
                            ? game?.nftGame?.name
                            : game?.miniGame?.name}
                        </span>
                        {/* <span>Playground</span> */}
                        {/* <span className="type-games">{"Playground"}</span> */}
                        <span className="genre-account">
                          {game?.nftGame
                            ? "NFT Game"
                            : game?.miniGame?.categories[0]?.name}
                        </span>
                        <HandleRate game={game} />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={fetchRated}
                    hasMore={hasMoreRated}
                    loader={<div className="loader"> Loading... </div>}
                    // useWindow={false}
                    threshold={350}
                    // initialLoad={true}
                  >
                    <div
                      className="flex-center cards-free-game wrapper-cards m-auto"
                      style={{ margin: "auto" }}
                    >
                      {!isEmptyRated &&
                        ratedGamesLoadMore?.map((game: any) => (
                          <div key={game?.id} className="card-game-account">
                            <img
                              src={
                                game?.nftGame
                                  ? game?.nftGame?.images[1]
                                  : game?.miniGame?.image
                              }
                              alt="game"
                            />
                            <div className="content-card-account">
                              <span className="content-card-account-title">
                                {game?.nftGame
                                  ? game?.nftGame?.name
                                  : game?.miniGame?.name}
                              </span>
                              {/* <span>Playground</span> */}
                              {/* <span className="type-games">{"Playground"}</span> */}
                              <span className="genre-account">
                                {game?.nftGame
                                  ? "NFT Game"
                                  : game?.miniGame?.categories[0]?.name}
                              </span>
                              <HandleRate game={game} />
                            </div>
                          </div>
                        ))}
                    </div>
                  </InfiniteScroll>
                </>
              )}
            </div>
          ) : (
            <div className="empty-content">
              <ThumbUpOffAltIcon className="icon-empty" />
              <span className="title-empty">
                Your rated games will appear here
              </span>
              <span>
                Play some games if you like them, gives them a thumb up!
              </span>
            </div>
          )
        ) : downloadGames?.total > 0 ? (
          <div className="wrapper-card-account">
            {downloadGames?.data.map((game: any) => (
              <div key={game?.game?.id} className="card-game-account">
                <img src={game?.game?.images[1]} alt="game" />
                <div className="content-card-account">
                  <span className="content-card-account-title">
                    {game?.game?.name}
                  </span>
                  {/* <span>Playground</span> */}
                  <span className="genre-account">"NFT Game"</span>
                  <HandleRate game={game} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="empty-content">
            <CloudUploadIcon className="icon-empty" />
            <span className="title-empty">
              Your download games will appear here
            </span>
            <span>
              Play some games if you like them, gives them a thumb up!
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container-account">
      {isLoading && <LoadingIcon />}
      <img src={bgAccount} alt="control-game" className="control-game" />
      <Info />
      {/* <Status /> */}
      <Tags />
      {count > 1 && (
        <div className="pagination-account">
          {width > 1000 ? (
            <PaginationGame
              count={count}
              page={page}
              setPage={handleChagePage}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default AccountPage;
