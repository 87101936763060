import { logos } from "helpers/ExportImg";
import { useEffect, useState } from "react";
import { PopupTag } from "./styled";
import { useAppDispatch, useAppSelector } from "index";
import { setPopupPlayShowState } from "slices/popupPlaySlice";
import { Link } from "react-router-dom";
import { discover, openSourcePath } from "constants/path";
import { addGameToHistory } from "pages/services/api";




const PopupPlay = (props: any) => {
  const dispatchApp = useAppDispatch()

  const popupPlayState = useAppSelector(state => state.popupPlayState);

  const handlerClosePopup = () => {
    dispatchApp(setPopupPlayShowState(!popupPlayState.show));
  };
  const [nameRep, setNameRep] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    if (popupPlayState && popupPlayState.game.name) setNameRep(popupPlayState.game.name.replaceAll(" ", "-").toLowerCase());
    let linkGame = `${openSourcePath}/${nameRep}/${popupPlayState.game.id}`
    if(popupPlayState.gameType === 'minigame') linkGame = `${discover}/${popupPlayState.game.id}`
    setLink(linkGame)
  }, [nameRep, popupPlayState, popupPlayState.game, props.type]);

  return (
    <PopupTag>

      <>
        {popupPlayState.show && (
          <>
            <div onClick={handlerClosePopup} className={`backdrop`}></div>
            <div className="popup-img">
              <div className={`popup img-mobile`}>
                <>
                  <div className="bg-[#F0F0F0] rounded-3xl p-14 min-w-[80%]">
                    <div className="inline-block  divide-y divide-[#E0E0E0]">
                      <div className="flex items-center w-[284px] h-[76px]   overflow-hidden rounded-lg " >
                        <div className="flex-shrink-0 ml-4">
                          <img className="w-[60px] h-[60px] rounded-lg" src={popupPlayState.game.image || popupPlayState.game.thumbnail} alt="img" />
                        </div>
                        <div className="flex-1 min-w-0 ms-4 ml-6">
                          <p className="game-card-new-title">
                            {popupPlayState.game.name}
                          </p>
                          <div className="game-card-new-play-count flex  items-center	">

                            {popupPlayState && popupPlayState.game && popupPlayState.game.categories[0] && popupPlayState.game.categories[0].name && (<div className="ml-2 game-card-new-play-count-text flex"> {popupPlayState.game.categories[0].name}</div>)}
                          </div>
                        </div>
                      </div>
                      <div></div>


                    </div>
                    <div>
                      {popupPlayState && popupPlayState.game && popupPlayState.game.game_mission && (<>

                        <div className="popup-title text-center mt-5 pt-5" >
                          Mission
                        </div>
                        <div className="relative w-full items-center  ">
                          <div className="bg-gradient-to-r top-[45%] h-[5px] w-[210px] ml-auto mr-auto left-0 right-0 absolute from-yellow-600 z-10"></div>
                          <div className="flex ">
                            <span className="mt-5 popup-sc">sc:</span>
                            {popupPlayState.game.game_mission?.map((mission: any) => (
                              <div className=" relative z-20  w-full gap-1"               >
                                <div className=" flex flex-col p-3 items-center text-center gap-2 "    >

                                  <div className="popup-text">{mission.pointToPass}</div>
                                  <div> <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={26}
                                    height={25}
                                    fill="none"
                                  >
                                    <path
                                      fill="url(#a)"
                                      d="M19.126 24.717c-.29 0-.575-.066-.835-.19L12.56 22.07 6.83 24.52a1.946 1.946 0 0 1-1.636.017 1.937 1.937 0 0 1-1.053-1.2 1.923 1.923 0 0 1-.066-.812l.58-6.224-4.118-4.625a1.926 1.926 0 0 1 1.038-3.228l6.097-1.475 3.186-5.25a1.931 1.931 0 0 1 1.7-1.006 1.94 1.94 0 0 1 1.7 1.005l3.187 5.251 6.099 1.47a1.937 1.937 0 0 1 1.488 1.307 1.92 1.92 0 0 1-.451 1.926l-4.116 4.625.58 6.224a1.922 1.922 0 0 1-1.117 2.02 1.941 1.941 0 0 1-.802.172Z"
                                    />
                                    <g filter="url(#b)">
                                      <path
                                        fill="url(#c)"
                                        d="m18.941 23.676-6.04-2.582a.586.586 0 0 0-.557 0l-6.032 2.582a.543.543 0 0 1-.786-.57l.612-6.556a.597.597 0 0 0-.17-.525l-4.344-4.886a.54.54 0 0 1 .3-.92l6.418-1.546a.591.591 0 0 0 .444-.326l3.355-5.525a.54.54 0 0 1 .775-.222.54.54 0 0 1 .2.222l3.351 5.525a.59.59 0 0 0 .446.325l6.416 1.545a.542.542 0 0 1 .447.663.54.54 0 0 1-.147.257l-4.343 4.886a.595.595 0 0 0-.17.526l.61 6.556a.538.538 0 0 1-.494.63.543.543 0 0 1-.291-.06Z"
                                      />
                                    </g>
                                    <defs>
                                      <linearGradient
                                        id="a"
                                        x1={12.562}
                                        x2={12.562}
                                        y1={0.717}
                                        y2={24.717}
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#ECA600" />
                                        <stop offset={1} stopColor="#FFDE01" />
                                      </linearGradient>
                                      <linearGradient
                                        id="c"
                                        x1={2.214}
                                        x2={31.212}
                                        y1={1.625}
                                        y2={25.361}
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#ECA600" />
                                        <stop offset={0.443} stopColor="#F4BF00" />
                                        <stop offset={1} stopColor="#FFDE01" />
                                      </linearGradient>
                                      <filter
                                        id="b"
                                        width={28.074}
                                        height={26.962}
                                        x={-1.41}
                                        y={-0.354}
                                        colorInterpolationFilters="sRGB"
                                        filterUnits="userSpaceOnUse"
                                      >
                                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.436} />
                                        <feComposite
                                          in2="SourceAlpha"
                                          operator="in"
                                          result="effect1_backgroundBlur_556_15667"
                                        />
                                        <feBlend
                                          in="SourceGraphic"
                                          in2="effect1_backgroundBlur_556_15667"
                                          result="shape"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          result="hardAlpha"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        />
                                        <feOffset dy={0.718} />
                                        <feGaussianBlur stdDeviation={0.718} />
                                        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                        <feBlend in2="shape" result="effect2_innerShadow_556_15667" />
                                      </filter>
                                    </defs>
                                  </svg></div>
                                  <div className=" flex items-center" >  <div className="popup-text">{mission.pointReward ? mission.pointReward : '0'}</div><div className="ml-1"> <img className="w-8 h-8 " src={logos.currency}
                                    alt="img" /></div></div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>)}

                      <Link className="popup-btn mt-5 w-full" onClick={async () => { handlerClosePopup(); await addGameToHistory({ gameId: popupPlayState.game.id, gameType: "game" }) }} to={link}>Play</Link>


                    </div>

                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupPlay;
