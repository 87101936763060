export const API_URL = process.env.REACT_APP_API_DOMAIN;

export const CMS_BANNER = process.env.REACT_APP_CMS_BANNER;
export const API_CMS_KEY = process.env.REACT_APP_API_CMS_KEY;
export const FOLDER_BANNER = process.env.REACT_APP_FOLDER_BANNER;

const CONTRAC_VOTE = process.env.REACT_APP_CONTRAC_VOTE ;


const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 97;

const METAMASK_DOMAIN = process.env.REACT_APP_METAMASK_DOMAIN;

export const config = {
  CHAIN_ID,
  METAMASK_DOMAIN,
  CONTRAC_VOTE ,
};


