import * as React from "react"
const PrivacyPolicyIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <path
    d="M9.99967 18.3334C9.99967 18.3334 16.6663 15.0001 16.6663 10.0001V4.16675L9.99967 1.66675L3.33301 4.16675V10.0001C3.33301 15.0001 9.99967 18.3334 9.99967 18.3334Z"
    stroke="#626262"
    strokeWidth={1.5}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>
)
export default PrivacyPolicyIcon
