import { buyEventTicket, getEventById, getSignature } from "pages/services/api";
import { useEffect, useState } from "react";
import { wealthyAbi } from "web3/contract/wealthyAbi";
import { PopupTag } from "./styled";


import { prepareWriteContract, writeContract } from '@wagmi/core';
import { toast } from "react-toastify";
import { eventsImg, slidebarImg } from "helpers/ExportImg";



const PopupBuyTicket = ({ show, setShow, eventId, setShowPopupTicket, setTicketNumber , setHistoryTicket}: any) => {

  const [loading, setLoading] = useState(false)

  const handlerClosePopup = () => {
    setShow(false)
  };

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === 'Enter') {
    }
  }

  const fetchApiEvent = async () => {
    try {
      setLoading(true);
      const data = await getEventById(eventId);
      setHistoryTicket(data?.history?.data)
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await buyEventTicket(eventId);
      setLoading(false);
      setShow(false)
      setTicketNumber(data.ticketNumber)
      fetchApiEvent()
      setShowPopupTicket(true)
    } catch (err: any) {
      console.log()
      toast.error(err?.message || '');
      setShow(false)
      setLoading(false);
    }
  };

  const handlerBuy = async (eventId: number) => {
    fetchApi()
  }

  useEffect(() => {

  }, []);

  return (
    <PopupTag>
      <>
        {show && (
          <>
            <div onClick={handlerClosePopup} className={`backdrop`}></div>
            <div className="">
              <div className={`popup img-mobile w-10/12  min-w-[80%]`}>
                <>
                  <div className="bg-[#FFF] rounded-3xl p-14  w-full">
                    <div className="p-5">
                      <div className="popup-title">
                        Confirmation popup
                      </div>
                      <div className=" mt-5" >
                        <img className="m-auto" src={eventsImg.ticket1}
                          alt="img" />
                      </div>
                    </div>
                    <div  className="text-center popup-text">Are you sure buy ticket?</div>
                    <div className="flex items-center justify-center gap-6 mb-[12px] mt-10">
                      <div

                        className="w-full text-3xl text-center hover:text-blue-400 cursor-pointer"
                      >
                        <button
                          className="popup-btn-border w-full"
                          onClick={async () => { handlerClosePopup(); }}
                          style={{ borderRadius: "10px !important" }}
                        >
                          No
                        </button>
                      </div>
                      <button onClick={async () => { handlerBuy(eventId) }} className="w-full ">
                        <div className="text-3xl text-center hover:text-blue-400 cursor-pointer">
                          <button
                            className="popup-btn  w-full"
                            disabled={loading}
                          >
                            {loading ? 'Loading...' : 'Yes'}

                          </button>
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupBuyTicket;


