import styled from "styled-components";

export const MiniGame = styled.section`
  // width: var(--width_content_home);
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: aliceblue; */
  flex-direction: column;
  overflow-x: hidden;
  margin: 4rem 0;

  .btn-backhome {
    width: 87%;
    display: flex;
    justify-content: flex-start;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`;
