import { popup } from "helpers/ExportImg";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { PopupTag } from "./styled";
import { deleteAllUserNotification } from "pages/services/api";




const PopupAlert = ({ open, setOpen,resetGetNoti }: any) => {


  const handlerClosePopup = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState<boolean>(false);




  const fetchApi = async () => {
    try {
      setLoading(true);
      await deleteAllUserNotification();
      setLoading(false);
      setOpen(false);
      resetGetNoti()
    } catch (err) {
      setLoading(false);
      setOpen(false);
    }
  };


  return (
    <PopupTag>
      <>
        {open && (
          <>
            <div onClick={handlerClosePopup} className={`backdrop`}></div>
            <div className="popup-img">
              <div className={`popup img-mobile w-10/12 min-w-[80%]`}>
                <>
                  <div className="bg-[#F0F0F0] w-full rounded-3xl p-14">

                    <div>
                      <div className="popup-title text-center mt-5 pt-5" >
                        Notice
                      </div>
                      <div className="popup-text text-center mt-5 pt-5" >
                        Are you sure clear all items here?
                      </div>
                      <div className="flex items-center justify-center gap-5 mt-5">
                        <button onClick={handlerClosePopup} className="popup-btn-no mt-5 w-full">
                          No
                        </button>
                        <button onClick={fetchApi} className="popup-btn mt-5 w-full">
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupAlert;
