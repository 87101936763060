import { useSearchParams } from "react-router-dom";

const SaveAccessTokenPage = () => {
  const [searchParams] = useSearchParams();
  const accesToken = searchParams.get("accessToken");
  localStorage["accessToken"] = accesToken;
  window.close();

  return <h1>Loading...</h1>;
};

export default SaveAccessTokenPage;
