import { FC, useRef, useState } from "react";
import { resultGameImg } from "helpers/ExportImg";
import { Result } from "./styled";
import moment from "moment";
import { useEffect } from "react";
import CountDown from "components/countDown/CountDown";
import {
  getUserGameMission,
  getUserGameMissionPoint,
} from "pages/services/api";
import { useSelector } from "react-redux";

type GameOpenSource = {
  isFullScreen?: boolean;
  game?: any;
};

const ResultGame: FC<GameOpenSource> = ({ isFullScreen, game }) => {
  const { cup, king, medal, hourglass } = resultGameImg;
  const [dataPoitReward, setDataPointReward] = useState<any>();

  const { user } = useSelector((state: any) => state.auth);

  const fetchDataPointReward = async () => {
    try {
      const res = await getUserGameMissionPoint(game?.id);
      setDataPointReward(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataPointReward();
  }, [game?.id, user?.pointPool?.point]);

  return (
    <>
      {isFullScreen ? (
        ""
      ) : (
        <>
          <Result>
            <div className="wrapper">
              <div className="">
                <div className="cart-chead result-top">
                  <div className="cart-ctr">
                    <div className="cart-cth">
                      <span className="cart-ctxt title-top">Game:</span>
                    </div>
                    <div className="cart-cth">
                      <span className="cart-ctxt title-top">Reset time:</span>
                    </div>
                    <div className="cart-cth">
                      <span className="cart-ctxt title-top">Mission:</span>
                    </div>
                  </div>
                </div>
                <div className="cart-chead result-bot-1">
                  <div className="cart-ctr">
                    <div className="cart-cth">
                      <span className="cart-ctxt title-bot">{game?.name}</span>
                    </div>
                    <div className="cart-cth">
                      <span className="cart-ctxt title-bot">
                        <CountDown />
                      </span>
                    </div>
                    <div className="cart-cth">
                      {dataPoitReward?.missionPass === null ? (
                        <span className="cart-ctxt title-bot">
                          {0}/{dataPoitReward?.gameMission || 0}
                        </span>
                      ) : (
                        <span className="cart-ctxt title-bot">
                          {dataPoitReward?.missionPass || 0}/
                          {dataPoitReward?.gameMission || 0}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="result-bot">
              <div className="result-bot-item">
                <div className="result-bot-title">
                  <span>World best</span>
                </div>
                <div className="result-bot-score">
                  <div className="result-bot-score-num">
                    {dataPoitReward?.maxScoreAll?.toLocaleString("en-US") || 0}
                  </div>
                  <div className="result-bot-score-tit">scores</div>
                </div>
                <div className="result-bot-img">
                  <img src={cup} alt="cup" />
                </div>
              </div>
              <div className="result-bot-item">
                <div className="result-bot-title">
                  <span>My best</span>
                </div>
                <div className="result-bot-score">
                  <div className="result-bot-score-num">
                    {dataPoitReward?.maxScore?.toLocaleString("en-US") || 0}
                  </div>
                  <div className="result-bot-score-tit">scores</div>
                </div>
                <div className="result-bot-img">
                  <img src={medal} alt="cup" />
                </div>
              </div>
              <div className="result-bot-item">
                <div className="result-bot-title">
                  <span>My ranking</span>
                </div>
                <div className="result-bot-score">
                  <div className="result-bot-score-num">
                    #{dataPoitReward?.ranking || 0}
                  </div>
                  <div className="result-bot-score-tit">{"  "}</div>
                </div>
                <div className="result-bot-img">
                  <img src={king} alt="cup" />
                </div>
              </div>
              <div className="result-bot-item">
                <div className="result-bot-title">
                  <span>Last score</span>
                </div>
                <div className="result-bot-score">
                  <div className="result-bot-score-num">
                    {dataPoitReward?.lastScore?.toLocaleString("en-US") || 0}
                  </div>
                  <div className="result-bot-score-tit">scores</div>
                </div>
                <div className="result-bot-img">
                  <img src={hourglass} alt="cup" />
                </div>
              </div>
            </div>
          </Result>
        </>
      )}
    </>
  );
};

export default ResultGame;
