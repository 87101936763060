import { useEffect, useState } from "react";

import { footerImg } from "helpers/ExportImg";

import ListSpecialEvents from "components/List/ListSpecialEvents";
import TopPrize from "components/List/TopPrize";
import TableSpecialEventHistory from "components/Table/TableSpecialEventHistory";
import { useAppDispatch } from "index";
import { setPageState } from "slices/pageSlice";
import { getEventDetail } from "./services/api";

const SpecialEvent = () => {
  const [eventData, setEventData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatchApp = useAppDispatch()
  const { controlMobile } = footerImg;


  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await getEventDetail('week');
      setLoading(false);
      setEventData(data);
    } catch (err) {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchApi();
  }, []);


  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: true,
      tab: 2,
      title: '',
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'


    }
    ))
  }, []);


  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: true,
      tab: 2,
      title: eventData?.name || '',
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'

    }
    ))
  }, [eventData]);
  
  

  return (
    <>
      {/* <Popup /> */}
      {/* <PopupPlay/> */}
      {/* <PopupAlert/> */}
      {/* <PopupNoti/> */}
      <div className="flex-center flex-col mt-[80px] pb-[50px] ">
        {loading ? (
          <img
            style={{ padding: "5rem 0", margin: 'auto' }}
            src={controlMobile}
            alt="img"
            className="logo-loading"
          />
        ) : (
          <>
            <ListSpecialEvents eventData={eventData} />
            <TopPrize eventData={eventData} />
            {/* <TableUserLucky eventData={eventData} /> */}
            <TableSpecialEventHistory/>
          </>

        )}
      </div>
    </>
  );
};

export default SpecialEvent;
