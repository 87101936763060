import styled from "styled-components";

export const Progess = styled.section`
  display: flex;
  align-items: center;
  .Parentdiv {
    width: 100%;
    height: 50%;
    /* border-radius: 40px; */
    background: rgba(140, 140, 140, 1);
  }
  .text {
    display: flex;
    margin-right: 20px;
    width: 11rem;
    justify-content: space-between;
    p {
      font-size: 25px;
    }
    svg {
      width: 14px !important;
      height: 11px !important;
      color: orange;
    }
  }
  @media (max-width: 1000px) {
    .text {
      width: 20rem;
      p {
        font-size: 15px;
      }
    }
  }
  @media (max-width: 550px) {
    .text {
      width: 20rem;
      p {
        font-size: 15px;
      }
    }
  }
`;
