import CloseModalButton from "components/views/CloseModalButton";
import { walletImg } from "helpers/ExportImg";
import React from "react";
import { Button, Modal } from "react-daisyui";
import { useConnect } from "wagmi";
// import metamask from "Web3Custom/metamask";
import { config } from "app/environment";
import { connectWallet } from "web3/connector";

const { metaMask, coinbaseWallet, walletConnect } = walletImg;

const Icons: any = {
  metaMask: metaMask,
  coinbaseWallet: coinbaseWallet,
  walletConnect: walletConnect,
};

const ModalWallet: React.FC<{
  visible: boolean;
  onClose: any;
}> = ({ visible, onClose }) => {
  const { connectors } = useConnect();

  return (
    <Modal
      open={visible}
      onClickBackdrop={onClose}
      className={`min-w-[320px] md:min-w-[250px] relative animate__animated animate__bounceInDown animate__faster`}
    >
      <CloseModalButton closeModal={onClose} />
      <Modal.Header className="font-bold text-center text-[20px] md:text-[25px] ">
        Connect to a wallet
      </Modal.Header>
      <Modal.Body>
        {connectors.map((connector) => {
          const connectHandler = async () => {
            const isMetamask = connector.id === "metaMask";

            try {
              if (isMetamask && !connector.ready)
                return window.open(config.METAMASK_DOMAIN, "_blank");
              await connectWallet(connector);

              onClose();
            } catch (error) {
              console.log("error:", error);
            }
          };
          return (
            <div className="flex mt-2 " key={connector.id}>
              <Button
                className="w-full h-[45px] mb-3 justify-start border-none"
                onClick={() => connectHandler()}
              >
                <div className="flex items-center text-[15px] justify-start gap-6">
                  <img
                    src={Icons[connector.id]}
                    alt="icon"
                    className="w-[5rem] h-[5rem] md:w-10 md:h-10"
                  />
                  <span> {connector.name}</span>
                </div>
              </Button>
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

export default ModalWallet;
