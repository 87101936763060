import appContext from "appContext";
import React, { useState, useContext, useRef, useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import LoadingIcon from "components/LoadingIcon";
import WalletNew from "components/walletNew";
import useWindowSize from "hooks/useWindowSize";
import { CustomDropDown } from "components/walletNew/styled";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { headerImg } from "helpers/ExportImg";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { config } from "../../app/environment";
import useClickOutSide from "hooks/useClickOutside";
import { getTransPending } from "pages/services/api";

export const PopUpLogin = (size: { width: number; height: number }) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : 0;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : 0;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - size.width) / 2 / systemZoom + dualScreenLeft;
  const top = (height - size.height) / 2 / systemZoom + dualScreenTop;
  var win: any = window.open(
    process.env.REACT_APP_LOGIN_URL,
    "chromeWindow",
    `
    scrollbars=yes,
    width=${size.width / systemZoom}, 
    height=${size.height / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );
  const timer = setInterval(() => {
    if (win.closed) {
      clearInterval(timer);

      if (sessionStorage["activeSlide"] === "2") {
        window.location.href = "/account";
      } else {
        window.location.href = "";
      }
    }
  });
};

function GoogleButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { account, setAccount } = useContext(appContext);
  const logoutRef = useRef<any>();

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    // window.open('https://accounts.google.com/logout', 'Google Login', 'width=500,height=500');
    setIsLoading(true);
    setTimeout(() => {
      localStorage.removeItem("accessToken");
      setIsLoading(false);
      setAccount(null);
      window.location.reload();
    }, 1000);
  };

  const PopUpLogin = (size: { width: number; height: number }) => {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : 0;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : 0;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - size.width) / 2 / systemZoom + dualScreenLeft;
    const top = (height - size.height) / 2 / systemZoom + dualScreenTop;
    var win: any = window.open(
      process.env.REACT_APP_LOGIN_URL,
      "chromeWindow",
      `
      scrollbars=yes,
      width=${size.width / systemZoom}, 
      height=${size.height / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );
    const timer = setInterval(() => {
      if (win.closed) {
        clearInterval(timer);

        if (sessionStorage["activeSlide"] === "2") {
          window.location.href = "/account";
        } else {
          window.location.href = "/";
        }
      }
    });
  };
  const width: any = useWindowSize();
  const dropDownRef = useRef(null);

  const closeSocialHandler = (e: any) => {
    if (!isOpen) {
      return setIsOpen(!isOpen);
    }
    setIsOpen(false);
  };
  useClickOutSide(closeSocialHandler, dropDownRef);

  const { user } = useSelector((state: any) => state.auth);
  let numPoint = user?.pointPool?.point;
  const usFormat = numPoint?.toLocaleString("en-US");
  let numTicket = user?.ticket?.amount;
  const usFormatTicket = numTicket?.toLocaleString("en-US");
  const { cen, ruong } = headerImg;
  const { chain } = useNetwork();
  const {
    switchNetworkAsync,
    isLoading: loadingChangeNetwork,
    error,
    reset,
    chains,
    ...switchNetworkArgs
  } = useSwitchNetwork({});

  const switchNetWork = async () => {
    try {
      await switchNetworkAsync?.(+(config?.CHAIN_ID || 97));
    } catch (error) {
      console.log("error:", error);
    }
  };

  const [pending, setPending] = useState();

  useEffect(() => {
    const dataPending = async () => {
      try {
        const data = await getTransPending();

        setPending(data.data?.length);
      } catch (error) {
        console.log(error);
      }
    };

    dataPending();
  }, []);
  const RenderLogin = () => {
    if (account) {
      return (
        <div className="info-user" ref={logoutRef} onClick={openModal}>
          {isLoading && <LoadingIcon />}
          <img
            className="img-user icon-group"
            src={account ? account?.avatar : ""}
            alt="avatar"
          />
          <div className="content-info">
            <span className="status-user">Online</span>
            <span
              className="name"
              // ref={logoutRef}
              // onClick={openModal}
            >
              {account?.displayName?.length <= 10
                ? account?.displayName
                : `${account?.displayName?.slice(0, 10)}...`}
              {/* {account?.displayName.slice(0, 10)}... */}
            </span>
            {isOpen && (
              <div className="modal-info">
                {/* <LogoutIcon fontSize="large" /> */}
                {/* <WalletNew /> */}
                <CustomDropDown
                  ref={dropDownRef}
                  className="animate__animated animate__bounceInDown animate__faster"
                  style={{ width: "auto" }}
                >
                  <div className={`drop-down`} style={{ width: "max-content" }}>
                    <span>
                      <Link to={"/account"}>My Account</Link>
                    </span>
                    {/* <span>
                      <Link to={"/point-history"}>Widthdraw</Link>
                    </span> */}

                    {width > 1000 ? (
                      <></>
                    ) : (
                      <>
                        <div className="ticket-point">
                          <span>Ticket: {usFormatTicket || 0}</span>
                          <div className="ticket-img">
                            <img src={cen} alt="img ticket" />
                          </div>
                        </div>
                        <div className="ticket-point">
                          <span>Point: {usFormat || 0}</span>
                          <div className="point-img">
                            <img src={ruong} alt="img point" />
                          </div>
                        </div>
                        {/* {pending ? (
                          <p className="pending-trans">
                            {pending} pending transactions
                          </p>
                        ) : (
                          ""
                        )} */}
                      </>
                    )}
                    {/* {chain?.id !== +config.CHAIN_ID ? (
                      <span onClick={switchNetWork}>Switch Network</span>
                    ) : (
                      ""
                    )} */}
                    <div className="border-setting"></div>
                    <span onClick={handleLogout}>Logout</span>
                  </div>
                </CustomDropDown>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <button
          className="btn-login"
          onClick={() => {
            // window.location.href = process.env.REACT_APP_LOGIN_URL || '';
            // window.open(process.env.REACT_APP_LOGIN_URL, 'Google Login', 'width=500,height=500');
            PopUpLogin({ width: 500, height: 500 });
          }}
        >
          Sign In
        </button>
      );
    }
  };

  return <RenderLogin />;
}

export default GoogleButton;
