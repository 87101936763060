import styled from "styled-components";

export const PopupTag = styled.section`
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .backdrop {
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    display: block;
    z-index: 999999;
  }


    .popup-title {
      color: var(--Grey-100, #111);
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal; 
    }
    .popup-text{
      color: var(--Grey-100, #111);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.07px; 
    }

    .popup-btn{
      display: flex;
      height: 40px;
      padding: 0px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch; 
      border-radius: 8px;
      background: #06C270; 

      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.024px; 
    }

    .vote-input::placeholder {
        color: var(--Gray-600, var(--Grey-50, #7E7E7E));
        /* PoolWallet/Body 2-Regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px; /* 135.714% */ 
    }


    .popup-btn-border{

      display: flex;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;


      border-radius: 10px;
      border: 1px solid var(--Primary, #06C270); 

      color: var(--Primary, #06C270);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal
    }

`;
