import appContext from "appContext";
import React, { useContext } from "react";
import { useNavigate } from "react-router";

/* eslint-disable jsx-a11y/anchor-is-valid */
export const HeaderGames = ({ title, icon, route, show, genre }: any) => {
  const navigate = useNavigate();
  const { setInfoGenre, setCategories } = useContext(appContext);

  const handleClick = () => {
    navigate(route);
    sessionStorage.setItem("activeSlide", "1");

    setInfoGenre(genre);
    setCategories(genre?.id);
  };

  return (
    <div className="header-games">
      <div className="header-left">
        <img src={icon} alt="" />
        <span>{title}</span>
      </div>
      {!show ? (
        ""
      ) : (
        <div className="show-more" onClick={handleClick}>
          Show more
        </div>
        // <a href={route} className="show-more" onClick={handleClick}>
        //   Show more
        // </a>
      )}
    </div>
  );
};
