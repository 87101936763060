import styled from "styled-components";

export const DropDown = styled.section`
  position: absolute;
  top: 110%;
  right: 0;

  width: 100%;

  .drop-down {
    display: flex;
    flex-direction: column;
    width:fit-content;
    background: #ffffff;
    border: 3px solid #39dada;
    box-shadow: rgb(38 57 77) 0px 20px 30px -10px;
    border-radius: 10px;
  }

  .drop-down span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #383838;
    padding: 2px 20px;
  }

  @media (max-width: 768px) {
    .drop-down {
      max-width: 145px;
      min-width: 145px;
    }

    .drop-down span {
      font-size: 16px;
      line-height: 20px;
      padding: 2px 10px;
    }
  }


`;


export const ButtonConnect = styled.section`
  .popup-btn-connect{
      display: flex;
      height: 40px;
      padding: 0px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch; 
      border-radius: 8px;

      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.024px; 
    }
`;
