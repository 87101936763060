import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserTokenSlice } from "interfaces";
import { NETWORKS } from "web3/connector";
import { CONTRACTS, useWeb3 } from "web3/web3";

const initialState: UserTokenSlice = {
  bnbBalance: 0,
  busdBalance: 0,
  bpoolsBalance: 0,
  busdAllowance: 0,
};

const userTokenSlice = createSlice({
  name: "userToken",
  initialState,
  reducers: {
    setUserToken: (
      state: UserTokenSlice,
      action: PayloadAction<UserTokenSlice>
    ) => {
      return {
        ...state,
        bnbBalance: action.payload.bnbBalance,
        busdBalance: action.payload.busdBalance,
        bpoolsBalance: action.payload.bpoolsBalance,
        busdAllowance: action.payload.busdAllowance,
      };
    },
    setBnbBalance: (
      state: UserTokenSlice,
      action: PayloadAction<{ wallet: string }>
    ) => {
      async function logic() {
        const bnbBalance = await useWeb3.getBNBBalance(action.payload.wallet);

        return {
          ...state,
          bnbBalance: bnbBalance,
        };
      }

      logic();
    },
    setBusdBalance: (
      state: UserTokenSlice,
      action: PayloadAction<{ wallet: string }>
    ) => {
      async function logic() {
        const busdBalance = await useWeb3.getTokenBalance(
          CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].BUSD,
          action.payload.wallet
        );

        return {
          ...state,
          busdBalance: busdBalance,
        };
      }

      logic();
    },
    setBpoolsBalance: (
      state: UserTokenSlice,
      action: PayloadAction<{ wallet: string }>
    ) => {
      async function logic() {
        const bpoolsBalance = await useWeb3.getTokenBalance(
          CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].BPOOLS,
          action.payload.wallet
        );

        return {
          ...state,
          bpoolsBalance: bpoolsBalance,
        };
      }

      logic();
    },
    setBusdAllowance: (
      state: UserTokenSlice,
      action: PayloadAction<{ wallet: string }>
    ) => {
      async function logic() {
        const busdAllowance = await useWeb3.getAllowance({
          token: CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].BUSD,
          owner: action.payload.wallet,
          spender: CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].MARKET,
        });

        return {
          ...state,
          busdAllowance: busdAllowance,
        };
      }

      logic();
    },
  },
});

export const {
  setUserToken,
  setBnbBalance,
  setBpoolsBalance,
  setBusdAllowance,
  setBusdBalance,
} = userTokenSlice.actions;

export const userTokenReducer = userTokenSlice.reducer;
