import styled from "styled-components";

export const CustomDropDown = styled.section`
  position: absolute;
  top: 110%;
  right: 0;

  width: 100%;

  .drop-down {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    background: #ffffff;
    border: 3px solid #39dada;
    box-shadow: rgb(38 57 77) 0px 20px 30px -10px;
    border-radius: 10px;
    padding: 1rem 0;
  }

  .drop-down span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #383838;
    padding: 2px 20px;
  }
  /* .drop-down a{
    padding: 2px 20px;
    width: 100%;
    text-align: left;
    margin-bottom:10px;
    font-size: 20px;
  } */

  .border-setting {
    border: 1px solid rgb(137 134 134);
    width: 80%;
    margin: 5px 20px;
  }

  .ticket-point {
    display: flex;
    align-items: center;
    padding: 2px 20px;
    gap:5px;
  }
  .ticket-point span{
    padding:0;
    font-size:20px;
  }
  .ticket-img {
    width: 4rem;
  }

  .point-img {
    width: 5rem;
  }

  @media (max-width: 768px) {
    right: 40%;

    .drop-down {
      max-width: 170px;
      min-width: 170px;
    }
    .ticket-point{
      padding:0;
    }
    .drop-down span{
      font-size: 16px;
      line-height: 20px;
      padding: 2px 10px;
    }
   

    .border-setting {
      margin: 5px 10px;
    }
  }
`;
