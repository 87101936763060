import { useEffect, useState } from "react";

import TableUserLucky from "components/Table/TableUserLucky";
import { games } from "helpers/ExportImg";
import { useAppDispatch } from "index";
import { useParams, useSearchParams } from "react-router-dom";
import { setPageState } from "slices/pageSlice";
import { getEventDayParticipant, getEventDetail, getEventWinner } from "./services/api";
import { GameTag } from "./styled";
import TableDayWinner from "components/Table/TableDayWinner";
import TableDayYouTicket from "components/Table/TableDayYouTicket";

const HistoryDayEvent = () => {
  const dispatchApp = useAppDispatch()
  const [searchParams] = useSearchParams();
  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: false,
      tab: 2,
      iconHeader: parseInt(searchParams.get('iconHeader')!) || 0,
      title: "History",
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'
    }
    ))
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);


  const paramsUrl = useParams();
  const [eventData, setEventData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const id = parseInt(paramsUrl.id!);


  const fetchApi = async () => {
    try {
      setLoading(true);
      const params = { page: 1, limit: 10, allowUser : true , sortBy: 'createdAt', direction: 'DESC' };
      const data = await getEventDayParticipant(paramsUrl.id, params);
      setLoading(false);
      setEventData(data);
    } catch (err) {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <GameTag className="mt-[40px] pb-[50px]">
      <div className="mt-10  w-full rounded overflow-hidden shadow-lg  flex items-center justify-center	">
         {/* <img className=" rounded-xl" src={games.bannerAds}
          alt="ads" /> */}
{/* <div id="container-e49302f0df0a34efc084889cd3314a5d"></div> */}
      </div>
      <div className="game_page container-new">
        <TableDayWinner />
        <TableDayYouTicket/>
      </div>
    </GameTag>
  );
};

export default HistoryDayEvent;
