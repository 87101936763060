import * as React from "react"
const History = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#626262"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M1.666 2.326v5h5"
    />
    <path
      stroke="#626262"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M3.758 11.492a7.5 7.5 0 1 0 1.775-7.8L1.666 7.326"
    />
  </svg>
)
export default History
