import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ItemBox, ModalBuy } from "interfaces";

const initialState: ModalBuy = {
  item: null,
  isVisible: false,
  status: null,
};

const pageSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal: (state: ModalBuy, action: PayloadAction<ItemBox>) => {
      return {
        ...state,
        item: {
          id: action.payload.id,
          icon: action.payload.icon,

          name: action.payload.name,
          price: action.payload.price,
          packageIndex: action.payload.packageIndex,
        },
      };
    },
    resetModal: (state: ModalBuy) => {
      return {
        ...state,
        isVisible: false,
        item: null,
      };
    },
    toggleModalBuy: (state: ModalBuy) => {
      state.isVisible = !state.isVisible;
    },

    setModalWallet: (state: ModalBuy, action: PayloadAction<ModalBuy>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setStatusTicket: (state, action: { payload: any }) => {
      return { ...state, status: action.payload };
    },
  },
});

export const {
  setModal,
  toggleModalBuy,
  resetModal,
  setModalWallet,
  setStatusTicket,
} = pageSlice.actions;

export const modalReducer = pageSlice.reducer;
