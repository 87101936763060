import React from "react";
type CloseModalType = {
  closeModal?: () => void;
  htmlFor?: string;
};
const CloseModalButton: React.FC<CloseModalType> = (props) => {
  const { closeModal, htmlFor } = props;
  return (
    <label
      className=" absolute top-[1rem] right-[1rem] text-[20px]  md:text-white text-color-main cursor-pointer hover:opacity-60 transition bg-[#ccc] rounded-full 
      w-[25px] h-[25px] md:w-[30px] md:h-[30px] flex items-center justify-center"
      onClick={closeModal}
      htmlFor={htmlFor}
    >
      <i className="fas fa-times"></i>
    </label>
  );
};

export default CloseModalButton;
