
import { Link } from "react-router-dom";
import { CardSpecialHistoryWrapper } from "./styled";

const CardDayEventHistory: any = ({ game }: any) => {
  const { userBuyTicket, startedAt, id } = game;
  return (
    <>
      <CardSpecialHistoryWrapper>
        <Link to={`/history-day-event/${id}`}>
          <div className="flex flex-row mt-5 history-row overflow-x-auto w-full justify-between p-5">
            <div className="flex justify-end items-end value-total">{startedAt?.slice(0, 10).replaceAll('-', '/')}</div>
            <div className="history-lable">
              Bought Ticket: {userBuyTicket}
            </div>
          </div>
        </Link>
      </CardSpecialHistoryWrapper>
    </>
  );
};

export default CardDayEventHistory;