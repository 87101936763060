import styled from "styled-components";

export const PlayToEarnStyled = styled.section`
  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  .pte-header-left{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Poppin/Subtitle/Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 

  }

  .table-header-right{
    color: var(--Primary, #06C270);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.021px;
    text-transform: capitalize; 
 }

  .pte-header-right{
    color: var(--Primary, #06C270);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.021px;
    text-transform: capitalize; 
  }

  .pte-text{
    color: var(--Grey-50, #7E7E7E);
    text-align: center;
    /* Poppin/Body/Body medium - regular */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.021px; 
  }


  table{
    border-radius: 1em 1em 0em 0em;

  }
  thead{

    color: var(--Grey-0, #FFF);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px;
  }
  td{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 

  }
  tbody{
    background: #F7F7F7; 

  }

  thead th:first-child {
    border-radius: 10px 0 0 0;
    background: #06C270;

  }

  thead th:last-child {
    border-radius: 0 10px 0 0;
    background: #06C270;
  }

  table tr:nth-child(even) {
    background-color: #FFFFFF;
  }


  .ticket-list{
    thead{
      tr {
        background: #CDF3E2;
      }
    }
    thead th:last-child {
      background: #CDF3E2;
      color: var(--Grey-Grey-90, var(--Grey-100, #111)); 
    }
    thead th:first-child {
      background: #CDF3E2;
      color: var(--Grey-Grey-90, var(--Grey-100, #111)); 
    }
  }

  .leaderboard-header-left{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1 / Subtitle / Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;  
  }

      .fixed_header {
        width: 300px;
        table-layout: fixed;
        border-collapse: collapse;
      }
      .fixed_header tbody {
        display: block;
        width: 100%;
        overflow: auto;
        max-height: 50vh;
      }
      .fixed_header thead tr {
        display: block;
      }
      .fixed_header thead {
      }
      .fixed_header th
       {
        padding: 10px;
        width: 150px;
      }
      .fixed_header td {
        padding: 10px;
        width: 150px;
      }


      .btn-by-ticket{
        border-radius: 8px;
        background: var(--Primary, #06C270); 
        display: flex;
        padding: 10px 10px;
        justify-content: center;
        align-items: center;

        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
        letter-spacing: 0.024px; 
      }
`;


export const PageGame = styled.section`
  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  .pte-header-left{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Poppin/Subtitle/Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 

  }

  .pte-header-right{
    color: var(--Primary, #06C270);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.021px;
    text-transform: capitalize; 
  }
`;

export const HistoryGameWraper = styled.section`
  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  .pte-header-left{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Poppin/Subtitle/Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 

  }

  .pte-header-right{
    color: var(--Primary, #06C270);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.021px;
    text-transform: capitalize; 
  }
`;



export const PolicyWraper = styled.section`
  ol, ul {
      list-style: disc ;
      margin: 0;
      padding: 0;
  }
  ul li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .title-policy{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Poppin/Subtitle/Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
  }

  .text-policy{
    color: var(--Neutral-Color-Black, #000);
    /* Poppin/Body/Body medium - regular */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.021px; 
  }



  table{
    
      border-radius: 10px 10px 0px 0px;
    }
  thead{

    color: var(--Grey-0, #FFF);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }
  td{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
    padding: 8px;

  }
  tbody{
    background: #F7F7F7; 

  }

   thead th:first-child {
    border-radius: 10px 0 0 0;
    background: #06C270;

}
 thead th:last-child {
    border-radius: 0 10px 0 0;
    background: #06C270;
}

  table tr:nth-child(even) {
    background-color: #FFFFFF;
  }
`;

export const GuidelineWraper = styled.section`
   .popup-btn{
      display: flex;
      height: 40px;
      padding: 0px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch; 
      border-radius: 8px;

      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.024px; 
    }
`;