import styled from "styled-components";

export const YourComment = styled.section`
  // width: var(--width_content_home);
  max-width: 1440px;
  width: 100%;
  margin: 8rem 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  font-family: "Regular";

  .write_comment {
    width: 65%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    input {
      width: 100%;
      border: 3px solid rgba(0, 137, 111, 1);
      border-width: 2px 2px 5px 2px;
      border-radius: 20px;
      padding: 2rem;
      padding-bottom: 10rem;
      font-size: 2rem;
      ::placeholder {
        margin-top: 10px;
      }
    }
    p {
      font-weight: bold;
      font-size: 28px;
      line-height: 100%;
      margin: 0;
      margin-bottom: 2rem;
    }
    .btn-mobile {
      display: none;
    }
  }
  .voting {
    width: 30%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-weight: bold;
      font-size: 28px;
      line-height: 100%;
      margin: 0;
    }
    button {
      background-color: #ff6700;
      width: 100%;
      height: 60px;
      justify-content: center;
      align-items: center;
      display: flex;
      color: white;
      font-size: 24px;
      line-height: 100%;
      border: 1px solid;
      border-radius: 20px;
      cursor: pointer;
    }
    .rating_start {
      font-size: 7.5rem;
    }
  }
  @media (max-width: 1300px) {
    width: 95%;
    .voting {
      .rating_start {
        font-size: 4.5rem;
      }
    }
  }

  @media (max-width: 500px) {
    height: auto;
    flex-direction: column-reverse;
    justify-content: space-around;
    margin: 0;
    .write_comment,
    .voting {
      p {
        font-size: 20px;
        margin-bottom: 2rem;
      }
    }
    .write_comment {
      margin: 4rem 0;
      width: 100%;
      height: 40%;
      align-items: center;
      input {
        padding-top: 20px;
        font-family: "bold";
      }
      input::placeholder {
        font-size: 3rem;
        font-family: "bold";
      }
      p {
        margin-bottom: 10px;
      }
      .btn-mobile {
        background-color: #ff6700;
        font-family: "bold";
        width: 100%;
        height: 60px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: white;
        font-size: 24px;
        line-height: 100%;
        border: 1px solid;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 2rem;
      }
    }
    .voting {
      width: 100%;
      min-height: auto;
      margin: 4rem 0;
      align-items: center;
      button {
        display: none;
      }
      .rating_start {
        font-size: 9.5rem;
      }
    }
  }
`;
