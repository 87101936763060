import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState } from "interfaces";

const initialState: AuthState = {
  wallet: null,
  walletNoSign: null,
  accessToken: localStorage.getItem("accessToken") || null,
  user: null,
  callback: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: AuthState, action: PayloadAction<AuthState>) => {
      return {
        ...state,
        ...action.payload,
        // accessToken: action.payload.accessToken,
        // user: action.payload.user,
        // wallet: action.payload.wallet,
      };
    },
    callback: (state: AuthState) => {
      state.callback = !state.callback;
    },
  },
});

export const { setAuth, callback } = authSlice.actions;

export const authReducer = authSlice.reducer;
