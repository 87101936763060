import { guideline, slidebarImg } from "helpers/ExportImg";
import { PolicyWraper } from "./styled";
import { getEventDetail, getListGamesOpenSource } from "pages/services/api";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "index";


const ListPrivacyPolicy = () => {

  const PER_PAGE = 100;
  const [listGame, setListGame] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const { pools } = slidebarImg;
  const [listTop, setListTop] = useState<any>([]);

  const fetchListGame = async () => {
    if (loading) return;
    const params = { limit: PER_PAGE, sortBy: 'createdAt', direction: 'DESC' };
    try {
      const response = await getListGamesOpenSource(params);
      setListGame(response.data);

    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListGame();
  }, []);

  const [eventData, setEventData] = useState<any>();
  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await getEventDetail('week');
      setLoading(false);
      setEventData(data);
    } catch (err) {
      setLoading(false);
    }
  };


  const groupTop = () => {
    const groups = eventData?.eventDetail?.reduce((acc: any, object: any) => {
      const groupKey = object.reward; // Nhóm theo điểm
      acc[groupKey] = (acc[groupKey] || []).concat(object);
      return acc;
    }, {});
    if (groups) {
      const dataList = Object.values(groups)?.reverse().map(
        (n: any) => {
          if (n.length === 1) {
            return { rank: n[0].rank, reward: n[0].reward }
          }
          if (n.length > 1) {
            return { rank: n[0].rank + ' - ' + n[n.length - 1].rank, reward: n[0].reward }
          }
        }
      );
      setListTop(dataList)
      return dataList
    }
  }


  useEffect(() => {
    fetchApi();
    groupTop()
  }, []);

  useEffect(() => {
    groupTop()
  }, [eventData]);

  return (
    <PolicyWraper>
      <div
        className=""
        style={{ scrollMargin: "10rem" }}
      >
        {/* <img src={bgSymbol} alt="bg-symbol" className="bg-symbol" /> */}
        <div
          className="component-games p-20  "
          style={{ margin: 0 }}
        >
          <>
            <div >
              <div className="title-policy">Funny Game: </div>
              <div className="text-policy pl-[20px] mt-5">
                <ul>
                  <li>Free to Play: Dive into the fun without breaking the bank. Funny Game is completely free to access and enjoy, offering endless entertainment without any in-game purchases or pay-to-win mechanics.</li>
                  <li>Unlimited Play: No need to worry about running out of lives or progress restrictions. Funny Game encourages exploration and experimentation with unlimited play sessions, ensuring you can laugh until your sides ache.</li>
                  <li>Non-Competitive Environment: Leave the scoreboards and leaderboards behind. Funny Game prioritizes lighthearted fun and personal satisfaction, focusing on the joy of experiencing its unique humor rather than competition.</li>
                </ul>
              </div>
            </div>

            <div className="mt-10">
              <div className="title-policy">Play to Earn Game (P2E):  </div>
              <div className="text-policy pl-[20px] mt-5">
                <ul>
                  <li>Earn Points: While Funny Game prioritizes pure laughter, you can now rack up points by completing daily quests. Each game features 4 unique quests, offering fresh challenges and rewarding your comedic prowess.</li>
                  <li>Daily Limit: Remember, laughter needs balance! You can complete a maximum of 4 daily quests per game, ensuring you have ample time to explore the humor without getting overwhelmed.</li>
                  <li>Quest Reset: Each day, the quest board refreshes at 0:00 UTC, giving you a clean slate to embark on new comedic adventures.</li>
                </ul>
              </div>
            </div>

            <div className="grid grid-cols-2  gap-10">

              {listGame?.map((game: any, index: number) => (
                (game) &&
                (<div
                  key={index}
                >
                  {/* <div className="card-genre-game game-open" key={game?.id}> */}
                  <div className="mt-10">
                    <div className="title-policy">{game?.name}</div>
                    <div className="text-policy pl-[20px] mt-5">
                      <ul>
                        {game?.game_mission?.map((mission: any, i: number) => (
                          (mission) &&
                          (<div
                            key={i}
                          >
                            <li>{mission?.pointToPass} score: {mission?.pointReward}  points</li>
                          </div>)
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>)
              ))}



            </div>

            <div className="mt-10">
              <div className="title-policy">Point:  </div>
              <div className="text-policy pl-[20px] mt-5">
                <ul>
                  <li>How to earn:</li>
                  <li>Daily Quests: Each game features 4 unique daily quests that refresh daily at 0:00 UTC. Completing these quests grants players rewards points, offering a fun and accessible way to progress</li>
                  <li>How to use:</li>
                  <li>
                    Special Events: Earn points to unlock tickets for exclusive Special Events. These limited-time experiences offer unique challenges, hilarious scenarios, and exciting rewards, providing players with fresh content and enhanced engagement.</li>
                  <li>
                    Disclaimer:</li>
                  <li>Point cannot exchange directly to $POOLS but only use for Special Event</li>
                </ul>
              </div>
            </div>


            <div className="mt-10">
              <div className="title-policy">Event schedule:  </div>
              <div className="text-policy pl-[20px] mt-5">
                <ul>
                  <li>Time: Monday to Sunday every week</li>
                  <li>Requirements</li>
                  <li>Earn at least 400 Points in the week when the event takes place</li>
                  <li>Each user can only participate once</li>
                  <li>Prizes:</li>
                  <li>20 lucky winners will randomly receive POOLS as shown below</li>
                </ul>
              </div>
            </div>



            <table className=" m-auto fixed_header min-w-[200px] mt-5">
              <thead className="">
                <tr>
                  <th className="text-left p-[8px]">Top</th>
                  <th className="text-right p-[8px]">
                    <div className="flex float-right justify-center items-center">
                      <div className="  ">Prize</div>
                      <div className="ml-2 items-center justify-center" >
                        <img className="w-[15px] h-[15px]" src={pools}
                          alt="img" />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {listTop.length > 0 && listTop?.map((rankInfo: any, index: number) => (
                  <tr key={rankInfo?.id}>
                    <td className="text-left">{rankInfo.rank}</td>
                    <td className="text-right">{rankInfo.reward}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-10">
              <div className="title-policy">How to participate:  </div>
              <div className="text-policy pl-[20px] mt-5">
                <ul>
                  <li>Login and play game which in Play to Earn category and start earning Points</li>
                  <li>Points can be earned through playing play-to-earn games</li>
                </ul>
              </div>
            </div>

            <div className="mt-10">
              <div className="title-policy">Results:  </div>
              <div className="text-policy pl-[20px] mt-5">
                <ul>
                  <li>The results will be announced on Monday the following week</li>
                  <li>Winning users will receive a notification in the Profile</li>
                </ul>
              </div>
            </div>
          </>
        </div>
      </div>
    </PolicyWraper>
  );
};

export default ListPrivacyPolicy;
