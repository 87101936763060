import styled from "styled-components";

export const PointItem = styled.section`
  width: 80%;
  margin-top: 3rem;
  /* height: 100vh; */
  display: flex;
  align-items: start;
  flex-direction: column;
  /* overflow-x: hidden; */
  position: relative;

  .point-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 49px;
    color: #383838;
    margin-bottom: 11px;
  }

  .point-table {
    background: #ffffff;
    border: 3px solid #00896f;
    box-shadow: 0px 6px 0px #00896f;
    border-radius: 10px;
    padding: 50px;
    margin-bottom: 40px;
  }

  .point-table-title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #383838;
  }

  .point-table-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .point-table-total-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    text-transform: uppercase;
    color: #383838;
  }

  .point-table-total-point {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 38px;
    /* max-width: 140px; */
    min-width: 140px;
  }

  .point-table-total-point-num {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 49px;
    text-align: right;
    color: #383838;
  }

  .point-table-total-point-tit {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #383838;
  }

  .point-line {
    border: 1px solid #00896f;
    margin: 20px 0 50px;
  }

  .point-table-redeemed,
  .point-table-bpool {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .point-table-redeemed-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    text-transform: uppercase;
    color: #f37945;
  }

  .point-table-redeemed-point,
  .point-table-bpool-point {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 38px;
    /* max-width: 140px; */
    min-width: 140px;
  }

  .point-table-redeemed-num {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 49px;
    text-align: right;
    color: #f37945;
  }

  .point-table-redeemed-bpool {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #383838;
  }

  .point-table-bpool-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    text-transform: uppercase;
    color: #007b63;
  }

  .point-table-bpool-num {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 49px;
    text-align: right;
    color: #007b63;
  }

  .point-table-bpool-bpool {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #383838;
  }

  .point-btn {
    background: #ff6700;
    border-radius: 10px;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding: 14px 20px;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    width: 95%;
    .point-table-title {
      font-size: 18px;
      line-height: 25px;
    }

    .point-table-total-point-num,
    .point-table-redeemed-num,
    .point-table-bpool-num {
      font-size: 26px;
    }

    .point-table-total-title,
    .point-table-total-point-tit,
    .point-table-redeemed-title,
    .point-table-redeemed-bpool,
    .point-table-bpool-title,
    .point-table-bpool-bpool {
      font-size: 20px;
    }
  }

  @media (max-width: 460px) {
    .point-table {
      padding: 15px;
    }

    .point-table-title {
      font-size: 16px;
    }

    .point-table-total-point-num,
    .point-table-redeemed-num,
    .point-table-bpool-num {
      font-size: 22px;
    }

    .point-table-total-title,
    .point-table-total-point-tit,
    .point-table-redeemed-title,
    .point-table-redeemed-bpool,
    .point-table-bpool-title,
    .point-table-bpool-bpool {
      font-size: 16px;
    }
  }
`;
