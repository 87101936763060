
import PopupVote from "components/popupVote";
import { slidebarImg } from "helpers/ExportImg";
import { handleShowTime } from "helpers/util";
import { useAppDispatch } from "index";
import { getEventDetail } from "pages/services/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setPopupInfoState } from "slices/popupInfoSlice";
import { EventCardWrapper } from "./styled";

const CardHourEvent: any = ({ event }: any) => {
  const navigate = useNavigate();

  const [eventData, setEventData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const { pools } = slidebarImg;
  const { name, image, id, description, startedAt, type, eventDetail, playCount } = event;
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [typeVote, setTypeVote] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);

  const getEndTime = (startedAt: string | number | Date, type: string) => {
    const start = new Date(startedAt);
    if (type === "Week") return new Date(start.getTime() + 7 * 24 * 60 * 60 * 1000)
    if (type === "Day") return new Date(start.getTime() + 24 * 60 * 60 * 1000)
    if (type === "Hour") return new Date(start.getTime() + 60 * 60 * 1000)
    return new Date();
  }
  let timer: any = null
  const loopFetch = () => {
    timer = setInterval(function () {
      fetchApi()
    }, 30000);
  }

  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await getEventDetail('hour');
      setLoading(false);
      console.log(data.id )
      console.log(id)
      if (data.id && data.id !== id) { navigate(`/hour-event/hour/${data.id}`) }
      setEventData(data);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApi()
    loopFetch()
    return () => clearInterval(timer);
  }, []);

  const countDownTime = () => {
    // Set the date we're counting down to
    var countDownDate = getEndTime(startedAt, type).getTime();

    // Update the count down every 1 second
    setInterval(function () {

      // Get today's date and time
      var now = new Date().getTime();
      // console.log("now", now)

      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // console.log("distance", distance)
      setDistance(distance)
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDays(days)
      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)
    }, 1000);
  }


  useEffect(() => {
    countDownTime()
  }, []);

  const dispatchApp = useAppDispatch()

  const handlerPopup = () => {
    dispatchApp(setPopupInfoState({ title: name, show: true, description: description }));
  };

  const getDetailByType = (arr: any[], type: any) => {
    const obj = arr.find((element) => element.type === type);
    return obj
  };

  const eventType1 = getDetailByType(eventDetail, 1);
  const eventType2 = getDetailByType(eventDetail, 2);
  const eventType3 = getDetailByType(eventDetail, 3);



  return (
    <>
      <PopupVote show={show} setShow={setShow} eventId={id} type={typeVote} />
      <EventCardWrapper>
        <div className="inline-block w-full rounded-xl">

          <div className="flex flex-col w-full space-y-2 overflow-hidden rounded-xl " >
            <div className="flex justify-center items-center ">
              <img className="w-full aspect-video	 mt-5" src={image}
                alt="img" />
            </div>
            <div className=" p-10 ">
              <div className="flex justify-center items-center   ">
                {/* <div className="  flex w-fit bg-[#FDEB7B]  rounded-xl justify-center items-center    ">
                <div className="  flex weekly-rewards">RACE FOR SUCCESS:</div>

                  <div className="  flex weekly-rewards-number ml-2">{totalReward}</div>
                  <div className="ml-2" >
                    <img className="w-[25px] h-[25px] mt-[2px]" src={pools}
                      alt="img" />
                  </div>

                  <div className="  flex weekly-rewards">WEEKLY REWARDS</div>

                </div> */}
              </div>

              <div className="flex text-center justify-center event-name mt-16">
                <div>
                  {name}
                </div>
                <button className="header-right ml-2" onClick={() => { handlerPopup() }}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_526_20158)">
                      <path d="M10.0003 18.3333C14.6027 18.3333 18.3337 14.6023 18.3337 9.99996C18.3337 5.39759 14.6027 1.66663 10.0003 1.66663C5.39795 1.66663 1.66699 5.39759 1.66699 9.99996C1.66699 14.6023 5.39795 18.3333 10.0003 18.3333Z" stroke="#626262" stroke-width="1.66667" stroke-linecap="round" strokeLinejoin="round" />
                      <path d="M10 13.3333V10" stroke="#626262" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10 6.66663H10.0083" stroke="#626262" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_526_20158">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div className="flex w-full space-x-4 mt-16 px-10">
                <div className="flex flex-col justify-center items-center  w-full ">
                  <div className="flex event-time-number">
                    {handleShowTime(days)}
                  </div>
                  <div className="flex event-info">
                    days
                  </div>
                </div>
                <div className="flex justify-center items-center w-full event-dots ">
                  :
                </div>
                <div className="flex flex-col  justify-center items-center  w-full  ">
                  <div className="flex event-time-number">
                    {handleShowTime(hours)}
                  </div>
                  <div className="flex event-info">
                    hours
                  </div>

                </div>
                <div className="flex justify-center items-center w-full event-dots">
                  :
                </div>
                <div className="flex flex-col justify-center items-center  w-full ">
                  <div className="flex event-time-number">
                    {handleShowTime(minutes)}

                  </div>
                  <div className="flex event-info">
                    min
                  </div>

                </div>
                <div className="flex justify-center items-center w-full event-dots ">
                  :
                </div>
                <div className="flex flex-col justify-center items-center  w-full ">
                  <div className="flex event-time-number">
                    {handleShowTime(seconds)}
                  </div>
                  <div className="flex event-info">
                    sec
                  </div>
                </div>
              </div>

              <div className={`${distance <= 0 ? '' : 'hidden'} text-event-end mt-12`}>The event was end. You can see result below.</div>
              <div className="flex flex-row justify-between mt-10 space-x-10">
                <div className="flex flex-col w-full ">
                  <div className="flex  w-full bg-[#FF7272] border-2	border-[#FF3535] rounded-3xl">
                    <img className="w-full aspect-video	" src={eventType1.image}
                      alt="img" />
                  </div>
                  <div className="total-pools">
                    {eventData?.eventDetail[0]?.totalPool || 0}
                  </div>
                  <div className="flex flex-col p-4">
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Users
                      </div> <div className="vote-value">
                        {eventData?.eventDetail[0]?.countWallet || 0}
                      </div>
                    </div>
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Reward:
                      </div>
                      <div className="vote-value">
                        {parseFloat(eventData?.eventDetail[0]?.userReward || 0 ).toFixed(2)}
                      </div>
                    </div>
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Pools Voted:
                      </div> <div className="vote-value">
                        {parseFloat(eventData?.eventDetail[0]?.userVoted || 0 ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <button disabled={distance <= 0} className={`${distance <= 0 ? 'opacity-30' : ''} vote-btn mt-10 w-full`} onClick={() => { setShow(true); setTypeVote(1) }} >Vote</button>
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex  w-full  bg-[#FCFF6A]  border-2	border-[#EDF115] rounded-3xl">
                    <img className="w-full aspect-video	 " src={eventType2.image}
                      alt="img" />
                  </div>
                  <div className="total-pools">
                    {eventData?.eventDetail[1]?.totalPool || 0}
                  </div>
                  <div className="flex flex-col p-4">
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Users
                      </div> <div className="vote-value">
                        {eventData?.eventDetail[1]?.countWallet || 0}
                      </div>
                    </div>
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Reward:
                      </div>
                      <div className="vote-value">
                        {parseFloat(eventData?.eventDetail[1]?.userReward || 0 ).toFixed(2)}

                      </div>
                    </div>
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Pools Voted:
                      </div> <div className="vote-value">
                        {parseFloat(eventData?.eventDetail[1]?.userVoted || 0 ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <button disabled={distance <= 0} className={`${distance <= 0 ? 'opacity-30' : ''} vote-btn mt-10 w-full`} onClick={() => { setShow(true); setTypeVote(2) }} >Vote</button>
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex  w-full bg-[#69DBFF] border-2	border-[#1DC9FF] rounded-3xl">
                    <img className="w-full aspect-video	" src={eventType3.image}
                      alt="img" />
                  </div>
                  <div className="total-pools">
                    {eventData?.eventDetail[2]?.totalPool || 0}
                  </div>
                  <div className="flex flex-col p-4">
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Users
                      </div> <div className="vote-value">
                        {eventData?.eventDetail[2]?.countWallet || 0}
                      </div>
                    </div>
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Reward:
                      </div>
                      <div className="vote-value">
                        {parseFloat(eventData?.eventDetail[2]?.userReward || 0).toFixed(2) }
                      </div>
                    </div>
                    <div className="flex  w-full justify-between">
                      <div className="vote-text">
                        Pools Voted:
                      </div> <div className="vote-value">
                        {parseFloat(eventData?.eventDetail[2]?.userVoted || 0).toFixed(2) }
                      </div>
                    </div>
                  </div>
                  <button disabled={distance <= 0} className={`${distance <= 0 ? 'opacity-30' : ''} vote-btn mt-10 w-full`} onClick={() => { setShow(true); setTypeVote(3) }} >Vote</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </EventCardWrapper>
    </>
  );
};

export default CardHourEvent;