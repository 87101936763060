import axios from "axios";
import { request } from "https";
import { API_URL } from "./environment";

const apiService = axios.create({
  baseURL: API_URL,
});

apiService.interceptors.request.use(
  (request) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      request.headers!.Authorization = `Bearer ${accessToken}`;
    }

    return request;
  },
  function (error) {
    console.log("REQUEST ERROR", error);
    const message = error.request?.data?.errors?.message || "Unknown Error";
    return Promise.reject({ message });
  }
);

apiService.interceptors.response.use(
  (response) => {
    return response;
  },

  function (error) {
    console.log("RESPONSE ERROR", error);
    const message = error.response?.data?.message || "Unknown Error";
    return Promise.reject({ message });
  }
);

export default apiService;
