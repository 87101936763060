import React, { useContext, useEffect, useState } from "react";
import appContext from "appContext";
import LoadingIcon from "components/LoadingIcon";
import PaginationGame from "components/Pagination";

import { homeImg, slidebarImg } from "helpers/ExportImg";
import { getListGames } from "./services/api";
import { useSelector } from "react-redux";
import TertiaryCard from "components/games/TertiaryCard";
import FilterGame from "components/FilterGame";

const MiniCenterPage = () => {
  const { limit, categories } = useContext(appContext);
  const [page, setPage] = useState(1)
  const handleChagePage = (value: number) => {
    setPage(value)
  }
  const { games, isLoading } = useSelector((state: any) => state.games);
  const count = Math.floor(games?.total / limit);

  useEffect(() => {
    const params = { page, limit, categories };
    getListGames(params);
  }, [page, categories]);

  const { bgSymbol, symbolFinal } = homeImg;
  const { ballon } = slidebarImg;

  return (
    <div className="flex-center wrapper-home">
      {/* <img src={bgSymbol} alt="bg-symbol" className="bg-symbol" /> */}
      <div className="component-games top-free-game" style={{ margin: 0 }}>
        {isLoading && <LoadingIcon />}
        <div
          className="header-games"
          style={{
            margin: "3rem 0",
          }}
        >
          <div className="header-left">
            <img src={ballon} alt="" />
            <span>Discover Games</span>
          </div>
          <FilterGame />
        </div>
        <div className="flex-center cards-free-game">
          {games?.data?.map((game: any) => (
            <div className="tertiary-card" key={game?.id}>
              <TertiaryCard game={game} />
            </div>
          ))}
        </div>
        {/* <img src={symbolFinal} alt="img" className="symbol-final" /> */}
      </div>
      <PaginationGame count={count} page={page} setPage={handleChagePage} />
    </div>
  );
};

export default MiniCenterPage;
