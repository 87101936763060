import React, { useEffect, FC } from "react";

import { homeImg } from "helpers/ExportImg";
import { Rating } from "@mui/material";
import { Button, ButtonMobile } from "../button";
import { discover } from "constants/path";
import useWindowSize from "hooks/useWindowSize";

import { GenreGameMobile, GenreGameTag } from "./styled";
type FullScreenType = {
  game?: any;
  onClick?: () => void;
};

const TertiaryCard: FC<FullScreenType> = ({ game, onClick }) => {
  const [value, setValue] = React.useState<any>(5);

  const width: any = useWindowSize();

  const { image, name, id, rateCount, rateSum, categories, playCount } = game;

  useEffect(() => {
    const rateGame = rateCount > 0 ? rateSum / rateCount : 5;
    setValue(Number(rateGame));
  }, []);

  return (
    <>
      {width > 1000 ? (
        // <div onClick={onClick}>
        //   <img src={image} alt="img" className="img-tertiary" />
        //   <div className="content-center-tertiary">
        //     <div className="wrapper-content">
        //       <span className="name-game-mini">{name}</span>
        //       <span className="category">{categories[0]?.name}</span>
        //     </div>
        //     <div className="upload-rate">
        //       <Rating
        //         name="read-only"
        //         value={value}
        //         className="rating-card"
        //         readOnly
        //       />
        //     </div>
        //   </div>
        //   <Button
        //     className="flex-center btn-freegame"
        //     route={`${discover}/${id}`}
        //     item={game}
        //   />
        // </div>
        <ButtonMobile route={`${discover}/${id}`} item={game}>
          <GenreGameTag>
            <img src={image} alt="img" className="img-tertiary" />
            <div className="content-game">
              <div className="wrapper-content">
                <span className="name-game">{name}</span>
                <span className="category-game">{categories[0]?.name}</span>
                <Rating
                  name="read-only"
                  value={value}
                  className="rating-card"
                  readOnly
                />
                <div className="review-star">
                  <span className="review-title">{rateCount} Review</span>
                  <span className="review-title">{playCount} Played</span>
                  <Button
                    className="flex-center btn-play-game"
                    route={`${discover}/${id}`}
                    item={game}
                  />
                </div>
              </div>
            </div>
          </GenreGameTag>
        </ButtonMobile>
      ) : (
        <>
          <ButtonMobile route={`${discover}/${id}`} item={game}>
            <GenreGameMobile>
              <img src={image} alt="img" className="img-tertiary" />
              <div className="" style={{ width: "100%" }}>
                <div className="wrapper-content">
                  <span className="name-game-mini">{name}</span>
                  <span className="category">{categories[0]?.name}</span>
                  <Rating
                    name="read-only"
                    value={value}
                    className="rating-card"
                    readOnly
                  />
                  <div className="review-star">
                    <span>{rateCount} Review</span>
                    <span>{playCount} Played</span>
                    <Button
                      className="flex-center btn-play-game"
                      route={`${discover}/${id}`}
                      item={game}
                    />
                  </div>
                </div>
              </div>
            </GenreGameMobile>
          </ButtonMobile>
        </>
      )}
    </>
  );
};

export default TertiaryCard;
