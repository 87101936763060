import { ShopB, ShopTag } from "./style";
import { shopImg } from "helpers/ExportImg";
import { useDispatch } from "react-redux";
import { setModal } from "slices/modalSlice";
import { ItemBox } from "interfaces";
import React from "react";

const ShopBox: React.FC<{ item: ItemBox }> = ({ item }) => {
  const dispatch = useDispatch();

  const { ticket } = shopImg;

  const showModal = () => {
    dispatch(
      setModal({
        id: "",
        icon: item?.icon || ticket,
        name: "",
        price: item?.price,
        packageIndex: item?.packageIndex,
      })
    );
  };
  return (
    <label
      htmlFor="box-buy-modal"
      className="cursor-pointer"
      onClick={showModal}
    >
      <ShopTag>
        {/* <div className="shop-item">
          <div className="shop-img">
            <img src={pool} alt="pool" />
          </div>
          <div className="shop-title">{item?.ticketAmount} Ticket</div>
          <div className="shop-bot">
            <div className="shop-bot-title">{item.price} BUSD </div>
            <div className="shop-plus">
              <img src={plus} alt="plus" />
            </div>
          </div>
        </div> */}
        <div className="shop-ticket">
          {/* <div className="shop-ticket--title">Ticket</div> */}
          <div className="shop-ticket--img_amount">
            <div className="shop-ticket--img">
              <img src={item?.icon || ticket} alt="ticket" />
            </div>
            <div className="shop-ticket--amount">x{item?.ticketAmount}</div>
          </div>
          <div className="shop-ticket--price">{item.price} BUSD</div>
        </div>
      </ShopTag>
    </label>
  );
};

export default ShopBox;
