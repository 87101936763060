import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { addGameToHistory, getListGames } from "pages/services/api";

import { homeImg } from "helpers/ExportImg";
import { HeaderGames } from "./HeaderGames";
import LoadingIcon from "components/LoadingIcon";
import TertiaryCard from "../games/TertiaryCard";
import { discover, genrePath } from "constants/path";
import appContext from "appContext";
import { useNavigate } from "react-router";

const SimilarGame = ({ data, change }: any) => {
  const { games, isLoading } = useSelector((state: any) => state.games);
  const [listGame, setListGame] = useState<any>([]);

  const navigate = useNavigate();

  const { freeGame, symbolFinal } = homeImg;

  useEffect(() => {
    const newGames = games?.data?.filter((game: any) => game.id !== data.id);
    setListGame(newGames);
  }, [data.id, change]);

  const handleClick = (game: any) => {
    const gameId = game?.id;
    const gameType = "miniGame";

    addGameToHistory({ gameId, gameType });
    navigate(`${discover}/${game?.id}`);
  };

  const GamesCard = () => {
    return (
      <div className="flex-center wrapper-cards similar-cards">
        <div className="mini-card">
          {listGame?.slice(0, 4).map((game: any) => (
            <div
              className="tertiary-card"
              key={game?.id}
              onClick={() => handleClick(game)}
            >
              <TertiaryCard game={game} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className="component-games top-free-game container-new"
      style={{ margin: 0, padding: 0 }}
    >
      {isLoading && <LoadingIcon />}
      <HeaderGames
        title="similar games"
        icon={freeGame}
        route={genrePath}
        show={true}
        genre={data?.categories[0]}
      />
      <GamesCard />
      {/* <img src={symbolFinal} alt="img" className="symbol-final" /> */}
    </div>
  );
};

export default SimilarGame;
