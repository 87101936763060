import React from "react";
import { Describe } from "./styled";
import world from "../../asset/games-center/icon/world.png";
import email from "../../asset/games-center/icon/envelope.png";
import comnent from "../../asset/games-center/icon/comment 1.png";
import community from "../../asset/games-center/icon/shield-check.png";
import ProgressBar from "components/progressBar";
import { AiFillStar } from "react-icons/ai";

const GameDescribe = ({ data, star, rateSum, gameType }: any) => {
  // const percentRate_1 = (star[0] * 100) / rateSum;

  const start5 = star[4];
  const start4 = star[3];
  const start3 = star[2];
  const start2 = star[1];
  const start1 = star[0];
  const starharcode = [start1, start2, start3, start4, start5];

  const rateCount = data.rateCount;
  const sum = data.rateSum;
  const overall = sum / rateCount;

  return (
    <div
      className={
        gameType !== "miniGame"
          ? "game_nft game_describe"
          : "game_mini_popular game_describe"
      }
    >
      <div
        className={
          gameType !== "miniGame" ? "content_describe" : "content_rating"
        }
      >
        {gameType !== "miniGame" && (
          <div className="describe">
            <p className="title_describe">Game Description:</p>
            <span>{data?.description}</span>
          </div>
        )}
        <div className="rate_information">
          <div className={gameType === "nftGame" ? "rate" : "rate_minipage"}>
            <div className="text_rate">
              <h1>RATINGS</h1>

              <span style={{ display: "flex", alignItems: "center" }}>
                Overall : {rateCount ? Math.round(overall * 10) / 10 : 0}{" "}
                <AiFillStar style={{ color: "orange", fontSize: "3.5rem" }} />
              </span>
            </div>
            <div className="progressbar">
              {starharcode.map((el: any, index: any) => (
                <ProgressBar
                  key={index}
                  el={el}
                  bgcolor={rateCount ? " rgba(255, 103, 0, 1)" : ""}
                  height={30}
                  rateCount={rateCount}
                  value={index + 1 + " stars"}
                />
              ))}
            </div>
          </div>
          {gameType === "nftGame" && (
            <div className="informations">
              <p className="title_infor">Informations</p>
              <div className="contact">
                <div className="content_contact" key={data.id}>
                  <div className="content_information">
                    <img src={world} alt="btn" width={30} height={30} />
                    <div>
                      <p className="title">Website</p>
                      <a
                        href={data.website}
                        target="_blank"
                        rel="noreferrer"
                        className="link-media"
                      >
                        <p>{data.website}</p>
                      </a>
                    </div>
                  </div>
                  <div className="content_information">
                    <img src={comnent} alt="btn" width={30} height={30} />
                    <div>
                      <p className="title">SNS</p>
                      <a
                        href={data.sns}
                        target="_blank"
                        rel="noreferrer"
                        className="link-media"
                      >
                        <p>{data.sns}</p>
                      </a>
                    </div>
                  </div>
                  <div className="content_information">
                    <img src={community} alt="btn" width={30} height={30} />
                    <div>
                      <p className="title">Community</p>
                      <a
                        href={data.community}
                        target="_blank"
                        rel="noreferrer"
                        className="link-media"
                      >
                        <p>{data.community}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameDescribe;
