import styled from "styled-components";

export const Shop = styled.section`
  width: 65%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 10rem;
  margin-bottom: 10rem;

  .title-ticket {
    display: flex;
    align-items: center;
    column-gap: 1.7rem;
    margin-bottom: 3.7rem;
  }

  .ticket-img {
    width: 5.1rem;
  }

  .shop-ticket--title {
    font-style: normal;
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 7.4rem;
    text-align: center;
    color: #007b63;
  }

  .shop-box {
    width: 100%;
    gap: 10rem;
  }

  .item-shop {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;
  }

  .shop-pagination {
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 1440px) {
    width: 75%;
    .shop-box {
      gap: 5rem;
    }
  }

  @media (max-width: 1200px) {
    width: 85%;
    .shop-box {
      gap: 5rem;
    }
  }

  @media (max-width: 768px) {
    width: 85%;
    padding-bottom: 6rem;
    .shop-box {
      gap: 7rem;
    }
  }
`;
