import { ButtonShop } from "components/button";
import ShopBox from "components/shopBox";
import { ItemBox } from "interfaces";
import { getPackageTicker } from "pages/services/api";
import { useEffect, useState } from "react";
import { Shop } from "./styled";
import cen from "../../asset/shop/cen.png";
import { useSelector } from "react-redux";

const ShopItem: React.FC<{ data: any[] }> = ({ data }) => {
  // const [data, setData] = useState([]);
  // const getPackages = async () => {
  //   try {
  //     const result = await getPackageTicker();
  //     if (result) setData(result?.data);
  //   } catch (error) {
  //     console.log(error);
  //     setData([]);
  //   }
  // };
  // useEffect(() => {
  //   getPackages();
  // }, []);

  const dataSort = data?.sort(function (a, b) {
    return a.id - b.id;
  });

  return (
    <Shop>
      <ButtonShop name={"Shop"} />
      <div className="title-ticket">
        <div className="ticket-img">
          <img src={cen} alt="cen" />
        </div>
        <div className="shop-ticket--title">Ticket</div>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-3 shop-box">
        {dataSort?.map((item: ItemBox) => (
          <ShopBox key={item.id} item={item} />
        ))}
      </div>
      {/* <div className="shop-pagination">
        <PaginationGame count={10} />
      </div> */}
    </Shop>
  );
};

export default ShopItem;
