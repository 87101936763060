import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

import { SliceNFTTag } from "./styled";
import { useEffect, useState } from "react";
import { slideNFT } from "helpers/ExportImg";
import { useSelector } from "react-redux";
import {
  getBingoVersion,
  getCHTSVersion,
  getCoinHaloVersion,
  getListNFTGames,
  getMarbleVersion,
  getPMGVersion,
  getRpsVersion,
} from "pages/services/api";
import useWindowSize from "hooks/useWindowSize";

function SliceNFTGame() {
  const { gamesNFT } = useSelector((state: any) => state.gamenft);

  const width: any = useWindowSize();

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeClickBtn, setActiveClickBtn] = useState(false);
  const [activePrevClickBtn, setActivePrevClickBtn] = useState(false);

  const handleClickBtn = () => {
    setActiveClickBtn(true);
    setTimeout(() => {
      setActiveClickBtn(false);
    }, 300);
  };

  const handleClickPrevBtn = () => {
    setActivePrevClickBtn(true);
    setTimeout(() => {
      setActivePrevClickBtn(false);
    }, 300);
  };
  const {
    whitepaper,
    download,
    token,
    website,
    sns,
    commu,
    back,
    nextBtn,
    center,
    left,
    right,
    bg_back_mobile,
    bg_next_mobile,
    next_unactive,
    prev_unactive,
  } = slideNFT;

  useEffect(() => {
    getListNFTGames();
  }, []);

  const [apkVersion, setApkVersion] = useState<any>({
    Rps: "",
    CoinHalo: "",
    PMG: "",
    Bingo: "",
    CHTS: "",
    Marble: "",
  });

  useEffect(() => {
    const getInfoGame = async () => {
      try {
        const [rpsGame, coinHaloGame, pmGame, bingoGame, CHTSGame, marbleGame] =
          await Promise.all([
            getRpsVersion(),
            getCoinHaloVersion(),
            getPMGVersion(),
            getBingoVersion(),
            getCHTSVersion(),
            getMarbleVersion(),
          ]);

        setApkVersion({
          Rps: rpsGame,
          CoinHalo: coinHaloGame,
          PMG: pmGame,
          Bingo: JSON.parse(bingoGame.trim()).data,
          CHTS: CHTSGame,
          Marble: marbleGame,
        });
      } catch (error) {
        console.log(error);
      }
    };
    getInfoGame();
  }, []);

  return (
    <SliceNFTTag>
      <div className="nft-game-slide">
        <Swiper
          className="mySwiper"
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.realIndex);
          }}
          navigation={{
            prevEl: ".prevEl_nft_game",
            nextEl: ".nextEl_nft_game",
          }}
          //   effect="coverflow"
          //   coverflowEffect={{
          //     rotate: 0,
          //     stretch: 0,
          //     depth: 300,
          //     slideShadows: false,
          //   }}
          // centeredSlides={true}
          loop={true}
          //   loopedSlides={6}

          centeredSlides
          spaceBetween={10}
          allowTouchMove={false}
          loopAdditionalSlides={30}
          slidesPerView={3}
          breakpoints={{
            1366: {
              spaceBetween: 20,
              slidesPerView: 3,
            },
            1000: {
              spaceBetween: 20,
              slidesPerView: 3,
            },

            280: {
              spaceBetween: 0,
              slidesPerView: 1,
            },
          }}
          modules={[Navigation, EffectCoverflow, Autoplay, Pagination]}
        >
          {gamesNFT?.map((item: any, id: any) => {
            return (
              <SwiperSlide key={item.id}>
                <div
                  className={`${
                    activeIndex === id ? "" : "un_active"
                  } nft-game-img`}
                >
                  <img src={item.images[3]} alt="img" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={`prevEl_nft_game `} onClick={handleClickPrevBtn}>
          {activePrevClickBtn ? (
            <img
              src={prev_unactive}
              alt="btn-back"
              style={{ cursor: "pointer" }}
              className="click-btn"
            />
          ) : (
            <img
              src={back}
              alt="btn-back"
              style={{ cursor: "pointer" }}
              className="click-btn"
            />
          )}
        </div>
        <div className="nextEl_nft_game" onClick={handleClickBtn}>
          {activeClickBtn ? (
            <img
              src={next_unactive}
              alt="btn=next"
              style={{ cursor: "pointer" }}
              className="click-btn"
            />
          ) : (
            <img
              src={nextBtn}
              alt="btn=next"
              style={{ cursor: "pointer" }}
              className="click-btn"
            />
          )}
        </div>
        {width > 767 ? (
          <div className="game-tect game-tect--left ">
            <img src={left} alt="img" />
          </div>
        ) : (
          <div className="game-tect game-tect--left bg_back_mobile">
            <img src={bg_back_mobile} alt="img" />
          </div>
        )}

        <div className="game-tect game-tect--center">
          <img src={center} alt="img" />
        </div>
        {width > 767 ? (
          <div className="game-tect game-tect--right">
            <img src={right} alt="img" />
          </div>
        ) : (
          <div className="game-tect game-tect--right bg_back_mobile">
            <img src={bg_next_mobile} alt="img" />
          </div>
        )}
      </div>

      {[gamesNFT[activeIndex]]?.map((item: any) => (
        <div className="nft-game-content container-new content-nft">
          <div className="container-wrapper">
            <div className="nft-content-top">
              <a href={item?.whitePaper} target="_blank" rel="noreferrer">
                <div className="nft-content-whitepaper btn-hover">
                  <div className="img-whitepaper">
                    <img src={whitepaper} alt="whitepaper" />
                  </div>
                  <div className="nft-text">WHITEPAPER</div>
                </div>
              </a>
              <a href={item?.tokenomic} target="_blank" rel="noreferrer">
                <div className="nft-content-whitepaper btn-hover">
                  <div className="img-token">
                    <img src={token} alt="token" />
                  </div>
                  <div className="nft-text">TOKENOMIC</div>
                </div>
              </a>

              {/* rps */}
              {activeIndex === 5 && (
                <a href={apkVersion.Rps?.link} rel="noreferrer">
                  <div className="nft-content-downlod btn-hover">
                    <div className="img-download">
                      <img src={download} alt="download" />
                    </div>
                    <div className="nft-text">DOWNLOAD</div>
                  </div>
                </a>
              )}
              {/* coin  */}
              {activeIndex === 0 && (
                <a href={apkVersion.CoinHalo?.linkDownApk} rel="noreferrer">
                  <div className="nft-content-downlod btn-hover">
                    <div className="img-download">
                      <img src={download} alt="download" />
                    </div>
                    <div className="nft-text">DOWNLOAD</div>
                  </div>
                </a>
              )}
              {/* mar ble */}
              {activeIndex === 4 && (
                <a href={apkVersion.Marble?.link} rel="noreferrer">
                  <div className="nft-content-downlod btn-hover">
                    <div className="img-download">
                      <img src={download} alt="download" />
                    </div>
                    <div className="nft-text">DOWNLOAD</div>
                  </div>
                </a>
              )}
              {/* bingo  */}
              {activeIndex === 2 && (
                <a href={apkVersion.Bingo?.apk} rel="noreferrer">
                  <div className="nft-content-downlod btn-hover">
                    <div className="img-download">
                      <img src={download} alt="download" />
                    </div>
                    <div className="nft-text">DOWNLOAD</div>
                  </div>
                </a>
              )}
              {/* creter */}
              {activeIndex === 1 && (
                <a href={apkVersion.CHTS?.link_apk} rel="noreferrer">
                  <div className="nft-content-downlod btn-hover">
                    <div className="img-download">
                      <img src={download} alt="download" />
                    </div>
                    <div className="nft-text">DOWNLOAD</div>
                  </div>
                </a>
              )}
              {/*pmg  */}
              {activeIndex === 3 && (
                <a href={apkVersion.PMG?.link} rel="noreferrer">
                  <div className="nft-content-downlod btn-hover">
                    <div className="img-download">
                      <img src={download} alt="download" />
                    </div>
                    <div className="nft-text">DOWNLOAD</div>
                  </div>
                </a>
              )}
            </div>
            <div className="nft-content-bot">
              <div className="nft-content-bot-title">
                <span className="title-game">{item?.name}</span>

                <span className="category-game">NFT - PLAY TO EARN</span>
              </div>
              <div className="desc-info">
                <div className="nft-content-bot-desc">
                  <div className="nft-content-bot-desc-title">DESCRIPTION:</div>
                  {/* rps */}
                  {activeIndex === 5 && (
                    <div className="nft-content-ver">
                      Version: {apkVersion.Rps?.version}
                    </div>
                  )}
                  {/* coin  */}
                  {activeIndex === 0 && (
                    <div className="nft-content-ver">
                      Version: {apkVersion.CoinHalo?.version}
                    </div>
                  )}
                  {/* mar ble */}
                  {activeIndex === 4 && (
                    <div className="nft-content-ver">
                      Version: {apkVersion.Marble?.version}
                    </div>
                  )}
                  {/* bingo  */}
                  {activeIndex === 2 && (
                    <div className="nft-content-ver">
                      Version: {apkVersion.Bingo?.version}
                    </div>
                  )}
                  {/* creter */}
                  {activeIndex === 1 && (
                    <div className="nft-content-ver">
                      Version: {apkVersion.CHTS?.version}
                    </div>
                  )}
                  {/*pmg  */}
                  {activeIndex === 3 && (
                    <div className="nft-content-ver">
                      Version: {apkVersion.PMG?.version}
                    </div>
                  )}
                  <div className="nft-content-bot-desc-text">
                    {item?.description}
                  </div>
                </div>
                <div className="nft-content-bot-info">
                  <div className="nft-content-bot-desc-title">Information:</div>
                  <div className="nft-content-bot-info-social">
                    <div className="nft-content-bot-info-social-web">
                      <div className="nft-content-bot-info-img">
                        <img src={website} alt="website" />
                      </div>
                      <div className="nft-content-bot-info-info">
                        <div className="nft-content-bot-info-title">
                          Website
                        </div>
                        <div className="nft-content-bot-info-link">
                          <a
                            href={item?.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.website}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="nft-content-bot-info-social-web">
                      <div className="nft-content-bot-info-img">
                        <img src={sns} alt="website" />
                      </div>
                      <div className="nft-content-bot-info-info">
                        <div className="nft-content-bot-info-title">SNS</div>
                        <div className="nft-content-bot-info-link">
                          <a
                            href={item?.sns}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.sns}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="nft-content-bot-info-social-web">
                      <div className="nft-content-bot-info-img">
                        <img src={commu} alt="website" />
                      </div>
                      <div className="nft-content-bot-info-info">
                        <div className="nft-content-bot-info-title">
                          Community
                        </div>
                        <div className="nft-content-bot-info-link">
                          Playground Game Center
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </SliceNFTTag>
  );
}

export default SliceNFTGame;
