import React, { useEffect, useState } from "react";

import useWindowSize from "hooks/useWindowSize";
import { GameCardHistoryWraper, GameCardNew, NotificationCardWraper } from "./styled";
import { Link } from "react-router-dom";
import { openSourcePath } from "constants/path";
import { logos } from "helpers/ExportImg";
import PopupNoti from "components/popupNoti";

const GameCard: any = ({ game }: any) => {


  const { image, id, name, thumbnail, description, createdAt, seen } = game;
  const [openPopup, setOpenPopup] = useState(false);
  const [getSeen, setSeen] = useState(seen);

  // const nameRep = name.replaceAll(" ", "-").toLowerCase();
  // const sum = game_mission.reduce((accumulator: number, currentObject: { pointToPass: number; }) => accumulator + currentObject.pointToPass, 0);
  const getTime = (time: string | number | Date) => {
    // Lấy thời điểm cụ thể
    const targetTime = new Date(time);

    // Lấy timestamp của thời điểm cụ thể
    const targetTimeInMillis = targetTime.getTime();

    // Lấy timestamp của thời điểm hiện tại
    const nowInMillis = new Date().getTime();

    // Tính thời gian theo giờ tới thời điểm cụ thể
    const remainHours = (nowInMillis - targetTimeInMillis) / (1000 * 60 * 60);
    return remainHours.toFixed(2)
  }
  function ReactHtmlParser(description: any): React.ReactNode {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <NotificationCardWraper>
        <PopupNoti open={openPopup} setOpen={setOpenPopup} game={game} setSeen={setSeen} getSeen={getSeen} />
        <button className="w-full" onClick={(e: any) => {
          if (e.target?.tagName === "A") {
            return
          }
          setOpenPopup(true)
        }
        }>
          <div className="inline-block w-full">
            <div className={`flex items-center w-full    ${getSeen ? 'bg-[#FFFFFF]' : 'bg-[#E6FFF0]'}  px-2 py-8 `} >
              {/* <div className="flex-shrink-0 ml-4">
                <img className="w-[30px] h-[30px] rounded-full" src=''
                  alt="img" />
              </div> */}
              <div className="flex-1 min-w-0 ms-4 ml-6 px-10">
                <p className="game-card-new-title" dangerouslySetInnerHTML={{ __html: description }}
                >
                </p>
                <div className="game-card-new-play-count flex  items-center	">
                  <div className="" >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      fill="none"
                    >
                      <g
                        stroke="#7E7E7E"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                      >
                        <path d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10Z" />
                        <path d="M6 3v3l2 1" />
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path fill="#fff" d="M0 0h12v12H0z" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="ml-2 game-card-new-play-count-text flex"> {getTime(createdAt)} hour ago</div>
                </div>
              </div>
            </div>
          </div>
        </button>
      </NotificationCardWraper>
    </>
  );
};

export default GameCard;
