
import { openSourcePath } from "constants/path";
import { logos } from "helpers/ExportImg";
import { Link } from "react-router-dom";
import { GameCardNew } from "./styled";

const GameCard: any = ({ game }: any) => {


  const { image, id, name, thumbnail, rewardPoint, game_mission } = game;

  const nameRep = name.replaceAll(" ", "-").toLowerCase();
  const sum = game_mission.reduce((accumulator: number, currentObject: { pointReward: number; }) => accumulator + currentObject.pointReward, 0);

  return (
    <>
      <GameCardNew>
        <Link onClick ={async ()=>{} } to={`${openSourcePath}/${nameRep}/${id}`}>
        <div className="inline-block ">
          <div className="flex items-center w-[300px] h-[76px]  overflow-hidden rounded-lg " >
            <div className="flex-shrink-0 ">
              <img className="w-[60px] h-[60px] rounded-lg" src={image || thumbnail}
                alt="img" />
            </div>
            <div className="flex-1 min-w-0 ms-4 ml-6">
              <p className="game-card-new-title">
                {name}
              </p>
              <div className="game-card-new-play-count flex  items-center	">
              <div className="" >
                <img className="w-[15px] h-[15px] mt-[2px]" src={logos.currency}
                alt="img" />
                </div>
                <div className="ml-2 game-card-new-play-count-text flex"> Reward: {rewardPoint }/ {sum}</div>
              </div>
            </div>
          </div>
        </div>
        </Link>
      </GameCardNew>
    </>
  );
};

export default GameCard;