import appContext from "appContext";
import { useContext, useEffect } from "react";
import avatar from "asset/account/avatar.png";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const GenrePopular = ({ genres }: any) => {
  const { setCategories, setInfoGenre, setPage, categories } =
    useContext(appContext);

  const handleClickCard = (genre: any) => {
    sessionStorage.setItem("activeSlide", "1");
    setCategories(genre?.id);
    setInfoGenre(genre);
    setPage(1);
  };

  const GenreCard = ({ genre, mobile }: any) => {
    return (
      <div
        className={`${mobile ? "card-genre mobile-genre" : "card-genre"} ${
          +categories === genre.id && "card-genre-active"
        }`}
        onClick={() => handleClickCard(genre)}
      >
        <img
          src={genre?.image || avatar}
          alt="genre"
          className={`${
            genre?.name === "Solitaire"
              ? "img-genre img-solitaire"
              : "img-genre"
          }`}
          // className="img-genre"
        />
        <div className="content-genre">
          <span className="name-genre">{genre?.name}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="container-genre">
      {/* <h1 className="title-genre">All Genre Game</h1> */}
      <div className="wrapper-genre">
        {genres.map((genre: any) => (
          <GenreCard genre={genre} key={genre?.id} />
        ))}
      </div>
    </div>
  );
};

export default GenrePopular;
