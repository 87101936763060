import { logos } from "helpers/ExportImg";
import { useEffect, useState } from "react";
import { PopupTag } from "./styled";

import { seenUserNotification } from "pages/services/api";
import { capitalizeFirstLetter } from "helpers/util";



const PopupNoti = ({open, setOpen, game , setSeen, getSeen}: any) => {


  const handlerClosePopup = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState<boolean>(false);

  const { point, type, description, unit} = game;

  useEffect(() => {
   if(open === true && !getSeen ) fetchApiSeenAll()
  }, [open]);

  const fetchApiSeenAll = async () => {
    try {
      setLoading(true);
      await seenUserNotification( game.id, {
        "seen": true
      })
      setLoading(false);
      setSeen(true)
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <PopupTag>

      <>
        {open && (
          <>
            <div onClick={handlerClosePopup} className={`backdrop`}></div>
            <div className="popup-img">


              <div className={`popup img-mobile relative min-w-[80%]`}>
                <>
                  {/* <img className=" absolute -top-40 w-80" src={logos.coinPopup}
                    alt="img" /> */}
                  <div className="bg-[#F0F0F0] rounded-3xl p-20 min-w-[80%]">

                    <div>
                      <div className={`popup-title text-center  pt-5 ${type === '-'? 'text-[#FD4B53]': 'text-[#06C270]'}`} >
                        {type}{point} {capitalizeFirstLetter(unit)}
                      </div>

                      <p className="game-card-new-title mt-5" dangerouslySetInnerHTML={{ __html: description || '' }}
                      />

                      <button onClick={handlerClosePopup} className="popup-btn mt-12 w-full">
                      Close
                      </button>

                    </div>

                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupNoti;
