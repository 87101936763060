import { useEffect, useState } from "react";

import { footerImg } from "helpers/ExportImg";

import ListLotteryEvents from "components/List/ListLotteryEvents";
import { useAppDispatch } from "index";
import { useParams } from "react-router";
import { setPageState } from "slices/pageSlice";
import { getEventById } from "./services/api";
import TopPrize from "components/List/TopPrize";
import TableUserLucky from "components/Table/TableUserLucky";
import TopPrizeDay from "components/List/TopPrizeDay";
import TableHistoryDayEvent from "components/Table/TableHistoryDayEvent";

const LotteryEvent = () => {
  const params = useParams();
  const [eventData, setEventData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const id = parseInt(params.id!);
  const dispatchApp = useAppDispatch()
  const { controlMobile } = footerImg;
  
  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await getEventById(id);
      setLoading(false);
      setEventData(data);
    } catch (err) {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchApi();
  }, []);


  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: true,
      tab: 2,
      title: '',
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'


    }
    ))
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: true,
      tab: 2,
      title: eventData?.name || '',
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'

    }
    ))
  }, [eventData]);
  
  

  return (
    <>
      {/* <Popup /> */}
      {/* <PopupPlay/> */}
      {/* <PopupAlert/> */}
      {/* <PopupNoti/> */}
      <div className="flex-center flex-col mt-[80px] pb-[50px] ">
        {loading ? (
          <img
            style={{ padding: "5rem 0", margin: 'auto' }}
            src={controlMobile}
            alt="img"
            className="logo-loading"
          />
        ) : (
          <>
            <ListLotteryEvents eventData={eventData} />
            <TopPrizeDay eventData={eventData} />
            <TableHistoryDayEvent/>
            {/* <TableUserLucky eventData={eventData} /> */}
          </>

        )}
      </div>
    </>
  );
};

export default LotteryEvent;
