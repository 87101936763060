import LoadingIcon from "components/LoadingIcon";
import React, { useEffect } from "react";

import Hero from "components/home/hero";
import Search from "components/home/search/Search";
import Popup from "components/popup";
import { adsImg, popup } from "helpers/ExportImg";
import useWindowSize from "hooks/useWindowSize";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import GameOpenSource from "./GameOpenSource";
import { getListNFTGames, getListPopularGameMini } from "./services/api";
import { useAppDispatch } from "index";
import { setPageState } from "slices/pageSlice";
import PopupPlay from "components/popupPlay";
import PopupAlert from "components/popupAlert";
import PopupNoti from "components/popupNoti";
import TopFreeGame from "components/home/TopFreeGame";
import OpenSourceAll from "components/List/OpenSourceAll";
import FunnyGame from "components/List/FunnyGame";
import Recent from "components/List/Recent";
import Popular from "components/List/Popular";
import Recommend from "components/List/Recommend";
import GoogleAds from "ads/GoogleAds";
import FilterGenreFunnyGame from "components/List/FilterGenreFunnyGame";

const HomePage = () => {
  const navigate = useNavigate();
  const { gamesNFT, isLoading } = useSelector((state: any) => state.gamenft);
  const { gamesPopular } = useSelector((state: any) => state.gamepopular);

  const width: any = useWindowSize();

  const { popupImg, popupImgMobile } = popup;
  const dispatchApp = useAppDispatch()

  // const popupHome = () => {
  //   if (width > 768) {
  //     sweetalert2.fire({
  //       width: 1200,
  //       customClass: "swal-desk",
  //       html: `
  //                 <div id="popup-desk"}>
  //                   <img src=${popupImg} class="w-100"/>
  //                 </div>
  //               `,
  //       showConfirmButton: false,
  //       background: "transparent",
  //       backdrop: `rgba(0,0,0,.6)`,
  //       showCloseButton: true,
  //     });
  //   } else {
  //     sweetalert2.fire({
  //       width: 800,
  //       customClass: "swal-desk",
  //       html: `
  //                 <div id="popup-desk"}>
  //                   <img src=${popupImgMobile} class="w-100"/>
  //                 </div>
  //               `,
  //       showConfirmButton: false,
  //       background: "transparent",
  //       backdrop: `rgba(0,0,0,.6)`,
  //       showCloseButton: true,
  //     });
  //   }
  // };

  // React.useEffect(() => {
  //   popupHome();
  // }, []);

  useEffect(() => {
    getListPopularGameMini();
  }, []);
  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: false,
      showAccount: true,
      tab: 1,
      title: '',
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'

    }
    ))
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      {/* <Popup /> */}
      {/* <PopupPlay/> */}
      {/* <PopupAlert/> */}
      {/* <PopupNoti/> */}
      <div className="flex-center wrapper-home mt-[40px] pb-[50px] ">
        {isLoading && <LoadingIcon />}
        <div className="w-full">
          {/* <div className="mt-10  w-full rounded overflow-hidden shadow-lg  flex items-center justify-center	">
            <div id="container-e49302f0df0a34efc084889cd3314a5d"></div>
          </div> */}
        </div>
        {/* <Banner data={gamesNFT} /> */}
        {/* <HeroBanner/> */}
        <Search />
        <Hero />
        {/* <NFTGames data={gamesNFT} /> */}
        {/* <GameOpenSource /> */}
        <OpenSourceAll />
        <Recent />
        <Popular />
        <Recommend />
        <FilterGenreFunnyGame />
        {/* <FunnyGame /> */}
        {/* <FunnyGame categoriesId="11" categoriesName="IO" />
        <FunnyGame categoriesId="257" categoriesName="Action" />
        <FunnyGame categoriesId="17" categoriesName="Puzzle" /> */}
        {/* <PopularGame data={gamesPopular} /> */}
        {/* <TopFreeGame /> */}
      </div>
    </>
  );
};

export default HomePage;
