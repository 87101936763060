import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import { useState, useRef } from "react";
import ModalWallet from "components/modals/ModalWallet";
import { config } from "app/environment";
import { CustomDropDown } from "./styled";
import useWindowSize from "hooks/useWindowSize";
import useClickOutSide from "hooks/useClickOutside";
import CostReward from "components/costReward";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setModalWallet } from "slices/modalSlice";
import { headerImg } from "helpers/ExportImg";

const WalletNew: React.FC<{
  className?: any;
}> = ({ className }) => {
  const { address: account } = useAccount();

  const dispatch = useDispatch();

  const { chain } = useNetwork();

  const [visibleWallet, setVisibleWallet] = useState<boolean>(false);

  const openModalWallet = () => {
    // setVisibleWallet(true);
    dispatch(setModalWallet({ isVisible: true }));
  };

  const {
    switchNetworkAsync,
    isLoading: loadingChangeNetwork,
    error,
    reset,
    chains,
    ...switchNetworkArgs
  } = useSwitchNetwork({});

  const switchNetWork = async () => {
    try {
      await switchNetworkAsync?.(+(config?.CHAIN_ID || 97));
    } catch (error) {
      console.log("error:", error);
    }
  };

  const { user } = useSelector((state: any) => state.auth);

  let numPoint = user?.pointPool?.point;

  const usFormat = numPoint?.toLocaleString("en-US");

  let numTicket = user?.ticket?.amount;

  const usFormatTicket = numTicket?.toLocaleString("en-US");

  const { cen, ruong } = headerImg;

  const width: any = useWindowSize();

  const dropDownRef = useRef(null);

  const closeSocialHandler = (e: any) => {
    if (!openDropdown) {
      return setOpenDropdown(!openDropdown);
    }
    setOpenDropdown(false);
  };
  useClickOutSide(closeSocialHandler, dropDownRef);

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const { disconnect } = useDisconnect();

  const handleLogout = () => {
    disconnect();
    localStorage.removeItem("accessToken");
    window.location.reload();
  };

  return (
    <>
      <button
        onClick={
          account ? () => setOpenDropdown(!openDropdown) : openModalWallet
        }
        className={`${className} cursor-pointer`}
      >
        {account ? (
          <>
            {account.slice(0, 4)}...
            {account.slice(account.length - 4)}
            {openDropdown && (
              <CustomDropDown
                ref={dropDownRef}
                className="animate__animated animate__bounceInDown animate__faster"
              >
                <div className={`drop-down`}>
                  <span>
                    <Link to={"/account"}>My Account</Link>
                  </span>
                  {/* <span>
                    <Link to={"/point-history"}>Withdraw</Link>
                  </span> */}
                  {width > 1000 ? (
                    <></>
                  ) : (
                    <>
                      <div className="ticket-point">
                        <span>Ticket: {usFormatTicket || 0}</span>
                        <div className="ticket-img">
                          <img src={cen} alt="img ticket" />
                        </div>
                      </div>
                      <div className="ticket-point">
                        <span>Point: {usFormat || 0}</span>
                        <div className="point-img">
                          <img src={ruong} alt="img point" />
                        </div>
                      </div>
                    </>
                  )}
                  {chain?.id !== +config.CHAIN_ID ? (
                    <span onClick={switchNetWork}>Switch Network</span>
                  ) : (
                    ""
                  )}
                  <div className="border-setting"></div>
                  <span onClick={handleLogout}>Disconnect</span>
                </div>
              </CustomDropDown>
            )}
          </>
        ) : (
          "Connect wallet"
        )}
      </button>
      {/* <ModalWallet
        visible={visibleWallet}
        onClose={() => setVisibleWallet(false)}
      /> */}
    </>
  );
};

export default WalletNew;
