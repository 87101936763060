import styled from "styled-components";

export const NoticeStyle = styled.section`
  width: 80%;
  min-height: 100vh;
  display: flex;
  align-items: start;
  flex-direction: column;
  overflow-x: hidden;

  .notice {
    width: 100%;
  }

  .notice-search {
    background: #ffffff;
    border: 3px solid #00896f;
    box-shadow: 0px 3px 0px #00896f;
    border-radius: 81px;
    height: 52px;
    width: 263px;
    padding: 12px 27px;

    display: flex;
    align-items: center;
    column-gap: 16.5px;
  }

  .notice-search_icon {
    color: #383838;
    font-size: 25px;
    height: 100%;
  }

  .notice-search input {
    height: 100%;
    width: 90%;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #383838;
    outline: none;
    background-color: transparent;
  }

  .notice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .notice-content {
    background: #ffffff;
    border: 3px solid #00896f;
    box-shadow: 0px 6px 0px #00896f;
    border-radius: 10px;
    padding: 36px 50px;
    margin-top: 28px;
  }

  .notice-content_title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    text-transform: uppercase;
    color: #007b63;
    margin-bottom: 24px;
  }

  .notice-content_info {
    margin-left: 26px;
  }

  .notice-content_info__main {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 20px;
  }

  .notice-content_info__img {
    min-width: 20px;
    height: 18px;
  }

  .notice-content_info__img img {
    width: 100%;
    height: 100%;
  }

  .notice-content_info__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #383838;
  }

  .notice-content_info__viewall {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #3b72fe;
  }

  .arrow_down {
    font-size: 20px;
  }

  @media (max-width: 900px) {
    .notice-header {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
    }
  }

  @media (max-width: 600px) {
    .notice-content {
      padding: 20px 25px;
    }

    .notice-content_info__desc,
    .notice-content_info__viewall {
      font-size: 18px;
      line-height: 25px;
    }
  }

  @media (max-width: 420px) {
    .notice-content_info__desc,
    .notice-content_info__viewall {
      font-size: 16px;
    }
  }
`;
