import React from "react";

const TeamsAndConditionsPage = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  });

  return (
    <div className="game_page">
      <div className="conditions_page">
        <h1>TERMS AND CONDITIONS</h1>
        <span>
          By viewing or accessing <span>https://games.playgroundx.site/</span>,
          products, or services by Playground Ltd Co. (hereafter referred to as,
          ”Playground”, ”We”, ”Us” or ”Our”, “Our Website”), You expressly agree
          to the following Terms and Conditions (hereafter referred to as,
          “Terms of Use”, “Terms”) detailed below. These Terms apply to all
          content, software, and services available on Our Website. By using Our
          Website, as well as receiving the required notices and transacting
          with Us via computer, you agree to these Terms. If you do not agree
          with these terms, we recommend that you do not use our website.
          <br />
          1. TERMS AND CONDITIONS
          <br />
          2. ELECTRONIC-DELIVERY POLICY AND YOUR CONSENT
          <br />
          3. SERVICES AVAILABILITY
          <br />
          4. PRIVACY POLICY
          <br />
          5. ACCESS COSTS
          <br />
          6. YOUR RESPONSIBILITIES
          <br />
          7. USER CONDUCTION
          <br />
          8. AGE RESTRICTIONS
          <br />
          9. UPLOADING GAMES
          <br />
          10. VIRUSES
          <br />
          11. THIRD-PARTY WEBSITES
          <br />
          12. THIRD-PARTY GAMES
          <br />
          13. INTELLECTUAL PROPERTY
        </span>

        <p>1. TERMS AND CONDITIONS</p>
        <p>1.1 CHANGES TO THE TERMS</p>
        <span>
          We may change these Terms of Use at any time, You are responsible for
          checking periodically any changes. The phrase "Last modified,"
          followed by a date, will alert You to changes to these Terms. Please
          do not use Our Website if You do not agree to these future changes to
          Our Terms.
        </span>
        <p>2. ELECTRONIC-DELIVERY POLICY AND YOUR CONSENT</p>
        <span>
          You agree that We may send You necessary notices, agreements, and
          other website-related information electronically. If You no longer
          agree to receive notices electronically, please cease to use the
          Website
        </span>
        <p>3. SERVICES AVAILABILITY</p>
        <span>
          {" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> aims
          to provide a stable and reliable Website. However, it may happen that
          games and services are unavailable due to maintenance of the system or
          technical problems.{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> may
          also suspend or permanently remove any game or service for any reason
          without Your consent or giving prior notice.{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> will
          not be liable for any loss, damage, or upset that You suffer as a
          consequence of a game or service becoming temporarily or permanently
          unavailable
        </span>
        <p>4. PRIVACY POLICY</p>
        <span>
          We created Our Privacy Policy to make important disclosures about Our
          privacy practices with regard to any personal data You provide Us or
          that we receive from You. For all questions about how We use the
          information, We obtain through Your use of the Website
        </span>
        <p>5. ACCESS COSTS</p>
        <span>
          You must provide the equipment and Internet connections required to
          access and use Our Website at Your own expense. If You access Our
          Website via phone, please check with Your local phone company to see
          if the access numbers You choose are subject to long-distance or other
          toll charges in Your area. Furthermore, if You access the Website
          through wireless applications (e.g., cell phones), Your carrier, such
          as a wireless carrier, may charge fees for alerts, web browsing,
          messaging, and other services that necessitate the use of airtime and
          wireless data services. Check with Your carrier to verify whether any
          such fees apply to you. You are solely responsible for any costs You
          incur to access the Websites through any wireless or other
          communication services
        </span>
        <p>6. YOUR RESPONSIBILITIES</p>
        <p>6.1. USE OF THE WEBSITE</p>
        <span>
          You may use the Website for lawful purposes only. By using Our Website
          You must keep Your contact information up to date
        </span>
        <p>6.2. RESTRICTION OF USE</p>
        <span>
          You may not use Our Websites if:
          <br />
          1 .For Your own commercial gain;
          <br />2 .To offer any form of advertising or promotion without Our
          prior written consent
          <br /> 3 .To provide any false personal information or any
          information, content, or material on account of anyone other than
          Yourself without permission. You may not use Our Website in any manner
          that could damage, disable, overburden, or impair Our servers or
          networks or interfere with any other party's use and enjoyment of the
          Website. You may not attempt to gain unauthorized access to any
          services, user accounts, computer systems, or networks through
          hacking, password mining, or any other means. We may take any legal
          and technical measures to prevent the violation of this provision and
          to enforce these Terms of Use. You may not use the Website or any of
          Our communication tools to transmit, directly or indirectly, any
          unsolicited bulk communications (including emails and instant
          messages). You may not collect information about Our users in order to
          send (or assist in the sending of) unsolicited bulk communications.
          You may not induce or allow others to use the Website to violate the
          terms of this section. If You or anyone using Your access to the
          Website violates these provisions, we may immediately terminate Your
          access to or use of the Website and take other legal action. We
          reserve the right to take any technical measures necessary to prevent
          unsolicited bulk communications from entering, using, or remaining in
          Our computer or communications networks.
        </span>
        <p>6.3. SUBMITTING ON THE WEBSITE</p>
        <span>
          You may not submit or transmit through the Website any information,
          content, or material or otherwise engage in any conduct that:
          <br />1 .Violates or infringes the rights of others, including,
          without limitation, patent, trademark, trade secret, copyright,
          publicity, or other proprietary rights;
          <br />2 .Is unlawful, threatening, abusive, harassing, defamatory,
          libelous, deceptive, fraudulent, invasive of another”s privacy,
          tortuous, contains explicit or graphic descriptions or accounts of
          sexual acts, or is pornographic;
          <br />3 .Victimizes harasses, degrades, or intimidates an individual
          or group of individuals on the basis of religion, gender, sexual
          orientation, race, ethnicity, age, or disability;
          <br />4 .Impersonates any person, business, or entity, including [URL
          Name] and its employees and agents;
          <br />5 .Contains viruses or any other computer code, files, or
          programs that interrupt, destroy, or limit the functionality of any
          computer software or hardware, or telecommunications equipment, or
          that otherwise permit the unauthorized use of a computer or computer
          network;
          <br />6 Encourages conduct that would constitute a criminal offense or
          give rise to civil liability;
          <br />7 .Violates these Terms of Use, guidelines, or any policy posted
          on the Websites;
          <br />8 .Interferes with the use of the Websites by Any intellectual
          property (or similar rights)-protected information, content, or
          material You create and submit or transmit through Our Website shall
          be licensed to Us as a nonexclusive, transferable, sub-licensable,
          royalty-free, perpetual, worldwide license, and We shall be entitled
          to use the such intellectual property on or in connection with the
          Website. Except in the case where We have signed an exclusive
          agreement When You provide information, content, or material and use
          the public privacy setting, every website visitor will have access to
          that information, and We have no control over what users do with that
          information. We reserve the right to remove any of your information,
          content, or material from the Website if it violates these Terms of
          Use.
          <br />
          UNDER NO CIRCUMSTANCES INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE,
          SHALL{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> OR
          ANY THIRD-PARTY CONTENT PROVIDER OR THEIR RESPECTIVE AGENTS BE LIABLE
          FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
          DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO USE{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> WEB
          SITES, SOFTWARE AND/OR SERVICES, EVEN IF SUCH PARTY HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE TO ASSUME ALL RISKS
          RELATED TO YOUR USE OF{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span>{" "}
          WEBSITES AND SERVICES, INCLUDING BUT NOT LIMITED TO, THE RISK OF
          COMMUNICATIONS WITH OTHER PEOPLE OR DAMAGE TO YOUR COMPUTER.
        </span>
        <p>7. USER CONDUCTION</p>
        <span>
          Users who use inappropriate language or behavior as determined by [URL
          Name] may be banned, have their accounts suspended, or have their
          accounts terminated across the entire[URL Name] network websites or
          any game available on Our Website.{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> is
          not responsible for user content, postings, chat, and/or
          communications. Users are responsible for their own actions. You agree
          not to do any of the following:
          <br />
          1. Post, distribute, or otherwise make available or transmit any data,
          text, message, or computer file that We deem to be: (a) defamatory,
          abusive, harassing, insulting, threatening, or that could be deemed to
          be stalking; (b) bigoted, hateful, or racially offensive; (c) vulgar,
          obscene, or sexually explicit (language or images); or that (d)
          encourages or advocates illegal activity or the discussion of illegal
          activities with the intent to commit them;
          <br />
          2. Post, distribute, or otherwise make available or transmit any data,
          text, message, computer file, or other material that infringes and/or
          violates any third-party right or any domestic or international law,
          rule, or regulation, including but not limited to: (a) copyright,
          patent, trademark, or other proprietary rights; (b) right of privacy
          (specifically, You must not distribute another person's personal
          information of any kind without their express permission); or (c)
          right of publicity (specifically, You must
          <br />
          3. Advertise or sell any products, services, or otherwise (whether or
          not for profit), or solicit others or use any Forum for commercial
          purposes of any kind;
          <br />
          4. Post, distribute, or otherwise make available or transmit any
          software or other computer files that contain a virus or other harmful
          component;
          <br />
          5. Impersonate any person or entity or misrepresent Your identity or
          affiliation with any person or entity;
          <br />
          6. Engage in antisocial, disruptive, or destructive acts, including
          “flaming,” “spamming,” “flooding,” “trolling,” and “griefing” as those
          terms are commonly understood and used on the Internet; 7. Post,
          distribute, or otherwise make available or transmit any data, text,
          message, computer file, or other material that infringes and/or
          violates any third-party right or any domestic or international law,
          rule, or regulation, including but not limited to: (a) copyright,
          patent, trademark, or other proprietary rights; (b) right of privacy
          (specifically, You must not distribute another person's personal
          information of any kind without their express permission); or (c)
          right of publicity (specifically, You must
          <br />
          7. Delete any legal notices, disclaimers, or proprietary notices such
          as copyright or trademark symbols, or modify any logos that You do not
          own or have express permission to modify;
          <br />
          8. Post, distribute, or otherwise make available or transmit material
          or make statements that do not generally pertain to the designated
          topic or theme of any Forum. We reserve the right to remove any
          material, and if We deem it appropriate, to turn over to law
          enforcement officials, any material, including message, e-mail, or
          posting, that is in violation of these Rules. We also reserve the
          right to remove messages, e-mails, or postings that do not pertain to
          the designated topic or theme of the service as determined by [URL
          Name]. We also reserve the right to comply with any order or otherwise
          cooperate with law enforcement officials regarding the identification
          of any user alleged to be using a{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span>{" "}
          network website or services in violation of the law.
        </span>
        <p>8. AGE RESTRICTIONS</p>
        <span>
          The <span style={{ fontWeight: "700" }}>Playground Game Center</span>{" "}
          network or its services are not intended for persons under 13 years of
          age. By accessing or using the services, You represent and warrant
          that You are 13 or older. Our website is not intended for children
          under the age of 13. By accessing and/or using Our website, you
          represent and warrant that you are 13 or older. In addition, parents
          and guardians of children over the age of thirteen should be aware
          that Our Website and services are designed to appeal to a broad
          audience. As a result, it is the responsibility of the children's
          parents or guardians to determine whether any portion of the website
          or services is appropriate for their child. Moreover, any person
          allowing under 13-year-olds to access the{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span>{" "}
          network websites or services, with its computer, internet-enabled
          device, internet connection, and/or facilities (whether owned, leased,
          or borrowed) shall assume full liability for any consequences and
          UNDER NO CIRCUMSTANCES INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE
          NEITHER{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> ,
          ANY THIRD PARTY CONTENT PROVIDER NOR THEIR RESPECTIVE AGENTS SHALL BE
          LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
          DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO USE THE SITE BY
          USERS UNDER 13 YEARS OF AGE, EVEN IF SUCH PARTY HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES.
        </span>
        <p>9. UPLOADING GAMES</p>
        <span>
          By uploading a file, you represent and warrant that you own the
          copyright or have the necessary rights to do so.
          <br />
          If you have your own sponsors, please be aware that we reserve the
          right to block external links and/or cover up in-game advertisements
          that do not adhere to our advertisement policy. Uploading games is for
          non-commercial purposes only. You must respect the following rules:
          <br />
          1. Be at least 13 years of age;
          <br />
          2. Not upload any software or any computer file that contains a virus
          or other harmful component;
          <br />
          3. You also agree to respect our logo rules, you understand that your
          file might not be posted on{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> if
          the logo and/or intro is missing.
          <br />
          4. You agree that We reserve the right to reject any game
        </span>
        <p>10. VIRUSES</p>
        <span>
          You understand that it is Your responsibility to keep the anti-virus
          software on Your computer up to date. We will take reasonable steps to
          ensure that the content on the site is free from viruses and other
          malicious software. We will not be liable for any loss or damage You
          incur as a result of receiving a virus or other malicious software
          through Our website. We will not be liable for any data loss or
          corruption that You may suffer as a result of receiving a virus or
          other malicious software via Our website.
        </span>
        <p>11. THIRD-PARTY WEBSITES</p>
        <span>
          Our Website may include links to other websites or resources. Because
          We have no control over such websites and resources, you acknowledge
          and agree that We are not responsible for their availability and do
          not endorse or are responsible for any content, advertising, products,
          or other materials on or available from such websites or resources.
          Our Terms and Privacy Policy do not apply to Your use of those other
          websites and resources. We cannot guarantee the completeness or
          accuracy of the websites or URLs to which Website’s services link or
          refer. The process of including websites in Our Website services is
          largely automatic.{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> does
          not screen the websites included in the services, and these other
          websites are maintained by persons over whom we exercise no control.
          For these reasons, You further acknowledge and agree that We shall not
          be responsible, directly or indirectly, for any damage or loss caused
          or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods, or services available on or
          through any such websites or resources.
        </span>
        <p>12. DISCLAIMER REGARDING THIRD-PARTY GAMES</p>
        <span>
          APPLICATIONS OR SERVICES YOU ACKNOWLEDGE AND AGREE THAT ANY
          THIRD-PARTY GAME, APPLICATION, OR SERVICE ACCESSIBLE VIA{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span>{" "}
          NETWORK WEBSITES, INCLUDING BUT NOT LIMITED TO GAME, IS PROVIDED TO
          YOU BY A THIRD-PARTY PROVIDER AND NOT BY{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> .
          YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOUR USE OF ANY SUCH THIRD-PARTY
          GAME, APPLICATION, OR SERVICE MAY ALSO BE GOVERNED BY SUCH THIRD-PARTY
          GAME, APPLICATION, OR SERVICE PROVIDER” S TERMS OF USE, LICENSE
          AGREEMENT, PRIVACY POLICY, OR OTHER SUCH AGREEMENT. LICENSOR EXPRESSLY
          DISCLAIMS ANY LIABILITY IN CONNECTION WITH ANY SUCH THIRD-PARTY GAMES,
          APPLICATIONS, OR SERVICES OR YOUR USE THEREOF.
        </span>
        <p>13. INTELLECTUAL PROPERTY</p>
        <span>
          YOU ACKNOWLEDGE AND AGREE THAT ANY THIRD-PARTY GAME, APPLICATION, OR
          SERVICE ACCESSIBLE VIA{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span>{" "}
          NETWORK WEBSITES, INCLUDING BUT NOT LIMITED TO THE GAME, IS PROVIDED
          TO YOU BY A THIRD-PARTY PROVIDER AND NOT BY{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> .
          YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOUR USE OF ANY SUCH THIRD-PARTY
          GAME, APPLICATION, OR SERVICE MAY ALSO BE GOVERNED BY SUCH THIRD-PARTY
          GAME, APPLICATION, OR SERVICE PROVIDER” S TERMS OF USE, LICENSE
          AGREEMENT, PRIVACY POLICY, OR OTHER SUCH AGREEMENT. LICENSOR EXPRESSLY
          DISCLAIMS ANY LIABILITY IN CONNECTION WITH ANY SUCH THIRD-PARTY GAMES,
          APPLICATIONS, OR SERVICES OR YOUR USE THEREOF.
        </span>
        <p>
          13.1 <span style={{ fontWeight: "700" }}>Playground Game Center</span>{" "}
          TRADEMARKS
        </p>
        <span>
          Our Website, including its content, is protected under international
          intellectual property by rights such as trademark and copyright law.
        </span>
        <p>13.2. USE OF OUR TRADEMARKS</p>
        <span>
          Without our prior written consent, you agree not to use, copy,
          download, or exploit our trademarks. You agree not to use Our
          trademarks, or copyrights, in a manner that creates confusion.
          Consistent with trademark infringement law, You are prohibited from
          using any of Our Trademarks. You may not register a domain name in any
          jurisdiction that contains all or part of Our trademarks in a way that
          implies a partnership with Us or creates confusion between{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> and
          Your brand, services, or products. You may not imitate Our Trademarks,
          logo, or other elements of the Website in any of Your advertising, on
          Your personal website, for promotional matters, or other materials.
          You agree not to use Our copyrights and/or trademarks in a disparaging
          or derogatory manner.
        </span>
        <p>13.3. TRADEMARK INFRINGEMENT</p>
        <span>
          You understand that if You violate this clause, You will be liable for
          trademark infringement and that We have the right to sue You. If You
          become aware of anyone using Our trademarks illegally, please contact
          Us. You agree to indemnify and hold Us and Our subsidiaries,
          affiliates, officers, agents, employees, partners, and licensors
          harmless from any claim or demand, including reasonable attorney fees,
          made by any third party due to or arising out of the content You
          submit, post, transmit, make available through the Services, Your
          connection to the services, Your violation of the Terms, or Your
          violation of any rights of another.
        </span>
      </div>
    </div>
  );
};

export default TeamsAndConditionsPage;
