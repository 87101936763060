import React, { ReactNode, useState } from "react";
import { Outlet } from "react-router-dom";
import { Element, Link } from "react-scroll";

import { Adsense } from '@ctrl/react-adsense';
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import PopupInfo from "components/popupInfo";
import { useAppSelector } from "index";
import BottomNavigation from "./footer/BottomNavigation";
import MainHeader from "./header/MainHeader";
import bannerAds from "asset/ads/AURUM-Ads1.png"
// import GoogleAds from "ads/GoogleAds";

const MainLayout = (props: { children?: ReactNode }) => {
  const pageState = useAppSelector(state => state.pageState);

  const scrollToTopRef = React.useRef<any>();
  const scaleRef = React.useRef<any>();

  const [slideChange, setSlideChange] = useState(false);
  const [render, setRender] = useState(true);

  window.addEventListener("scroll", (event) => {
    const scrollY = window.scrollY;
    if (scrollToTopRef.current) {
      if (scrollY > 400) {
        scrollToTopRef.current.style.display = "block";
      } else {
        scrollToTopRef.current.style.display = "none";
      }
    }
  });

  React.useEffect(() => {
    setRender(false);
    if (render) return;
    if (slideChange) {
      scaleRef?.current?.classList.add("zoom-out");
      scaleRef?.current?.classList.remove("zoom-in");
    } else {
      scaleRef?.current?.classList.remove("zoom-out");
      scaleRef?.current?.classList.add("zoom-in");
    }
  }, [slideChange]);

  return (
    <>
      <Element
        name="scrollTop2"
        style={{
          position: "absolute",
          top: 0,
        }}
      />
      <MainHeader />
      <div className="max-w-[1200px] m-auto mt-[60px] w-full">
        {/* <Adsense
          client="ca-pub-4008915664462453"
          slot="6967095920"
        />  */}
        {/* <GoogleAds client="ca-pub-4008915664462453"
              slot="6967095920"/> */}
          {/* <a href={"https://token.aurumai.io"} target="_blank" rel="noreferrer">
            <img className="h-auto w-full" src={bannerAds} alt="banner ads" />
          </a> */}
      </div>
      <div className={`container-game  justify-center items-center `} style={{ background: pageState.background }}>
        <div className="max-w-[1200px] m-auto pb-[30px]">
          <Outlet />
        </div>
      </div>
      {/* <SlideBar
        positionFooter={positionFooter}
        setSlideChange={setSlideChange}
        slideChange={slideChange}
        scroll={scroll}
        setOpenFormContact={setOpenFormContact}
      /> */}
      <div ref={scaleRef}>
        {/* <div ref={scaleRef} className="container-game"> */}

        <Link
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          to="scrollTop2"
        >
          <KeyboardDoubleArrowUpIcon
            ref={scrollToTopRef}
            style={{ display: "none" }}
            className="scroll-top"
          />
        </Link>
      </div>
      {/* <img src={bgSymbol} alt="bg-symbol" className="bg-symbol" /> */}
      {/* <MainFooter
        setScroll={setScroll}
        setPositionFooter={setPositionFooter}
        openFormContact={openFormContact}
        setOpenFormContact={setOpenFormContact}
      /> */}
      <BottomNavigation />
      <PopupInfo />
    </>
  );
};

export default MainLayout;
