import NFTGames from "components/home/NFTGames";
import LoadingIcon from "components/LoadingIcon";
import React from "react";
import { useSelector } from "react-redux";
import { homeImg } from "helpers/ExportImg";
import { getListNFTGames } from "./services/api";

const NFTCenterPage = () => {
  const { bgSymbol } = homeImg;

  const { gamesNFT, isLoading } = useSelector((state: any) => state.gamenft);

  React.useEffect(() => {
    getListNFTGames();
  }, []);

  return (
    <div className="flex-center wrapper-home">
      {isLoading && <LoadingIcon />}
      {/* <img src={bgSymbol} alt="bg-symbol" className="bg-symbol" /> */}
      <NFTGames filter={true} data={gamesNFT} type="nftgame" />
    </div>
  );
};

export default NFTCenterPage;
