import moment from 'moment';
import * as React from 'react';
import { useRef, useEffect, useState } from 'react';

export interface ICountDownProps {
  time?: any;
}

export default function CountDown({ time }: ICountDownProps) {
  const intervalRef = useRef<any>(null);
  const [countDownValue, setCountDownValue] = useState<any>(0);
  const countDownTime = () => {
    let res = 0;
    const now = moment().utc();
    const endOfDay = moment().endOf('day').utcOffset(0).utc();
    let diff = endOfDay.diff(now, 'seconds');

    intervalRef.current = setInterval(() => {
      diff = endOfDay.diff(moment().utcOffset(0).utc(), 'seconds');
      let duration = moment.duration(diff, 'seconds');
      let formatedDuration = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
      setCountDownValue(formatedDuration);
    }, 1000);
  };
  useEffect(() => {
    countDownTime();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  return <div>{countDownValue}</div>;
}
