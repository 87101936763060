import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GameItem, GameState } from "interfaces";

const initialState: GameState = {
  games: [],
  searchGames: [],
  isLoading: false,
  detailGame: {
    id: "",
    categories: [],
    name: "",
    link: "",
    image: "",
    playCound: 0,
  },
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    startLoading: (state: GameState) => {
      state.isLoading = true;
    },
    setListGame: (
      state: GameState,
      action: PayloadAction<GameState["games"]>
    ) => {
      state.games = action.payload;
      state.isLoading = false;
    },
    setListSearch: (
      state: GameState,
      action: PayloadAction<GameState["searchGames"]>
    ) => {
      state.searchGames = action.payload;
      state.isLoading = false;
    },
    setDetailGame: (
      state: GameState,
      action: PayloadAction<GameState["detailGame"]>
    ) => {
      state.detailGame = action.payload;
      state.isLoading = false;
    },
    setNextDetailGame: (state: GameState, action: PayloadAction<GameItem>) => {
      state.detailGame = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  setListGame,
  setListSearch,
  setDetailGame,
  startLoading,
  setNextDetailGame,
} = gameSlice.actions;

export const gameReducer = gameSlice.reducer;
