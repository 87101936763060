import { useEffect, useRef, useState } from "react";

import CardTopPrizeDay from "components/Card/CardTopPrizeDay";
import PopupBuyTicket from "components/popupBuyTicket";
import PopupTicket from "components/popupTicket";
import { slidebarImg } from "helpers/ExportImg";
import { handleShowTime } from "helpers/util";
import { useAppDispatch } from "index";
import { useNavigate } from "react-router-dom";
import { setPopupAllPrizesState } from "slices/popupAllPrizesSlice";
import { PlayToEarnStyled } from "./styled";

const TopPrizeDay = ({ eventData }: any) => {

  const rootRef = useRef<any>();
  const [typeVote, setTypeVote] = useState<number>(0);
  const dispatchApp = useAppDispatch()
  const navigate = useNavigate();

  const { pools , coinDes } = slidebarImg;

  const handlerPopup = () => {
    dispatchApp(setPopupAllPrizesState({ title: 'All Prizes', show: true, description: "", eventData: eventData }));
  };
  const [show, setShow] = useState<boolean>(false);
  const [showPopupTicket, setShowPopupTicket] = useState<boolean>(false);
  const [ticketNumber, setTicketNumber] = useState();
  const [historyTicket, setHistoryTicket] = useState([]);

  useEffect(() => {
    setHistoryTicket(eventData?.history?.data)
  }, []);

  return (
    <PlayToEarnStyled className="relative w-full">
      <PopupBuyTicket setHistoryTicket={setHistoryTicket} show={show} setShow={setShow} setTicketNumber={setTicketNumber} setShowPopupTicket={setShowPopupTicket} eventId={eventData?.id} type={typeVote} />
      <PopupTicket open={showPopupTicket} setOpen={setShowPopupTicket} ticketNumber={ticketNumber} />
      <div
        className=""
        style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
        ref={rootRef}
      >
        <div
          className=""
          style={{ margin: 0 }}
        >
          <div className="flex justify-between w-full p-5 ">
            <div className="pte-header-left">
              <span>List top prize</span>
            </div>
            {/* <div className="pte-header-right">
              <button onClick={() => { handlerPopup() }} >See all</button>
            </div> */}
          </div>
          <>
            <div className="mt-5 grid grid-cols-1 gap-10  max-w-[1200px] pb-10  justify-between">
              <div
              >
                <CardTopPrizeDay eventData={eventData} />
              </div>

              <div className="mt-8">
                <table className=" m-auto  w-[300px] max-w-full">
                  <thead className="">
                    <tr>
                      <th className="text-left p-5">Top</th>
                      <th className="text-right p-5">
                        <div className="flex float-right justify-center items-center">
                          <div className="  ">Prize</div>
                          <div className="ml-2 items-center justify-center" >
                            <img className="w-[16px] h-[16px]" src={pools}
                              alt="img" />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {eventData?.eventDetail?.length > 0 && eventData?.eventDetail?.map((rankInfo: any, index: number) => (
                      <tr key={rankInfo?.id}>
                        <td className="text-left p-5 pl-10">{rankInfo.rank}</td>
                        <td className="text-right p-5 pr-10">{rankInfo.reward}</td>
                      </tr>
                    ))}
  
                  </tbody>
             
                </table>
                {
                  (!eventData ||  !eventData?.eventDetail || !eventData?.eventDetail?.length ) && <div className="text-center text-[14px]">No data</div>
                }
              </div>
            </div>
          </>
          <div className="flex justify-between w-full p-5 ">
            <div className="pte-header-left">
              <span>Ticket list</span>
            </div>
            <div className="table-header-right">
              <button onClick={() => { navigate('/all-ticket/'+ eventData?.id) }} >See all</button>
            </div>
          </div>

          <div className="mt-8">
            <table className=" m-auto w-10/12 ticket-list">
              <thead className="">
                <tr>
                  <th className="text-left p-5 pl-10">Time</th>
                  <th className="text-right p-5 pr-10">
                    <div className="flex float-right justify-center items-center">
                      <div className="  ">Number</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {historyTicket.length > 0 && historyTicket.map((rankInfo: any, index: number) => (
                  <tr key={rankInfo?.id}>
                    <td className="text-left p-5 pl-14">{handleShowTime((new Date(rankInfo.createdAt)).getHours())}:{handleShowTime((new Date(rankInfo.createdAt)).getMinutes())}</td>
                    <td className="text-right p-5 pr-14">{rankInfo.ticketNumber}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {
              (!historyTicket || !historyTicket.length ) && <div className="text-center text-[14px]">No data</div>
            }
          </div>
          <div className="flex justify-center items-center mt-8">
            <button className="btn-by-ticket " onClick={() => { setShow(true) }} ><div className="flex items-center justify-center" >
              <div>Buy ticket: {eventData?.ticketPoint}</div>
              <img className="ml-2 w-[20px] h-[20px]" src={coinDes}
                alt="img" />
            </div> </button>
          </div>
        </div>
      </div>
    </PlayToEarnStyled>
  );
};

export default TopPrizeDay;