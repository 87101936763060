import apiService from "app/apiService";
import { store } from "app/store";
import axios from "axios";
// import { showNotification } from "helpers/util";
// import { QueryGames } from "interfaces";
// import { setAuth } from "slices/authSlice";
import { setDetailGameNFT, setListGameNFT } from "slices/gameNFTSlice";
import { setListGameOpenSource } from "slices/gameOpenSource";
import { setDetailPopular, setListGamePopular } from "slices/gamePopularSlice";
// import { setDetailGame, setListGame, startLoading } from "slices/gameSlice";
import {
  setDetailGame,
  setListGame,
  setListSearch,
  startLoading,
} from "slices/gameSlice";
import { setUserPoint } from "slices/userPointSlice";

const accessToken = localStorage.getItem("accessToken");
const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
};

export const getDetailGame = async (id: any) => {
  store.dispatch(startLoading());
  const response = await apiService.get(`/v1/miniGame/${id}`);
  store.dispatch(setDetailGame(response.data));
};

export const getUserGameMission = async (id: any) => {
  const response = await apiService.get(
    `/v1/pointRewardPool/mission-pass/${id}`
  );

  return response;
};

export const getUserGameMissionPoint = async (id: any) => {
  const data = await apiService.get(
    `/v1/pointRewardPool/mission-pass/${id}`,
    config
  );
  return data;
};

export const getListGames = async (params?: any, search?: any) => {
  if (!search) store.dispatch(startLoading());
  const response = await apiService.get(`/v1/miniGame/all`, {
    params: params,
  });

  search
    ? store.dispatch(setListSearch(response.data))
    : store.dispatch(setListGame(response.data));

  return response.data;
};

export const getDetailNFTGame = async (id: any) => {
  // store.dispatch(startLoading());
  const response = await apiService.get(`/v1/nftGame/${id}`);
  store.dispatch(setDetailGameNFT(response.data));
};

export const getListNFTGames = async () => {
  store.dispatch(startLoading());
  const response = await apiService.get(`/v1/nftGame/all?limit=6`);
  store.dispatch(setListGameNFT(response.data.data));
};

export const getListPopularGameMini = async () => {
  store.dispatch(startLoading());
  const response = await apiService.get(
    `v1/miniGame/all?limit=8&sortBy=popularity&direction=DESC`
  );
  store.dispatch(setListGamePopular(response.data.data));
  return response.data;
};

export const getListPopularGameNFT = async () => {
  store.dispatch(startLoading());
  const response = await apiService.get(
    `v1/nftGame/all?limit=8&sortBy=popularity&direction=DESC`
  );
  store.dispatch(setListGamePopular(response.data.data));
};

export const getDetailGamePopular = async (id: any) => {
  // store.dispatch(startLoading());
  const response = await apiService.get(`/v1/miniGame/${id}`);
  store.dispatch(setDetailPopular(response.data));
};

export const getListGenre = async () => {
  const response = await apiService.get(`/v1/category`);
  return response.data;
};

export const getInfoUser = async () => {
  const response = await apiService.get(`/v1/auth/me`, config);
  return response.data;
};

export const rateStar = async (
  gameId: string,
  ratePoint: number,
  gameType: string
) => {
  await apiService.post(`/v1/rate/${gameId}`, { gameType, ratePoint }, config);
};

export const commentGame = async (
  gameId: string,
  gameType: string,
  content: string
) => {
  await apiService.post(`/v1/comment/${gameId}`, { gameType, content }, config);
};

export const getComment = async (
  gameType: string,
  gameId: string,
  limit: number
) => {
  const response = await apiService.get(
    `/v1/comment/${gameType}/${gameId}?limit=${limit}&sortBy=createdAt&direction=DESC`
  );

  return response.data.data;
};

export const getCountStar = async (gameType: string, gameId: number) => {
  const response = await apiService.get(`/v1/rate/${gameType}/${gameId}/count`);
  return response.data;
};

export const getRatedUser = async (params: any, accessToken: any) => {
  const { limit, page } = params;
  const response: any = await apiService.get(
    `/v1/rate/all?limit=${limit}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};


export const getUserNotification = async (params: any) => {
  const response = await apiService.get(
    `/v1/user-notification`,
    {
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  return response.data;
};


export const deleteAllUserNotification = async () => {
  const response = await apiService.delete(
    `/v1/user-notification/delete`);
  return response.data;
};

export const seenAllUserNotification = async () => {
  const response = await apiService.patch(
    `/v1/user-notification/seen`);
  return response.data;
};

export const seenUserNotification = async (id: number, params: any) => {
  const response = await apiService.post(
    `/v1/user-notification/${id}`,
    params
    );
  return response.data;
};




export const getHistoryGame = async (params: any) => {
  const { limit, page } = params;
  const response = await apiService.get(
    `/v1/playGame?limit=${limit}&page=${page}`
  );
  return response.data;
};

export const getRecentGame = async (params: any) => {
  const { limit, page } = params;
  const response = await apiService.get(
    `/v1/playGame/recent?limit=${limit}&page=${page}`
  );
  return response.data;
};

export const getPopularGame = async (params: any) => {
  const { limit, page } = params;
  const response = await apiService.get(
    `/v1/playGame/popular?limit=${limit}&page=${page}`
  );
  return response.data;
};

export const addGameToHistory = async ({ gameId, gameType }: any) => {
  gameId = gameId.toString();
  const response = await apiService.post(
    `/v1/playGame`,
    {
      gameId,
      gameType,
    },
    config
  );
  return response.data;
};

export const addDownloadHistory = async ({ gameId }: any) => {
  gameId = gameId.toString();
  const response = await apiService.post(
    `/v1/download`,
    {
      gameId,
    },
    config
  );
  return response.data;
};

export const getHistoryDownload = async (accessToken: any) => {
  const response = await apiService.get(`/v1/download`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
};

export const getAllnotification = async () => {
  const response = await apiService.get(
    `/v1/notification/all?sortBy=createdAt&direction=DESC`
  );
  return response.data;
};

export const getGameReward = async () => {
  const response = await apiService.get(`/v1/reward`, {});
  return response.data;
};

export const getPointRewardPool = async (accessToken?: any) => {
  const response = await apiService.get(`/v1/pointRewardPool/byUser`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response && response.data)
    store.dispatch(
      setUserPoint({
        point: response.data.point,
        redeemPoint: response.data.redeemPoint,
      })
    );
  return response.data;
};

export const getpointRewardHistory = async (params: any) => {
  const response = await apiService.get(
    `/v1/pointRewardHistory/byUser`,
    config
  );
  return response.data;
};

export const postWithdraw = async (amount: number, walletAddress: any) => {
  const response = await apiService.post(
    "/v1/withdraw",
    {
      amount,
      walletAddress,
    },
    config
  );
  return response.data;
};

export const loginWallet = async (sign: string, walletAddress: string) => {
  const response = await apiService.post("/v1/user/loginWallet", {
    sign,
    walletAddress,
  });
  return response.data;
};

export const login = async (sign: string, walletAddress: string, message: string) => {
  const response = await apiService.post("/v1/auth/login", {
    sign,
    walletAddress,
    message,
  });
  return response.data;
};

export const getPackageTicker = async () => {
  try {
    const response = await apiService.get("/v1/ticketPackage");
    if (response && response.data) return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const getListGamesRecommend = async (params?: any) => {
  const response = await apiService.get(`/v1/game/games`, {
    params: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getListGamesOpenSource = async (params?: any) => {
  const response = await apiService.get(`/v1/game/all`, {
    params: params,
    headers: {
      "Content-Type": "application/json",
    },
  });

  store.dispatch(setListGameOpenSource(response.data));
  sessionStorage.setItem("listGamesOpen", JSON.stringify(response.data.data));
  return response.data;
};

export const getGamesOpenSourceDetail = async (id: number, user_id: number) => {
  const { data } = await apiService.get(`/v1/game/${id}/${user_id}`);
  return data;
};

export const postPlayGame = async (id: any) => {
  await apiService.post(`/v1/miniGame/playGame/${id}`, config);
};

export const getSearch = async (params?: any) => {
  const response = await apiService.get(
    `/v1/game/search-game/search/${params}`
  );

  return response.data;
};

export const postBuyTicket = async (
  packageIndex: number,
  walletAddress: any
) => {
  return await apiService.post(
    `/v1/ticket/buyTicket`,
    { packageIndex, walletAddress },
    config
  );
};

export const getTransPending = async () => {
  return await apiService.get(`/v1/ticket/byUserPending`, config);
};

export const getRpsVersion = async () => {
  const response = await axios.get(
    `https://api-v2.rpsgame.world/api/config/getGameVersion`
  );

  return response.data;
};

export const getCoinHaloVersion = async () => {
  const response = await axios.get(
    `https://api-testnet.coinhalo.world/api/config/getGameVersion`
  );

  return response.data;
};

export const getPMGVersion = async () => {
  const response = await axios.get(`https://api-v3.pmgame.world/api/version`);

  return response.data.data;
};

export const getBingoVersion = async () => {
  const response = await axios.get(
    `https://api-v2.play.bingo.family/GetSys.php`
  );

  return response.data;
};

export const getCHTSVersion = async () => {
  const response = await axios.get(
    `https://api-v4.puzzlehunters.net/v2/version/latest`
  );

  return response.data;
};

export const getMarbleVersion = async () => {
  const response = await axios.get(`https://api.nftmarble.games/api/version`);

  return response.data;
};


// Event

export const getListEvent= async () => {
  const response = await apiService.get(`/v1/events/list`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getEventById= async (id?: number) => {
  const response = await apiService.get(`/v1/events/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const buyEventTicket= async (id?: number) => {
  const response = await apiService.get(`/v1/events/buy-ticket/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};


export const getMaintenance= async () => {
  const response = await apiService.get(`/v1/settings/maintenance`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getEventDetail= async (type?: string) => {
  const response = await apiService.post(`/v1/events/detail/${type}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getEventWinner= async (id?: string) => {
  const response = await apiService.get(`/v1/events/winner/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getEventDayParticipant= async (id?: string, params?: any) => {
  const response = await apiService.get(`/v1/events/participant/${id}`, {
    params: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};




export const getSignature = async (eventId?: number, pool?: number ,type?: number) => {
  const response = await apiService.post(`/v1/events/signature`, { eventId, pool, type });
  return response.data;
};


export const getUsersJoin= async (id: number, params?: any) => {
  const response = await apiService.get(`/v1/events/user-join/${id}`, {
    params: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};


export const getEventHistory= async (params?: any) => {
  const response = await apiService.get(`/v1/events`, {
    params: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};


// notification

