import { useRef } from "react";

import CardTopPrize from "components/Card/CardTopPrize";
import PopupAllPrizes from "components/popupAllPrizes";
import { useAppDispatch } from "index";
import { setPopupAllPrizesState } from "slices/popupAllPrizesSlice";
import { PlayToEarnStyled } from "./styled";

const TopPrize = ({ eventData }: any) => {

  const rootRef = useRef<any>();



  const dispatchApp = useAppDispatch()


  const handlerPopup = () => {
    dispatchApp(setPopupAllPrizesState({ title: 'All Prizes', show: true, description: "" , eventData: eventData}));
  };

  return (
    <PlayToEarnStyled className="relative w-full">
      <PopupAllPrizes />
      <div
        className=""
        style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
        ref={rootRef}
      >
        <div
          className=""
          style={{ margin: 0 }}
        >
          <div className="flex justify-between w-full p-5 ">
            <div className="pte-header-left">
              <span>Total prize</span>
            </div>
            <div className="pte-header-right">
              <button onClick={() => { handlerPopup() }} >See all</button>
            </div>
          </div>
          <>
            <div className="mt-5 grid grid-cols-1 gap-10  max-w-[1200px] pb-10  justify-between">
              <div
              >
                <CardTopPrize eventData={eventData}/>
              </div>

            </div>

          </>
        </div>
      </div>
    </PlayToEarnStyled>

  );
};

export default TopPrize;