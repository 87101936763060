import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {pageState } from "interfaces";

const initialState: any = {
  game: {},
  show: false,
  gameType: 'game'
};

const popupPlaySlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPopupPlayState: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        game: action.payload.game,
        show: action.payload.show,
        gameType: action.payload.gameType
      };
    },
    setPopupPlayShowState: (state: any, action: PayloadAction<boolean>) => {
      return {
        ...state,
        show: action.payload,
      };
    },
  },
});

export const {
  setPopupPlayState,
  setPopupPlayShowState
} = popupPlaySlice.actions;

export const popupPlayReducer = popupPlaySlice.reducer;
