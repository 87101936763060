import PointHistory from "components/pointHistory";

function PointHistoryPage() {
  return (
    <>
      <div
        className="flex-center wrapper-home"
        style={{ marginBottom: "22rem" }}
      >
        <PointHistory />
      </div>
    </>
  );
}

export default PointHistoryPage;
