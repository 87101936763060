import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import appContext from "appContext";
import { GameTag } from "./styled";
import GameOpenSource from "./GameOpenSource";
import OpenSourceAll from "components/List/OpenSourceAll";
import OpenSourceFilter from "components/List/OpenSourceFilter";
import { setPageState } from "slices/pageSlice";
import { useAppDispatch } from "index";
import HistoryPlay from "components/List/HistoryPlay";

const HistoryPlayGame = () => {
  const { detailGame, games } = useSelector((state: any) => state.games);

  const { categories } = React.useContext(appContext);
  const [change, setChange] = React.useState(false);
  const [comments, setComments] = React.useState<any>([]);
  const [rate, setRate] = useState(0);
  const [star, setStarts] = React.useState<any>([]);

  const params = useParams();
  const currentID = params.id;
  const dispatchApp = useAppDispatch()

  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: false,
      tab: 3,
      title: "History Play Game",
      background: '#F5F5F5',
      backgroundHeader: '#FFFFFF'

    }
    ))
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <GameTag className="mt-[40px] pb-[50px]">
    <div className="game_page container-new">
      <HistoryPlay />
    </div>
    </GameTag>
  );
};

export default HistoryPlayGame;
