import Notice from "components/notice";
import { useEffect } from "react";

function NoticePage() {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <div className="game_page">
        <Notice />
      </div>
    </>
  );
}

export default NoticePage;
