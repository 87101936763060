import styled from "styled-components";

export const PopupTag = styled.section`
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .backdrop {
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    display: block;
    z-index: 999999;
  }


    .popup-title {
      color: var(--Grey-100, #111);
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal; 
    }
    .popup-text{
      color: var(--Grey-100, #111);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.07px; 
    }

    .popup-btn{
      display: flex;
      height: 40px;
      padding: 0px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch; 
      border-radius: 8px;
      background: #06C270; 

      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.024px; 
    }

    table{
      border-radius: 10px 10px 0px 0px;
    }
  thead{

    color: var(--Grey-0, #FFF);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }
  td{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 

  }
  tbody{
    background: #F7F7F7; 

  }

   thead th:first-child {
    border-radius: 10px 0 0 0;
    background: #06C270;

}
 thead th:last-child {
    border-radius: 0 10px 0 0;
    background: #06C270;
}

  table tr:nth-child(even) {
    background-color: #FFFFFF;
  }

  .leaderboard-header-left{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1 / Subtitle / Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;  
  }

      .fixed_header {
        width: 200px;
        table-layout: fixed;
        border-collapse: collapse;
      }
      .fixed_header tbody {
        display: block;
        width: 100%;
        overflow: auto;
        max-height: 50vh;
      }
      .fixed_header thead tr {
        display: block;
      }
      .fixed_header thead {
      }
      .fixed_header th
       {
        padding: 10px;
        width: 100px;
      }
      .fixed_header td {
        padding: 10px;
        width: 100px;
      }
`;
