import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { popupAllPrizesState } from "interfaces";


const initialState: popupAllPrizesState = {
  show: false,
  title: '',
  description: '',
  eventData: null
};

const popupAllPrizesSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPopupAllPrizesState: (state: popupAllPrizesState, action: PayloadAction<popupAllPrizesState>) => {
      return {
        ...state,
        description: action.payload.description,
        show: action.payload.show,
        title: action.payload.title,
        eventData: action.payload.eventData
      };
    },
    setPopupAllPrizesShowState: (state: popupAllPrizesState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        show: action.payload,
      };
    },
  },
});

export const {
  setPopupAllPrizesState,
  setPopupAllPrizesShowState
} = popupAllPrizesSlice.actions;

export const popupAllPrizesReducer = popupAllPrizesSlice.reducer;
