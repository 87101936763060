import LoadingIcon from "components/LoadingIcon";
import ShopItem from "components/shopItem";
import { useEffect, useState } from "react";
import { getPackageTicker } from "./services/api";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

function ShopPage() {
  const loaction = useLocation();
  const navigate = useNavigate();
  // console.log({ loaction, navigate });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getPackages = async () => {
    setIsLoading(true);
    try {
      const result = await getPackageTicker();
      if (result) setData(result?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setData([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPackages();
  }, []);
  return (
    <>
      {/* {isLoading && <LoadingIcon />} */}
      <div className="game_page shop-page">
        <ShopItem data={data} />
      </div>
    </>
  );
}

export default ShopPage;
