import { guideline } from "helpers/ExportImg";
import { useState, } from "react";
import { GuidelineWraper } from "./styled";

const ListGuideline = () => {
  const [language, setLanguage] = useState('en')

  return (
    <GuidelineWraper>
      <div
        className=""
        style={{ scrollMargin: "10rem" }}
      >
        {/* <img src={bgSymbol} alt="bg-symbol" className="bg-symbol" /> */}


        <div
          className="component-games top-free-game all-game-mobile m-auto"
          style={{ margin: 0 }}
        >
          
        <div className="flex justify-center space-x-5 ">
          <div><button onClick={() => { setLanguage('en') }} disabled={language === 'en'} className={`popup-btn w-full ${language === 'en' ? 'bg-[#7E7E7E]' : 'bg-[#06C270]'}`}>
            English
          </button></div>
          <div><button onClick={() => { setLanguage('ko') }} disabled={language === 'ko'} className={`popup-btn w-full ${language === 'ko' ? 'bg-[#7E7E7E]' : 'bg-[#06C270]'}`}>
            Korean
          </button></div>
        </div>
          <>
            <div className={`${language !== 'en' ? 'hidden' : ''}`}>
              <img src={guideline.en.guideline1} alt="guideline1" className="w-full" />
              <img src={guideline.en.guideline2} alt="guideline2" className="w-full" />
              <img src={guideline.en.guideline3} alt="guideline3" className="w-full" />
              <img src={guideline.en.guideline4} alt="guideline4" className="w-full" />
              <img src={guideline.en.guideline5} alt="guideline5" className="w-full" />
              <img src={guideline.en.guideline6} alt="guideline6" className="w-full" />
              <img src={guideline.en.guideline7} alt="guideline7" className="w-full" />
              <img src={guideline.en.guideline8} alt="guideline8" className="w-full" />
              <img src={guideline.en.guideline9} alt="guideline9" className="w-full" />
              <img src={guideline.en.guideline10} alt="guideline10" className="w-full" />
              <img src={guideline.en.guideline11} alt="guideline11" className="w-full" />
              <img src={guideline.en.guideline12} alt="guideline12" className="w-full" />
              <img src={guideline.en.guideline13} alt="guideline13" className="w-full" />
              <img src={guideline.en.guideline14} alt="guideline14" className="w-full" />
              <img src={guideline.en.guideline15} alt="guideline15" className="w-full" />
              <img src={guideline.en.guideline16} alt="guideline16" className="w-full" />
              <img src={guideline.en.guideline17} alt="guideline17" className="w-full" />
              <img src={guideline.en.guideline18} alt="guideline18" className="w-full" />
            </div>

            <div className={`${language !== 'ko' ? 'hidden' : ''}`}>
              <img src={guideline.ko.guideline1} alt="guideline1" className="w-full" />
              <img src={guideline.ko.guideline2} alt="guideline2" className="w-full" />
              <img src={guideline.ko.guideline3} alt="guideline3" className="w-full" />
              <img src={guideline.ko.guideline4} alt="guideline4" className="w-full" />
              <img src={guideline.ko.guideline5} alt="guideline5" className="w-full" />
              <img src={guideline.ko.guideline6} alt="guideline6" className="w-full" />
              <img src={guideline.ko.guideline7} alt="guideline7" className="w-full" />
              <img src={guideline.ko.guideline8} alt="guideline8" className="w-full" />
              <img src={guideline.ko.guideline9} alt="guideline9" className="w-full" />
              <img src={guideline.ko.guideline10} alt="guideline10" className="w-full" />
              <img src={guideline.ko.guideline11} alt="guideline11" className="w-full" />
              <img src={guideline.ko.guideline12} alt="guideline12" className="w-full" />
              <img src={guideline.ko.guideline13} alt="guideline13" className="w-full" />
              <img src={guideline.ko.guideline14} alt="guideline14" className="w-full" />
              <img src={guideline.ko.guideline15} alt="guideline15" className="w-full" />
              <img src={guideline.ko.guideline16} alt="guideline16" className="w-full" />
              <img src={guideline.ko.guideline17} alt="guideline17" className="w-full" />
              <img src={guideline.ko.guideline18} alt="guideline18" className="w-full" />
            </div>
          </>
        </div>
      </div>
    </GuidelineWraper>
  );
};

export default ListGuideline;

