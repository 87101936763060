import { Route, Routes } from "react-router-dom";

import FilterGenre from "components/home/search/FilterGenre";
import MainLayout from "layouts/MainLayout";
import AllGamePTE from "pages/AllGamePTE";
import AllHistoryDayEvent from "pages/AllHistoryDayEvent";
import AllHistorySpecialEvent from "pages/AllHistorySpecialEvent";
import AllMiniGame from "pages/AllMiniGame";
import AllTicketList from "pages/AllTicketList";
import GameFilterPage from "pages/GameFilterPage";
import GameNFTPage from "pages/GameNFTPage";
import GameNFTPageNew from "pages/GameNFTPageNew";
import GameOpenSource from "pages/GameOpenSource";
import GamePage from "pages/GamePage";
import GamePopularPage from "pages/GamePopularPage";
import GenrePage from "pages/GenrePage";
import Guideline from "pages/Guideline";
import HistoryDayEvent from "pages/HistoryDayEvent";
import HistoryPlayGame from "pages/HistoryPlayGame";
import HistorySpecialEvent from "pages/HistorySpecialEvent";
import HomePage from "pages/HomePage";
import HourEvent from "pages/HourEvent";
import LotteryEvent from "pages/LotteryEvent";
import MiniCenterPage from "pages/MiniCenterPage";
import NFTCenterPage from "pages/NFTCenterPage";
import NoticePage from "pages/NoticePage";
import NotificationPage from "pages/NotificationPage";
import PlayOpenSource from "pages/PlayOpenSource";
import PointHistoryPage from "pages/PointHistoryPage";
import PrivacyPolicy from "pages/PrivacyPolicy";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import Prizes from "pages/Prizes";
import ProfilePage from "pages/ProfilePage";
import ShopPage from "pages/ShopPage";
import SpecialEvent from "pages/SpecialEvent";
import TeamsAndConditionsPage from "pages/TeamsAndConditionsPage";
import AccountPage from "pages/accounts/AccountPage";
import SearchPage from "pages/search/SearchPage";
import SearchPtePage from "pages/searchPte/SearchPtePage";
import SaveAccessTokenPage from "../pages/SaveAccessTokenPage";

const Router = () => {
  return (
      <Routes>
        {/* <Route path="/" element={<MainLayout />}> */}
        <Route path="/search" element={<SearchPage />} />
        <Route path="/search-genre" element={<FilterGenre  />} />

        <Route path="/searchPte" element={<SearchPtePage />} />

        <Route element={<MainLayout/>}>
          <Route path="/" element={<HomePage />} />
          <Route path="/prizes" element={<Prizes />} />
          <Route path="/special-event/:type" element={<SpecialEvent />} />
          <Route path="/hour-event/:type" element={<HourEvent />} />
          <Route path="/lottery-event/:type" element={<LotteryEvent />} />
          <Route path="/history-special" element={<AllHistorySpecialEvent />} />
          <Route path="/history-special/:id" element={<HistorySpecialEvent />} />
          <Route path="/all-ticket/:id" element={<AllTicketList />} />


          <Route path="/guideline" element={<Guideline />} />

          <Route path="/point-policy" element={<PrivacyPolicy />} />


          <Route path="/history-day-event/:id" element={<HistoryDayEvent />} />

          <Route path="/history-day-event" element={<AllHistoryDayEvent />} />

          <Route path="/filter/:id" element={<GameFilterPage />} />
          <Route path="/history" element={<HistoryPlayGame />} />
          <Route path="/notification" element={<NotificationPage />} />
          


          <Route path="/all-game-pta" element={<AllGamePTE />} />
          <Route path="/all-mini-game" element={<AllMiniGame />} />

          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/discover-games/:id" element={<GamePage />} />
          <Route path="/games-nft" element={<NFTCenterPage />} />
          <Route path="/discover-games" element={<MiniCenterPage />} />
          <Route path="/game-nft/:id" element={<GameNFTPage />} />
          <Route path="/game-popular/:id" element={<GamePopularPage />} />
          <Route path="/genre" element={<GenrePage />} />
          <Route path="/google/login" element={<SaveAccessTokenPage />} />
          <Route path="/point-policy" element={<PrivacyPolicyPage />} />
          <Route path="/teams-conditions" element={<TeamsAndConditionsPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/p2egame" element={<GameOpenSource />} />
          <Route path={`/p2egame/:name/:id`} element={<PlayOpenSource />} />
          {/* <Route path="/p2egame/:id" element={<PlayOpenSource />} /> */}
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/point-history" element={<PointHistoryPage />} />
          <Route path="/notice" element={<NoticePage />} />
          <Route path="/nft-games" element={<GameNFTPageNew />} />
        </Route>
        {/* </Route> */}
      </Routes>
  );
};

export default Router;
