import React, { useEffect, useState, useContext } from "react";

import { Rating } from "@mui/material";
import { YourComment } from "./styled";
import { commentGame, getRatedUser, rateStar } from "pages/services/api";
import { useSelector } from "react-redux";
import LoadingIcon from "components/LoadingIcon";
import { showNotification } from "helpers/util";
// import appContext from "appContext";

const WriteComment = ({ data, gameType }: any) => {
  const [value, setValue] = useState<number>(0);
  const [content, setContent] = useState("");
  const { isLoading } = useSelector((state: any) => state.games);
  // const { account } = useContext(appContext);

  const { wallet: account } = useSelector((state: any) => state.auth);

  const rateGame = (newValue: any) => {
    if (!account) {
      showNotification("error", "You must login to submit for review");
      return;
    }
    setValue(newValue);
    // sessionStorage.setItem("rateGameUser", newValue + "");
  };

  const handleChangeComment = (event: any) => {
    setContent(event.target.value);
  };

  const sendComment = (event: any) => {
    event.preventDefault();
    const gameId = data.id;
    if (!account) {
      showNotification("error", "You must login to submit for review");
      return;
    }
    if (content.length < 3 && content.length >= 1) {
      showNotification("error", "Comment must be have at least 3 character!");
      return;
    } else if (content.length > 0) {
      setContent("");
      commentGame(gameId, gameType, content).then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 500)
      );
      rateStar(gameId, value, gameType);
      showNotification("success", "Send successfully!");
    } else {
      rateStar(gameId, value, gameType).then(() =>
        showNotification("success", "Rate game successfully!")
      );
    }
  };

  // useEffect(() => {
  //   const rate = sessionStorage.getItem("rateGameUser");
  //   setValue(Number(rate));
  // }, [value]);

  return (
    <YourComment>
      {isLoading && <LoadingIcon />}
      <form onSubmit={sendComment} className="write_comment">
        <p>WRITE YOUR REVIEW</p>
        <input
          placeholder="Tell us what you think about this game."
          onChange={handleChangeComment}
          value={content}
          style={{ fontFamily: "bold" }}
        />
        <button className="btn-mobile" onClick={sendComment}>
          SEND
        </button>
      </form>
      <div className="voting">
        <p>VOTING</p>
        <div>
          <Rating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => rateGame(newValue)}
            // onChange={(event, newValue) => rateGame(newValue)}
            className="rating_start"
          />
        </div>
        <button onClick={sendComment}>SEND</button>
      </div>
    </YourComment>
  );
};

export default WriteComment;
