import { getListEvent } from "pages/services/api";
import { useEffect, useState } from "react";
import { PopupTag } from "./styled";

const PopupMainten = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await getListEvent();
      setOpen(false)
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <PopupTag>
      <>
        {open && (
          <>
            <div className={`backdrop`}></div>
            <div className="popup-img ">
              <div className={`popup w-full relative `}>
                  <div className="bg-[#FFFFFF] ">
                    <img src={'https://cms.isaacplayground.co.kr/storage/uploads/2024/01/05/Globall-run_uid_6597c446bf848.png'} alt="img soft" />
                  </div>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupMainten;