import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import appContext from "appContext";
import Notifications from "components/List/Notifications";
import PopupAlert from "components/popupAlert";
import { useAppDispatch } from "index";
import { setPageState } from "slices/pageSlice";
import { seenAllUserNotification } from "./services/api";
import { GameTag } from "./styled";
import LoadingIcon from "components/LoadingIcon";

const NotificationPage = () => {
  const { detailGame, games } = useSelector((state: any) => state.games);

  const { categories } = React.useContext(appContext);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [comments, setComments] = React.useState<any>([]);
  const [rate, setRate] = useState(0);
  const [star, setStarts] = React.useState<any>([]);

  const params = useParams();
  const currentID = params.id;
  const dispatchApp = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false);
  const [seed, setSeed] = useState(1);
  const resetGetNoti = () => {
       setSeed(Math.random());
   }
  const fetchApiSeenAll = async () => {
    try {
      setLoading(true);
      await seenAllUserNotification();
      setLoading(false);
      resetGetNoti()
    } catch (err) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: false,
      tab: 3,
      title: "Notification",
      background: '#F5F5F5',
      backgroundHeader: '#FFFFFF'

    }
    ))
  }, []);
  

  return (
    <GameTag className="mt-[40px] pb-[50px]">
      <PopupAlert open={openPopup} setOpen={setOpenPopup} resetGetNoti={resetGetNoti}/>
      <div className="game_page container-new">
        <div className="flex justify-between w-full px-10">
          <div className="title-header-left">
            <button onClick={fetchApiSeenAll}>Mark all as read</button>
          </div>
          <div className="title-header-right">
            <button onClick={()=>{setOpenPopup(true)}}>Clear all</button>
          </div>
        </div>
        {/* {loading && (
          <div className="min-h-[50vh] bg-white flex items-center justify-center">
            <LoadingIcon />
          </div>
        )} */}
        <Notifications  key={seed}/>
      </div>
    </GameTag>
  );
};

export default NotificationPage;
