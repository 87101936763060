import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { typeImplementation } from "@testing-library/user-event/dist/type/typeImplementation";

import { gamePopular, gamePopularState } from "interfaces";

const initialState: gamePopularState = {
  gamesPopular: [],
  isLoading: false,
  detailGamePopular: {
    id: "",
    name: "",
    link: "",
    image: "",
    status: "",
    playCount: 0,
    license: "",
    rateCount: 0,
    rateSum: 0,
    popularity: 0,
    createdAt: "",
    updatedAt: "",
  },
};

const gameSlice = createSlice({
  name: "gamesPopular",
  initialState,
  reducers: {
    startLoading: (state: gamePopularState) => {
      state.isLoading = true;
    },
    setListGamePopular: (
      state: gamePopularState,
      action: PayloadAction<gamePopularState["gamesPopular"]>
    ) => {
      state.isLoading = false;
      state.gamesPopular = action.payload;
    },
    setDetailPopular: (
      state: gamePopularState,
      action: PayloadAction<gamePopularState["detailGamePopular"]>
    ) => {
      state.isLoading = false;
      state.detailGamePopular = action.payload;
    },
    setNextDetailGamePopular: (
      state: gamePopularState,
      action: PayloadAction<gamePopular>
    ) => {
      state.detailGamePopular = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  setListGamePopular,
  setDetailPopular,
  setNextDetailGamePopular,
} = gameSlice.actions;

export const gamepopularReducer = gameSlice.reducer;
