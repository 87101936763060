import React, { useContext, useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import btn_prel from "../../asset/popular/chevron-left.png";
import { homeImg, slidebarImg } from "helpers/ExportImg";
import { FaArrowCircleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  addGameToHistory,
  getListGames,
  getListGamesOpenSource,
  getListPopularGameMini,
  postPlayGame,
} from "pages/services/api";
import { useDispatch, useSelector } from "react-redux";
import sweetalert2 from "sweetalert2";
import { setModalWallet } from "slices/modalSlice";
import { setDetailOpen } from "slices/gameOpenSource";

import { loginWallet } from "pages/services/api";
import { setAuth } from "slices/authSlice";
import Swal from "sweetalert2";
import { getAccount, sign } from "web3/connector";
import { openSourcePath } from "constants/path";
import { watchAccount } from "@wagmi/core";

export const PrevNextBtn = ({
  length,
  action,
  activeNft,
  setActiveNft,
}: any) => {
  const topsideRef = React.useRef<any>();

  const { prev, next } = homeImg;

  const handlePrev = () => {
    if (activeNft > 0) {
      setActiveNft(activeNft - 1);
    }

    topsideRef.current.classList.add("prev-btn");
    setTimeout(() => {
      topsideRef.current.classList.remove("prev-btn");
    }, 400);
  };

  const handleNext = () => {
    if (activeNft < length - 1) {
      setActiveNft(activeNft + 1);
    }

    topsideRef.current.classList.add("prev-btn");
    setTimeout(() => {
      topsideRef.current.classList.remove("prev-btn");
    }, 400);
  };

  return (
    <div
      className={`flex-center control-btn cursor-p ${
        action === "prev" ? "prevEl" : "nextEl"
      }`}
      onClick={action === "prev" ? handlePrev : handleNext}
    >
      <div className="under-side">
        <span className="circle-first" />
        <span className="circle-second" />
      </div>
      <div className="top-side">
        <span className="circle-third" />
        <span className="flex-center circle-four" ref={topsideRef}>
          {action === "prev" && (
            <img src={prev} alt="img" className="prev-next-img" />
          )}
          {action === "next" && (
            <img src={next} alt="img" className="prev-next-img" />
          )}
        </span>
      </div>
    </div>
  );
};

export const Button = ({ className, route, item }: any) => {
  const navigate = useNavigate();

  const { accessToken, wallet } = useSelector((state: any) => state.auth);

  const listGameStorage = JSON.parse(
    sessionStorage.getItem("listGames") as any
  );
  const listGamePopularStorage = JSON.parse(
    sessionStorage.getItem("listPopularGame") as any
  );

  const dispatch = useDispatch();

  const handleClick = async (event: any) => {
    event.stopPropagation();

    // if (!accessToken && !wallet) {
    //   sweetalert2
    //     .fire({
    //       title: "Connect wallet to play game !!!",
    //       icon: "warning",
    //       width: 450,
    //       padding: "2rem",
    //       showConfirmButton: true,
    //       showCancelButton: true,
    //       confirmButtonColor: "#56D8BB",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Connect Wallet",
    //     })
    //     .then((result) => {
    //       if (result.isConfirmed) {
    //         dispatch(setModalWallet({ isVisible: true }));
    //       }
    //     });
    // } else {
    const gameId = item?.id;
    const gameType = "miniGame";
    try {
      if (accessToken && wallet) {
        await addGameToHistory({ gameId, gameType });
        await postPlayGame(gameId);
      }
      navigate(route);

      const params = { page: 1, limit: 10 };
      if (!listGameStorage) {
        const response = await getListGames(params);
        sessionStorage.setItem("listGames", JSON.stringify(response.data));
      }

      if (!listGamePopularStorage) {
        const listPopularGame = await getListPopularGameMini();
        sessionStorage.setItem(
          "listPopularGame",
          JSON.stringify(listPopularGame?.data)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  // };

  return (
    <div
      className={className ? `hover-btn ${className}` : "flex-center btn-play"}
      style={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <span>Play Now</span>
      <i className="fa-solid fa-play"></i>
    </div>
  );
};

export const ButtonOpenSource = ({ className, route, item }: any) => {
  const navigate = useNavigate();

  const { accessToken, wallet, walletNoSign } = useSelector(
    (state: any) => state.auth
  );

  const listGameStorage = JSON.parse(
    sessionStorage.getItem("listGames") as any
  );
  const listGamePopularStorage = JSON.parse(
    sessionStorage.getItem("listPopularGame") as any
  );

  const nameRep = item.name.replaceAll(" ", "-").toLowerCase();

  const dispatch = useDispatch();

  const handleSign = async (event: any) => {
    event.stopPropagation();
    if (walletNoSign && !accessToken) {
      sweetalert2
        .fire({
          title: "Sign data to claim reward !!!",
          icon: "warning",
          width: 450,
          padding: "2rem",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: "#56D8BB",
          backdrop: "rgba(0,0,0,.6)",
          confirmButtonText: "Sign",
          cancelButtonText: "No, later!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const signature = await sign();
            const res = await loginWallet(signature, walletNoSign as string);

            localStorage.setItem("accessToken", res?.accessToken);
            dispatch(
              setAuth({
                accessToken: res?.accessToken,
                user: res?.user,
                wallet: walletNoSign,
              })
            );
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate(`${openSourcePath}/${nameRep}/${item.id}`);
          }
        });
    }
  };

  const handleClick = async (event: any) => {
    event.stopPropagation();
    const gameId = item?.id;
    const gameType = "miniGame";
    try {
      if (accessToken && wallet) {
        await addGameToHistory({ gameId, gameType });
        await postPlayGame(gameId);
      }
      navigate(route);

      const params = { page: 1, limit: 10 };
      if (!listGameStorage) {
        const response = await getListGames(params);
        sessionStorage.setItem("listGames", JSON.stringify(response.data));
      }

      if (!listGamePopularStorage) {
        const listPopularGame = await getListPopularGameMini();
        sessionStorage.setItem(
          "listPopularGame",
          JSON.stringify(listPopularGame?.data)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  // };

  return (
    <div
      className={className ? `hover-btn ${className}` : "flex-center btn-play"}
      style={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <span>Play Now</span>
      <i className="fa-solid fa-play"></i>
    </div>
  );
};

export const ButtonMobile = ({ className, route, item, children }: any) => {
  const navigate = useNavigate();
  const listGameStorage = JSON.parse(
    sessionStorage.getItem("listGames") as any
  );

  const { accessToken, wallet } = useSelector((state: any) => state.auth);

  const handleClick = async (event: any) => {
    event.stopPropagation();
    // if (!accessToken && !wallet) {
    //   sweetalert2
    //     .fire({
    //       title: "Connect wallet to play game !!!",
    //       icon: "warning",
    //       width: 350,
    //       padding: "2rem",
    //       showConfirmButton: true,
    //       showCancelButton: true,
    //       confirmButtonColor: "#56D8BB",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Connect Wallet",
    //     })
    //     .then((result) => {
    //       if (result.isConfirmed) {
    //         dispatch(setModalWallet({ isVisible: true }));
    //       }
    //     });
    // } else {
    const gameId = item?.id;
    const gameType = "miniGame";

    try {
      if (accessToken && wallet) {
        await addGameToHistory({ gameId, gameType });
        await postPlayGame(gameId);
      }
      navigate(route);

      if (!listGameStorage) {
        const params = { page: 1, limit: 10 };

        const response = await getListGames(params);
        sessionStorage.setItem("listGames", JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
  // };

  return (
    <div style={{ cursor: "pointer", width: "100%" }} onClick={handleClick}>
      {children}
    </div>
  );
};

export const ButtonGameOpenSource = ({
  className,
  route,
  item,
  children,
}: any) => {
  const navigate = useNavigate();
  const listGameStorage = JSON.parse(
    sessionStorage.getItem("listGames") as any
  );

  const { accessToken, walletNoSign, wallet } = useSelector(
    (state: any) => state.auth
  );

  const nameRep = item.name.replaceAll(" ", "-").toLowerCase();

  const dispatch = useDispatch();

  const handleSign = async (event: any) => {
    event.stopPropagation();
    if (!accessToken) {
      sweetalert2
        .fire({
          title: "Sign In!!!",
          icon: "warning",
          width: 450,
          padding: "2rem",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: "#56D8BB",
          backdrop: "rgba(0,0,0,.6)",
          confirmButtonText: "Sign",
          cancelButtonText: "No, later!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const signature = await sign();
            const res = await loginWallet(signature, walletNoSign as string);
            localStorage.setItem("accessToken", res?.accessToken);
            dispatch(
              setAuth({
                accessToken: res?.accessToken,
                user: res?.user,
                wallet: walletNoSign,
              })
            );
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate(`${openSourcePath}/${nameRep}/${item.id}`);
          }
        });
    }
  };

  const handleClick = async (event: any) => {
    event.stopPropagation();
    // if (!accessToken && !wallet) {
    //   sweetalert2
    //     .fire({
    //       title: "Connect wallet to play game !!!",
    //       icon: "warning",
    //       width: 350,
    //       padding: "2rem",
    //       showConfirmButton: true,
    //       showCancelButton: true,
    //       confirmButtonColor: "#56D8BB",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Connect Wallet",
    //     })
    //     .then((result) => {
    //       if (result.isConfirmed) {
    //         dispatch(setModalWallet({ isVisible: true }));
    //       }
    //     });
    // } else {
    const gameId = item?.id;
    const gameType = "miniGame";

    try {
      if (accessToken && wallet) {
        await addGameToHistory({ gameId, gameType });
        await postPlayGame(gameId);
      }
      navigate(route);

      if (!listGameStorage) {
        const params = { page: 1, limit: 10 };

        const response = await getListGames(params);
        sessionStorage.setItem("listGames", JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
  // };

  return (
    <div style={{ cursor: "pointer", width: "100%" }} onClick={handleClick}>
      {children}
    </div>
  );
};

export const ButtonHome = ({ name, image }: any) => {
  const { home } = slidebarImg;
  const navigate = useNavigate();

  return (
    <div className="flex-center btn-back-home">
      <div onClick={() => navigate(-1)}>
        <img src={btn_prel} alt="btn_prel" className="button" />
      </div>
      <img src={home} alt="icon_game" className="icon_game" />
      <div className="title_game">
        <p>Homepage/ {name}</p>
        <h1>{name}</h1>
      </div>
    </div>
  );
};

export const ButtonShop = ({ name, image }: any) => {
  const { shop } = slidebarImg;
  const navigate = useNavigate();

  return (
    <div className="flex-center btn-back-home">
      <div onClick={() => navigate(-1)}>
        <img src={btn_prel} alt="btn_prel" className="button" />
      </div>
      <div className="shop-page">
        <p>Homepage/ {name}</p>
        <div className="title_shop">
          <img src={shop} alt="icon_game" className="icon_shop" />
          <span>{name}</span>
        </div>
      </div>
    </div>
  );
};

export const ButtonPointHistory = ({ name, title }: any) => {
  const navigate = useNavigate();
  return (
    <div className="flex-center btn-back-home">
      <div onClick={() => navigate(-1)}>
        <img src={btn_prel} alt="btn_prel" className="button" />
      </div>
      <div className="shop-page">
        <p>Homepage/ My Account/ {name}</p>
        <div className="title_shop title_history">
          <span>
            {title} {name}
          </span>
        </div>
      </div>
    </div>
  );
};

export const ButtonNotice = ({ name, title }: any) => {
  const navigate = useNavigate();
  return (
    <div className="flex-center">
      <div onClick={() => navigate(-1)}>
        <img src={btn_prel} alt="btn_prel" className="button" />
      </div>
      <div className="shop-page">
        <p>Homepage/ {name}</p>
        <div className="title_shop title_history">
          <span>
            {title} {name}
          </span>
        </div>
      </div>
    </div>
  );
};
