import { useEffect, useRef, useState } from "react";

import CardSpecialHistory from "components/Card/CardSpecialHistory";
import { useNavigate } from "react-router-dom";
import { LeaderboardEventWraper } from "./styled";
import { getEventHistory } from "pages/services/api";

const TableSpecialEventHistory = () => {
    const [eventData, setEventData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const params = { page: 1, limit: 5, summary: true, history: true, type: "Week" , sortBy: 'startedAt', direction: 'DESC'  };
    const fetchApi = async () => {
        try {
            setLoading(true);
            const data = await getEventHistory(params);
            setLoading(false);
            setEventData(data.data);
        } catch (err) {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    const navigate = useNavigate();
    const rootRef = useRef<any>();
    return (

        <LeaderboardEventWraper className="relative w-full">
            {/* <PopupInfo /> */}
            <div
                className=""
                style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
                ref={rootRef}
            >
                <div
                    className=""
                    style={{ margin: 0 }}
                >
                    <div className="p-5 flex justify-between w-full space-x-5   ">
                        <div className="leaderboard-header-left">
                            <span>History</span>
                        </div>
                        <div className="table-header-right">
                            <button onClick={() => { navigate('/history-special') }} >See all</button>
                        </div>
                    </div>
                    <>
                        <div className="mt-5 p-5 grid grid-cols-1 gap-10   mb-10  overflow-x-auto">
                            {eventData?.length > 0 && eventData?.map((eventInfo: any, index: number) => (
                                <CardSpecialHistory game={eventInfo} />
                            ))}
                            {(!eventData || !eventData.length ||  eventData.length  <= 0) && <div className="text-center info-description">Empty </div>}
                        </div>
                    </>
                </div>
            </div>
        </LeaderboardEventWraper>

    );
};

export default TableSpecialEventHistory;
