import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import appContext from "appContext";
import { GameTag } from "./styled";
import GameOpenSource from "./GameOpenSource";
import OpenSourceAll from "components/List/OpenSourceAll";
import OpenSourceFilter from "components/List/OpenSourceFilter";
import { setPageState } from "slices/pageSlice";
import { useAppDispatch } from "index";
import Profile from "components/profile";
import { adsImg } from "helpers/ExportImg";

const ProfilePage = () => {
  const { detailGame, games } = useSelector((state: any) => state.games);

  const { categories } = React.useContext(appContext);
  const [change, setChange] = React.useState(false);
  const [comments, setComments] = React.useState<any>([]);
  const [rate, setRate] = useState(0);
  const [star, setStarts] = React.useState<any>([]);

  const params = useParams();
  const currentID = params.id;
  const dispatchApp = useAppDispatch()

  useEffect(() => {

    dispatchApp(setPageState({
      showTitle: false,
      showAccount: true,
      tab: 3,
      title: params.id,
      background: '#F5F5F5',
      backgroundHeader: '#FFFFFF'

    }
    ))
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <GameTag className="mt-[40px] pb-[50px]">
      <div className="mt-10  w-full rounded overflow-hidden shadow-lg  flex items-center justify-center	">
        {/* <img className=" rounded-xl" src={adsImg.bannerAds}
          alt="ads" /> */}
          {/* <div id="container-e49302f0df0a34efc084889cd3314a5d"></div> */}
      </div>
      <div className="game_page container-new">
        <Profile />
      </div>
    </GameTag>
  );
};

export default ProfilePage;
