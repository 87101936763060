import styled from "styled-components";

export const Information = styled.section`
  width: 80%;
  height: 178px;
  max-height: 178px;
  border: 3px solid rgba(0, 137, 111, 1);
  border-width: 3px 3px 7px 3px;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Regular";

  font-weight: 700;
  z-index: -1;
  .information {
    .title {
      font-weight: bold;
      font-size: 34px;
      margin: 0;
    }
    .text_install,
    p {
      // font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      margin: 0;
    }
  }
  .content_infor {
    width: 90%;
    display: flex;
    justify-content: space-between;
    .number_download {
      display: flex;
      align-items: center;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .swap {
      display: flex;
      flex-direction: column;
      .btn_share {
        width: 100%;
        display: flex;
        justify-content: end;
      }
      .btn_swap {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 326px;
        width: 326px;
        height: 80px;
        background: #ff6700;
        border: 3px solid #b44b05;
        border-width: 3px 3px 7px 3px;
        border-radius: 20px;
        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 100%;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .content_infor {
      height: 73%;
      width: 90%;
      .information {
        .title {
          font-size: 24px;
        }
        p {
          font-size: 16px;
        }
      }
      .swap {
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 930px) {
    .content_infor {
      .btn_share {
        width: 28px;
        height: 28px;
      }
      .swap {
        img {
          width: 30px;
          height: 30px;
        }
        .btn_swap {
          width: 270px;
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    height: 225px;
    max-height: 335px;
    .content_infor {
      flex-direction: column;
      .information {
        text-align: center;
        .number_download {
          justify-content: center;
        }
      }
      .swap {
        align-items: center;
        height: 50%;
        img {
          width: 25px;
          height: 25px;
        }
        .btn_swap {
          width: 170px;
          height: 50px;
          p {
            font-family: "bold";
          }
        }
      }
    }
  }
  @media (max-width: 300px) {
    .content_infor {
      .swap {
        .btn_swap {
          width: 200px;
        }
      }
    }
  }
`;
