import { userTokenReducer } from "./../slices/userTokenSlice";
import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "slices/authSlice";
import { gamenftReducer } from "slices/gameNFTSlice";
import { gamepopularReducer } from "slices/gamePopularSlice";
import { gameReducer } from "slices/gameSlice";
import { modalReducer } from "slices/modalSlice";
import { userPointReducer } from "slices/userPointSlice";
import { gameOpenSourceReducer } from "slices/gameOpenSource";
import { pageReducer } from "slices/pageSlice";
import { popupPlayReducer } from "slices/popupPlaySlice";
import { popupConnectReducer } from "slices/popupConnectSlice";
import { popupInfoReducer } from "slices/popupInfoSlice";
import { popupAllPrizesReducer } from "slices/popupAllPrizesSlice";


export const store = configureStore({
  reducer: {
    games: gameReducer,
    gamenft: gamenftReducer,
    gamepopular: gamepopularReducer,
    auth: authReducer,
    modalBuy: modalReducer,
    userPoint: userPointReducer,
    userToken: userTokenReducer,
    gameOpenSource: gameOpenSourceReducer,
    pageState: pageReducer,
    popupPlayState: popupPlayReducer,
    popupConnectState: popupConnectReducer,
    popupInfoState: popupInfoReducer,
    popupAllPrizesState: popupAllPrizesReducer,
  },
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

