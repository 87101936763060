import { useEffect } from "react";

import ListPrivacyPolicy from "components/List/ListPrivacyPolicy";
import { useAppDispatch } from "index";
import { useSearchParams } from "react-router-dom";
import { setPageState } from "slices/pageSlice";
import { ellipsisMiddle } from "web3/connector";
import { GameTag } from "./styled";

const PrivacyPolicy = () => {


  const dispatchApp = useAppDispatch()
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: false,
      tab: 3,
      iconHeader: 0,
      title: ellipsisMiddle("Point Policy", 20, -Infinity),
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'

    }
    ))
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <GameTag className="mt-[40px] pb-[50px]">
      <div className="mt-10  w-full rounded overflow-hidden shadow-lg  flex items-center justify-center	">
         {/* <img className=" rounded-xl" src={games.bannerAds}
          alt="ads" /> */}
{/* <div id="container-e49302f0df0a34efc084889cd3314a5d"></div> */}
      </div>
      <div className=" container-new">
        <ListPrivacyPolicy />
      </div>
    </GameTag>
  );
};

export default PrivacyPolicy;
