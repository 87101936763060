import ModalWallet from "components/modals/ModalWallet";
import { eventsImg, slidebarImg } from "helpers/ExportImg";
import {
  getGameReward,
  getUserGameMissionPoint,
  loginWallet
} from "pages/services/api";
import * as React from "react";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { setAuth } from "slices/authSlice";
import { sign } from "web3/connector";
import { PopupTag } from "./styled";
const { logo, pools, coinDes, avatar } = slidebarImg;

export interface IOverLayPlayGameResultProps {
  game: any;
  result: any;
  onClose: () => void;
}

export default function RewardClaime({
  game,
  result,
  onClose,
}: IOverLayPlayGameResultProps) {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log({ game });
  const rootRef = useRef<any>(null);

  const [isOpen, setIsOpen] = useState<any>(false);
  const [missionRewards, setMissionRewards] = useState<any>([]);
  const { accessToken, wallet, walletNoSign, user } = useSelector(
    (state: any) => state.auth
  );

  const [dataPoitReward, setDataPointReward] = useState<any>();
  const fetchDataPointReward = async () => {
    try {
      const res = await getUserGameMissionPoint(game?.id);
      setDataPointReward(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchDataPointReward();
  }, []);

  const dispatch = useDispatch();

  const handleSign = async () => {
    const signature = await sign();
    const res = await loginWallet(signature, walletNoSign as string);

    localStorage.setItem("accessToken", res?.accessToken);
    dispatch(
      setAuth({
        accessToken: res?.accessToken,
        user: res?.user,
        wallet: walletNoSign,
      })
    );
  };

  const handleHideOverLay = () => {
    setTimeout(() => {
      rootRef.current.style.display = "none";
    }, 500);
  };



  const handleFetchMissionReward = async () => {
    try {
      const data = await getGameReward();
      setMissionRewards(data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const user_mission = game?.game_mission;
    const missionPass = game?.missionPass || 0;
    const nextMissionReward = user_mission[missionPass];
    handleFetchMissionReward();
  }, []);
  return (
    <>
      <PopupTag>

        <div
          ref={rootRef}
          style={{
            position: "absolute",
            zIndex: "99999",
            inset: "0",
            backdropFilter: "blur(8px)",
            background: "rgba(0,0,0,.6)",
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            className="flex align-center justify-center relative h-full w-full"
          // style={{ width: '100vw', height: '100vh' }}
          >
            <div
              className={`flex flex-col align-center justify-center rounded-[30px]  gap-[40px] absolute w-[360px] mb-[80px] bottom-[0px]`}
              style={{ height: "auto", background: "white" }}
            >
              <div className="relative p-[20px] pt-[40px]">
                <div className=" rounded-xl flex justify-center items-center flex-col p-5">
                  <div className="title-reward">
                    REWARD CLAIMED
                  </div>
                  <div className="flex mt-5 flex-col justify-center text-center">
                    <div className="point-reward">+ {result.realReward ? result.realReward : '0'} POINT </div>
                    <div className=" flex items-center" ><img className="w-full  " src={eventsImg.claime}
                      alt="img" />
                    </div>

                  </div>
                  <div className="flex items-center justify-center gap-6 mb-[12px] mt-20 w-full ">
                    <button onClick={onClose} className="w-full popup-btn text-3xl text-center hover:text-blue-400 cursor-pointer">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <ModalWallet visible={isOpen} onClose={() => setIsOpen(false)} />
        )}
      </PopupTag>

    </>
  );
}
