import styled from "styled-components";

export const LeaderboardEventWraper = styled.section`
  table{
    border-radius: 8px 8px 0em 0em;

  }
  thead{
    padding: 10px;
    color: var(--Grey-0, #FFF);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
    background: #06C270;
    tr{
      background: #06C270;
    }
  }
  th, td{
    padding: 10px

  }
  td{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 

  }
  tbody{
    background: #F7F7F7AA; 

  }

  table tr:nth-child(even) {
    background-color: #FFFFFFAA;
  }

  .leaderboard-header-left{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1 / Subtitle / Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;  
  }

  .info-description{
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }

    th{
        background:#06C270;
        color: #fff;
    }
    th:nth-child(1) {
      border-radius: 8px 0px 0px 0px;
    }

    th:nth-last-child(1) {
      border-radius: 0px 8px 0px 0px;
    }
 

 .table-header-right{
    color: var(--Primary, #06C270);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.021px;
    text-transform: capitalize; 
 }

 .history-header-right{
  color: var(--Neutral-Color-Black, #000);
/* Poppin/Body/Body medium - regular */
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
letter-spacing: 0.021px; 
 }

  .history-lable{
      color: var(--Grey-Grey-50, #7E7E7E);
    /* Poppin/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }

  .value-prize{
    color: var(--Primary, #06C270);
    /* Ver 1/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }
  .value-total{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }

  .history-row{
    border-bottom: 0.5px solid var(--Grey-Grey-10, #E1E1E1);
  }

  .header-history{
    color: #D9AD13;
    /* Ver 1 / Subtitle / Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
  }

  .ticket-number {
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.021px; 
  }

`;
