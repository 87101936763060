import { ButtonHome } from "../button";
import React, { FC } from "react";
import { PopularGame } from "./styled";
import useScreenSize from "hooks/useScreenSize";

type GamePageType = {
  isFullScreen?: boolean;
  data: any;
};
const PagePopularGame: FC<GamePageType> = ({ data, isFullScreen }) => {
  const screen: any = useScreenSize();

  return (
    <PopularGame>
      <ButtonHome name={data.name} image={data.image} />
      <div className="frameGame">
        {/* <div className="game"> */}
        <div className={isFullScreen ? "full_screenn" : "game"}>
          <iframe
            title="game"
            id="item-direct-container"
            width="852"
            height="568"
            // allow="autoplay"
            scrolling="yes"
            //data-src={data?.link || undefined}
            src={data?.link || undefined}
            style={{ display: "block", borderStyle: "none" }}
            className={`${
              isFullScreen
                ? (data?.type === "Horizontal" && screen.height > screen.width)
                  ? "rotate_game_html"
                  : ""
                : ""
            } ${
              isFullScreen ? "iframe_game" : ""
            } item-direct-container resizable`}
          ></iframe>
        </div>
      </div>
    </PopularGame>
  );
};

export default PagePopularGame;
