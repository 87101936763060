import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./tailwind.css";

import Scroll from "react-scroll";
import Router from "routes";
import io from "socket.io-client";
import AppContext from "./appContext";

import { watchAccount, watchNetwork } from "@wagmi/core";
import { AuthState, UserTokenSlice } from "interfaces";
import { getInfoUser, getMaintenance, login } from "pages/services/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { setAuth } from "slices/authSlice";
import { CHAINS, NETWORKS, sign } from "web3/connector";

import PopupMainten from "components/popupMainten";
import PopupMaintenance from "components/popupMaintenance";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { useAppDispatch } from "index";
import { setPopupConnectShowState } from "slices/popupConnectSlice";
import { ConnectorData, useAccount } from "wagmi";
import { getCData, isSameDate, setCData } from "helpers/util";

const firebaseConfig = {
  apiKey: "AIzaSyBaMs4Ujo9jI0AtrKn6597p6PojTRr7U88",
  authDomain: "game-center-681ad.firebaseapp.com",
  projectId: "game-center-681ad",
  storageBucket: "game-center-681ad.appspot.com",
  messagingSenderId: "965340649958",
  appId: "1:965340649958:web:e0bdeeb983cadfd8acde27",
  measurementId: "G-J6TWRJ8M4K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

function App() {
  const dispatch = useDispatch();

  const [categories, setCategories] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [active, setActive] = useState(0);
  const [infoGenre, setInfoGenre] = useState("");
  const [account, setAccount] = useState();
  const [loading, setLoading] = useState(false);
  const [openPopupMaintenance, setOpenPopupMaintenance] = useState(false);
  const { isConnected , connector: activeConnector } = useAccount();


  const [route, setRoute] = useState({
    to: "/",
    from: "/",
  });
  const { wallet, accessToken, callback } = useSelector(
    (state: any) => state.auth as AuthState
  );

  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await getMaintenance();
      setLoading(false);
      setOpenPopupMaintenance(data === 1);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApi();
    var click = getCData();
    console.log(click)
    if(!isSameDate(new Date(), new Date(click?.cTime))){
      setCData(0, new Date())
    }
  }, []);
  


  
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getInfoUser();
        dispatch(
          setAuth({
            user: response,
          })
        );
        setAccount(response);
      } catch (error: any) {
        console.log(error);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    try {
      if (accessToken && isConnected) {
        const socket = io(
          process.env.REACT_APP_SOCKET_DOMAIN + "?accessToken=" + accessToken
        );

        socket.on("connect", () => {
          console.log("[Socket Connected]: " + socket.id);
        });

        socket.on("UpdateUserTicket", async (data) => {
          const response = await getInfoUser();
          setAccount(response);
          dispatch(
            setAuth({
              user: response,
            })
          );

          console.log("[UpdateUserTicket]", data, response);
        });

        socket.on("UpdateUserPoint", async (data) => {
          const response = await getInfoUser();
          setAccount(response);
          dispatch(
            setAuth({
              user: response,
            })
          );

          console.log("[UpdateUserPoint]", data, response);
        });

        socket.on("ReloadClient", (data) => {
          console.log("[Socket ReloadClient]: ", data);

          window.location.reload();
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [accessToken]);

  // watchAccount(async (account) => {

  //   if (!wallet && account.isConnected) {
  //     if (accessToken) {
  //       const response = await getInfoUser();

  //       dispatch(
  //         setAuth({
  //           accessToken,
  //           user: response,
  //           wallet: response?.walletAddress,
  //         })
  //       );
  //     } else {
  //       sweetalert2
  //         .fire({
  //           title: "Sign data to continue !!!",
  //           icon: "warning",
  //           width: 400,
  //           padding: "2rem",
  //           showConfirmButton: true,
  //           showCancelButton: false,
  //           backdrop: "rgba(0,0,0,.6)",
  //           confirmButtonColor: "#56D8BB",
  //           confirmButtonText: "Sign",
  //         })
  //         .then(async (result) => {
  //           if (result.isConfirmed) {
  //             const signature = await sign();
  //             const res = await loginWallet(
  //               signature,
  //               account.address as string
  //             );

  //             localStorage.setItem("accessToken", res?.accessToken);
  //             dispatch(
  //               setAuth({
  //                 accessToken: res?.accessToken,
  //                 user: res?.user,
  //                 wallet: account.address,
  //               })
  //             );
  //           }
  //         });
  //     }
  //   } else if (account.isConnected) {
  //     localStorage.removeItem("accessToken");
  //     window.location.reload();
  //   }
  // });

  watchAccount(async (account) => {
    if (account.isConnected) {
      dispatch(
        setAuth({
          wallet: account?.address,
        })
      );
    }
  });


  // Listen for wallet connections to change
  useEffect(() => {
		const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
			if (account) {
				console.log("new account", account);
        // window.location.reload();
        window.location.href = "/"
        // handleConnectWallet(account);
			} else if (chain) {
				console.log("new chain", chain);
			}
		};

		if (activeConnector) {
			activeConnector.on("change", handleConnectorUpdate);
		}

		return () => activeConnector?.off("change", handleConnectorUpdate) as any;
	}, [activeConnector]);


  const handleConnectWallet = async (account: string) => {
    try {
      setLoading(true);
      const time = Date.now()
      const message = "Welcome to Playground Game Center! Timestamp: " + time

        const signature = await sign(account, message);
        const res = await login(signature, account, message);
        localStorage.setItem("accessToken", res?.accessToken);
        localStorage.setItem("user", JSON.stringify(res?.user));
        dispatch(
          setAuth({
            accessToken: res?.accessToken,
            user: res?.user,
            wallet: account,
          })
        );
        setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const dispatchApp = useAppDispatch()

  const handlerPopup = (show: boolean) => {
    dispatchApp(setPopupConnectShowState(show));
  };

  watchNetwork((network) => {
    const myNetworkId = CHAINS[process.env.REACT_APP_NETWORK as NETWORKS].CHAIN_ID;
    if (network?.chain?.id !== myNetworkId) {
      // window.location.reload();
      handlerPopup(true)
    }
  });

  useEffect(() => {
    // async function logic() {
    //   try {
    //     if (wallet) {
    //       const [
    //         userBNBBalance,
    //         userBUSDBalance,
    //         userBPoolsBalance,
    //         userBUSDAllowance,
    //       ] = await Promise.all([
    //         useWeb3.getBNBBalance(wallet),
    //         useWeb3.getTokenBalance(
    //           CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].BUSD,
    //           wallet
    //         ),
    //         useWeb3.getTokenBalance(
    //           CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].BPOOLS,
    //           wallet
    //         ),
    //         useWeb3.getAllowance({
    //           token: CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].BUSD,
    //           owner: wallet,
    //           spender:
    //             CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].MARKET,
    //         }),
    //       ]);

    //       console.log(
    //         "userBNBBalance, userBUSDBalance, userBPoolsBalance, userBPoolsAllowance",
    //         userBNBBalance,
    //         userBUSDBalance,
    //         userBPoolsBalance,
    //         userBUSDAllowance
    //       );

    //       dispatch(
    //         setUserToken({
    //           bnbBalance: userBNBBalance,
    //           busdBalance: userBUSDBalance,
    //           bpoolsBalance: userBPoolsBalance,
    //           busdAllowance: userBUSDAllowance,
    //         })
    //       );
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }

    // logic();
  }, [callback, wallet]);

  const Link = Scroll.Link;
  const Element = Scroll.Element;

  // useEffect(() => {
  //   window.addEventListener("message", event => {
  //     console.log({ event })
  //     // Kiểm tra xem tin nhắn được gửi từ iframe
  //     // if (event.origin !== "https://game-center-pg.s3.ap-southeast-1.amazonaws.com")
  //     //   return;
  //     // Lấy dữ liệu từ tin nhắn
  //     const data = event.data;
  //     // Xử lý dữ liệu
  //     console.log({ dataaaaaaaaa: data.message });
  //     if (data.message) {

  //     }
  //   });
  // }, [])

  return (
    <AppContext.Provider
      value={{
        categories,
        page,
        limit,
        setPage,
        setLimit,
        setCategories,
        active,
        setActive,
        Link,
        Element,
        route,
        setRoute,
        infoGenre,
        setInfoGenre,
        account,
        setAccount,
      }}
    >
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        bodyClassName="toastBody"
        pauseOnFocusLoss
        draggable
        pauseOnHover
        position="top-right"
        bodyStyle={{ fontSize: "2rem" }}
      />
      <PopupMaintenance open={openPopupMaintenance} setOpen={setOpenPopupMaintenance}/>
      <BrowserRouter>
      <PopupMainten/>
        <Router />
        {/* <ModalBuyBox /> */}
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;