import styled from "styled-components";
import bg_frame from "../../asset/games/frame.png";
import rate from "../../asset/popular-game/rate.png";

export const Game = styled.section`
  width: 92%;
  height: 825px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  /* background: aliceblue; */
  flex-direction: column;
  overflow-x: hidden;
  border: 1px solid rgba(250, 165, 37, 1);
  background-image: linear-gradient(
    to right,
    rgba(250, 203, 37, 1),
    rgba(0, 255, 206, 0.7)
  );
  border-radius: 20px;
  .title {
    width: 100%;
    font-size: 45px;
    // font-weight: bold;
    line-height: 56px;
    text-align: center;
    color: white;
    // font-family: "Karla", sans-serif;
  }
  .content_games {
    width: 99%;
    height: 66%;
    display: flex;
    flex-wrap: wrap;
  }
  .game {
    background-image: url(${bg_frame});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 374px;
    height: 470px;
    max-width: 374px;
    min-height: 470px;
    overflow: hidden;
    text-align: center;
    font-size: 19px;
    // font-weight: 500;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img_game {
      position: absolute;
      top: 5%;
      left: 6%;
    }
    .infor_game {
      position: absolute;
      left: 7%;
      top: 55%;
      height: 40%;
      width: 88%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .title_game {
        // font-family: "Karla", sans-serif;
        // font-weight: bold;
        font-size: 34px;
        line-height: 40px;
        color: white;
        margin: 0;
      }
      .social {
        width: 85%;
        height: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        img {
          height: 60px;
          width: 60px;
        }
      }
      .btn_play {
        background: rgba(250, 165, 37, 1);
        width: 250px;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid;
        border-radius: 12px;
      }
    }
  }
  .more_button {
    width: 40%;
    display: flex;
    justify-content: space-around;

    .btn_more_game,
    .btn_visit_swap {
      width: 250px;
      height: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(250, 165, 37, 1);
      border-radius: 12px;
      color: white;
    }

    .btn_visit_swap {
      /* background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.168)
      ); */
      background: linear-gradient(
        94.15deg,
        rgba(255, 255, 255, 0.6) 3.38%,
        rgba(255, 255, 255, 0.168) 98.5%
      );
    }
    .btn_more_game {
      background: rgba(250, 165, 37, 1);
    }
  }
`;

export const NewVersion = styled.section`
  .nft-card_newversion {
    position: relative;
  }

  .title-newversion {
    position: absolute;
    left: 15px;
    top: -10px;
    background-color: #54d8bb;
    color: #161616;
    font-size: 16px;
    text-transform: capitalize;
    padding: 0.2rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    z-index: 9;
  }

  .title-newversion::after {
    position: absolute;
    content: "";
    left: 0;
    top: 100%;
    transform: translateY(-5px);
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #54d8bb;
  }

  .new-version {
    font-size: 18px;
  }
`;

export const GenreGameTag = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  row-gap: 3rem;
  column-gap: 2rem;
  background: rgba(103, 219, 192, 0.3);
  border-radius: 10px;
  padding: 1.2rem 2rem;
  width: 100%;

  :active {
    transform: scale(0.98);
  }

  .name-game {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 31px;
    color: #000000;
  }

  .category-game {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #007b63;
  }

  .img-tertiary {
    /* width: 30%; */
    max-width: 12rem;
    max-height: 12rem;
    min-width: 12rem;
    min-height: 12rem;
    object-fit: cover;
    border-radius: 16px;
  }

  .content-game {
    width: 100%;
  }

  .review-star {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 6rem;

    .review-title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #161616;
    }

    .btn-play-game {
      background: #ff6700;
      border-radius: 8px;
      padding: 15px;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
    }
  }
`;

export const GenreGameMobile = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  row-gap: 3rem;
  column-gap: 2rem;
  /* background-color: #f4f5f6; */
  background: rgba(103, 219, 192, 0.3);
  border-radius: 24px;
  padding: 10px;
  width: 100%;

  :active {
    transform: scale(0.98);
  }

  .img-tertiary {
    /* width: 30%; */
    max-width: 80px;
    max-height: 80px;
    min-width: 80px;
    min-height: 80px;
    object-fit: cover;
    border-radius: 16px;
  }

  .review-star {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 3rem;
  }

  .btn-play-game {
    background: #ff6700;
    border-radius: 8px;
    padding: 10px 15px;

    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 100%;
    color: #ffffff;
  }
`;

export const PrimaryCardMobile = styled.section`
  width: 100%;
  font-size: 2.4rem;
  :not(:last-child) {
    margin-bottom: 4rem;
  }
  position: relative;

  .primary-card-mobile {
    display: flex;
    align-items: center;
    column-gap: 3rem;
    /* background-color: #f4f5f6; */
    background: rgba(103, 219, 192, 0.3);
    border-radius: 24px;
    padding: 10px;
  }
  .primary-card-bg {
    width: 35%;
    /* max-width: 80px;
    max-height: 80px;
    min-width: 80px;
    min-height: 80px; */

    img {
      /* width: 100%; */
      width: 80px;
      height: 80px;
      border-radius: 16px;
      object-fit: cover;
    }
  }

  .primary-card-content__mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .content-speed {
      font-size: 3.2rem;
      font-weight: 700;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-play-game {
      background: rgb(255, 103, 0);
      border-radius: 8px;
      padding: 10px 15px;
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 100%;
      color: rgb(255, 255, 255);
    }

    .primary-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 100%;
      font-size: 2rem;
      color: white;
      background: var(--cl_button);
      padding: 1rem;
      border-radius: 5px;
    }
  }
  .rate-game-mobile {
    position: absolute;
    top: 5%;
    left: 5%;
    transform: translate(-50%, -50%);
    color: black;
    width: 4rem;
    height: 4rem;

    span {
      position: absolute;
      font-size: 1.8rem;
      transform: rotateZ(-10deg);
    }
  }

  .review-star {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 3rem;
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    width: calc(100% / 2 - 4rem);
  }
`;

export const PrimaryTag = styled.section`
  width: 58rem;
  height: 36rem;
  background: #ffffff;
  border: 5px solid #00896f;
  border-radius: 15px;
  margin: 0 2rem;

  .card-popular {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
    position: relative;

    .card-header {
      width: 100%;
      height: 25rem;
      overflow: hidden;
      img {
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2rem;
    margin: 0 5rem;

    .card-footer-img {
      width: 8rem;
      height: 8rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .card-footer-text {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
      text-transform: uppercase;
      color: #272727;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-game-popular {
      background: var(--cl_button);
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      padding: 2rem 2rem;
      border-radius: 15px;
    }
  }
  .rate-popular {
    position: absolute;
    top: 5%;
    left: -4%;
    z-index: 999999;

    background-image: url(${rate});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 9rem;
    height: 5rem;

    display: flex;
    justify-content: center;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      text-transform: uppercase;
      color: #000000;
    }
  }
`;
