import LoadingIcon from "components/LoadingIcon";
import { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import GameCardNewTwo from "components/Card/GameCardNewTwo";
import LoadingVerTwo from "components/loadingVerTwo/LoadingVerTwo";
import { homeImg } from "helpers/ExportImg";
import { getListGamesOpenSource } from "pages/services/api";
import { useSearchParams } from "react-router-dom";

const OpenSoureAllGame = ({ genres }: any) => {


  // useEffect(() => {
  //   const params = { page, limit };
  //   getListGamesOpenSource(params);
  // }, [page, categories]);


  const { bgSymbol } = homeImg;

  const PER_PAGE = 20;
  const [listGame, setListGame] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [hasMore, setHasMore] = useState<any>(true);
  const [offset, setOffset] = useState<any>(0);
  const isEmptyGame = !listGame || listGame.length === 0;
  const [flagCount, setFlagCount] = useState<number>(0);
  const [searchParams] = useSearchParams();

  const fetchListGame = async () => {

    if (loading) return;
    const newOffset = offset + 1;
    const query = searchParams.get('query')?.toString()
    const categoriesId = searchParams.get('categoriesId')?.toString()
    const params = { page: newOffset, limit: PER_PAGE , keyword: query || '', searchBy: 'name' , sortBy: 'createdAt', direction: 'DESC', categories: categoriesId || null };

    try {
      const response = await getListGamesOpenSource(params);
      if (response && response.data) {
        const newListGame = [...listGame, ...response.data];

        setFlagCount(flagCount + newListGame.length);

        if (flagCount >= response.total) {
          setHasMore(false);
        }

        setListGame(newListGame);

        setOffset(newOffset);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  // useEffect(() => {
  //   fetchListGame();
  // }, []);
  const rootRef = useRef<any>();


  return (
    <div
      className="all-game-mini"
      style={{ scrollMargin: "10rem" }}
      ref={rootRef}
    >
      {/* <img src={bgSymbol} alt="bg-symbol" className="bg-symbol" /> */}
      <div
        className="component-games top-free-game all-game-mobile"
        style={{ margin: 0 }}
      >
        {loading && (
          <div className="min-h-[50vh] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )}
        <>

          <InfiniteScroll
            pageStart={0}
            loadMore={fetchListGame}
            hasMore={hasMore}
            loader={<LoadingVerTwo />}
            // useWindow={false}
            threshold={50}
            // initialLoad={true}
            className="loadmore-games"
          >
            <div className="mt-5 grid grid-cols-2  gap-10 scroll-p-0 w-[90vw] max-w-[1200px] pb-10 hide-scroll-bar justify-between">
              {!isEmptyGame &&
                listGame?.map((game: any, id: any) => (
                  <div className="" key={id}>
                    <GameCardNewTwo game={game} />
                  </div>
                ))}

            </div>
            {
              isEmptyGame && <div className="text-center text-[14px]">No data</div>
            }
          </InfiniteScroll>
        </>
      </div>
    </div>
  );
};

export default OpenSoureAllGame;
