import React, { useEffect } from "react";

import useWindowSize from "hooks/useWindowSize";
import { GameCardHistoryWraper, GameCardNew } from "./styled";
import { Link } from "react-router-dom";
import { discover, openSourcePath } from "constants/path";
import { logos } from "helpers/ExportImg";

const GameCardRecent: any = ({ game }: any) => {


  const {gameType } = game;
  let nameRep = '';

  if(game[`${gameType}Name`]) nameRep = game[`${gameType}Name`].replaceAll(" ", "-").toLowerCase()
  const getTime = (time: string | number | Date) => {
    // Lấy thời điểm cụ thể
    const targetTime = new Date(time);

    // Lấy timestamp của thời điểm cụ thể
    const targetTimeInMillis = targetTime.getTime();

    // Lấy timestamp của thời điểm hiện tại
    const nowInMillis = new Date().getTime();

    // Tính thời gian theo giờ tới thời điểm cụ thể
    const remainHours = (nowInMillis - targetTimeInMillis) / (1000 * 60 * 60);
    return remainHours.toFixed(2)
  }
  const getLinkPlay = ()=>{
    if(gameType==='game'){
      return `${openSourcePath}/${nameRep}/${game[`gameId`]}`
    }
    if(gameType==='miniGame') return `${discover}/${game[`gameId`]}`
    
    return '/'
}
  return (
    <>
      {nameRep && (<GameCardHistoryWraper>
        <Link to={getLinkPlay()}>
          <div className="inline-block w-full">
            <div className="flex items-center  w-[300px] h-[76px]   overflow-hidden rounded-lg " >
              <div className="flex-shrink-0 ">
                <img className="w-[60px] h-[60px] rounded-lg" src={game[`${gameType}Thumbnail`]}
                  alt="img" />
              </div>
              <div className="flex-1 min-w-0 ms-4 ml-6">
                <p className="game-card-new-title">
                {game[`${gameType}Name`]}
                </p>
                <div className="game-card-new-play-count flex  items-center	">
                  <div className="" >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      fill="none"
                    >
                      <g
                        stroke="#7E7E7E"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                      >
                        <path d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10Z" />
                        <path d="M6 3v3l2 1" />
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path fill="#fff" d="M0 0h12v12H0z" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="ml-2 game-card-new-play-count-text flex"> {getTime(game['play_time'])} hour ago</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </GameCardHistoryWraper>)}
    </>
  );
};

export default GameCardRecent;
