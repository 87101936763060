import Web3 from "web3";
import { AbiItem } from "web3-utils";
import {
  getContract as defineContract,
  fetchBalance,
  readContract,
  prepareWriteContract,
  writeContract,
} from "@wagmi/core";

import BuyTicketABI from "abi/buy_ticket.json";
import ERC20ABI from "abi/abi_erc20.json";
import WithdrawABI from "abi/withdraw.json";
import { NETWORKS } from "./connector";

export const CONTRACTS = {
  [NETWORKS.BSC_TESTNET]: {
    MARKET: "0xbf5f4d32eBa7b254972f5834F84123EA6F81838C",
    // MARKET: "0x58B0031403d81C3075bc4A8F7e1eb171ca75A77E",
    WITHDRAW: "0xCa9526AD6A18B3b5844A38C6ad851Bb43fAA5F86",
    BUSD: "0x98649fde88981790b574c9A6066004D5170Bf3EF",
    BPOOLS: "0xd0F8aFC0C5f26f53fc6c61727a185420129082b8",
  },
  [NETWORKS.BSC]: {
    MARKET: "",
    WITHDRAW: "",
    BUSD: "",
    BPOOLS: "",
  },
  [NETWORKS.POOLS]: {
    MARKET: "0xbf5f4d32eBa7b254972f5834F84123EA6F81838C",
    // MARKET: "0x58B0031403d81C3075bc4A8F7e1eb171ca75A77E",
    WITHDRAW: "0xCa9526AD6A18B3b5844A38C6ad851Bb43fAA5F86",
    BUSD: "0x98649fde88981790b574c9A6066004D5170Bf3EF",
    BPOOLS: "0xd0F8aFC0C5f26f53fc6c61727a185420129082b8",
  },
  [NETWORKS.POOLS_TESTNET]: {
    MARKET: "0xbf5f4d32eBa7b254972f5834F84123EA6F81838C",
    // MARKET: "0x58B0031403d81C3075bc4A8F7e1eb171ca75A77E",
    WITHDRAW: "0xCa9526AD6A18B3b5844A38C6ad851Bb43fAA5F86",
    BUSD: "0x98649fde88981790b574c9A6066004D5170Bf3EF",
    BPOOLS: "0xd0F8aFC0C5f26f53fc6c61727a185420129082b8",
  },
};

function getContract(abi: AbiItem[], address: string) {
  return defineContract({ address, abi });
}

function getWeb3ByProvider(provider: string) {
  return new Web3(new Web3.providers.HttpProvider(provider));
}

function getContractByProvider(
  provider: string,
  abi: AbiItem,
  address: string
) {
  const web3 = getWeb3ByProvider(provider);
  return new web3.eth.Contract(abi, address);
}

function fromWei(amount: any) {
  return +Web3.utils.fromWei(amount);
}

const useWeb3 = {
  buyTicket: async (packageIndex: number, wallet: string, id: string) => {
    console.log({ packageIndex, wallet, id });
    const config = await prepareWriteContract({
      address: CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS]
        .MARKET as any,
      abi: BuyTicketABI,
      functionName: "buyTicket",
      args: [packageIndex, id],
    });
    const result = await writeContract(config);

    console.log("config", config);
    console.log("result", result);

    return await result.wait();
  },

  swapPointToToken: async (data: {
    amount: string;
    block: number;
    signature: string;
  }) => {
    const config = await prepareWriteContract({
      address: CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS]
        .WITHDRAW as any,
      abi: WithdrawABI,
      functionName: "claimReward",
      args: [data.amount, data.block, data.signature],
    });
    const result = await writeContract(config);

    return await result.wait();
  },

  getAllowance: async (data: {
    token: string;
    owner: string;
    spender: string;
  }) => {
    const result: any = await readContract({
      address: data.token as any,
      abi: ERC20ABI,
      functionName: "allowance",
      args: [data.owner, data.spender],
    });

    return fromWei(String(result));
  },

  approve: async (data: { token: string; owner: string; spender: string }) => {
    const config = await prepareWriteContract({
      address: data.token as any,
      abi: ERC20ABI,
      functionName: "approve",
      args: [data.spender, "10000000000000000000000000000000000"],
    });
    const result = await writeContract(config);

    return await result.wait();
  },

  getBNBBalance: async (wallet: string) => {
    const balance = await fetchBalance({
      address: wallet as any,
    });
    return Number(balance.formatted);
  },

  getTokenBalance: async (token: string, wallet: string) => {
    const result: any = await readContract({
      address: token as any,
      abi: ERC20ABI,
      functionName: "balanceOf",
      args: [wallet],
    });

    return fromWei(String(result));
  },
};

export {
  getContract,
  getWeb3ByProvider,
  getContractByProvider,
  fromWei,
  useWeb3,
};
