import styled from "styled-components";

export const PopupTag = styled.section`
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .backdrop {
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    display: block;
    z-index: 999999;
  }

  .game-card-new-title {
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.024px; 
    }

    .game-card-new-play-count-text{
      color: var(--Grey-Grey-50, #7E7E7E);
      text-overflow: ellipsis;
      /* Poppin/Body/Body small - regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }

    .popup-title {
      color: var(--Primary, #06C270);
      text-align: center;
      /* Poppin/Button/Button medium - medium */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.024px; 
    }
    .popup-text{
      color: var(--Grey-Grey-50, #7E7E7E);
      /* Poppin/Body/Body small - regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }
    .popup-sc {
      color: var(--Grey-Grey-80, #333);
      /* Poppin/Caption 1 - medium */
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.05px; 
      
    }

    .popup-btn{
      display: flex;
      height: 40px;
      padding: 0px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch; 
      border-radius: 8px;
      background: #06C270; 

      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.024px; 
    }

    .text-result{
      color: var(--Grey-Grey-50, #7E7E7E);
      /* Poppin/Body/Body small - regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }

    .title-result{
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      /* Poppin/Button/Button medium - medium */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.024px; 
    }

    .title-reward{
    

      background: var(--Dark-ver-2-Card-Highlight, linear-gradient(104deg, #B9943B 0.73%, #D0B15F 52.43%, #F6EAA2 65.88%, #E3CC7F 79.84%, #C9A551 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal

    }


    .point-reward{
    

    background: var(--Dark-ver-2-Card-Highlight, linear-gradient(104deg, #B9943B 0.73%, #D0B15F 52.43%, #F6EAA2 65.88%, #E3CC7F 79.84%, #C9A551 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal

  }
`;
