import styled from "styled-components";

export const PopularGame = styled.section`
  width: 80%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  overflow-x: hidden;
  .frameGame {
    width: 100%;
    iframe {
      width: 100% !important;
      height: 658px !important;
    }
    img {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .iframe_game {
      width: 100%;
      height: 100vh !important;
    }
  }
`;
