import { useNavigate } from "react-router";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import useWindowSize from "hooks/useWindowSize";

import freeGame from "../../../asset/slide-hero/game-free1.png";
import nftGame from "../../../asset/slide-hero/game-nft1.png";

import btnNFTgame from "../../../asset/slide-hero/1.png";
import btnGamefree from "../../../asset/slide-hero/btn-game-free.png";
import { HeroTag } from "./styled";
import { ellipsisMiddle } from "web3/connector";
import { useEffect, useState } from "react";
import { getBanner } from "pages/services/cms";

// const arr = [
//   {
//     id: 1,
//     img: freeGame,
//     alt: "free game",
//     className: "free-game button_3d",
//     btn: btnGamefree,
//     url: "#free-games",
//   },
//   {
//     id: 2,
//     img: nftGame,
//     alt: "game nft",
//     className: "game-free button_3d",
//     btn: btnNFTgame,
//     url: "/nft-games",
//   },
//   // {
//   //   id: 3,
//   //   img: banner,
//   //   alt: "banner",
//   //   className: "banner",
//   //   btn: "",
//   //   url: "/",
//   // },
// ];

const Hero = ({ data }: any) => {

  const [arr, setArr] = useState<any>([]);

  const navigate = useNavigate();


  useEffect(() => {(async () => {
    const res = await getBanner();
    setArr(res)
  })()
  }, []);

  return (
    <HeroTag>
      <div className="hero-banner ">
        <Swiper
          pagination={{
            type: "bullets",
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          loop
          className="mySwiper rounded-lg "
        >
          {arr?.map((item: any, index: number) => (
            <SwiperSlide className="banner-slide " key={index}>
              <div className="flex bg-[#FFFFFF] rounded-3xl justify-center  items-center p-5">
                <div className=" p-10">
                <img onClick={() => {navigate(item.link)} }  src={`${process.env.REACT_APP_CMS_STORAGE}${item?.banner?.path}`} alt={''} className="rounded-3xl" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </HeroTag>
  );
};

export default Hero;
