import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserPointState } from "interfaces";

const initialState: UserPointState = {
  point: null,
  redeemPoint: null,
};

const userPointSlice = createSlice({
  name: "userPoint",
  initialState,
  reducers: {
    setUserPoint: (
      state: UserPointState,
      action: PayloadAction<UserPointState>
    ) => {
      return {
        ...state,
        point: action.payload.point,
        redeemPoint: action.payload.redeemPoint,
      };
    },
  },
});

export const { setUserPoint } = userPointSlice.actions;

export const userPointReducer = userPointSlice.reducer;
