import { logos } from "helpers/ExportImg";
import { useEffect, useState } from "react";
import { PopupTag } from "./styled";
import { useAppDispatch, useAppSelector } from "index";
import { setPopupInfoShowState } from "slices/popupInfoSlice";
import { Link } from "react-router-dom";
import { discover, openSourcePath } from "constants/path";
import { addGameToHistory } from "pages/services/api";




const PopupInfo = (props: any) => {
  const dispatchApp = useAppDispatch()

  const popupInfoState = useAppSelector(state => state.popupInfoState);

  const handlerClosePopup = () => {
    dispatchApp(setPopupInfoShowState(!popupInfoState.show));
  };


  useEffect(() => {

  }, []);

  return (
    <PopupTag>

      <>
        {popupInfoState.show && (
          <>
            <div onClick={handlerClosePopup} className={`backdrop`}></div>
            <div className="">
              <div className={`popup img-mobile w-10/12 max-w-[500px] min-w-[80%]`}>
                <>
                  <div className="bg-[#F0F0F0] rounded-3xl p-14 w-full max-h-screen overflow-scroll">
                        <div className="p-5">
                      <div className="popup-title">
                        {popupInfoState.title}
                      </div>
                      <div className="popup-text mt-10" dangerouslySetInnerHTML={{ __html: popupInfoState.description! }}>
                      </div>
                      </div>
                      <button className="popup-btn mt-8 w-full" onClick={async () => { handlerClosePopup(); }} >Close</button>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupInfo;
