import "animate.css";
import appContext from "appContext";
import ModalWallet from "components/modals/ModalWallet";
import { slidebarImg } from "helpers/ExportImg";
import useClickOutSide from "hooks/useClickOutside";
import { useAppDispatch, useAppSelector } from "index";
import { getAllnotification } from "pages/services/api";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setModalWallet } from "slices/modalSlice";
import { useAccount, useBalance } from 'wagmi';
import { ellipsisMiddle } from "web3/connector";
import { NavBar } from "./styled";

import { getNetwork } from '@wagmi/core';
import PopupConnect from "components/popupConnect";
import { useSearchParams } from 'react-router-dom';
import { setPopupConnectShowState } from "slices/popupConnectSlice";


const MainHeaderTwo = () => {

  const [autoFocus, setAutoFocus] = useState(false);
  const [searchActive, setSearch] = useState(false);
  const [active, setActive] = useState(false);
  const [listNotification, setListNotification] = React.useState<any>([]);
  const { account, setAccount } = useContext(appContext);

  const auth = useAppSelector(state => state.auth);

  const [isShowSocial, setShowSocial] = useState(false);
  const snsRef = useRef(null);
  const pageState = useAppSelector(state => state.pageState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isVisible } = useSelector((state: any) => state.modalBuy);
  const [searchParams] = useSearchParams();

  const { pools, coinDes, avatar } = slidebarImg;

  // window.addEventListener("scroll", (event: any) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   setShowSocial(false);
  // });
  // console.log(listNotification);


  const closeSocialHandler = (e: any) => {
    if (!isShowSocial) {
      return setShowSocial(!isShowSocial);
    }
    setShowSocial(false);
  };
  useClickOutSide(closeSocialHandler, snsRef);

  const inputRef = useRef<any>();



  useEffect(() => {
    window.addEventListener("click", (event) => {
      const clickInside = inputRef?.current?.contains(event.target);
      if (!clickInside && searchActive) {
        inputRef.current.classList.remove("pull-search");
        inputRef.current.classList.add("push-search");

        setAutoFocus(false);
        setSearch(false);
      }
    });
  }, [searchActive]);


  const { chain, chains } = getNetwork()


  const { address, isConnected } = useAccount()

  const { data, isError, isLoading } = useBalance({
    address: address,
    chainId: chain?.id,
    watch: true,
  })
  const handleConnectWallet = async () => {
    const token = localStorage.getItem("accessToken");
    const user = JSON.parse(localStorage.getItem("user")!);
    if (!isConnected || !user || !token || (address !== user.walletAddress)) {
      handlerPopup(true)
    }
  };

  React.useEffect(() => {
    handleConnectWallet()
    if (!localStorage["accessToken"]) return;
    getAllnotification().then((response: any) => setListNotification(response));
  }, []);

  const dispatchApp = useAppDispatch()


  const handlerPopup = (show: boolean) => {
    dispatchApp(setPopupConnectShowState(show));
  };



  // if (isLoading) return <div>Fetching balance…</div>
  // if (isError) return <div>Error fetching balance</div>

  return (
    <>
      <NavBar className={`navbar flex-col `}  style={{ background : pageState.backgroundHeader }} status={isShowSocial}>
        <div className={`${pageState.showTitle ? "" : "hidden "}  flex w-full  max-w-[1200px]`} >
          <nav className="  w-full  ">
            <div className=" flex flex-wrap  justify-center p-5">
              <button type="submit" onClick={() => {
                  navigate(-1)
              }
              } className="">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 18L9 12L15 6" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button >
              <div className="grow text-center">
                <div className="nav-title">
                  {pageState.title}
                </div>
              </div>
              {(pageState.iconHeader === 1) && (
                <button type="submit" onClick={() => { navigate('/') }} className="">

                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
                      stroke="#111111"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 22V12H15V22"
                      stroke="#111111"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button >
              )}
              {(pageState.iconHeader === 2) && (
                <button type="submit" onClick={() => { navigate(`/search?${searchParams.toString()}`, { replace: true }) }} className="">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.5 17.5L13.875 13.875" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button >
              )}
              {(pageState.iconHeader === 3) && (
                <button type="submit" onClick={() => { navigate(`/searchPte?${searchParams.toString()}`, { replace: true }) }} className="">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.5 17.5L13.875 13.875" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button >
              )}
            </div>
          </nav>
        </div>

        <div className={`${pageState.showAccount ? "" : "hidden"} content`} ref={inputRef}>
          <>
            <div className="logo-search ">
              <button  onClick={() => { navigate('/') }} className="logo-header ">
                <img src={(account && account.avatar) ? account.avatar : avatar} alt="avatar" className="logo-img w-[40px]  h-[40px] rounded-full" />
              </button>
              <div
                className="bg-[var(--color-Grey-50)] text-[14px]"
              >
                {(address) ? ellipsisMiddle(address, 4, 5) : ''}</div>

              {/* <Wallet /> */}

              {/* <img
                src={search}
                alt="search"
                className="search-icon search-icon-input"
                onClick={showInputSearch}
              /> */}
              {/* <FormSearch autoFocus={autoFocus} star={active} /> */}
            </div>

            <div className="icon-group">
              <div className="noti-shop">
                {/* <div className="wrapper-notify">
                  {listNotification?.total !==
                    +sessionStorage["readNotification"] &&
                    sessionStorage["accessToken"] ? (
                    <img
                      src={no_notify}
                      alt="notify"
                      onClick={() => toggleSocialHandler()}
                    />
                  ) : (
                    <img
                      src={no_notify}
                      alt="notify"
                      onClick={() => toggleSocialHandler()}
                    />
                  )}
                </div> */}
                {/* {isShowSocial && (
                  <Notifications
                    data={listNotification.data}
                    ref={snsRef}
                    account={account}
                  />
                )} */}

                {/* <ModalLanguage /> */}
                {/* {width > 1000 ? (
                  <div
                    onClick={() => navigate("/nft-games")}
                    className="nft-game-btn"
                  >
                    <img src={NFTgame} alt="img" />
                    <div className="nft-games">NFT GAMES</div>
                  </div>
                ) : (
                  <div
                    onClick={() => navigate("/nft-games")}
                    className="playgame-img"
                  >
                    <img src={playgame} alt="img" />
                  </div>
                )} */}

                {/* <Shop /> */}
              </div>
              <div
                className="score "
              >
                <div className="w-[30px]  h-[30px]">
                  <img src={pools} alt="pools" className=" rounded-full" />
                </div>
                {data?.formatted ? parseFloat(data?.formatted).toFixed(2) : 0}
              </div>
              <div
                className="score"
              >
                <div className="w-[30px]  h-[30px]">
                  <img src={coinDes} alt="pools" className=" rounded-full" />
                </div>
                {(auth && auth.user && auth.user.pointPool && auth.user.pointPool.point) ? auth.user.pointPool.point.toFixed(2) : 0}
              </div>
              {/* {<CostReward />} */}
              {/* <ConnectWalletBtn /> */}
              {/* <WalletNew className="flex justify-center items-center gap-[10px]  rounded-[1rem] min-w-[120px] max-w-[120px] md:min-w-[200px] md:max-w-[200px] py-[1.5rem] bg-custom-1 text-[#fff] text-[2.5rem] relative" /> */}
              {/* <GoogleButton /> */}

              <PopupConnect />
            </div>
          </>
        </div>
      </NavBar>
      {/* <ModalWallet
        visible={visibleWallet}
        onClose={() => setVisibleWallet(false)}
      /> */}
      {isVisible && (
        <ModalWallet
          visible={isVisible}
          onClose={() => dispatch(setModalWallet({ isVisible: false }))}
        />
      )}
    </>
  );
};

export default MainHeaderTwo;
