
import { slidebarImg } from "helpers/ExportImg";
import { handleShowTime } from "helpers/util";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EventCardWrapper } from "./styled";

const CardEvent: any = ({ event }: any) => {


  const { name, image, id, description, startedAt, type, totalReward, playCount } = event;

  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);


  const getEndTime = (startedAt: string | number | Date, type: string) => {
    const start = new Date(startedAt);
    if (type === "Week") return new Date(start.getTime() + 7 * 24 * 60 * 60 * 1000)
    if (type === "Day") return new Date(start.getTime() + 24 * 60 * 60 * 1000)
    if (type === "Hour") return new Date(start.getTime() + 60 * 60 * 1000)
    return new Date();
  }

  const countDownTime = () => {
    // Set the date we're counting down to
    var countDownDate = getEndTime(startedAt, type).getTime();

    // Update the count down every 1 second
    setInterval(function () {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDays(days)
      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)
    }, 1000);
  }
 
  const { pools } = slidebarImg;

  const getLinkEvent = ()=>{
    if(type === "Week") return `/special-event/week`
    if(type === "Hour") return `/hour-event/hour`
    if(type === "Day") return `/lottery-event/day`


    //toast.error(`${type} events are not yet supported`);
    return '#'
  }

  useEffect(() => {
    countDownTime()
  }, []);


  return (
    <>
      <EventCardWrapper>
        <Link onClick={async () => { }} to={getLinkEvent()}>
          <div className="inline-block w-full rounded-xl p-5">
            <div className="flex flex-col w-full space-y-2 overflow-hidden rounded-xl  p-10 " >
              <div className="text-left event-name mt-4">
                {name}
              </div>
              {/* <p className="text-left event-info pt-2">
                {description}
              </p> */}

                <div className="text-left event-info pt-2 pb-10" dangerouslySetInnerHTML={{ __html: description.split(".")[0] }}>
                </div>
              <div className="flex w-full space-x-4 pt-5 px-10">
                <div className="flex flex-col justify-center items-center  w-full ">
                  <div className="flex event-time-number">
                    {handleShowTime(days)}
                  </div>
                  <div className="flex event-info">
                    days
                  </div>
                </div>
                <div className="flex justify-center items-center w-full event-dots">
                  :
                </div>
                <div className="flex flex-col  justify-center items-center  w-full  ">
                  <div className="flex event-time-number">
                    {handleShowTime(hours)}

                  </div>
                  <div className="flex event-info">
                    hours
                  </div>

                </div>
                <div className="flex justify-center items-center w-full event-dots ">
                  :

                </div>
                <div className="flex flex-col justify-center items-center  w-full ">
                  <div className="flex event-time-number">
                    {handleShowTime(minutes)}
                  </div>
                  <div className="flex event-info">
                    min
                  </div>
                </div>
                <div className="flex justify-center items-center w-full  event-dots">
                  :
                </div>
                <div className="flex flex-col justify-center items-center  w-full ">
                  <div className="flex event-time-number">
                    {handleShowTime(seconds)}
                  </div>
                  <div className="flex event-info">
                    sec
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center ">
                <img className="w-full aspect-video	 rounded-3xl mt-5" src={image || ''}
                  alt="img" />
              </div>
              {(totalReward !== null) && (<div className=" game-card-new-play-count flex flex-col items-center	pt-1">
                <div className="event-prize-text mt-12" >
                  Total Prize
                </div>
                <div className="flex justify-center items-center mb-12" >
                  
                  <div className=" event-prize-number flex">{totalReward}</div>
                  <div className="ml-2" >
                    <img className="w-[25px] h-[25px] mt-[2px]" src={pools}
                      alt="img" />
                  </div>
                </div>
              </div>)}
              <div className="event-btn mt-5 w-full" onClick={() => { }} >Fortune collection</div>
            </div>
          </div>
        </Link>
      </EventCardWrapper>
    </>
  );
};

export default CardEvent;