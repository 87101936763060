import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import CropFreeIcon from "@mui/icons-material/CropFree";

import {
  getComment,
  getCountStar,
  getDetailGamePopular,
  getListGames,
  postPlayGame,
} from "./services/api";
import PagePopularGame from "components/pagePopular";
import WriteComment from "components/writeComment";
import Comments from "components/comments";
import GameDescribe from "components/gameDescribe";
import { GameTag } from "./styled";
import { FullScreen, HomeBtn, NextGameBtn } from "components/buttonScreen";
import { setNextDetailGamePopular } from "slices/gamePopularSlice";
import { popular } from "constants/path";
import appContext from "appContext";

const GamePopularPage = () => {
  const { detailGamePopular } = useSelector((state: any) => state.gamepopular);
  const { categories } = React.useContext(appContext);

  const [comments, setComments] = React.useState<any>([]);
  const [star, setStarts] = React.useState<any>([]);
  const [offset, setOffset] = useState<any>(1);

  const [rate, setRate] = useState(0);
  const params = useParams();
  const currentID = params.id;
  const { rateCount, rateSum } = detailGamePopular;

  const [fullscreen, setFullScrenn] = useState(true);

  const handleToggleScreen = () => {
    setFullScrenn(!fullscreen);
  };

  React.useEffect(() => {
    getDetailGamePopular(currentID);
    window.scrollTo({ top: 0 });

    const gameId: any = currentID;
    const gameType = "miniGame";
    getComment(gameType, gameId, 3).then((response: any) =>
      setComments(response.slice(0, 3))
    );
    getCountStar(gameType, gameId).then((response: any) => setStarts(response));
  }, [currentID]);

  React.useEffect(() => {
    const rateGame = rateCount > 0 ? rateSum / rateCount : 5;
    setRate(rateGame);
  }, [rateCount]);

  const listGameSessionPopular = JSON.parse(
    sessionStorage.getItem("listPopularGame") as any
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, wallet } = useSelector((state: any) => state.auth);

  const handleNextGame = async () => {
    const currentIndex = listGameSessionPopular?.findIndex(
      (game: any) => game.id === detailGamePopular.id
    );

    const nextIndex = (currentIndex + 1) % listGameSessionPopular?.length;

    const nextGame = listGameSessionPopular[nextIndex];

    dispatch(setNextDetailGamePopular(nextGame));
    navigate(`${popular}/${nextGame.id}`);

    if (currentIndex === 7) {
      const newOffset = offset + 1;
      const params = { page: newOffset, limit: 10, categories };
      try {
        const response = await getListGames(params);
        sessionStorage.setItem(
          "listPopularGame",
          JSON.stringify(response.data)
        );
        setOffset(newOffset);
      } catch (error) {
        console.log(error);
      }
    }

    if (accessToken && wallet) {
      await postPlayGame(detailGamePopular.id);
    }
  };

  return (
    <GameTag>
      <div className={`${fullscreen ? "" : "game_page"}`}>
        {fullscreen ? (
          <>
            <PagePopularGame
              isFullScreen={fullscreen}
              data={detailGamePopular}
            />
          </>
        ) : (
          <>
            <PagePopularGame data={detailGamePopular} />
            {/* <informationsGame /> */}
            <GameDescribe
              gameType={"miniGame"}
              data={detailGamePopular}
              star={star}
              rateSum={rateSum}
            />
            <Comments data={comments} rate={rate} />
            <WriteComment data={detailGamePopular} gameType={"miniGame"} />
            {/* <TopFreeGame /> */}
          </>
        )}
      </div>
      {/* <FullScreen
        className={fullscreen ? "" : "icon_fullscreen_discovery"}
        onClick={handleToggleScreen}
      /> */}
      {/* {fullscreen ? (
        <NextGameBtn handleNextGame={() => handleNextGame()} />
      ) : (
        ""
      )} */}

      {fullscreen ? <HomeBtn /> : ""}
    </GameTag>
  );
};

export default GamePopularPage;
function setStarts(response: any): any {
  throw new Error("Function not implemented.");
}
