import styled from "styled-components";

export const SwapStyle = styled.section`
  width: 90%;
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 60px 80px;

  .swap {
    display: flex;
    align-items: flex-end;
    /* justify-content: space-between; */
    column-gap: 50px;
    position: relative;
    z-index: 999999;
  }

  .swap-btn-close {
    position: absolute;
    top: -4%;
    right: -6%;
    transform: translate(-50%, -50%);
    max-width: 30px;
    cursor: pointer;
  }

  .swap-btn-close img {
    width: 100%;
  }

  .swap-left {
    display: flex;
    flex-direction: column;
    width: fit-content;
    row-gap: 50px;
  }

  .swap-left-top {
    display: flex;
    align-items: center;
    column-gap: 38px;
  }

  .swap-left-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #008369;
    border-radius: 10px;
    padding: 30px 60px;
  }

  .swap-left-center-cen {
    margin: 20px 0;
  }

  .swap-left-center-top,
  .swap-left-center-bot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 285px;
    height: 54px;
    background: #ffffff;
    border-radius: 10px;
  }

  .swap-left-center-top {
    position: relative;
  }

  .swap-left-center-top-img {
    max-width: 32px;
  }

  .swap-left-center-top-img img {
    width: 100%;
  }

  .swap-left-center-top-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 60px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 0 35px 0 5px;
  }

  .swap-left-center-bot-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 60px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-left: 5px;
  }

  .swap-left-bot {
    background: #008369;
    border-radius: 10px;
    padding: 30px 60px;
  }

  .swap-left-bot-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .swap-line {
    border: 1px solid #ffffff;
    margin: 15px 0;
  }

  .swap-left-bot-cen,
  .swap-left-bot-bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .swap-left-bot-cen {
    margin-bottom: 20px;
  }

  .swap-left-bot-cen-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .swap-left-bot-cen-price {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #ffffff;
  }

  .swap-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #008369;
    border-radius: 10px;
    padding: 30px 60px;
  }

  .swap-right-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 12px;
    margin-top: 51px;
  }

  .swap-right-1_top {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  .error-point {
    color: #bd1d1d;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  }

  .swap-right-1-price {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    width: 225.95px;
    height: 62.64px;
  }

  .swap-right-1-price input {
    padding-left: 18px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    background: #ffffff;
    border-radius: 11.1856px;
  }

  .swap-right-1-token {
    display: flex;
    align-items: center;
    width: 225.95px;
    height: 62.64px;
    background: #ffffff;
    border-radius: 11.1856px;
  }

  .swap-right-1-token-img {
    max-width: 42px;
    margin: 0 15px 0 23px;
  }
  .swap-right-1-token-img img {
    width: 100%;
  }

  .swap-right-1-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 60px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .swap-right-2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #dbdbdb;
    margin: 10px 0;
  }

  .swap-right-3 {
    max-width: 50px;
    margin: 0 auto;
  }

  .swap-right-3 img {
    width: 100%;
  }

  .swap-right-4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 40px 0 61px;
  }

  .swap-right-4-left-num {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #ffffff;
  }

  .swap-right-4-left-price {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #dbdbdb;
  }

  .swap-right-4-right {
    display: flex;
    align-items: center;
    width: 225.95px;
    height: 62.64px;

    background: #ffffff;
    border-radius: 11.1856px;
  }

  .swap-right-4-right-num {
    max-width: 40px;
    margin: 0 21px 0 30px;
  }

  .swap-right-4-right-num img {
    width: 100%;
  }

  .swap-right-4-right-price {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .swap-right-5 {
    outline: none;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;

    color: #008369;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 0px #002921;
    border-radius: 10px;
    margin-bottom: 44px;
    cursor: pointer;
  }

  .messsage-server {
    color: rgb(189, 29, 29);
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  }

  @media (max-width: 1900px) {
    width: 65%;
  }

  @media (max-width: 1700px) {
    .swap-left-center,
    .swap-right {
      padding: 30px 60px;
    }

    .swap-left-center-top,
    .swap-left-center-bot {
      width: 205px;
    }

    .swap-right-1-price,
    .swap-right-1-token,
    .swap-right-4-right {
      width: 165px;
    }

    .swap-right-4-right-num {
      max-width: 25px;
      margin: 0 13px 0 17px;
    }
  }

  @media (max-width: 1440px) {
    padding: 60px 40px;

    .swap-left-center,
    .swap-right {
      padding: 30px 50px;
    }
  }

  @media (max-width: 1300px) {
    padding: 35px 40px;

    .swap {
      flex-wrap: wrap;
    }

    .swap-left {
      row-gap: 20px;
      width: 100%;
    }

    .swap-left-center {
      flex-direction: row;
      column-gap: 20px;
    }

    .swap-right {
      width: 100%;
      margin-top: 20px;
    }

    .swap-btn-close {
      top: -3%;
    }

    .swap-left-bot,
    .swap-left-center {
      padding: 15px 60px;
    }

    .swap-right-1 {
      margin-top: 0;
      justify-content: space-between;
      width: 100%;
    }

    .swap-right-5 {
      margin-bottom: 0;
    }

    .swap-right-4 {
      margin: 0px 0 20px;
    }
  }

  @media (max-width: 600px) {
    padding: 30px 5px;
    width: 95%;

    .swap-left-center,
    .swap-left-bot,
    .swap-right {
      padding: 15px 15px;
    }

    .swap-right-1_top {
      width: 95%;
    }

    .swap-left-center-top-title {
      margin: 0 10px 0 5px;
    }

    .swap-left-center-top,
    .swap-left-center-bot,
    .swap-right-1-price,
    .swap-right-1-token,
    .swap-right-4-right {
      height: 45px;
    }

    .swap-left-center-top-title,
    .swap-left-center-bot-title,
    .swap-right-1-title,
    .swap-right-4-right-price {
      font-size: 18px;
    }

    .swap-left-bot-cen {
      margin-bottom: 10px;
    }

    .swap-right-3 {
      max-width: 30px;
    }

    .swap-right-4-left-num {
      font-size: 20px;
    }

    .swap-right-4 {
      margin: 0px 0 10px;
    }

    .swap-right-5 {
      font-size: 25px;
    }
  }
`;

export const OptionToken = styled.section`
  position: absolute;
  top: 100%;
  left: 0;
  max-width: 285px;
  min-width: 285px;
  height: fit-content;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid #008369;

  .token-option {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .token-option-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .token-option-img {
    max-width: 40px;
  }

  .token-option-img img {
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 210px;
    min-width: 210px;
  }
`;
