import React, { useEffect } from "react";

import useWindowSize from "hooks/useWindowSize";
import { GameCardHistoryWraper, GameCardNew } from "./styled";
import { Link } from "react-router-dom";
import { discover, openSourcePath } from "constants/path";
import { logos } from "helpers/ExportImg";

const GameCardPopular: any = ({ game }: any) => {


  const {gameType } = game;
  const nameRep = game[`${gameType}Name`].replaceAll(" ", "-").toLowerCase();
  // const sum = game_mission.reduce((accumulator: number, currentObject: { pointToPass: number; }) => accumulator + currentObject.pointToPass, 0);
  const getTime = (time: string | number | Date) => {
    // Lấy thời điểm cụ thể
    const targetTime = new Date(time);

    // Lấy timestamp của thời điểm cụ thể
    const targetTimeInMillis = targetTime.getTime();

    // Lấy timestamp của thời điểm hiện tại
    const nowInMillis = new Date().getTime();

    // Tính thời gian theo giờ tới thời điểm cụ thể
    const remainHours = (nowInMillis - targetTimeInMillis) / (1000 * 60 * 60);
    return remainHours.toFixed(2)
  }

  const getLinkPlay = ()=>{
      if(gameType==='game'){
        return `${openSourcePath}/${nameRep}/${game[`gameId`]}`
      }
      if(gameType==='miniGame') return `${discover}/${game[`gameId`]}`
      
      return '/'
  }
  return (
    <>
      <GameCardHistoryWraper>
        <Link to={getLinkPlay()}>
          <div className="inline-block w-full">
            <div className="flex  flex-col w-[265px] h-[300px]   overflow-hidden rounded-lg" >
              <div className="flex-shrink-0 ">
                <img className="w-[250px] h-[250px] rounded-3xl" src={game[`${gameType}Thumbnail`]}
                  alt="img" />
              </div>
              <div className="flex-1 min-w-0 mt-5 ">
      
                <div className="game-card-new-play-count flex  items-center	">
                  <div className="" >
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.66667 1.33337L2 9.33337H8L7.33333 14.6667L14 6.66671H8L8.66667 1.33337Z"
                        stroke="#06C270"
                        strokeWidth={1.33333}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="ml-2 play-time flex"> {game['total']} times play</div>
                </div>

                <p className="popular-game-name">
                  {game[`${gameType}Name`]}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </GameCardHistoryWraper>
    </>
  );
};

export default GameCardPopular;
