import styled from "styled-components";

export const GameTag = styled.section`
  .icon_unfullscreen_discovery {
    z-index: 999999999999999999;
    position: fixed;
    top: 10%;
    right: 2%;
    transform: translate(-50%, -50%);
  }

  .icon_fullscreen_discovery {
    z-index: 99999999999999999;
    position: absolute;
    top: 10%;
    right: 4%;
    transform: translate(-50%, -50%);
  }

  .icon_fullscreen_game {
    z-index: 99;
    position: absolute;
    top: 3%;
    right: 4%;
    transform: translate(-50%, -50%);
  }

  .full_screenn {
    margin: 0;
    width: 100% !important;
    height: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    background-color: #000;
  }

  .rotate_game_html {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    width: 100vh;
    height: 100vw;
    transform: translateY(100vh) rotate(-90deg);
    transform-origin: top left;
    }

  @media (max-width: 767px) {
    .icon_unfullscreen_discovery {
      right: 2.9%;
      top: 8%;
    }
    .icon_fullscreen_discovery {
      top: 6%;
    }
  }

  @media (orientation: landscape) {
    .icon_unfullscreen {
      z-index: 99999999999;
      position: fixed;
      top: 8%;
      right: 0;
      transform: translate(-50%, -50%);
    }

    .full_screenn {
      height: 100% !important;
      position: fixed;
    }

    .rotate_game_html {
      z-index: 9999;
      
      width: 100vh;
      height: 100vw;
      transform: translateY(100vh) rotate(-90deg);
      transform-origin: top left;
    }

    .bg-gamemini {
      height: unset !important;
    }
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    .icon_unfullscreen_discovery {
      right: 3%;
    }
  }

  @media (max-width: 600px) {
    .icon_unfullscreen_discovery {
      right: 3.1%;
    }
  }

  @media (max-width: 490px) {
    .icon_unfullscreen_discovery {
      right: 3.9%;
    }
  }

  @media (max-width: 420px) {
    .icon_fullscreen_game {
      top: 4%;
    }

    .icon_unfullscreen_discovery {
      right: 2.6%;
    }
  }

  @media (max-width: 375px) {
    .icon_unfullscreen_discovery {
      right: 2.3%;
    }
  }


  .title-header-right, .title-header-left {
    color: var(--Primary, #06C270);
    /* Poppin/Button/Button small - medium */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.021px; 
  }
`;
