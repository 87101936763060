import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OpenSourceAll from "components/List/OpenSourceAll";
import { getListGamesOpenSource } from "./services/api";
import { setListGameOpenSource } from "slices/gameOpenSource";

const GameOpenSource = () => {
  // const dispatch = useDispatch();
  // const [openList, setOpenList] = useState<any>([]);

  // const { listGame } = useSelector((state: any) => state.gameOpenSource);
  // console.log("xxxx", listGame);

  // const handleFetchListGame = async () => {
  //   try {
  //     const data = await getListGamesOpenSource();
  //     console.log("data", data);

  //     // setOpenList((prev: any) => [...prev, ...data]);
  //     if (data && data.data) {
  //       const newListGame = [...openList, data];
  //       setOpenList(newListGame);
  //       dispatch(setListGameOpenSource(newListGame));
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   handleFetchListGame();
  // }, []);
  return (
    <div className="game_page container-new">
      <OpenSourceAll />
    </div>
  );
};

export default GameOpenSource;
