import { footerImg } from "helpers/ExportImg";
import { useEffect, useRef, useState } from "react";

import CardEvent from "components/Card/CardEvent";
import { getListEvent } from "pages/services/api";
import { toast } from "react-toastify";
import { PlayToEarnStyled } from "./styled";

const ListEvents = ({ openList }: any) => {
  const [listEvent, setListEvent] = useState<any>();

  const [loading, setLoading] = useState<boolean>(true);

  const rootRef = useRef<any>();

  const { controlMobile } = footerImg;


  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await getListEvent();
      setLoading(false);
      setListEvent(data);
      if (data.total <= 0) {
        toast.error(`There are currently no events`);
      }
    } catch (err) {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchApi();
  }, []);


  const payToEarnRef = useRef<any>(null)


  return (
    <PlayToEarnStyled className="relative w-full mt-[40px] pb-[50px]">

      <div
        className=""
        style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
        ref={rootRef}
      >
        <div
          className=""
          style={{ margin: 0 }}
        >
          {/* <div className="flex justify-between w-full  ">
          <div className="pte-header-left">
            <span>Funny game</span>
          </div>
          <div className="pte-header-right">
            <Link to={"/all-mini-game"}>See all</Link>
          </div>
        </div> */}

          <>
            {loading ? (
              <img
                style={{ padding: "5rem 0", margin: 'auto' }}
                src={controlMobile}
                alt="img"
                className="logo-loading"
              />
            ) : (
              <>
                <div ref={payToEarnRef} className="mt-5 grid grid-cols-1 gap-10  max-w-[1200px] pb-10  justify-between">
                  {listEvent?.data?.map((event: any) => (
                    <div
                      key={event?.id}
                    >
                      {/* <div className="card-genre-game game-open" key={game?.id}> */}
                      <CardEvent event={event} />
                    </div>
                  ))}
                </div>
                {
                  (!listEvent || !listEvent.data || !listEvent.data.length) && <div className="text-center text-[14px]">No data</div>
                }
              </>
            )}
          </>
        </div>
      </div>
    </PlayToEarnStyled>

  );
};

export default ListEvents;
