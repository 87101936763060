import styled from "styled-components";
import bg_frame from "../../asset/games/frame.png";

export const Game = styled.section`
  width: 92%;
  height: 825px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  /* background: aliceblue; */
  flex-direction: column;
  overflow-x: hidden;
  border: 1px solid rgba(250, 165, 37, 1);
  background-image: linear-gradient(
    to right,
    rgba(250, 203, 37, 1),
    rgba(0, 255, 206, 0.7)
  );
  border-radius: 20px;
  .title {
    width: 100%;
    font-size: 45px;
    // font-weight: bold;
    line-height: 56px;
    text-align: center;
    color: white;
    // font-family: "Karla", sans-serif;
  }
  .content_games {
    width: 99%;
    height: 66%;
    display: flex;
    flex-wrap: wrap;
  }
  .game {
    background-image: url(${bg_frame});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 374px;
    height: 470px;
    max-width: 374px;
    min-height: 470px;
    overflow: hidden;
    text-align: center;
    font-size: 19px;
    // font-weight: 500;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img_game {
      position: absolute;
      top: 5%;
      left: 6%;
    }
    .infor_game {
      position: absolute;
      left: 7%;
      top: 55%;
      height: 40%;
      width: 88%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .title_game {
        // font-family: "Karla", sans-serif;
        // font-weight: bold;
        font-size: 34px;
        line-height: 40px;
        color: white;
        margin: 0;
      }
      .social {
        width: 85%;
        height: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        img {
          height: 60px;
          width: 60px;
        }
      }
      .btn_play {
        background: rgba(250, 165, 37, 1);
        width: 250px;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid;
        border-radius: 12px;
      }
    }
  }
  .more_button {
    width: 40%;
    display: flex;
    justify-content: space-around;

    .btn_more_game,
    .btn_visit_swap {
      width: 250px;
      height: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(250, 165, 37, 1);
      border-radius: 12px;
      color: white;
    }

    .btn_visit_swap {
      /* background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.168)
      ); */
      background: linear-gradient(
        94.15deg,
        rgba(255, 255, 255, 0.6) 3.38%,
        rgba(255, 255, 255, 0.168) 98.5%
      );
    }
    .btn_more_game {
      background: rgba(250, 165, 37, 1);
    }
  }
`;

export const GameOpenTag = styled.section`
  display: flex;
  align-items: center;
  width: 94%;
  margin: 0 auto;
  column-gap: 3rem;
  /* background-color: #f4f5f6; */
  background: rgba(103, 219, 192, 0.3);
  border-radius: 15px;
  padding: 10px;

  .img-game-open {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    object-fit: cover;
  }

  .game-open-content {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    /* width: 90%; */
  }

  .game-open-info {
    width: 95%;
  }

  .game-open-name {
    font-size: 3.2rem;
    font-weight: 700;
  }

  .rating-play {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .btn-play-game {
    background: #ff6700;
    border-radius: 8px;
    padding: 10px 15px;

    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 100%;
    color: #ffffff;
  }

  @media (max-width: 600px) {
    .game-open-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const OpenGameDeskTag = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  row-gap: 3rem;
  column-gap: 2rem;
  background: rgba(103, 219, 192, 0.3);
  border-radius: 10px;
  padding: 1.2rem 2rem;
  width: 100%;

  .rating-game {
    row-gap: 1rem;
  }

  .name-game {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    /* line-height: 31px; */
    color: #000000;
  }

  .category-game {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #007b63;
  }

  .rating-play {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .img-tertiary {
    /* width: 30%; */
    max-width: 12rem;
    max-height: 12rem;
    min-width: 12rem;
    min-height: 12rem;
    object-fit: cover;
    border-radius: 16px;
  }

  .btn-play-game {
    background: #ff6700;
    border-radius: 8px;
    padding: 15px;

    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 100%;
    color: #ffffff;
  }


`;


export const GameCardNew = styled.section`

    .game-card-new-title {
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      text-overflow: ellipsis;
      font-family: Poppins;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.024px; 
    }

    .game-card-new-play-count-text{
      color: var(--Grey-Grey-50, #7E7E7E);
      text-overflow: ellipsis;
      /* Poppin/Body/Body small - regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }

    .text-type {
      height: 24px;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid var(--Primary, #06C270);


      color: var(--Primary, #06C270);
      /* Ver 1/Caption 1 - medium */
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.05px; 
    }

    .popup-btn{
      display: flex;
      height: 40px;
      padding: 0px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch; 
      border-radius: 8px;
      background: #06C270; 

      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.024px; 
    }

    .text-card-genre {
      color: var(--Neutral-Color-Black, #000);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; 
    }

`;

export const GameCardHistoryWraper = styled.section`

.game-card-new-title {
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      text-overflow: ellipsis;
      font-family: Poppins;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.024px; 
    }

    .game-card-new-play-count-text{
      color: var(--Grey-Grey-50, #7E7E7E);
      text-overflow: ellipsis;
      /* Poppin/Body/Body small - regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }


    
  .play-time{
    overflow: hidden;
    color: var(--Primary, #06C270);
    text-overflow: ellipsis;
    /* Poppin/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }

  .popular-game-name{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Poppin/Button/Button medium - medium */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px;  
  }

`;


export const NotificationCardWraper = styled.section`

    .game-card-new-title {
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      font-family: Poppins;
      font-size: 16px;
      text-align: left;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.024px;
      a {
        color: #06C270;
      }
    }

    .game-card-new-play-count-text{
      color: var(--Grey-Grey-50, #7E7E7E);
      text-overflow: ellipsis;
      /* Poppin/Body/Body small - regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }

`;


export const EventCardWrapper = styled.section`

    .event-name{
      color: var(--Neutral-Color-Black, #000);
      /* Ver 1/Button/Button small - medium */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.021px; 
    }

    .event-info{
      overflow: hidden;
      color: var(--Grey-Grey-40, #9E9E9E);
      text-overflow: ellipsis;
      /* Poppin/Body/Body small - regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }

    .event-info p{
      overflow: hidden;
      color: var(--Grey-Grey-40, #9E9E9E);
      text-overflow: ellipsis;
      /* Poppin/Body/Body small - regular */
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }

    .event-time-number{
      color: var(--Neutral-Color-Black, #000);
      text-align: center;
      /* Ver 1 / Subtitle / Subtitle 1 - semi */
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; 
    }

    .event-prize-text{
      color: var(--Grey-Grey-60, var(--Grey-60, #626262));
      /* Ver 1 / Body / Body medium - regular */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.021px; 
    }

    .event-prize-number{
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal; 
    }

    .text-event-end{
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      text-align: center;
      /* Poppin/Body/Body medium - regular */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.021px; 
    }

    .event-btn{
      display: flex;
      height: 40px;
      padding: 0px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch; 
      border-radius: 8px;
      background: #06C270; 

      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.024px; 
    }

    .vote-btn{

      display: flex;
      height: 32px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch; 
      border-radius: 8px;
      background: var(--Primary, #06C270); 

      color: #FFF;
      text-align: center;
      /* Poppin/Subtitle/Subtitle 3- semi */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; 

    }

    .weekly-rewards{
      color: var(--Neutral-Color-Black, #000);
      text-align: center;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 200% */
      letter-spacing: -0.26px; 
    }

    .weekly-rewards-number{
      color: var(--Pri-6, #119C5F);
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 108.333% */ 
    }

    .event-info-title{
      color: var(--Neutral-Color-Black, #000);
      /* Ver 1 / Subtitle / Subtitle 2 - semi */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; 
    }

    .info-description{
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: 0.018px; 
    }

    .info-description-number{
      color: var(--Grey-Grey-90, var(--Grey-100, #111));
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.018px;  
    }


      .top-rize-number{
        color: var(--Grey-Grey-90, var(--Grey-100, #111));
        /* Ver 1/Button/Button small - medium */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.021px; 
      }

      .event-dots{
        color: var(--Neutral-Color-Black, #000);
        text-align: center;
        /* Ver 1 / Subtitle / Subtitle 1 - semi */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal; 
      }

      .vote-text{
        color: var(--Grey-Grey-50, #7E7E7E);
        /* Poppin/Caption 1 - medium */
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.05px; 
      }

      .vote-value{
        color: var(--Grey-Grey-90, var(--Grey-100, #111));
        /* Poppin/Body/Body small - regular */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: 0.018px; 
      }
    .total-pools{
      margin-top: 10px;
      color: #D9AD13;
      text-align: center;
      /* Poppin/Subtitle/Subtitle 2 - semi */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; 
    }
`;

export const CardSpecialHistoryWrapper = styled.section`


    .history-lable{
      color: var(--Grey-Grey-50, #7E7E7E);
    /* Poppin/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }

  .value-prize{
    color: var(--Primary, #06C270);
    /* Ver 1/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }
  .value-total{
    color: var(--Neutral-Color-Black, #000);
    /* Ver 1/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px; 
  }

  .history-row{
    border-bottom: 0.5px solid var(--Grey-Grey-10, #E1E1E1);
  }

`;