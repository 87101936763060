import styled from "styled-components";

export const HeroTag = styled.section`
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  .hero-banner {
    width: 100%;
  }

  .banner-slide {
    position: relative;
  }


  .banner-title {
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Poppin/Button/Button medium - medium */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px; 
    
  }

  .banner-tag{
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Grey-Grey-50, #7E7E7E); 
    color: var(--Grey-0, #FFF);
    /* Ver 1/Caption 1 - medium */
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05px; 
  }

 
  .banner-content {
    color: var(--Grey-Grey-40, #9E9E9E);
    /* Poppin/Body/Body small - regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px;  
  }
  

  .banner-btn {
    display: flex;
    height: 40px;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
    border-radius: 8px;
    background: #06C270; 

    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.024px; 
  }


  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-top: -4rem;
    @media (min-width: 500px) {
      margin-top: 2rem;
    }
  }

  .free-game {
    position: absolute;
    max-width: 28rem;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  .free-game:active {
    transform: translate(-50%, 12%);
  }
  .game-free {
    position: absolute;
    max-width: 30rem;
    bottom: 20%;
    left: 25%;
    transform: translateX(-50%);
  }
  .game-free:active {
    transform: translate(-50%, 12%);
  }

  .mySwiper .swiper-pagination-bullet {
    ${'' /* border: 3px solid #D9D9D9 !important; */}
    width: 8px !important;
    height: 8px !important;
    background-color: #959595 !important;
    border-radius: 50%;
  }

  .mySwiper .swiper-pagination-bullet-active {
    ${'' /* border: 3px solid #0D103C !important; */}

    width: 24px !important;
    height: 8px !important;
    background-color: #0D103C !important;
    border-radius: 50px !important;
  }

  @media (max-width: 1000px) {
    .free-game {
      max-width: 15rem;
    }

    .game-free {
      max-width: 15rem;
    }
  }
${'' /* 
  @media (max-width: 600px) {
    height: 56rem;
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      margin-top: -6rem;
    }
  }

  @media (max-width: 500px) {
    height: 48rem;
  }

  @media (max-width: 420px) {
    height: 40rem;
  } */}
`;
