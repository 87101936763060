import React from "react";
import { Information } from "./styled";
import icon_download from "../../asset/games-center/icon-download.png";
import icon_share from "../../asset/games-center/ic_baseline-share.png";
import btn_swap from "../../asset/games-center/logo_game.png";

const InformationsGame = ({ data }: any) => {
  return (
    <Information>
      <div
        className="content_infor"
        style={{ zIndex: "2003", alignItems: "center" }}
      >
        <div className="information">
          <p className="title">{data?.name}</p>
          <a
            href={data.downloadLink}
            target="_blank"
            rel="noreferrer"
            className="number_download"
            style={{ textDecoration: "none", color: "black" }}
          >
            <img src={icon_download} alt="download" />
            {/* <p className="text_install"> 2,23 M Installed</p> */}
          </a>
          <div>{/* <p>(2800)</p> */}</div>
        </div>
        <div className="swap">
          {/* <div className="btn_share" style={{ cursor: "pointer" }}>
            <img src={icon_share} alt="share" width={28} />
          </div> */}
          <a
            href="https://swap.poolsmobility.com/"
            target="_blank"
            rel="noreferrer"
            className="btn_swap"
            style={{ textDecoration: "none", color: "black" }}
          >
            <img
              src={data.coin}
              alt="btn"
              width={50}
              height={50}
              style={{ marginRight: "10px" }}
            />
            <p>Swap Token</p>
          </a>
        </div>
      </div>
    </Information>
  );
};

export default InformationsGame;
