import React, { useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import Moment from "react-moment";

import { Comment } from "./styled";
import world from "../../asset/games-center/icon/ic_baseline-mode-comment.png";
import avatar from "asset/account/avatar.png";

const Comments = ({ data }: any) => {
  return (
    <div className="bg-comment">
      {/* <div className=""> */}
      <div className="comment">
        <img src={world} alt="btn" width={30} height={30} />
        <p>COMMENT</p>
        {/* </div> */}
      </div>
      <div
        className="content_comment"
        style={{
          justifyContent: data.length < 3 ? "flex-start" : "space-between",
        }}
      >
        {data?.map((item: any) => (
          <div
            key={item?.id}
            className="frame_comment"
            style={{
              marginRight: data.length < 3 ? "1rem" : 0,
            }}
          >
            <div className="infor_user">
              <div className="wrapper-user">
                <img
                  src={item?.user?.avatar || avatar}
                  alt="avatar"
                  width={48}
                  height={48}
                />
                <div className="content-comment">
                  <p className="name-user">{item?.user.displayName}</p>
                  <Moment className="moment-time" fromNow>
                    {item?.updatedAt}
                  </Moment>
                </div>
              </div>
              {item?.rate?.ratePoint > 0 && (
                <div className="rate_star">
                  {item?.rate?.ratePoint}
                  {/* {item?.rate?.ratePoint > 0 ? item?.rate?.ratePoint : 5}{" "} */}
                  <StarIcon
                    fontSize="large"
                    style={{ color: "var(--cl_button)" }}
                  />
                </div>
              )}
            </div>
            <div className="border" />
            <p className="text-comment">{item?.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comments;
