import { useEffect, useState } from "react";

import GenreAllGame from "components/genre/GenreAllGame";
import GenrePopular from "components/genre/GenrePopular";
import { footerImg } from "helpers/ExportImg";
import { getListGenre } from "pages/services/api";

const GenrePage = () => {
  const [genres, setGenres] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { controlMobile } = footerImg;

  useEffect(() => {
    window.scrollTo({ top: 0 });
    getListGenre().then((response: any) => {
      setGenres(response);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="flex-center wrapper-home">
      <GenrePopular genres={genres} />
      {/* <GenreSlide genres={genres} /> */}

      {isLoading ? <img src={controlMobile} alt="img" className="logo-loading" style={{ position: "relative", padding: "10rem 0" }} /> : <GenreAllGame genres={genres} />}
      {/* <HTML5 data={games} /> */}
    </div>
  );
};

export default GenrePage;
