
import { openSourcePath } from "constants/path";
import { logos } from "helpers/ExportImg";
import { Link } from "react-router-dom";
import { GameCardNew } from "./styled";
const GameCard: any = ({ game }: any) => {
  
  const { image, id, name, rewardPoint ,game_mission, thumbnail } = game;

  const nameRep = name.replaceAll(" ", "-").toLowerCase();

  const sum = game_mission.reduce((accumulator: number, currentObject: { pointReward: number; }) => accumulator + currentObject.pointReward, 0);

  return (
    <>
      <GameCardNew>
        <Link onClick ={async ()=>{ } }  to={`${openSourcePath}/${nameRep}/${id}`}>
          <div className="flex items-center   overflow-hidden rounded-3xl " >
       
            <div className=" min-w-0 ms-4 p-5">
            <div className="flex-shrink-0 ">
              <img className="w-[60vw] h-[20vw] max-h-[200px] rounded-3xl" src={image || thumbnail}
                alt="img" />
            </div>
            <div className="mt-5 ml-5">
              <div className="game-card-new-title">
                {name}
              </div>
              <div className="game-card-new-play-count flex items-center	">
                <div className="" >
                <img className="w-8 h-8 " src={logos.currency}
                alt="img" />
                </div>
                <div className="ml-2 game-card-new-play-count-text flex"> Reward: {rewardPoint }/ {sum}</div>
              </div>
              </div>
            </div>
        </div>

        </Link>
      </GameCardNew>
    </>
  );
};

export default GameCard;