import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { gameOpen, GameOpenSource } from "interfaces";

const initialState: GameOpenSource = {
  listGame: [],
  detailGameOpen: {
    id: "",
    name: "",
    url: "",
    thumbnail: "",
    status: "",
    playCount: 0,
    license: "",
    rateCount: 0,
    rateSum: 0,
    popularity: 0,
    createdAt: "",
    updatedAt: "",
    is_landscape: false,
  },
};

const gameOpenSourceSlice = createSlice({
  name: "gameOpenSource",
  initialState,
  reducers: {
    setListGameOpenSource: (state, action: { payload: any[] }) => {
      return { ...state, listGame: action.payload };
    },
    setDetailOpen: (state: GameOpenSource, action: PayloadAction<gameOpen>) => {
      // state.isLoading = false;
      state.detailGameOpen = action.payload;
    },
  },
});

export const { setListGameOpenSource, setDetailOpen } =
  gameOpenSourceSlice.actions;

export const gameOpenSourceReducer = gameOpenSourceSlice.reducer;
