import { useEffect } from "react";

import OpenSoureAllGame from "components/List/OpenSoureAllGame";
import { useAppDispatch } from "index";
import { setPageState } from "slices/pageSlice";
import { GameTag } from "./styled";
import ListAllMiniGame from "components/List/ListAllMiniGame";
import { useSearchParams } from "react-router-dom";
import { ellipsisMiddle } from "web3/connector";
import { games } from "helpers/ExportImg";

const AllMiniGame = () => {


  const dispatchApp = useAppDispatch()
  const [searchParams] = useSearchParams();
  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: true,
      showAccount: false,
      tab: 1,
      iconHeader: parseInt(searchParams.get('iconHeader')!) || 2,
      title: ellipsisMiddle(searchParams.get('categoriesName')?.toString() || searchParams.get('query')?.toString() || "Funny Game", 20, -Infinity),
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'

    }
    ))
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <GameTag className="mt-[40px] pb-[50px]">
      <div className="mt-10  w-full rounded overflow-hidden shadow-lg  flex items-center justify-center	">
         {/* <img className=" rounded-xl" src={games.bannerAds}
          alt="ads" /> */}
{/* <div id="container-e49302f0df0a34efc084889cd3314a5d"></div> */}
      </div>
      <div className="game_page container-new">
        <ListAllMiniGame />
      </div>
    </GameTag>
  );
};

export default AllMiniGame;
