import { slidebarImg } from "helpers/ExportImg";
import { useEffect, useState } from "react";

import { getEventById, getEventWinner } from "pages/services/api";
import { useParams } from "react-router-dom";
import { ellipsisMiddle } from "web3/connector";
import { LeaderboardEventWraper } from "./styled";

const TableDayWinner = () => {
    const params = useParams();

    const { pools } = slidebarImg;

    const [eventData, setEventData] = useState<any>();

    const [loading, setLoading] = useState<boolean>(true);

    const fetchApi = async () => {
        try {
          setLoading(true);
          const data = await getEventWinner( params.id!);
          setLoading(false);
          console.log("dd", eventData && eventData.data && eventData?.data?.length > 0)
          setEventData(data);
        } catch (err) {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        fetchApi();
      }, []);

    const getRank = (rank: any)=>{
        if(rank === 1) return '1st'
        if(rank === 2) return '2nd'
        if(rank === 3) return '3rd'
        return rank;
    }

    return (

        <LeaderboardEventWraper className="relative w-full">
            {/* <PopupInfo /> */}
            <div
                className=""
                style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
            >
                <div
                    className=""
                    style={{ margin: 0 }}
                >
                    <div className="p-5 flex justify-between space-x-5 w-full  ">
                        {/* <div className="leaderboard-header-left">
                            <span>History Lottery</span>
                        </div>
                        <div className="history-header-right">
                            <span>{eventData?.startedAt?.slice(0, 10).replaceAll('-','/')}</span>
                        </div> */}
                    </div>
                    <>
                        <div className="mt-5 p-5 grid grid-cols-1 gap-10   mb-10  overflow-x-auto">
                            <table className="">
                                <thead className="text-left">
                                    <tr>
                                        <th>Top</th>
                                        <th>Number</th>
                                        {/* <th>
                                            <div className="flex items-center ">
                                                <div className="  flex">Collect</div>
                                                <div className="ml-2 items-center" >
                                                    <img className="w-[20px] h-[20px]" src={logos.currency}
                                                        alt="img" />
                                                </div>
                                            </div>
                                        </th> */}
                                        <th>
                                            <div className="flex items-center ">
                                                <div className="  flex">Prize</div>
                                                <div className="ml-2 items-center" >
                                                    <img className="w-[18px] h-[18px]" src={pools}
                                                        alt="img" />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventData && eventData.data && eventData?.data?.length > 0 && eventData.data.map((rankInfo: any, index: number) => (
                                        (<tr key={rankInfo?.id}>
                                            <td>{getRank(index + 1)}</td>
                                            {/* <td><img className="w-8 h-8 rounded-full"  src={logos.currency}
                                                            alt="img" /></td> */}
                                            <td>{rankInfo.ticketNumber}</td>
                                            {/* <td>{rankInfo.walletAddress}</td> */}
                                            {/* <td>{rankInfo.currentPoint}</td> */}
                                            <td>{rankInfo.reward}</td>
                                        </tr>)
                                    ))}
                                </tbody>
                            </table>
                            {(!eventData ||  !eventData.data || !eventData.data.length) && <div className="text-center info-description">Empty </div>}

                        </div>
                    </>
                </div>
            </div>
        </LeaderboardEventWraper>

    );
};

export default TableDayWinner;
