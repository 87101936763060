import History from "components/icons/History"
import Notification from "components/icons/Notification"
import { ProfileWraper } from "./styled"
import { Link } from "react-router-dom"
import { useAccount } from "wagmi"
import { ellipsisMiddle } from "web3/connector"
import { useEffect, useState } from "react"
import { getUserNotification } from "pages/services/api"
import PrivacyPolicyIcon from "components/icons/PrivacyPolicyIcon"
import GuidelineIcon from "components/icons/GuidelineIcon"

const people = [
    {
        name: 'History Play Game',
        icon: <History />,
        id: 1,
        link: '/history'

    },
    {
        name: 'Notification',
        icon: <Notification />,
        id: 2,
        link: '/notification'

    },
    {
        name: 'Point Policy',
        icon: <PrivacyPolicyIcon />,
        id: 3,
        link: '/point-policy'

    },
    {
        name: 'Guideline',
        icon: <GuidelineIcon />,
        id: 4,
        link: '/guideline'

    },
]




export default function Profile() {
    const { address, isConnected } = useAccount()

    const [dataNoti, setDataNoti] = useState<any>();

    const [loading, setLoading] = useState<boolean>(true);
  
  
    const fetchApi = async () => {
      try {
        setLoading(true);
        const params = { limit: 1, page: 1 };
        const data = await getUserNotification(params);
        setLoading(false);
        setDataNoti(data);
      } catch (err) {
        setLoading(false);
      }
    };
  

    useEffect(() => {
      fetchApi();
      console.log(dataNoti)
    }, []);

    useEffect(() => {
        console.log(dataNoti)
      }, [dataNoti]);

    return (
        <ProfileWraper>
            <div className="p-5  w-[100vw] max-w-[1200px]  ">
                <div className="profile-addres">{(address) ? ellipsisMiddle(address, 4, 5) : ''}</div>
                <div className="profile-title mt-5" >Account</div>
                <div className="bg-white rounded-xl p-5 mt-10">
                    <ul className="divide-y divide-[#E0E0E0]">
                        {people.map((filter) => (
                            <li key={filter.id} className="  ">
                                <Link to={filter.link} className="flex justify-between  py-16 gap-x-6 px-5">
                                    <div className="flex min-w-0 gap-x-4">
                                        <div className="relative text-blue-100 rounded " >{filter.icon}<span className={`${(filter.id !== 2) ? 'hidden' : ''} absolute bg-red-600 text-white px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3 `}>{(dataNoti && dataNoti.totalUnSeen)? dataNoti.totalUnSeen : 0}</span>
                                        </div>
                                        <div className="min-w-0 ml-5 flex-auto">
                                            <p className="profile-item">{filter.name}</p>
                                        </div>
                                    </div>
                                    <div className=" shrink-0 sm:flex sm:flex-col sm:items-end">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_493_7690)">
                                                <path d="M3.24508 1.93505L4.13508 1.05005L9.08008 6.00005L4.13008 10.95L3.24508 10.065L7.31008 6.00005L3.24508 1.93505Z" fill="#626262" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_493_7690">
                                                    <rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 12 0)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </ProfileWraper>
    )
}