import React, { useEffect } from "react";

import ListEvents from "components/List/ListEvents";
import LeaderboardEvent from "components/Table/LeaderboardEvent";
import { useAppDispatch } from "index";
import { setPageState } from "slices/pageSlice";
import { getListNFTGames, getListPopularGameMini } from "./services/api";

const Prizes = () => {

  const dispatchApp = useAppDispatch()

  useEffect(() => {
    getListPopularGameMini();
  }, []);
  useEffect(() => {
    dispatchApp(setPageState({
      showTitle: false,
      showAccount: true,
      tab: 2,
      title: '',
      background: '#FFFFFF',
      backgroundHeader: '#FFFFFF'


    }
    ))
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      {/* <Popup /> */}
      {/* <PopupPlay/> */}
      {/* <PopupAlert/> */}
      {/* <PopupNoti/> */}
      <div className="flex-center wrapper-home ">
        {/* {isLoading && <LoadingIcon />} */}
        {/* <Banner data={gamesNFT} /> */}
        {/* <HeroBanner/> */}
        {/* <Search /> */}
        {/* <Hero /> */}
        {/* <NFTGames data={gamesNFT} /> */}
        {/* <GameOpenSource /> */}
        {/* <Recent /> */}
        {/* <Popular /> */}
        {/* <Recommend /> */}


        {/* <OpenSourceAll /> */}
        <ListEvents />
        {/* <LeaderboardEvent/> */}
        {/* <PopularGame data={gamesPopular} /> */}
        {/* <TopFreeGame /> */}
      </div>
    </>
  );
};

export default Prizes;
