
import { eventsImg, logos, slidebarImg } from "helpers/ExportImg";
import { EventCardWrapper } from "./styled";
import moment from 'moment';

const CardTopPrize: any = ({ eventData }: any) => {

  const { joinCounter, pointCondition, eventDetail, userPoint , startedAt} = eventData;
  const { pools } = slidebarImg;

  let cache: any[] = []

  const getDetailByRank = (rank: number) => {
    if (cache[rank]) return cache[rank];
    cache[rank] = eventDetail?.find((a: { rank: number; }) => a.rank === rank);
    return cache[rank];
  }

  return (
    <>
      <EventCardWrapper>
        <div className="inline-block w-full rounded-xl ">
          <div className="flex flex-col m-auto overflow-hidden rounded-xl " >
            <div className="flex   m-auto mt-5">
              {getDetailByRank(2) && (<div className="flex flex-col justify-end ">
                <div className="mb-10" >
                  <img className="m-auto" src={eventsImg.iconTop2}
                    alt="img" />
                </div>
                <div className="flex justify-center mb-2">
                  <div className=" top-rize-number flex">{getDetailByRank(2)?.reward}</div>
                  <div className="ml-2 items-center" >
                    <img className="w-[24px] h-[24px]" src={pools}
                      alt="img" />
                  </div>
                </div>
                <div className="flex event-info">
                  <div className="" >
                    <img className="" src={eventsImg.top2}
                      alt="img" />
                  </div>
                </div>
              </div>)}
              {getDetailByRank(1) && (<div className="flex flex-col  justify-end ">
                <div className="mb-10" >
                  <img className="m-auto" src={eventsImg.iconTop1}
                    alt="img" />
                </div>
                <div className="flex justify-center">
                  <div className="flex items-center mb-2">
                    <div className=" top-rize-number flex">{getDetailByRank(1)?.reward}</div>
                    <div className="ml-2 items-center" >
                      <img className="w-[24px] h-[24px]" src={pools}
                        alt="img" />
                    </div>
                  </div>
                </div>
                <div className="flex event-info">
                  <div className="" >
                    <img className="" src={eventsImg.top1}
                      alt="img" />
                  </div>
                </div>

              </div>)}
              {getDetailByRank(1) && (<div className="flex flex-col  justify-end ">
                <div className="mb-10" >
                  <img className="m-auto" src={eventsImg.iconTop3}
                    alt="img" />
                </div>
                <div className="flex justify-center">
                  <div className="flex items-center  mb-2">
                    <div className=" top-rize-number flex">{getDetailByRank(3)?.reward}</div>
                    <div className="ml-2 items-center" >
                      <img className="w-[24px] h-[24px]" src={pools}
                        alt="img" />
                    </div>
                  </div>
                </div>
                <div className="flex event-info">
                  <div className="" >
                    <img className="" src={eventsImg.top3}
                      alt="img" />
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
        <div className="mt-20 bg-[#CDF3E2] p-5">
          <div className="flex justify-between w-full ">
            <div className="pte-header-left">
              <span>Event infomation</span>
            </div>
            <div className="pte-header-right">
              {(userPoint >= pointCondition) ? <span className="">Joined</span> : <span className="text-red-500">Not join</span>}
            </div>
          </div>
          <div className="mt-6"><span className=" event-info ">Total user joined: </span><span className=" info-description-number">{joinCounter || 0}</span></div>
          <div className="mt-6"><span className=" event-info ">Time: </span><span className=" info-description-number">{moment(startedAt).format('HH:mm:ss DD/MM/YYYY')} - {moment(startedAt).add(1, 'w').format('HH:mm:ss DD/MM/YYYY')}</span></div>
          <div className="flex  mt-6 ">
            <div className="  flex w-fit  rounded-xl justify-center items-center">
              <div className="  flex "><span className=" event-info">Rule:</span></div>
              <div className="  flex  ml-2 info-description "><span>You need to collect  </span></div>
              <div className="ml-2 flex items-center  ">
                <div className="info-description-number flex">{pointCondition || 0}</div>
                <div className="ml-2 items-center" >
                  <img className="w-[12px] h-[12px]" src={logos.currency}
                    alt="img" />
                </div>
              </div>
              <div className="ml-2  flex info-description">during the event</div>
            </div>
          </div>
          <hr className="h-px my-4 bg-[#9BE7C6] border-0"/>
          <div className="flex  mt-6 ">
            <div className="  flex w-fit  rounded-xl justify-center items-center    ">
              <div className="  flex "><span className=" event-info">Points collected in this event:</span></div>
              <div className="ml-2 flex items-center justify-center  ">
                <div className="info-description-number flex">{userPoint || 0}</div>
                <div className="ml-2 " >
                  <img className="w-[12px] h-[12px] " src={logos.currency}
                    alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </EventCardWrapper>
    </>
  );
};

export default CardTopPrize;