import { SetStateAction, useEffect, useState } from "react";
import { HomeSearch } from "./styled";

import { getListGenre } from "pages/services/api";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function SearchPtePage() {
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [selectFilter, setSelectFilter] = useState<any>();
    const onSelectChanged = (e: { currentTarget: { value: SetStateAction<string>; }; }) => {
        const selectId = parseInt(e.currentTarget.value as string)
        setSelectFilter((genres.filter((obj: { id: number; }) => obj.id === selectId)[0]))

        
    }
    const [genres, setGenres] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState("");
    const handleChangeComment = (event: any) => {
        setQuery(event.target.value);
    };
    useEffect(() => {
        window.scrollTo({ top: 0 });
        getListGenre().then((response: any) => {
            setGenres(response);
            setIsLoading(false);
        });
    }, []);

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === 'Enter') {;
            searchParams.set("query", query)
            navigate(`/all-game-pta/?${searchParams.toString()}`, { replace: true })
        }
    }

    return (
        <HomeSearch>
            <div className="flex items-center mt-5">
                <label htmlFor="simple-search" className="sr-only">Search</label>
                <button type="submit" onClick={() => { navigate(-1) }} className="">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 18L9 12L15 6" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button >
                <div className="relative w-full homeSearch ml-5 pr-5">
                    <div className="absolute ml-6 inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#9E9E9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.5 17.5L13.875 13.875" stroke="#9E9E9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <input type="text" id="simple-search" className=" w-full  ps-10 p-5 " value={query} onChange={handleChangeComment}
                        onKeyDown={handleKeyDown} placeholder="Search by name..." required />
                </div>

            </div>
        </HomeSearch>
    )
}
