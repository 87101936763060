import {
  getAccount as defineAccount,
  connect,
  Connector,
  signMessage,
  getNetwork,
  switchNetwork as defineNetwork,
} from "@wagmi/core";
import { injectedConnector, metaMaskConnector } from "./wagmi";

export enum NETWORKS {
  BSC = "BSC",
  BSC_TESTNET = "BSC_TESTNET",
  POOLS = "POOLS",
  POOLS_TESTNET = "POOLS_TESTNET"
}

export const CHAINS = {
  [NETWORKS.BSC]: {
    CHAIN_ID: 56,
    RPC_URL: "https://bsc-dataseed.binance.org/",
    CHAIN_EXPLORER: "https://bscscan.com/",
    CHAIN_NAME: "Binance Smart Chain",
    SYMBOL: "BNB",
  },
  [NETWORKS.BSC_TESTNET]: {
    CHAIN_ID: 97,
    RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    CHAIN_EXPLORER: "https://testnet.bscscan.com/",
    CHAIN_NAME: "Binance Smart Chain",
    SYMBOL: "tBNB",
  },
  [NETWORKS.POOLS]: {
    CHAIN_ID: 6868,
    RPC_URL: "https://rpc.poolsmobility.com",
    CHAIN_EXPLORER: "https://scan.poolsmobility.com",
    CHAIN_NAME: "Pools Mainnet",
    SYMBOL: "Pools",
  },
  [NETWORKS.POOLS_TESTNET]: {
    CHAIN_ID: 12345,
    RPC_URL: "https://rpc-testnet.poolsmobility.com",
    CHAIN_EXPLORER: "https://scan-testnet.poolsmobility.com",
    CHAIN_NAME: "Pools Testnet",
    SYMBOL: "tPools",
  },
};

function getAccount() {
  const account = defineAccount();
  return account.address;
}

async function connectWallet(connector?: Connector) {
  const result = await connect({
    connector: connector ?? metaMaskConnector,
  });

  return result.account;
}

async function sign(
  wallet?: string,
  message: string = "Welcome to Playground Game Center!"
) {
  const signature = await signMessage({
    message,
  });

  return signature;
}

function checkNetwork() {
  const network = getNetwork();
  return (
    network.chain &&
    network.chain.id ===
    CHAINS[process.env.REACT_APP_NETWORK as NETWORKS].CHAIN_ID
  );
}

async function switchNetwork() {
  const idNetwork = getNetwork().chain?.id;
  const myNetworkId = CHAINS[process.env.REACT_APP_NETWORK as NETWORKS].CHAIN_ID;

  if (myNetworkId !== idNetwork) {
    const network = await defineNetwork({
      chainId: CHAINS[process.env.REACT_APP_NETWORK as NETWORKS].CHAIN_ID,
    });
    return network.id;
  }

  return idNetwork;
}

function ellipsisMiddle(account: string, beginning: number, final: number) {
  if (account.length <= beginning) return account
  return account
    ? account?.slice(0, beginning) + "..." + account?.slice(-final)
    : "";
}

export {
  getAccount,
  connectWallet,
  sign,
  checkNetwork,
  switchNetwork,
  ellipsisMiddle,
};
