import * as React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import appContext from "appContext";
export interface IPaginationGame {
  count: number;
  page: number;
  setPage: (value: number) => void;
}

export default function PaginationGame({ count, page, setPage }: IPaginationGame) {

  const handleChange = (event: any, value: any) => {
    setPage(value);
  };

  const deviceWidth = window.innerWidth;

  return (
    <Stack spacing={2} sx={{ marginBottom: "4rem" }}>
      <Pagination
        count={count || 1}
        page={page}
        onChange={handleChange}
        siblingCount={deviceWidth > 360 ? 1 : 0}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIosIcon, next: ArrowForwardIosIcon }}
            {...item}
            sx={{
              margin: { xs: "0.7rem", sm: "1rem" },
              fontSize: { xs: "2.8rem", sm: "2rem" },
              minWidth: { xs: "100%", sm: "4rem" },
              maxWidth: "100%",
              height: { xs: "auto", sm: "4rem" },
              padding: { xs: "2rem 1.2rem", sm: "1rem" },
            }}
            size="large"
          />
        )}
      />
    </Stack>
  );
}
