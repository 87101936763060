
import { discover } from "constants/path";
import { Link } from "react-router-dom";
import { EventCardWrapper } from "./styled";
import { logos, slidebarImg } from "helpers/ExportImg";
import { useEffect, useState } from "react";
import { handleShowTime } from "helpers/util";

const CardDayEvent: any = ({ event }: any) => {

  const { pools } = slidebarImg;

  
  const { name, image, id, description, startedAt, type, totalReward, playCount } = event;

  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);


  const getEndTime = (startedAt: string | number | Date, type: string) => {
    const start = new Date(startedAt);
    if (type === "Week") return new Date(start.getTime() + 7 * 24 * 60 * 60 * 1000)
    if (type === "Day") return new Date(start.getTime() + 24 * 60 * 60 * 1000)
    if (type === "Hour") return new Date(start.getTime() + 60 * 60 * 1000)
    return new Date();
  }

  const countDownTime = () => {
    // Set the date we're counting down to
    var countDownDate = getEndTime(startedAt, type).getTime();

    // Update the count down every 1 second
    setInterval(function () {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDays(days)
      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)
    }, 1000);
  }

  useEffect(() => {
    countDownTime()
  }, []);

  return (
    <>
      <EventCardWrapper>
          <div className="inline-block w-full rounded-xl">

            <div className="flex flex-col w-full space-y-2 overflow-hidden rounded-xl " >
              <div className="flex justify-center items-center ">
                <img className="w-full aspect-video	 mt-5" src={image}
                  alt="img" />
              </div>
              
              <div className=" p-10 ">
              <div className="flex justify-center items-center   ">
                <div className="  flex w-fit bg-[#FDEB7B]  rounded-xl justify-center items-center    ">
                <div className="  flex weekly-rewards">RACE FOR SUCCESS:</div>

                  <div className="  flex weekly-rewards-number ml-2">{totalReward}</div>
                  <div className="ml-2" >
                    <img className="w-[25px] h-[25px] mt-[2px]" src={pools}
                      alt="img" />
                  </div>

                  <div className="  flex weekly-rewards">DAY REWARDS</div>

                </div>
                </div>

                <div className="text-center event-name mt-16">
                {name}
                </div>
                <div className="flex w-full space-x-4 mt-16 px-10">
                  <div className="flex flex-col justify-center items-center  w-full ">
                    <div className="flex event-time-number">
                    {handleShowTime(days)}
                    </div>
                    <div className="flex event-info">
                      days
                    </div>
                  </div>
                  <div className="flex justify-center items-center w-full event-dots ">
                    :
                  </div>
                  <div className="flex flex-col  justify-center items-center  w-full  ">
                    <div className="flex event-time-number">
                    {handleShowTime(hours)}
                    </div>
                    <div className="flex event-info">
                      hours
                    </div>

                  </div>
                  <div className="flex justify-center items-center w-full event-dots">
                    :

                  </div>
                  <div className="flex flex-col justify-center items-center  w-full ">
                    <div className="flex event-time-number">
                    {handleShowTime(minutes)}

                    </div>
                    <div className="flex event-info">
                      min
                    </div>

                  </div>
                  <div className="flex justify-center items-center w-full event-dots ">
                    :
                  </div>
                  <div className="flex flex-col justify-center items-center  w-full ">
                    <div className="flex event-time-number">
                    {handleShowTime(seconds)}
                    </div>
                    <div className="flex event-info">
                      sec
                    </div>
                  </div>
                </div>
                <div className="event-btn mt-16 w-full" onClick={() => { }} >Fortune collection</div>
              </div>
            </div>
          </div>
      </EventCardWrapper>
    </>
  );
};

export default CardDayEvent;