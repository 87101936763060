import { slidebarImg } from "helpers/ExportImg";
import { useEffect, useState } from "react";

import { getEventById, getEventDayParticipant, getEventWinner } from "pages/services/api";
import { useParams } from "react-router-dom";
import { ellipsisMiddle } from "web3/connector";
import { LeaderboardEventWraper } from "./styled";

const TableDayYouTicket = () => {
    const paramsUrl = useParams();

    const { pools } = slidebarImg;

    const [eventData, setEventData] = useState<any>();

    const [loading, setLoading] = useState<boolean>(true);

    const fetchApi = async () => {
        try {
          setLoading(true);
          const params = { page: 1, limit: 10, allowUser : true , sortBy: 'createdAt', direction: 'DESC' };
          const data = await getEventDayParticipant(paramsUrl.id, params);
          setLoading(false);
          console.log("dd", eventData && eventData.data && eventData?.data?.length > 0)
          setEventData(data);
        } catch (err) {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        fetchApi();
      }, []);

    const getRank = (rank: any)=>{
        if(rank === 1) return '1st'
        if(rank === 2) return '2nd'
        if(rank === 3) return '3rd'
        return rank;
    }

    return (

        <LeaderboardEventWraper className="relative w-full">
            {/* <PopupInfo /> */}
            <div
                className=""
                style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
            >
                <div
                    className=""
                    style={{ margin: 0 }}
                >
                    <div className="p-5 flex justify-between space-x-5 w-full  ">
                        <div className="leaderboard-header-left">
                            <span>Your Ticket Number</span>
                        </div>
                    </div>
                    <>
                        <div className="mt-5 p-5 grid grid-cols-1 gap-10   mb-10  overflow-x-auto">
                            {eventData && eventData.data && eventData?.data?.length > 0 && eventData.data.map((rankInfo: any, index: number) => (
                                (<div key={rankInfo?.id} className="bg-[#CDF3E2] p-8 rounded-3xl ticket-number text-center">
                                    {rankInfo.ticketNumber}
                                </div>)
                            ))}
                            {(!eventData ||  !eventData.data || !eventData.data.length) && <div className="text-center info-description">Empty </div>}
                        </div>
                    </>
                </div>
            </div>
        </LeaderboardEventWraper>

    );
};

export default TableDayYouTicket;
