import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialState: any = {
  show: false,
};

const popupConnectSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPopupConnectShowState: (state: any, action: PayloadAction<boolean>) => {
      return {
        ...state,
        show: action.payload,
      };
    },
  },
});

export const {
  setPopupConnectShowState
} = popupConnectSlice.actions;

export const popupConnectReducer = popupConnectSlice.reducer;
