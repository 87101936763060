export const wealthyAbi = [{
    "type": "constructor",
    "stateMutability": "nonpayable",
    "inputs": [{
        "type": "address",
        "name": "defaultAdmin",
        "internalType": "address"
    }, {
        "type": "address",
        "name": "signer",
        "internalType": "address"
    }]
}, {
    "type": "event",
    "name": "BetDeposit",
    "inputs": [{
        "type": "address",
        "name": "user",
        "internalType": "address",
        "indexed": true
    }, {
        "type": "uint256",
        "name": "eventId",
        "internalType": "uint256",
        "indexed": false
    }, {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256",
        "indexed": false
    }, {
        "type": "uint256",
        "name": "typeBet",
        "internalType": "uint256",
        "indexed": false
    }],
    "anonymous": false
}, {
    "type": "event",
    "name": "Paused",
    "inputs": [{
        "type": "address",
        "name": "account",
        "internalType": "address",
        "indexed": false
    }],
    "anonymous": false
}, {
    "type": "event",
    "name": "RoleAdminChanged",
    "inputs": [{
        "type": "bytes32",
        "name": "role",
        "internalType": "bytes32",
        "indexed": true
    }, {
        "type": "bytes32",
        "name": "previousAdminRole",
        "internalType": "bytes32",
        "indexed": true
    }, {
        "type": "bytes32",
        "name": "newAdminRole",
        "internalType": "bytes32",
        "indexed": true
    }],
    "anonymous": false
}, {
    "type": "event",
    "name": "RoleGranted",
    "inputs": [{
        "type": "bytes32",
        "name": "role",
        "internalType": "bytes32",
        "indexed": true
    }, {
        "type": "address",
        "name": "account",
        "internalType": "address",
        "indexed": true
    }, {
        "type": "address",
        "name": "sender",
        "internalType": "address",
        "indexed": true
    }],
    "anonymous": false
}, {
    "type": "event",
    "name": "RoleRevoked",
    "inputs": [{
        "type": "bytes32",
        "name": "role",
        "internalType": "bytes32",
        "indexed": true
    }, {
        "type": "address",
        "name": "account",
        "internalType": "address",
        "indexed": true
    }, {
        "type": "address",
        "name": "sender",
        "internalType": "address",
        "indexed": true
    }],
    "anonymous": false
}, {
    "type": "event",
    "name": "Unpaused",
    "inputs": [{
        "type": "address",
        "name": "account",
        "internalType": "address",
        "indexed": false
    }],
    "anonymous": false
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "bytes32",
        "name": "",
        "internalType": "bytes32"
    }],
    "name": "BET_SIGNER",
    "inputs": []
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "bytes32",
        "name": "",
        "internalType": "bytes32"
    }],
    "name": "DEFAULT_ADMIN_ROLE",
    "inputs": []
}, {
    "type": "function",
    "stateMutability": "payable",
    "outputs": [],
    "name": "deposit",
    "inputs": [{
        "type": "uint256",
        "name": "eventId",
        "internalType": "uint256"
    }, {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256"
    }, {
        "type": "uint256",
        "name": "typeBet",
        "internalType": "uint256"
    }, {
        "type": "uint256",
        "name": "expireBlock",
        "internalType": "uint256"
    }, {
        "type": "bytes",
        "name": "signature",
        "internalType": "bytes"
    }]
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "bool",
        "name": "",
        "internalType": "bool"
    }],
    "name": "eventClose",
    "inputs": [{
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
    }]
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "bytes32",
        "name": "",
        "internalType": "bytes32"
    }],
    "name": "getRoleAdmin",
    "inputs": [{
        "type": "bytes32",
        "name": "role",
        "internalType": "bytes32"
    }]
}, {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "grantRole",
    "inputs": [{
        "type": "bytes32",
        "name": "role",
        "internalType": "bytes32"
    }, {
        "type": "address",
        "name": "account",
        "internalType": "address"
    }]
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "bool",
        "name": "",
        "internalType": "bool"
    }],
    "name": "hasRole",
    "inputs": [{
        "type": "bytes32",
        "name": "role",
        "internalType": "bytes32"
    }, {
        "type": "address",
        "name": "account",
        "internalType": "address"
    }]
}, {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "pause",
    "inputs": []
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "bool",
        "name": "",
        "internalType": "bool"
    }],
    "name": "paused",
    "inputs": []
}, {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "renounceRole",
    "inputs": [{
        "type": "bytes32",
        "name": "role",
        "internalType": "bytes32"
    }, {
        "type": "address",
        "name": "account",
        "internalType": "address"
    }]
}, {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "revokeRole",
    "inputs": [{
        "type": "bytes32",
        "name": "role",
        "internalType": "bytes32"
    }, {
        "type": "address",
        "name": "account",
        "internalType": "address"
    }]
}, {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "reward",
    "inputs": [{
        "type": "uint256",
        "name": "eventId",
        "internalType": "uint256"
    }, {
        "type": "tuple[]",
        "name": "winner",
        "internalType": "struct wealthy.Winner[]",
        "components": [{
            "type": "address",
            "name": "userWin",
            "internalType": "address"
        }, {
            "type": "uint256",
            "name": "amount",
            "internalType": "uint256"
        }]
    }]
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "bool",
        "name": "",
        "internalType": "bool"
    }],
    "name": "supportsInterface",
    "inputs": [{
        "type": "bytes4",
        "name": "interfaceId",
        "internalType": "bytes4"
    }]
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
    }],
    "name": "totalAmountForEvent",
    "inputs": [{
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
    }]
}, {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "unpause",
    "inputs": []
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "bool",
        "name": "",
        "internalType": "bool"
    }],
    "name": "usedSignatureList",
    "inputs": [{
        "type": "address",
        "name": "",
        "internalType": "address"
    }, {
        "type": "bytes",
        "name": "",
        "internalType": "bytes"
    }]
}, {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256"
    }, {
        "type": "uint256",
        "name": "typeBet",
        "internalType": "uint256"
    }, {
        "type": "uint256",
        "name": "timestamp",
        "internalType": "uint256"
    }],
    "name": "userDeposit",
    "inputs": [{
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
    }, {
        "type": "address",
        "name": "",
        "internalType": "address"
    }]
}, {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "withdraw",
    "inputs": [{
        "type": "uint256",
        "name": "value",
        "internalType": "uint256"
    }, {
        "type": "address",
        "name": "to",
        "internalType": "address payable"
    }]
}, {
    "type": "receive",
    "stateMutability": "payable"
}]