import { footerImg, homeImg } from "helpers/ExportImg";
import useWindowSize from "hooks/useWindowSize";
import { getHistoryGame, getPopularGame } from "pages/services/api";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListGameOpenSource } from "slices/gameOpenSource";

import GameCardHistory from "components/Card/GameCardHistory";
import { PlayToEarnStyled } from "./styled";
import GameCardPopular from "components/Card/GameCardPopular";

const Popular = ({ openList }: any) => {
  const [page, setPage] = useState(1);
  const [limitGame, setLimitGame] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const handleChagePage = (value: number) => {
    rootRef?.current.scrollIntoView({ behavior: "smooth" });
    setPage(value);
  };
  const rootRef = useRef<any>();
  const [listGame, setListGame] = useState([]);
  const { controlMobile } = footerImg;

  const fetchApi = async () => {
    try {
      setLoading(true);
      // const limit = 10;
      const params = { page, limit: limitGame };
      const data = await getPopularGame(params);
      setListGame(data.data)
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (listGame) {
      setLoading(false);
    }
  }, [listGame]);

  useEffect(() => {
    fetchApi();
  }, []);

  const payToEarnRef = useRef<any>(null)

  return (
    <PlayToEarnStyled className="relative">
      {(listGame && listGame.length >= 0)  &&
        (<div
          className=""
          style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
          ref={rootRef}
        >
          <div
            className=""
            style={{ margin: 0 }}
          >
            <div className="flex justify-between w-full  ">
              <div className="pte-header-left">
                <h1>Popular</h1>
                <span className="pte-text">The game with the most play</span>
              </div>
            </div>

            <>
              {loading ? (
                <img
                  style={{ padding: "5rem 0" }}
                  src={controlMobile}
                  alt="img"
                  className="logo-loading"
                />
              ) : (

                <div ref={payToEarnRef} className="mt-5 grid grid-rows-1 grid-flow-col overflow-x-scroll scroll-p-0 w-[90vw] max-w-[1200px] pb-5 hide-scroll-bar justify-between">
                  {listGame.map((game: any, index: number) => (
                    (game) &&
                      (<div
                        key={index}
                      >
                        {/* <div className="card-genre-game game-open" key={game?.id}> */}
                        <GameCardPopular game={game} />
                      </div>) 
                  ))}
                  {/* <div style={{ marginTop: "1rem", width: "100%" }}>
                <PaginationGame
                  count={count}
                  page={page}
                  setPage={handleChagePage}
                />
              </div> */}
                </div>
                // <>
                //   {width > 1000 ? (
                //     <div className="flex-center wrapper-cards cards-free-game  card-genre-game-custom">
                //       {listGame?.data?.map((game: any) => (
                //         <div className="card-all-games" key={game?.id}>
                //           <GameCard game={game} />
                //         </div>
                //       ))}
                //       <div style={{ marginTop: "1rem", width: "100%" }}>
                //         <PaginationGame
                //           count={count}
                //           page={page}
                //           setPage={handleChagePage}
                //         />
                //       </div>
                //     </div>
                //   ) : (
                //     <div className="flex-center wrapper-cards cards-free-game  card-genre-game-custom">
                //       {dataGame?.map((game: any) => (
                //         <div className="card-genre-game" key={game?.id}>
                //           <GameCard game={game} />
                //         </div>
                //       ))}
                //     </div>
                //   )}
                // </>
              )}
            </>
          </div>
        </div>)
      }
    </PlayToEarnStyled>


  );
};

export default Popular;