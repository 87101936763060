import appContext from "appContext";
import { nftPath } from "constants/path";
import { popularImg } from "helpers/ExportImg";
import { showNotification } from "helpers/util";
import useWindowSize from "hooks/useWindowSize";
import { addDownloadHistory } from "pages/services/api";
import { useContext } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { NewVersion } from "./styled";

const SecondaryCard = ({
  img,
  description,
  name,
  idGame,
  whitePaper,
  tokenomic,
  webSite,
  download,
  item,
  updateAt,
  version,
}: any) => {
  const { notePopular, webPopular, coinPopular } = popularImg;
  const width: any = useWindowSize();

  const current_day = new Date().getTime();
  const past_day = new Date(updateAt).getTime();

  const date = Math.ceil((current_day - past_day) / 86400000);

  const gameId = item?.id;
  const gameType = "nftGame";


  const downloadGame = () => {
    addDownloadHistory({ gameId });
  };
  const { account, setAccount } = useContext(appContext);

  const handleLogin = () => {
    showNotification("error", "You must login to download games");
  };

  const ButtonDownload = () => {
    return (
      <>
        {/* {!account?.email ? (
          <div
            className="flex-center btn-disable"
            onClick={handleLogin}
            style={{ cursor: "pointer" }}
          >
            <AiOutlineDownload />
            {width > 768 ? "Download" : ""}
          </div>
        ) : ( */}
        <a
          href={download}
          target="_blank"
          rel="noreferrer"
          download
          className="flex-center btn-popular"
          style={{ textDecoration: "none" }}
          onClick={downloadGame}
        >
          <AiOutlineDownload />
          {width > 768 ? "Download" : ""}
        </a>
        {/* )} */}
      </>
    );
  };

  const ButtonPopular = () => {
    return (
      <>
        <a
          href={whitePaper}
          target="_blank"
          rel="noreferrer"
          className="icon-popular"
        >
          <img src={notePopular} alt="img" className="img-popular" />
        </a>
        <a
          href={webSite}
          target="_blank"
          rel="noreferrer"
          className="icon-popular"
        >
          <img src={webPopular} alt="img" className="img-popular" />
        </a>
        <a
          href={tokenomic}
          target="_blank"
          rel="noreferrer"
          className="icon-popular"
        >
          <img src={coinPopular} alt="img" className="img-popular" />
        </a>
      </>
    );
  };

  return (
    <NewVersion>
      <div className="secondary-card nft-card_newversion">
        <img src={img} alt="img" className="card-popular" />
        {date < 7 ? <div className="title-newversion">New Version</div> : ""}

        <div className="content-secondary-card">
          <span className="title-secondary">NFT-PLAY TO EARN</span>
          <span className="game_name">{name}</span>
          <span className="description-secondary">
            {/* {width < 768 ? description : ""} */}
            {/* {date > 7 ? <div className="new-version">New Version</div> : ""} */}
            <div className="new-version">Version: {version}</div>
          </span>
          <NavLink
            to={`${nftPath}/${idGame}`}
            className="link-popular"
            
          >
            Show more
          </NavLink>
          <div className="btn-group-popular">
            {width > 768 ? (
              <>
                <ButtonPopular />
                {width < 768 && <ButtonDownload />}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex" }}>
                  <ButtonPopular />
                </div>
                {width < 768 && <ButtonDownload />}
              </div>
            )}
          </div>
          {width >= 768 && <ButtonDownload />}
        </div>
      </div>
    </NewVersion>
  );
};

export default SecondaryCard;
