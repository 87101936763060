import { logos, slidebarImg } from "helpers/ExportImg";
import { useAppDispatch, useAppSelector } from "index";
import { setPopupAllPrizesShowState } from "slices/popupAllPrizesSlice";
import { PopupTag } from "./styled";
import { useEffect, useState } from "react";




const PopupAllPrizes = (props: any) => {
  const dispatchApp = useAppDispatch()

  const popupAllPrizesState = useAppSelector(state => state.popupAllPrizesState);
  const [listTop, setListTop] = useState<any>([]);

  const handlerClosePopup = () => {
    dispatchApp(setPopupAllPrizesShowState(false));
  };

  const { pools } = slidebarImg;

  const groupTop = () => {
    const groups = popupAllPrizesState?.eventData?.eventDetail.reduce((acc: any, object: any) => {
      const groupKey = object.reward; // Nhóm theo điểm
      acc[groupKey] = (acc[groupKey] || []).concat(object);
      return acc;
    }, {});
    if (groups) {
      const dataList = Object.values(groups)?.reverse().map(
        (n: any) => {
          if (n.length === 1) {
            return { rank: n[0].rank, reward: n[0].reward }
          }
          if (n.length > 1) {
            return { rank: n[0].rank + ' - ' + n[n.length - 1].rank, reward: n[0].reward }
          }
        }
      );
      setListTop(dataList)
      return dataList
    }
  }

  useEffect(() => {
    groupTop()
  }, [popupAllPrizesState]);

  return (
    <PopupTag>

      <>
        {popupAllPrizesState.show && (
          <>
            <div onClick={handlerClosePopup} className={`backdrop`}></div>
            <div className="">
              <div className={`popup img-mobile min-w-[80%]`}>
                <>
                  <div className="bg-[#F0F0F0] rounded-3xl w-full p-14 px-40 ">
                    <div className="p-5">
                      <div className="popup-title">
                        {popupAllPrizesState.title}
                      </div>
                      {/* <div className="popup-text">
                        {popupAllPrizesState.description}
                      </div> */}
                      <div className="mt-8">
                        <table className=" m-auto fixed_header">
                          <thead className="">
                            <tr>
                              <th className="text-left">Top</th>
                              <th className="text-right">
                                <div className="flex float-right justify-center items-center">
                                  <div className="  ">Prize</div>
                                  <div className="ml-2 items-center justify-center" >
                                    <img className="w-[15px] h-[15px]" src={pools}
                                      alt="img" />
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                          {listTop.length > 0 && listTop?.map((rankInfo: any, index: number) => (
                            <tr key={rankInfo?.id}>
                              <td className="text-left">{rankInfo.rank}</td>
                              <td className="text-right">{rankInfo.reward}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <button className="popup-btn mt-5 w-full" onClick={async () => { handlerClosePopup(); }} >Close</button>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupAllPrizes;
