import { configureChains, createClient } from "wagmi";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
// import Logo from "assets/wagmi/logo.svg";
import { bscTestnet, bsc } from "@wagmi/core/chains";
import { publicProvider } from "@wagmi/core/providers/public";
import { Chain } from '@wagmi/core'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'


export const pools = {
  id: 6868,
  name: 'Pools Mainnet',
  network: 'pools-mainnet',
  nativeCurrency: { name: 'Pools', symbol: 'POOLS', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.poolsmobility.com'],
    },
    public: {
      http: ['https://rpc.poolsmobility.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Pools',
      url: 'https://scan.poolsmobility.com',
    },
  },
  testnet: true,
} as Chain

export const poolsTest = {
  id: 12345,
  name: 'Pools Testnet',
  network: 'pools-testnet',
  nativeCurrency: { name: 'Pools', symbol: 'POOLS', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-testnet.poolsmobility.com'],
    },
    public: {
      http: ['https://rpc-testnet.poolsmobility.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'tPools',
      url: 'https://scan-testnet.poolsmobility.com',
    },
  },
  testnet: true,
} as Chain

export const { provider, chains } = configureChains(
  [bsc, bscTestnet, poolsTest, pools],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        // if (!!process.env.REACT_APP_BSC && chain.id === +process.env.REACT_APP_CHAIN_ID) {
        //   return { http: process.env.REACT_APP_BSC };
        // }
        return { http: chain.rpcUrls.default.http[0] };
      },
  })]
);


export const metaMaskConnector = new MetaMaskConnector({
  chains: [pools, poolsTest],
  options: {
    shimDisconnect: true,
    shimChainChangedDisconnect: true
  },
});

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains: [bsc, bscTestnet],
  options: {
    appName: "Game Center",
    // appLogoUrl: Logo
  },
});

export const walletConnectConnector = new WalletConnectConnector({
  chains: [bsc, bscTestnet],
  options: {
    qrcode: true,
  },
});

export const injectedConnector = new InjectedConnector({
  chains: [poolsTest, pools],
})


export const client = createClient({
  // autoConnect: true,
  provider,
  connectors: [metaMaskConnector],
});

