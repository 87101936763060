import LoadingIcon from "components/LoadingIcon";
import { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import CardSpecialHistory from "components/Card/CardSpecialHistory";
import LoadingVerTwo from "components/loadingVerTwo/LoadingVerTwo";
import { getEventDayParticipant, getEventHistory } from "pages/services/api";
import CardDayEventHistory from "components/Card/CardDayEventHistory";
import { useParams } from "react-router-dom";
import { handleShowTime } from "helpers/util";

const ListAllTicket = ({ genres }: any) => {

  const PER_PAGE = 20;
  const [listGame, setListGame] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [hasMore, setHasMore] = useState<any>(true);
  const [offset, setOffset] = useState<any>(0);
  const isEmptyGame = !listGame || listGame.length === 0;
  const [flagCount, setFlagCount] = useState<number>(0);
  const paramsUrl = useParams();

  const fetchListGame = async () => {

    if (loading) return;
    const newOffset = offset + 1;
    const params = { page: newOffset, limit: PER_PAGE, allowUser: true, sortBy: 'createdAt', direction: 'DESC' };
    try {
      const response = await getEventDayParticipant(paramsUrl?.id, params);
      if (response && response.data) {
        const newListGame = [...listGame, ...response.data];
        setFlagCount(flagCount + newListGame.length);
        if (flagCount >= response.total) {
          setHasMore(false);
        }
        setListGame(newListGame);
        setOffset(newOffset);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const rootRef = useRef<any>();

  return (
    <div
      className="all-game-mini"
      style={{ scrollMargin: "10rem" }}
      ref={rootRef}
    >
      {/* <img src={bgSymbol} alt="bg-symbol" className="bg-symbol" /> */}
      <div
        className="component-games top-free-game all-game-mobile"
        style={{ margin: 0 }}
      >
        {loading && (
          <div className="min-h-[50vh] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )}
        <>
          <div className="flex w-full justify-between rounded-t-[8px] bg-[#CDF3E2] p-5 text-[#111111] text-[12px] " >
            <div className="px-10">
              Time
            </div>
            <div className="px-10">
              Number
            </div>
          </div>
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchListGame}
            hasMore={hasMore}
            loader={<LoadingVerTwo />}
            // useWindow={false}
            threshold={50}
            // initialLoad={true}
            className="loadmore-games w-full"
          >
              {!isEmptyGame &&
                listGame?.map((game: any, index: any) => (
                  <div className={`flex w-full justify-between p-5 text-[#000000] text-[12px] ${index%2?'bg-[#F7F7F7]':''}`} key={index}>
                    <div className="px-10">
                      {handleShowTime((new Date(game.createdAt)).getHours())}:{handleShowTime((new Date(game.createdAt)).getMinutes())}
                    </div>
                    <div className="px-10">
                      {
                        game.ticketNumber
                      }
                    </div>

                  </div>
                ))}
            {
              isEmptyGame && <div className="text-center text-[14px]">No data</div>
            }
          </InfiniteScroll>
        </>
      </div>
    </div>
  );
};

export default ListAllTicket;
