import { PopUpLogin } from "components/button/LoginButton";
import LoadingIcon from "components/LoadingIcon";
import WalletNew from "components/walletNew";
import { swapImg } from "helpers/ExportImg";
import { AuthState, UserTokenSlice } from "interfaces";
import { getInfoUser, postWithdraw } from "pages/services/api";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { callback, setAuth } from "slices/authSlice";
import { NETWORKS } from "web3/connector";

import { CONTRACTS, useWeb3 } from "web3/web3";
import { SwapStyle } from "./styled";

const Swap: React.FC<{
  onClick?: () => void;
}> = (props) => {
  const { bluec, whitec, keos, keob, arrblue, arrwh, close } = swapImg;

  //connect wallet
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [valueToken, setValueToken] = useState<number>(0);
  const [tokenBpools, setTokenBpools] = useState<number>(0);

  const [minPoint, setMinPoint] = useState(10000);
  const [maxPoint, setMaxPoint] = useState(20000);
  const [rate, setRate] = useState(1);
  const [fee, setFee] = useState(10);

  const { bpoolsBalance: balanceAmount } = useSelector(
    (state: any) => state.userToken as UserTokenSlice
  );
  // const [balanceAmount, setBalanceAmount] = useState<number>(0);
  const { wallet, accessToken, user } = useSelector(
    (state: any) => state.auth as AuthState
  );

  const handleChangeToken = (e: any) => {
    setValueToken(+e.target.value);
    const { value } = e.target;

    // const num = value * 10;
    const num = value * rate * ((100 - (fee ? fee : 0)) / 100);

    setTokenBpools(+num);
  };

  // const getBalanceAmount = async (wallet: string) => {
  //   try {
  //     const balance = useWeb3.getTokenBalance(
  //       CONTRACTS[process.env.REACT_APP_NETWORK as NETWORKS].BPOOLS,
  //       wallet
  //     )

  //     setBalanceAmount(+balance);
  //   } catch (error: any) {
  //     console.log(error);
  //   }
  // };

  // const getPointUser = async () => {
  //   try {
  //     const response = await getInfoUser();

  //     dispatch(
  //       setAuth({
  //         user: response,
  //         wallet: response?.walletAddress,
  //       })
  //     );
  //   } catch (error: any) {
  //     console.log(error);
  //   }
  // };

  const getWithdrawConfig = async (valueToken: number, wallet: any) => {
    try {
      const result = await postWithdraw(valueToken, wallet);

      return result;
    } catch (err: any) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const [infoServer, setInfoServer] = useState("");

  const swapPointToToken = async () => {
    setIsLoading(true);
    try {
      const withDrawConfig = await getWithdrawConfig(valueToken, wallet);

      console.log("withDrawConfig", withDrawConfig);

      if (withDrawConfig) {
        // const response = await getInfoUser();
        // dispatch(
        //   setAuth({
        //     accessToken,
        //     user: response,
        //     wallet: response?.walletAddress,
        //   })
        // );

        const txReceipt = await useWeb3.swapPointToToken({
          amount: withDrawConfig.amount,
          block: withDrawConfig.block,
          signature: withDrawConfig.signature,
        });

        if (txReceipt) {
          toast.success("Swap success: " + txReceipt.transactionHash);
          setInfoServer("Swap success: " + txReceipt.transactionHash);
        }

        dispatch(callback());
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err.message);
      setInfoServer(err.message);
    }
    setValueToken(0);
    setIsLoading(false);
  };

  const handleInputWithdraw = (e: any) => {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
  };

  const onCloseLoading = () => {
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (wallet) getBalanceAmount(wallet);
  // }, [wallet]);

  // useEffect(() => {
  //   getPointUser();
  // }, []);

  return (
    <>
      {isLoading && (
        <LoadingIcon
          title="Please wait for the transaction to complete ..."
          closeLoading={onCloseLoading}
        />
      )}
      <SwapStyle>
        <div className="swap">
          <div className="swap-left">
            {/* <div className="swap-left-top">
              <div className="swap-left-top-img">
                <img src={play} alt="play" />
              </div>
              <div className="swap-left-top-img">
                <img src={share} alt="play" />
              </div>
              <div className="swap-left-top-img">
                <img src={ham} alt="play" />
              </div>
              <div className="swap-left-top-img">
                <img src={info} alt="play" />
              </div>
            </div> */}
            <div className="swap-left-center">
              <div
                // onClick={() => setOpenTokenOption(!openTokenOption)}
                className="swap-left-center-top"
              >
                <div className="swap-left-center-top-img">
                  <img src={keos} alt="img" />
                </div>
                <div className="swap-left-center-top-title">POINTS</div>
                {/* <div
                className="swap-left-center-top-img"
                style={{ cursor: "pointer" }}
              >
                <img src={arr} alt="img" />
              </div> */}
                {/* {openTokenOption && <TokenOption />} */}
              </div>
              <div className="swap-left-center-cen">
                <img src={arrwh} alt="img" />
              </div>
              <div className="swap-left-center-bot">
                <div className="swap-left-center-bot-img">
                  <img src={whitec} alt="img" />
                </div>
                <div className="swap-left-center-bot-title">BPOOLS</div>
              </div>
            </div>
            <div className="swap-left-bot">
              <div className="swap-left-bot-title">Information</div>
              <div className="swap-line"></div>
              {/* <div className="swap-left-bot-cen">
              <div className="swap-left-bot-cen-title">LPRS Price</div>
              <div className="swap-left-bot-cen-price">$1,200.23</div>
            </div> */}

              <div className="swap-left-bot-bot">
                <div className="swap-left-bot-cen-title">
                  1 POINT = 1 BPOOLS
                </div>
              </div>
              <div className="swap-left-bot-bot">
                <div className="swap-left-bot-cen-title">BPOOLS Price</div>
                <div className="swap-left-bot-cen-price">$0.1</div>
              </div>
            </div>
          </div>
          <div className="swap-right">
            <div className="swap-right-1">
              <div className="swap-right-1_top">
                <div className="swap-right-1-price">
                  <input
                    type="number"
                    min={0}
                    maxLength={10}
                    placeholder="0"
                    // onInput={() => handleOnInput(valueToken, 2)}
                    // value={valueToken}
                    defaultValue={valueToken}
                    onChange={handleChangeToken}
                    onKeyUp={handleInputWithdraw}
                  />
                </div>
                <div className="swap-right-1-token">
                  <div className="swap-right-1-token-img">
                    <img src={keob} alt="img" />
                  </div>
                  <div className="swap-right-1-title">POINTS</div>
                </div>
              </div>

              {/* {valueToken < minPoint ? (
                <p className="error-point">*Enter at least {minPoint} point</p>
              ) : valueToken > user?.pointPool?.point ? (
                <p className="error-point">*Not enough point</p>
              ) : null} */}

              {valueToken < minPoint ? (
                <p className="error-point">*Enter at least {minPoint} point</p>
              ) : valueToken > maxPoint ? (
                <p className="error-point">*Enter at must {maxPoint} point</p>
              ) : valueToken > user?.pointPool?.point ? (
                <p className="error-point">*Not enough point</p>
              ) : null}
            </div>
            <div className="swap-right-2">
              Balance: {user?.pointPool?.point?.toLocaleString("en-US") || 0}
            </div>
            <div className="swap-right-3">
              <img src={arrblue} alt="img" />
            </div>
            <div className="swap-right-4">
              <div className="swap-right-4-left">
                <div className="swap-right-4-left-num">{tokenBpools}</div>
                <div className="swap-right-4-left-price">
                  Balance: {balanceAmount}
                </div>
              </div>
              <div className="swap-right-4-right">
                <div className="swap-right-4-right-num">
                  <img src={bluec} alt="img" />
                </div>
                <div className="swap-right-4-right-price">BPOOLS</div>
              </div>
            </div>
            {!accessToken ? (
              <div
                onClick={() => {
                  PopUpLogin({ width: 500, height: 500 });
                }}
                className="swap-right-5 button_3d"
              >
                Sign In
              </div>
            ) : (
              <>
                {wallet ? (
                  <>
                    {valueToken >= minPoint &&
                    valueToken <= maxPoint &&
                    valueToken <= user?.pointPool?.point ? (
                      <button
                        onClick={() => swapPointToToken()}
                        style={{
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                        className="swap-right-5 button_3d"
                      >
                        SWAP
                      </button>
                    ) : (
                      <button
                        disabled={true}
                        style={{
                          cursor: "not-allowed",
                        }}
                        className="swap-right-5"
                      >
                        SWAP
                      </button>
                    )}
                  </>
                ) : (
                  <WalletNew className="swap-right-5" />
                )}
                {infoServer && (
                  <div className="messsage-server">{infoServer}</div>
                )}
              </>
            )}
          </div>

          <div onClick={props.onClick} className="swap-btn-close">
            <img src={close} alt="close" />
          </div>
        </div>
      </SwapStyle>
    </>
  );
};

export default Swap;
