import styled from "styled-components";

export const ShopB = styled.section`
  max-width: 414px;
  background: #ffffff;
  border: 4px solid #00896f;
  box-shadow: 0px 6px 0px #00896f;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin-bottom: 5px;

  :hover {
    transform: translateY(-15px);
    transition: all 0.5s ease-in-out;
  }

  .shop-item {
    width: 80%;
  }

  .shop-img {
    max-width: 344px;
  }

  .shop-img img {
    width: 100%;
  }

  .shop-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    text-transform: uppercase;
    color: #000000;
    margin: 20px 0;
  }

  .shop-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .shop-bot-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    text-transform: uppercase;
    color: #ff6700;
  }

  .shop-plus {
    max-width: 35px;
    cursor: pointer;
  }

  .shop-plus img {
    width: 100%;
  }

  @media (max-width: 600px) {
    .shop-title {
      font-size: 14px;
    }

    .shop-bot-title {
      font-size: 12px;
    }

    .shop-plus {
      max-width: 15px;
    }
  }
`;

export const ShopTag = styled.section`
  position: relative;

  .shop-ticket--title {
    font-style: normal;
    font-weight: 700;
    /* font-size: 6.4rem; */
    font-size: 4rem;
    line-height: 9.8rem;
    text-align: center;
    color: #007b63;
  }

  .shop-ticket--img_amount {
    background: #e9e9e9;
    box-shadow: inset 0px 6.58296px 9.87444px 1.64574px rgba(0, 0, 0, 0.16);
    border-radius: 16.4574px;

    padding: 2rem 3rem 3rem;
  }

  .shop-ticket--img {
    max-width: 23rem;
    height: 19rem;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .shop-ticket--amount {
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 6.1rem;
    text-align: right;
    color: #ff6700;
  }

  .shop-ticket--price {
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 6.1rem;
    text-align: center;
    color: #ffffff;
    width: 85%;

    background: #ff6700;
    box-shadow: 0px 6.58296px 6.58296px rgba(0, 0, 0, 0.25);
    border-radius: 13.1659px;

    position: absolute;
    left: 50%;
    bottom: -20%;
    transform: translate(-50%, -50%);

    :active {
      transform: translate(-50%, -50%) scale(0.98);
    }
  }

  @media (max-width: 768px) {
  }
`;
