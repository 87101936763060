import React, { useContext, useRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import appContext from "appContext";
import { useNavigate } from "react-router";
import { discover, genrePath } from "constants/path";

const InfoGameMini = ({ data, rate }: any) => {
  const genreRef = useRef<any>();

  const { setCategories } = useContext(appContext);
  const navigate = useNavigate();

  const clickBtnGenre = (category: any) => {
    genreRef.current?.classList.add("click-genre");
    setTimeout(() => {
      genreRef.current?.classList.remove("click-genre");
      setCategories(category.id);
      navigate(genrePath);
      sessionStorage.setItem("activeSlide", "1");
    }, 300);
  };

  return (
    <div className="wrapper-info-game">
      <div className="header-info ">
        <h1>categories</h1>
        <div className="btn-categories">
          {data?.categories?.map((category: any) => (
            <div
              key={category.id}
              className="current-genre"
              onClick={() => clickBtnGenre(category)}
            >
              <span ref={genreRef} />
              <p>{category.name}</p>
            </div>
          ))}
        </div>
      </div>
      {/* <div className='rating-total'>
                <div className='header-rating'>
                    <h1>ratings</h1>
                    <div>
                        <span>Overall: </span>
                        <StarIcon />
                    </div>
                </div>
                <div>
                    <div></div>
                </div>
            </div> */}
    </div>
  );
};

export default InfoGameMini;
