import styled from "styled-components";


export const ProfileWraper = styled.section`
  .profile-addres{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Ver 1/Headline/Heading 2 - regular */
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
  }

  .profile-title{
    color: var(--Grey-Grey-90, var(--Grey-100, #111));
    /* Poppin/Subtitle/Subtitle 2 - semi */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    }

    .profile-item{
        color: var(--Black, var(--Grey-60, #626262));
        /* new-content */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */ 
    }
`;
