import App from "App";
import { store } from "app/store";
import ReactDOM from "react-dom/client";
import { Provider as StoreProvider, TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { WagmiConfig } from "wagmi";
import { client } from "web3/wagmi";
import * as buffer from "buffer";
import type { RootState, AppDispatch } from './app/store'


import "./index.css";

window.Buffer = buffer.Buffer;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <WagmiConfig client={client}>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </WagmiConfig>
);

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector