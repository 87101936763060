import search from "asset/navbar/search.png";
import notify from "asset/navbar/noti-new.png";
import no_notify from "asset/navbar/no-noti-new.png";
import bag from "asset/navbar/bag.png";
import web from "asset/navbar/web.png";
import cen from "asset/navbar/ticket.png";
import ruong from "asset/navbar/point.png";
import bgcen from "asset/navbar/1.png";
import bgruong from "asset/navbar/3.png";

import logo from "asset/sliderbar/logo.png";
import avatar from "asset/sliderbar/avatar.png";
import pools from "asset/sliderbar/pools.png";
import coinDes from "asset/sliderbar/coin-des.png";

import shop from "asset/shop/no-shop.png";
import ticket from "asset/shop/ticket.png";

import home from "asset/sliderbar/home.svg";
import homeActive from "asset/sliderbar/home-active.svg";
import ballon from "asset/sliderbar/ballon.svg";
import ballonActive from "asset/sliderbar/ballon-active.svg";
import menu from "asset/sliderbar/menu.svg";
import menuActive from "asset/sliderbar/menu-active.svg";
import gameControl from "asset/sliderbar/game-control.svg";
import gameControlActive from "asset/sliderbar/game-control-active.svg";
import headphone from "asset/sliderbar/headphone.svg";
import headphoneActive from "asset/sliderbar/headphone-active.svg";

import box from "asset/home/box-banner.png";
import boxMobile from "asset/home/box-mobile.png";
import bgSymbol from "asset/home/bg-symbol.png";
import note from "asset/home/note.png";
import webIcon from "asset/home/web.png";
import coin from "asset/home/coin.png";
import prev from "asset/home/prev.png";
import next from "asset/home/next.png";
import star from "asset/home/star.png";
import playGame from "asset/home/play-game.png";
import freeGame from "asset/home/free-game.png";
import shark from "asset/home/shark.png";
import starCard from "asset/home/star-card.png";
import coinHaloGame from "asset/home/coin-halo.png";
import marbleGame from "asset/home/marble.png";
import pilot from "asset/home/pilot.png";
import bingoPopular from "asset/home/bingo-popular.png";
import pmgPopular from "asset/home/PMGgame.png";
import rpsPopular from "asset/home/RPSGame.png";
import marblePopular from "asset/home/marble-game.png";
import haloPopular from "asset/home/halo-popular.png";
import upload from "asset/home/upload.png";
import symbolFinal from "asset/home/symbol-final.png";

import legend from "asset/games-center/legend.png";
import bingo from "asset/games-center/bingo.png";
import marble from "asset/games-center/marble.png";
import plusMinus from "asset/games-center/plus-minus.png";
import coinHalo from "asset/games-center/coin-halo.png";
import bannerLegend from "asset/games-center/banner-legend.png";
import bannerBingo from "asset/games-center/banner-bingo.png";

import notePopular from "asset/popular/note.png";
import webPopular from "asset/popular/web.png";
import coinPopular from "asset/popular/coin.png";

import control from "asset/footer/1.png";
import controlMobile from "asset/footer/control-mobile.png";
import bgFooter from "asset/footer/bg-footer.png";

import action from "asset/genre/action.png";
import shooting from "asset/genre/shooting.png";
import sport from "asset/genre/sport.png";
import educational from "asset/genre/educational.png";

import Shop from "asset/shop/no-shop.png";
import pool from "asset/shop/pool.png";
import plus from "asset/shop/plus.png";

import cup from "asset/result-game/cup.png";
import king from "asset/result-game/king.png";
import medal from "asset/result-game/medal.png";
import hourglass from "asset/result-game/hourglass.png";

import share from "asset/swap/share.png";
import info from "asset/swap/info.png";
import play from "asset/swap/play.png";
import ham from "asset/swap/ham.png";
import arr from "asset/swap/arr.png";
import bluec from "asset/swap/poolb.png";
import whitec from "asset/swap/pools.png";
import keos from "asset/swap/keos.png";
import keob from "asset/swap/keob.png";
import arrwh from "asset/swap/arrwh.png";
import arrblue from "asset/swap/arrblue.png";
import close from "asset/swap/close.png";

import metaMask from "asset/wallet/metamask.svg";
import coinbaseWallet from "asset/wallet/coinbase.svg";
import walletConnect from "asset/wallet/wallet.svg";

import whitepaper from "asset/nft-game/white.png";
import download from "asset/nft-game/down.png";
import token from "asset/nft-game/token.png";
import website from "asset/nft-game/world.png";
import sns from "asset/nft-game/sns.png";
import commu from "asset/nft-game/commu.png";
import back from "asset/nft-game/2.png";
import nextBtn from "asset/nft-game/1.png";
import gamebo from "asset/nft-game/game.png";
import nextNft from "asset/nft-game/1.png";
import prevNft from "asset/nft-game/2.png";
import left from "asset/nft-game/left.png";
import center from "asset/nft-game/center.png";
import right from "asset/nft-game/right.png";
import bg_next_mobile from "asset/nft-game/bg_next_mobile.png";
import bg_back_mobile from "asset/nft-game/bg_back_mobile.png";
import next_unactive from "asset/nft-game/next_un.png";
import prev_unactive from "asset/nft-game/prev_un.png";

import shopDesk from "asset/navbar/shop-desk.png";
import shopMo from "asset/navbar/shop-mobile1.png";
import NFTgame from "asset/navbar/2.png";
import playgame from "asset/navbar/playgame.png";

import banner from "asset/slide-hero/banner.png";

import popupImg from "asset/popup/popup.png";
import popupImgMobile from "asset/popup/popupMobile.png";

import currency from "asset/icons/currency.png";
import coinPopup from "asset/icons/coin-popup.png";

import top1 from "asset/events/top1.png";
import top2 from "asset/events/top2.png";
import top3 from "asset/events/top3.png";
import iconTop1 from "asset/events/iconTop1.png";
import iconTop2 from "asset/events/iconTop2.png";
import iconTop3 from "asset/events/iconTop3.png";
import claime from "asset/events/claime.png";
import ticket1 from "asset/events/ticket1.png";



import bannerAds from "asset/ads/banner-ads.png"

import guideline1 from "asset/guideline/en/guideline-1.png"
import guideline2 from "asset/guideline/en/guideline-2.png"
import guideline3 from "asset/guideline/en/guideline-3.png"
import guideline4 from "asset/guideline/en/guideline-4.png"
import guideline5 from "asset/guideline/en/guideline-5.png"
import guideline6 from "asset/guideline/en/guideline-6.png"
import guideline7 from "asset/guideline/en/guideline-7.png"
import guideline8 from "asset/guideline/en/guideline-8.png"
import guideline9 from "asset/guideline/en/guideline-9.png"
import guideline10 from "asset/guideline/en/guideline-10.png"
import guideline11 from "asset/guideline/en/guideline-11.png"
import guideline12 from "asset/guideline/en/guideline-12.png"
import guideline13 from "asset/guideline/en/guideline-13.png"
import guideline14 from "asset/guideline/en/guideline-14.png"
import guideline15 from "asset/guideline/en/guideline-15.png"
import guideline16 from "asset/guideline/en/guideline-16.png"
import guideline17 from "asset/guideline/en/guideline-17.png"
import guideline18 from "asset/guideline/en/guideline-18.png"


import guidelineKo1 from "asset/guideline/ko/guideline-1.png"
import guidelineKo2 from "asset/guideline/ko/guideline-2.png"
import guidelineKo3 from "asset/guideline/ko/guideline-3.png"
import guidelineKo4 from "asset/guideline/ko/guideline-4.png"
import guidelineKo5 from "asset/guideline/ko/guideline-5.png"
import guidelineKo6 from "asset/guideline/ko/guideline-6.png"
import guidelineKo7 from "asset/guideline/ko/guideline-7.png"
import guidelineKo8 from "asset/guideline/ko/guideline-8.png"
import guidelineKo9 from "asset/guideline/ko/guideline-9.png"
import guidelineKo10 from "asset/guideline/ko/guideline-10.png"
import guidelineKo11 from "asset/guideline/ko/guideline-11.png"
import guidelineKo12 from "asset/guideline/ko/guideline-12.png"
import guidelineKo13 from "asset/guideline/ko/guideline-13.png"
import guidelineKo14 from "asset/guideline/ko/guideline-14.png"
import guidelineKo15 from "asset/guideline/ko/guideline-15.png"
import guidelineKo16 from "asset/guideline/ko/guideline-16.png"
import guidelineKo17 from "asset/guideline/ko/guideline-17.png"
import guidelineKo18 from "asset/guideline/ko/guideline-18.png"




export const popup = { popupImg, popupImgMobile };

export const logos = {
  currency,
  coinPopup
}

export const guideline = {
  en: {
    guideline1,
    guideline2,
    guideline3,
    guideline4,
    guideline5,
    guideline6,
    guideline7,
    guideline8,
    guideline9,
    guideline10,
    guideline11,
    guideline12,
    guideline13,
    guideline14,
    guideline15,
    guideline16,
    guideline17,
    guideline18
  },

  ko: {
    guideline1: guidelineKo1,
    guideline2: guidelineKo2,
    guideline3: guidelineKo3,
    guideline4: guidelineKo4,
    guideline5: guidelineKo5,
    guideline6: guidelineKo6,
    guideline7: guidelineKo7,
    guideline8: guidelineKo8,
    guideline9: guidelineKo1,
    guideline10: guidelineKo10,
    guideline11: guidelineKo11,
    guideline12: guidelineKo12,
    guideline13: guidelineKo13,
    guideline14: guidelineKo14,
    guideline15: guidelineKo15,
    guideline16: guidelineKo16,
    guideline17: guidelineKo17,
    guideline18: guidelineKo18,
  }
}



export const eventsImg = {
  top1,
  top2,
  top3,
  iconTop1,
  iconTop2,
  iconTop3,
  claime,
  ticket1
}

export const slideNFT = {
  whitepaper,
  download,
  token,
  website,
  sns,
  commu,
  back,
  nextBtn,
  gamebo,
  nextNft,
  prevNft,
  left,
  center,
  right,
  bg_back_mobile,
  bg_next_mobile,
  next_unactive,
  prev_unactive,
  banner,
};

export const headerImg = {
  search,
  notify,
  bag,
  web,
  no_notify,
  cen,
  ruong,
  playgame,
};

export const slidebarImg = {
  home,
  homeActive,
  ballon,
  ballonActive,
  menu,
  menuActive,
  gameControl,
  gameControlActive,
  headphone,
  headphoneActive,
  logo,
  avatar,
  pools,
  coinDes,
  shop,
};

export const homeImg = {
  box,
  boxMobile,
  bgSymbol,
  webIcon,
  note,
  coin,
  prev,
  next,
  star,
  playGame,
  freeGame,
  shark,
  coinHaloGame,
  starCard,
  marbleGame,
  pilot,
  bingoPopular,
  haloPopular,
  upload,
  symbolFinal,
  pmgPopular,
  rpsPopular,
  marblePopular,
};

export const games = {
  legend,
  bingo,
  marble,
  plusMinus,
  coinHalo,
  bannerLegend,
  bannerAds,
};

export const adsImg = {
  bannerAds
}

export const popularImg = {
  notePopular,
  webPopular,
  coinPopular,
};

export const footerImg = {
  control,
  controlMobile,
  bgFooter,
};

export const genreImg = {
  action,
  sport,
  shooting,
  educational,
};

export const shopImg = {
  Shop,
  pool,
  plus,
  shopDesk,
  shopMo,
  NFTgame,
  ticket,
};

export const resultGameImg = {
  cup,
  king,
  medal,
  hourglass,
};

export const swapImg = {
  share,
  info,
  play,
  ham,
  arr,
  bluec,
  whitec,
  keos,
  keob,
  arrblue,
  arrwh,
  close,
};

export const walletImg = {
  metaMask,
  coinbaseWallet,
  walletConnect,
};
