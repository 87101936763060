import appContext from "appContext";
import LoadingIcon from "components/LoadingIcon";
import PaginationGame from "components/Pagination";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import TertiaryCard from "components/games/TertiaryCard";
import { homeImg, slidebarImg } from "helpers/ExportImg";
import useWindowSize from "hooks/useWindowSize";
import { getListGames } from "pages/services/api";
import { useSelector } from "react-redux";
import LoadingVerTwo from "components/loadingVerTwo/LoadingVerTwo";

const GenreAllGame = ({ genres }: any) => {
  const [page, setPage] = useState(1);
  const handleChagePage = (value: number) => {
    setPage(value);
  };
  const { limit, categories, infoGenre } = useContext(appContext);
  const { games, isLoading } = useSelector((state: any) => state.games);
  const count = Math.floor(games?.total / limit);

  useEffect(() => {
    const params = { page, limit, categories };
    getListGames(params);
  }, [page, categories]);

  const width: any = useWindowSize();

  const { bgSymbol, symbolFinal } = homeImg;
  const { ballon } = slidebarImg;

  const PER_PAGE = 20;
  const [listGame, setListGame] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [hasMore, setHasMore] = useState<any>(true);
  const totalCount = games?.total;
  const [offset, setOffset] = useState<any>(0);
  const isEmptyGame = !listGame || listGame.length === 0;
  const [flagCount, setFlagCount] = useState<number>(0);

  const fetchListGame = async () => {
    if (loading) return;
    setLoading(true);

    const newOffset = offset + 1;

    const params = { page: newOffset, limit: PER_PAGE };

    try {
      const response = await getListGames(params);

      if (response && response.data) {
        const newListGame = [...listGame, ...response.data];

        setFlagCount(flagCount + newListGame.length);

        if (flagCount >= totalCount) {
          setHasMore(false);
        }

        setListGame(newListGame);

        setOffset(newOffset);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  // useEffect(() => {
  //   fetchListGame();
  // }, []);
  const rootRef = useRef<any>();

  useEffect(() => {
    const handleFilter = async () => {
      const newOffset = offset + 1;
      const params = { page: page, limit: PER_PAGE, categories };
      const filterGame = await getListGames(params);
      setListGame(filterGame?.data);
      sessionStorage.setItem("listGames", JSON.stringify(filterGame?.data));
      setOffset(newOffset);

      try {
      } catch (error) {
        console.log(error);
      }
    };

    handleFilter();
  }, [page, categories]);

  return (
    <div
      className="all-game-mini"
      style={{ scrollMargin: "10rem" }}
      ref={rootRef}
    >
      {/* <img src={bgSymbol} alt="bg-symbol" className="bg-symbol" /> */}
      <div
        className="component-games top-free-game all-game-mobile"
        style={{ margin: 0 }}
      >
        {isLoading && (
          <div className="min-h-[50vh] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )}
        <>
          {/* <div
            className="header-games all-game-header"
            style={{
              margin: "3rem 0",
            }}
          >
            <div className="header-left">
              <img src={categories ? infoGenre?.image : ballon} alt="" />
              {infoGenre ? (
                <span>{infoGenre?.name}</span>
              ) : (
                <span>All games</span>
              )}
            </div>
          </div> */}
          {width > 1000 ? (
            <div className="flex-center wrapper-cards cards-free-game  card-genre-game-custom ">
              {games?.data?.map((game: any) => (
                <div
                  data-id={game?.id}
                  // className="card-genre-game"
                  className="card-all-games"
                  key={game?.id}
                >
                  <TertiaryCard game={game} />
                </div>
              ))}
            </div>
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchListGame}
              hasMore={hasMore}
              loader={<LoadingVerTwo />}
              // useWindow={false}
              threshold={350}
              // initialLoad={true}
              className="loadmore-games"
            >
              <div className="flex-center wrapper-cards cards-free-game cards-free-game-loadmore">
                {!isEmptyGame &&
                  listGame?.map((game: any, id: any) => (
                    <div className="card-genre-game" key={id}>
                      <TertiaryCard game={game} />
                    </div>
                  ))}
                {
                  isEmptyGame && <div className="text-center text-[14px]">No data</div>
                }
              </div>
            </InfiniteScroll>
          )}
        </>
      </div>
      {width > 1000 ? (
        <div style={{ marginBottom: "10rem" }}>
          <PaginationGame count={count} page={page} setPage={handleChagePage} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default GenreAllGame;
