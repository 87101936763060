import styled from "styled-components";

export const FullScreenTag = styled.section`
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  max-width: 4rem;
  cursor: pointer;
  top: 8%;
  right: 3%;
  position: fixed;
  z-index: 999999999;

  img {
    width: 100%;
  }

  .icon {
    font-size: 25px;
  }

  .icon:hover {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 767px) {
    max-width: 6rem;
    top: 6%;
    right: 6.5%;
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    max-width: 6rem;
    top: 5%;
    right: 5%;
  }
`;

export const NextGameTag = styled.section`
  z-index: 999999;
  top: 13%;
  right: 3%;
  position: fixed;
  cursor: pointer;

  .btn-next-game {
    max-width: 4rem;

    img {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    top: 12%;
    right: 6.5%;

    .btn-next-game {
      max-width: 6rem;
    }
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    top: 19%;
    right: 5%;

    .btn-next-game {
      max-width: 6rem;
    }
  }
`;

export const HomeTag = styled.section`
  z-index: 999999;
  position: fixed;
  cursor: pointer;
  background: #FFFFFF;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center; 
`;
