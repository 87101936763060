import ConnectWalletBtn from "components/wallet/ConnectWalletBtn";
import { useAppSelector } from "index";
import { PopupTag } from "./styled";




const PopupConnect = (props: any) => {

  const PopupConnectState = useAppSelector(state => state.popupConnectState);



  return (
    <PopupTag>

      <>
        {PopupConnectState.show && (
          <>
            <div className={`backdrop`}></div>
            <div className="popup-img">
              <div className={`popup img-mobile min-w-[80%]`}>
                <>
                  <div className="bg-[#F0F0F0] rounded-3xl p-14">

                    <div className="popup-title">Connect wallet</div>
                    <div className="popup-text text-center mt-5">Start by connecting Pools Wallet. Be sure to store your private keys or seed phrase securely. Never share them with anyone.</div>
                    <div className="mt-5">
                    <ConnectWalletBtn />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupConnect;
