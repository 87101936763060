import React, { FC, useEffect, useRef, useState } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { useNavigate } from "react-router";

import { FullScreenTag, HomeTag, NextGameTag } from "./styled";
import nextImg from "../../asset/screen/next.png";
import homeImg from "../../asset/screen/playground-logo.png";
import unfull from "../../asset/screen/unfull.png";
import full from "../../asset/screen/full.png";

type FullScreenType = {
  className?: any;
  onClick?: () => void;
  isFullScreen?: Boolean;
};
export const FullScreen: FC<FullScreenType> = ({
  className,
  onClick,
  isFullScreen,
}) => {
  return (
    <FullScreenTag
      className={`${className} full-screen`}
      style={{ color: "#ffffff", cursor: "pointer" }}
      onClick={onClick}
    >
      {isFullScreen ? (
        <img src={unfull} alt="unfull" />
      ) : (
        <img src={full} alt="full" />
      )}
    </FullScreenTag>
  );
};

export const NextGameBtn: any = (props: any) => {
  const { handleNextGame } = props;

  return (
    <NextGameTag>
      <div className="btn-next-game" onClick={handleNextGame}>
        <img src={nextImg} alt="next game" />
      </div>
    </NextGameTag>
  );
};

export const HomeBtn: any = (props: any) => {
  const homeBtnRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState({ top: 10, left: 10 });
  const [isMove, setIsMove] = useState(false);
  const navigate = useNavigate();
  let move = false

  useEffect(() => {
    let w = window.innerWidth - 40 ;
    let h = window.innerHeight - 40;
    if (homeBtnRef && homeBtnRef.current) {
      homeBtnRef.current.addEventListener("mousedown", () => { move = true });
      homeBtnRef.current.addEventListener("mouseup", () => { move = false });
      homeBtnRef.current.addEventListener("touchstart", () => { move = true });
      homeBtnRef.current.addEventListener("touchend", () => { move = false }
      );
    }

    document.addEventListener('mousemove', (e) => {
      if (homeBtnRef && homeBtnRef.current && move) {
        setPosition({ top: Math.min(Math.max(e.clientY - 20, 0), h), left: Math.min(Math.max(e.clientX - 20, 0),w) })
      }
    })

    document.addEventListener('touchmove', (e) => {
      if (homeBtnRef && homeBtnRef.current && move) {
        setPosition({ top: Math.min(Math.max(e.touches[0].clientY - 20, 0),h), left: Math.min(Math.max(e.touches[0].clientX - 20, 0),w) })
      }
    })
  }, [homeBtnRef]);

  return (
    <HomeTag ref={homeBtnRef} style={{
      position: 'fixed',
      top: position.top,
      left: position.left,
      backgroundColor: '#FFFFFF',
      borderRadius: 9000,
      border: '1px solid rgba(6, 194, 112, 0.50)',
      padding: 10,
    }}>
      <div
        onClick={() => navigate("/")}>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_796_6250)">
            <path d="M7.24109 19.6622L7.23859 19.6638C6.56486 20.077 5.79497 20.3 5.01 20.3C3.87165 20.3 2.78911 19.852 1.99355 19.0564C1.198 18.2609 0.75 17.1783 0.75 16.04V8.8C0.75 7.44278 1.2872 6.14991 2.24355 5.19355C3.19991 4.2372 4.49278 3.7 5.85 3.7H18.65C20.0072 3.7 21.3001 4.2372 22.2564 5.19355C23.2128 6.14991 23.75 7.44278 23.75 8.8V16.04C23.75 17.1783 23.302 18.2609 22.5064 19.0564C21.7113 19.8516 20.6189 20.3 19.49 20.3C18.7068 20.3 17.937 20.0779 17.2522 19.6643C16.5823 19.2436 16.0461 18.654 15.6874 17.9468L15.4088 17.3795L15.2715 17.1H14.96H9.54H9.22854L9.09122 17.3795L8.81259 17.9468C8.45212 18.6576 7.91516 19.2543 7.24109 19.6622ZM22.0806 18.6365L22.0806 18.6366L22.0865 18.6306C22.7677 17.9379 23.15 17.0123 23.15 16.04V8.8C23.15 7.60815 22.6779 6.46075 21.8336 5.61645C20.9893 4.77215 19.8419 4.3 18.65 4.3H5.85C4.65815 4.3 3.51075 4.77215 2.66645 5.61645C1.82214 6.46075 1.35 7.60815 1.35 8.8V16.04C1.35 17.0123 1.73235 17.9379 2.41349 18.6306L2.41344 18.6306L2.41943 18.6365C3.11211 19.3177 4.03769 19.7 5.01 19.7C5.67915 19.7 6.35188 19.516 6.92531 19.1538C7.49952 18.7973 7.97422 18.2896 8.27721 17.6836L8.27896 17.6801L8.77393 16.6703C8.80412 16.6167 8.84326 16.5759 8.89205 16.5458C8.94471 16.5134 8.99424 16.5 9.05 16.5H15.45C15.5058 16.5 15.5553 16.5134 15.608 16.5458C15.6567 16.5759 15.6959 16.6167 15.7261 16.6703L16.221 17.6801L16.221 17.6801L16.2245 17.687C16.8483 18.9114 18.1085 19.7 19.49 19.7C20.4623 19.7 21.3879 19.3177 22.0806 18.6365Z" fill="#06C270" stroke="#06C270" />
            <path d="M21.4801 5.97C20.7301 5.22 19.7101 4.8 18.6501 4.8H5.8501C4.7901 4.8 3.7701 5.22 3.0201 5.97C2.2701 6.72 1.8501 7.74 1.8501 8.8V16.04C1.8501 16.88 2.1801 17.68 2.7701 18.28C3.3701 18.87 4.1701 19.2 5.0101 19.2C5.5901 19.2 6.1701 19.04 6.6601 18.73C7.1601 18.42 7.5701 17.98 7.8301 17.46L8.3301 16.44C8.4001 16.31 8.5001 16.2 8.6301 16.12C8.7601 16.04 8.9001 16 9.0501 16H15.4501C15.6001 16 15.7401 16.04 15.8701 16.12C16.0001 16.2 16.1001 16.31 16.1701 16.44L16.6701 17.46C17.2101 18.52 18.3001 19.2 19.4901 19.2C20.3301 19.2 21.1301 18.87 21.7301 18.28C22.3201 17.68 22.6501 16.88 22.6501 16.04V8.8C22.6501 7.74 22.2301 6.72 21.4801 5.97ZM9.8501 11.2H8.2501V12.8H6.6501V11.2H5.0501V9.6H6.6501V8H8.2501V9.6H9.8501V11.2ZM16.2501 12.8H14.6501V11.2H16.2501V12.8ZM19.4501 9.6H17.8501V8H19.4501V9.6Z" fill="#06C270" />
          </g>
          <defs>
            <clipPath id="clip0_796_6250">
              <rect width="24" height="24" fill="white" transform="translate(0.25)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </HomeTag>
  );
};
