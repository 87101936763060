import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {pageState } from "interfaces";

const initialState: pageState = {
  title: 'Hello',
  showAccount: false,
  showTitle: false,
  tab: 1,
  iconHeader: 0,
  background: '#FFFFFF',
  backgroundHeader: '#FFFFFF'
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPageState: (state: pageState, action: PayloadAction<pageState>) => {
      return {
        ...state,
        showTitle: action.payload.showTitle,
        showAccount: action.payload.showAccount,
        tab: action.payload.tab, 
        title: action.payload.title,
        background: action.payload.background,
        iconHeader: action.payload.iconHeader || 0,
        backgroundHeader: action.payload.backgroundHeader,

      };
    },
  },
});

export const {
  setPageState
} = pageSlice.actions;

export const pageReducer = pageSlice.reducer;
