import { toast } from "react-toastify";

export const showNotification = (type: "success" | "error", message: any) => {
  if (typeof message === "string") {
    return toast[type](message);
  }
  if (message?.length > 0) {
    message.forEach((mes: string) => {
      toast[type](mes);
    });
  }
};

export const handleShowTime = (num: number) => {
  if(num >= 0 && num < 10 ) return '0' + num
  if(num < 0) return '00'
  return num
}


export const  capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}



export const setCData = (number, time) => {
  const dataToSave = {
    cTime: time,
    cNumber: number,
  };
  localStorage.setItem('cData', JSON.stringify(dataToSave));
}

export const getCData = () => {
  const savedDataString = localStorage.getItem('cData');
  if (savedDataString) {
    const savedData = JSON.parse(savedDataString);
    return savedData;
  } else {
    // Handle case where no data is saved
    return null;
  }
}


export const  isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}