
import { discover } from "constants/path";
import { Link } from "react-router-dom";
import { ellipsisMiddle } from "web3/connector";
import { GameCardNew } from "./styled";

const GameCardMini: any = ({ game }: any) => {


  const { image, id, name, thumbnail, rewardPoint, game_mission, categories, playCount } = game;

  const nameRep = name.replaceAll(" ", "-").toLowerCase();
  //const sum = game_mission.reduce((accumulator: number, currentObject: { pointReward: number; }) => accumulator + currentObject.pointReward, 0);

  return (
    <>
      <GameCardNew>
        <Link onClick={async () => {  }} to={`${discover}/${id}`}>
          <div className="inline-block rounded-xl">
            <div className="flex items-center w-[300px] h-[85px]  overflow-hidden rounded-xl " >
              <div className="flex-shrink-0 ml-0">
                <img className="w-[80px] h-[80px] rounded-xl" src={image || thumbnail}
                  alt="img" />
              </div>
              <div className="flex-1 min-w-0 ms-4 ml-6 space-y-2">
                <div className="text-left mt-4">
                  {categories && categories[0] && categories[0].name && (<span className=" text-type  p-2">{categories[0].name}</span>)}
                </div>
                <p className="game-card-new-title pt-2">
                  {ellipsisMiddle(name, 20, -Infinity)}
                </p>
                <div className="game-card-new-play-count flex  items-center	pt-1">
                  <div className="" >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      fill="none"
                    >
                      <path
                        stroke="#7E7E7E"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10 10.5v-1a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v1M6 5.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                      />
                    </svg>
                  </div>
                  <div className="ml-2 game-card-new-play-count-text flex"> Play count: {playCount}</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </GameCardNew>
    </>
  );
};

export default GameCardMini;