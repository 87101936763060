
import { discover, openSourcePath } from "constants/path";
import { Link } from "react-router-dom";
import { GameCardHistoryWraper } from "./styled";

const GameCardRecommend: any = ({ game }: any) => {


  const { image, id, name, thumbnail , playCount, gameType} = game;
  const {updatedAt} = game
  const nameRep = name.replaceAll(" ", "-").toLowerCase();
  // const sum = game_mission.reduce((accumulator: number, currentObject: { pointToPass: number; }) => accumulator + currentObject.pointToPass, 0);
  const getTime = (time: string | number | Date)=>{
      // Lấy thời điểm cụ thể
      const targetTime = new Date(time);

      // Lấy timestamp của thời điểm cụ thể
      const targetTimeInMillis = targetTime.getTime();

      // Lấy timestamp của thời điểm hiện tại
      const nowInMillis = new Date().getTime();

      // Tính thời gian theo giờ tới thời điểm cụ thể
      const remainHours = (nowInMillis - targetTimeInMillis) / (1000 * 60 * 60);
      return remainHours.toFixed(2)
  }


  const getLinkPlay = ()=>{
    if(gameType==='game'){
      return `${openSourcePath}/${nameRep}/${id}`
    }
    if(gameType==='miniGame') return `${discover}/${id}`
    return '/'
}
  return (
    <>
      <GameCardHistoryWraper>
        <Link to={getLinkPlay()}>
          <div className="inline-block w-full">
            <div className="flex items-center  w-[300px] h-[76px]   overflow-hidden rounded-lg " >
              <div className="flex-shrink-0 ">
                <img className="w-[60px] h-[60px] rounded-lg" src={image || thumbnail}
                  alt="img" />
              </div>
              <div className="flex-1 min-w-0 ms-4 ml-6">
                <p className="game-card-new-title">
                  {name}
                </p>
                <div className="game-card-new-play-count flex  items-center	">
                  <div className="" >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={12}
                        height={12}
                        fill="none"
                      >
                        <path
                          stroke="#7E7E7E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10 10.5v-1a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v1M6 5.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                        />
                      </svg>
                  </div>
                  <div className="ml-2 game-card-new-play-count-text flex"> Play count: {playCount}</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </GameCardHistoryWraper>
    </>
  );
};

export default GameCardRecommend;
