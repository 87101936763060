import { FullScreen, HomeBtn, NextGameBtn } from "components/buttonScreen";
import OverLayPlayGame from "components/overlayPlayGame/OverLayPlayGame";
import ResultGame from "components/resultGame";
import { openSourcePath } from "constants/path";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setDetailOpen } from "slices/gameOpenSource";
import {
  addGameToHistory,
  getGamesOpenSourceDetail,
  getListGamesOpenSource,
} from "./services/api";
import { GameTag } from "./styled";
import OverLayPlayGameResult from "components/overlayPlayGame/OverLayPlayGameResult";
import RewardClaime from "components/overlayPlayGame/RewardClaime";
import PopupAds from "components/popupAds";
import useScreenSize from "hooks/useScreenSize";

const PlayOpenSource = () => {
  const dispatch = useDispatch();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showOverlayResult, setShowOverlayResult] = useState(false);

  const [showNextClaime, setSshowNextClaime] = useState(false);

  const [detailGameResult, setDetailGameResult] = useState<any>();
  const [iframeKey, setIframeKey] = useState(0);

  const params = useParams();
  const currentID = params.id;
  React.useEffect(() => {
    setShowOverlay(true);
  }, [params.id]);
  // const [game, setGame] = useState<any>(null);

  const { user } = useSelector((state: any) => state.auth);
  const { detailGameOpen, listGame } = useSelector(
    (state: any) => state.gameOpenSource
  );

  const handleFetchGameDetail = async () => {
    setShowOverlay(false);
    try {
      if (params.id) {
        const data = await getGamesOpenSourceDetail(+params?.id, user?.id || 0);
        dispatch(setDetailOpen(data));
        setShowOverlay(true);
        // setGame(data);
      }
    } catch (err) {
      setShowOverlay(false);
      toast.error("Get game failed, please try again !!!!");
      console.log(err);
    }
  };

  const [fullscreen, setFullScrenn] = useState(true);

  // React.useEffect(() => {
  //   let idRoot = document.getElementsByTagName("BODY")[0];
  //   if (fullscreen) {
  //     idRoot?.classList.add("overFull");
  //     idRoot?.classList.remove("overNoFull");
  //   } else {
  //     idRoot?.classList.add("overNoFull");
  //     idRoot?.classList.remove("overFull");
  //   }
  // }, [fullscreen]);

  const handleToggleScreen = () => {
    setFullScrenn(!fullscreen);
  };

  React.useEffect(() => {
    handleFetchGameDetail();
  }, [user?.id, params.id]);

  const listGamesOpenSession = JSON.parse(
    sessionStorage.getItem("listGamesOpen") as any
  );

  const navigate = useNavigate();
  const [offset, setOffset] = useState<any>(1);

  const [flagCount, setFlagCount] = useState(10);

  const totalCount = listGame?.total;

  const playGameRef = useRef<any>(null);

  const handleNextGame = async () => {
    const currentIndex = listGamesOpenSession?.findIndex(
      (item: any) => item.id === detailGameOpen?.id
    );

    const nextIndex = (currentIndex + 1) % listGamesOpenSession?.length;

    const nextGame = listGamesOpenSession[nextIndex];

    const nameRep = nextGame.name.replaceAll(" ", "-").toLowerCase();

    dispatch(setDetailOpen(nextGame));
    navigate(`${openSourcePath}/${nameRep}/${nextGame.id}`);
    if (flagCount >= totalCount) {
      setOffset(0);
      setFlagCount(10);
    }
    if (currentIndex === listGamesOpenSession?.length - 2) {
      const newOffset = offset + 1;
      const params = { page: newOffset, limit: 10, id: user?.id || 0 };
      try {
        const response = await getListGamesOpenSource(params);

        setFlagCount(flagCount + response?.data?.length);

        sessionStorage.setItem("listGamesOpen", JSON.stringify(response.data));
        setOffset(newOffset);
      } catch (error) {
        console.log(error);
      }
    }

    // await postPlayGame(detailGame.id);
  };
  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentID]);

  const handleCloseOverLay = () => {
    setShowOverlay(false);

    playGameRef?.current!.focus();

    // console.log("playGameRef", playGameRef.current);
    // console.log("playGameRef focus", playGameRef.current!.focus());
  };

  const handleCloseOverLayResult = () => {
    setShowOverlayResult(false);
    setIframeKey(iframeKey + 1)
    //playGameRef?.current!.contentWindow.location.reload();
  };


  const handleCloseNextClaime = () => {
    setSshowNextClaime(false);
    setIframeKey(iframeKey + 1)
    //playGameRef?.current!.contentWindow.location.reload();
  };

  const handleOpenNextClaime = () => {
    setShowOverlayResult(false);
    setSshowNextClaime(true);

  };

  function onMessageHandler(event: any) {
    if(event.data.id === "game-result"){
      setDetailGameResult(event.data.message)
      setShowOverlayResult(true)
    }

  }
  React.useEffect(() => {
    window.addEventListener("message", onMessageHandler);
    addHistory()
  }, []); 

  const addHistory = async ()=>{
    await addGameToHistory({ gameId: currentID, gameType: "game" })

  }
  const [openPopup, setOpenPopup] = useState(true);
  const screen: any = useScreenSize();
  const childRef = useRef<any>();

  React.useEffect(() => {
    if(showOverlayResult){
      childRef?.current?.handlerTimeLeft(5)
      setOpenPopup(true)
    }
  }, [showOverlayResult]); 

  return (
    <GameTag>
      {/* <PopupAds open={openPopup}  ref={childRef}  setOpen={setOpenPopup}/> */}
      <script type='text/javascript' src='//pl22341529.toprevenuegate.com/73/e2/98/73e29840027fb6e679c70131afff5810.js'></script>
      {detailGameOpen?.url ? (
        <div className={` ${fullscreen ? "" : "game_page"}`}>
          <div
            className={
              fullscreen
                ? "full_screenn relative"
                : "un_fullscreenn frameGame relative"
            }
          >
            {showOverlay && (
              <OverLayPlayGame
                game={detailGameOpen}
                onClose={handleCloseOverLay}
              />
            )}

          {(showNextClaime) && (
              <OverLayPlayGameResult
                game={detailGameOpen}
                result={detailGameResult}
                onClose={handleCloseNextClaime}
              />
            )}

            {((showOverlayResult && (detailGameResult && detailGameResult.realReward <= 0) )) && (
              <OverLayPlayGameResult
                game={detailGameOpen}
                result={detailGameResult}
                onClose={handleCloseOverLayResult}
              />
            )}

              {(showOverlayResult && (detailGameResult && detailGameResult.realReward > 0)) && (
              <RewardClaime
                game={detailGameOpen}
                result={detailGameResult}
                onClose={handleOpenNextClaime}
              />
            )}     
            <iframe
               key = {iframeKey}
              title="myFrame"
              ref={playGameRef}
              src={
                `${detailGameOpen?.url}` +
                "?accessToken=" +
                localStorage?.getItem("accessToken")
              }
              style={{ width: "100%" }}
              className={`${
                fullscreen ? "ifame_game_w100vh" : "ifame_game_w100"
              } ${
                fullscreen
                  ? (detailGameOpen?.type === "Horizontal" && screen.height > screen.width)
                    ? "rotate_game_html"
                    : ""
                  : ""
              }`}
            ></iframe>
          </div>

          <ResultGame game={detailGameOpen} isFullScreen={fullscreen} />
          {/* <InfoGameMini data={game} rate={rate} />
          <GameDescribe
            gameType={"miniGame"}
            data={game}
            star={star}
            rateSum={rateSum}
          />
          <Comments data={comments} rate={rate} />
          <WriteComment data={game} rate={rate} gameType={"miniGame"} /> */}
          <>
            {/* <FullScreen
              className={fullscreen ? "" : "icon_fullscreen_discovery"}
              onClick={handleToggleScreen}
            /> */}
            {fullscreen ? (
              <>
                {/* <NextGameBtn handleNextGame={() => handleNextGame()} /> */}
                <HomeBtn />
              </>
            ) : (
              ""
            )}
          </>
        </div>
      ) : null}
    </GameTag>
  );
};
export default PlayOpenSource;
