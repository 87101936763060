import axios from "axios";
import { CMS_BANNER } from "./environment";

const cmsService = axios.create({
  baseURL: CMS_BANNER,
});

cmsService.interceptors.response.use(
  (response) => {
    return response;
  },

  function (error) {
    console.log("RESPONSE ERROR", error);
    const message = error.response?.data?.message || "Unknown Error";
    return Promise.reject({ message });
  }
);

export default cmsService;
