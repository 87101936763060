import styled from "styled-components";

export const NFTGame = styled.section`
  width: 87%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  .btn_back {
    width: 100%;
    display: flex;
    justify-content: start;
  }
  .frameGame {
    width: 100%;
    height: 100%;
    iframe {
      width: 100% !important;
      height: 658px !important;
    }
    img {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .frame_video {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    .frame_video {
      display: flex;
      flex-direction: column;
      iframe {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`;
