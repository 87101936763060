import React from "react";

const PrivacyPolicyPage = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  });

  return (
    <div className="game_page">
      <div className="policy_page">
        <span>
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> is
          committed to protecting the privacy of all visitors who use our site
          and applications, including older users above the age of 13 ("General
          Users") and younger users under the age of 13 ("Children Users"),
          together the "Users".
        </span>
        <span>
          This privacy policy ("Privacy Policy" or "Policy") is designed to
          provide transparency into Playground’s privacy practices and
          principles for information that we collect from General Users through
          our <span>https://games.playgroundx.site/ </span> web platform, mobile
          applications, our Playground Publishing services, and other
          general-audience sites and applications ("Playground Game Center",
          “Playground Gaming Site”).
        </span>
        <span>
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> is
          owned and operated by Playground LTD Co. (referred to in this Policy
          as "Playground", "We", "Our", or "Us"). Playground LTD Co.
          ("Playground") is an entity incorporated under the laws of Korea.
        </span>
        <br />
        <span>
          By using the{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span>, you
          agree to observe and be bound by this Privacy Policy and you consent
          to the processing, collection, use, and disclosure of your information
          and data in accordance with the terms of this Privacy Policy. Please
          do not use the Playground Game Center if you do not agree to this
          Privacy Policy. If you are a parent or legal guardian, by allowing
          your child(ren) to use the Playground Game Center, you are agreeing to
          the terms of this Privacy Policy on your child’s behalf. If you have
          any questions concerning this Privacy Policy, please contact us at{" "}
          <span>contact@playgroundvina.com</span>
        </span>
        <p>1. General information</p>
        <span>
          All features of the Playground Game Center are accessible without the
          need for users to register. No personal data (such as email addresses
          and phone numbers) are requested from users. Playground may collect
          certain electronic data (such as the User’s IP address, cookies, or
          device ID).
        </span>
        <span>
          When a User provides personal data on any third-party website or
          application, the processing of such personal data will be governed by
          any privacy statement applicable to that site/application. In any
          event, Playground shall not act as the Data Controller or the
          Processor of such personal data.
        </span>
        <p>2. Data collection and its purposes</p>
        <span>
          Our servers automatically recognize visitors’ domain names and IP
          addresses (the number assigned to computers on the Internet). The
          Playground Game Center may gather anonymous "traffic data" that does
          not personally identify you, but that data may be helpful for
          marketing purposes or for improving the services we offer.
        </span>
        <span>
          When your computer or mobile device contacts our web servers (for
          example, when you visit the Playground Gaming Site), our web servers
          automatically collect usage information. Such usage information
          includes information about how our visitors use and navigate the
          Playground Gaming Site. It can include the number and frequency of
          visitors to each web page and the length of their stays, browser type,
          and referrer data that identifies the web page visited prior to and
          subsequent to visiting our Site.
        </span>
        <span>
          Traffic information may also be shared with business partners and
          advertisers on an aggregate and anonymous basis. More information
          about sharing information will follow in part 7. In addition,
          Advertisers on the Playground Game Center might receive information
          gathered by cookies for targeted advertising purposes. Further
          information regarding the use of cookies can be found below.
        </span>
        <p>3. Data Retention</p>
        <span>
          PlaygroundPlayground may retain users’ data for the following
          purposes:
        </span>
        <li>To develop new features and improve the Playground Game Center;</li>
        <li>To support subsequent customer service inquiries;</li>
        <li>
          To improve the safety and security of the Playground Game Center; or
        </li>
        <li>Comply with our legal obligations.</li>
        <span>
          We will retain your information for as long as is reasonably necessary
          in relation to the purposes for which this data was collected.
        </span>
        <p>4. Third-party games</p>
        <span>
          This Privacy Policy does not apply to the processing of personal data
          by data controllers other than Playground, such as providers of
          third-party games available on the Playground Game Center. The
          relevant provider of such a third-party game is responsible for
          ensuring that any processing of personal data by them in the context
          of a game complies with applicable data protection laws.
        </span>
        <span>
          Generally,{" "}
          <span style={{ fontWeight: "700" }}>Playground Game Center</span> does
          not permit third-party content providers to collect personal data
          about Users. However, if any personal data will be collected by a
          third-party content provider, Playground will contractually require
          that the processing of personal data by them in the context of a game
          complies with applicable data protection laws.
        </span>
        <p>5. Cookies</p>
        <span>
          Like many websites and applications, this Playground Game Center makes
          use of "cookies", which are saved on the user’s computer. In addition,
          third-party networks serving advertisements on the Playground Game
          Center (the "Advertisers") might use cookies. Cookies are a way of
          remembering who you are. A cookie is a small text file that is stored
          on your computer’s hard drive or stored temporarily in your computer’s
          memory. There are two kinds of cookies: those that are "session"
          oriented and those that are "persistent." Session cookies are deleted
          from your computer when you close your browser. Persistent cookies
          retain information for later use tomorrow, next week, next month, or
          whenever they are set to expire.
        </span>
        <span>
          Playground and its Advertisers may use cookies or similar technologies
          to enhance and personalize your experience on the Playground Game
          Center, including the following:
        </span>
        <li>to operate and improve offerings on the Playground Game Center;</li>
        <li>
          to help authenticate you when you are at the Playground Game Center;
        </li>
        <li>to remember your preferences;</li>
        <li>
          to present and help measure and research the effectiveness of
          Playground’s offerings;
        </li>
        <li>
          to customize the content provided to you through the Playground Game
          Center; and,
        </li>
        <li>to show targeted ads.</li>
        <span>
          Users can refuse the saving of cookies or delete them by adjusting the
          browser settings on their computers. To learn more about how to adjust
          the settings of your internet browser, we recommend the user visit the
          website of the browser’s vendor.
        </span>
        <span>
          The user can also decide to remove installed cookies. Users can do so
          in the following ways:
        </span>
        <h5>Internet Explorer</h5>
        <span>
          Delete existing Tracking Technologies through the "Internet Options"
          sub-option of the "Tools" menu option of your browser or otherwise as
          directed by your browser’s support feature. Disable future Tracking
          Technologies by using the same browser controls. Additional
          information on disabling most Tracking Technologies may be available
          through your browser’s support feature. (See the "help" section of
          your browser for more information.)
        </span>
        <h5>Google Chrome</h5>
        <span>
          Delete existing Tracking Technologies through the "Clear Browsing
          Data…" sub-option of the "Tools" menu option of your browser or
          otherwise as directed by your browser’s support feature. Disable
          future Tracking Technologies by using the same browser controls.
          Additional information on disabling most Tracking Technologies may be
          available through your browser’s support feature. (See the "help"
          section of your browser for more information.)
        </span>
        <h5>Mozilla Firefox</h5>
        <span>
          Delete existing Tracking Technologies through the "Clear Recent
          History…" sub-option of the "History" menu option of your browser or
          otherwise as directed by your browser’s support feature. Disable
          future Tracking Technologies by using the same browser controls.
          Additional information on disabling most Tracking Technologies may be
          available through your browser’s support feature. (See the "help"
          section of your browser for more information.)
          <br />
          You may be able to have the device you use to access the Playground
          Game Center warn you each time a cookie or most other Tracking
          Technologies is being set (other than Flash cookies), or you can
          choose to turn off such warnings. This is done through your browser on
          the device you use to access the Website. Additional information on
          warnings and removal of warnings may be available through your
          browser’s support feature.
          <br />
          Please note that deleting, rejecting, disabling or turning off
          Tracking Technologies through the above options will not remove Flash
          cookies. For more information about Flash cookies and how to remove
          them from your computer, please visit Adobe’s website.
          <br />
          Please be aware that certain areas and features of the Playground Game
          Center can only be accessed in conjunction with Tracking Technologies,
          and that disabling Tracking Technologies might prevent you from
          accessing such content. If you have any questions concerning cookies,
          please contact us at
          <span> contact@playgroundvina.com</span>
        </span>
        <p>6. Links to third-party websites</p>
        <span>
          The Playground Game Center knowingly links out to the following
          third-party websites. The names of these third-party websites link to
          their privacy policy.
          <br />
          <li>YouTube</li>
          <li>Facebook</li>
          <li>Instagram</li>
          <li>Twitter</li>
          <br />
          Some of the third-party games published on the Playground Game Center
          do link out to third-party websites. Playground uses technology and
          people to monitor these links for inappropriate content, but it does
          not have full control over the third-party games on the Playground
          Game Center.
        </span>
        <p>7. Sharing information</p>
        <span>
          <li>
            Playground may share your persistent identifiers with thirds-party
            such as business partners under the following circumstances:
          </li>
          <br />
          Security: We may release information about our Users, including IP
          address, when legally required to do so, at the request of
          governmental authorities conducting an investigation, or to verify or
          enforce compliance with the policies governing the Playground Game
          Center and applicable laws. We may also disclose such user information
          whenever we believe disclosure is necessary to protect the rights,
          property, or safety of Playground, or any of our respective business
          partners, customers, or others.
          <br />
          Aggregate Information: We may disclose non-identifying, aggregated
          user statistics to third parties for a variety of purposes, including
          describing our services to prospective partners and other third
          parties. Examples of such non-personal information include the number
          of users who visited this Playground Game Center during a specific
          time period or played a specific game on this Website.
          <br />
          Advertising: We may share technical information such as IP address or
          device ID with our advertising partners. For advertising on the
          Playground Game Center, this information may be used to serve
          behaviorally targeted ads or profiling.
        </span>
        <p>8. Lawful Bases for Processing Personal Data</p>
        <span>
          We collect and process your data only when:
          <br />
          <li>
            It is necessary for a legitimate interest (which is not overridden
            by your individual privacy interests), such as preventing fraud,
            improving the Service, and increasing the security of the Service
            and network infrastructure;
          </li>
          <li>
            You have consented to this collection and processing for a specific
            purpose;
          </li>
          <li>It is necessary to fulfill our contractual obligations; or</li>
          <li>It is necessary to comply with a legal obligation.</li>
          <br />
          Where we rely on our legitimate interests to process your personal
          data, you have the right to object. More information on exercising
          this right can be found in section 9 addressing Individual Rights.
          Some examples of our legitimate interests and the data being processed
          include:
          <br />
          <li>Network and information security (IP address, user/device ID)</li>
          <li>Customer Support and fraud prevention (email address)</li>
          <li>Improving our products and services (activity logs)</li>
          <br />
          Where we rely on your consent to process your personal data, you have
          the right to withdraw or decline consent at any time. If you wish to
          withdraw your consent, please contact us using the information in
          section 10 regarding the Contacts for Individual Rights Requests. If
          you have any questions about or need further information concerning
          the legal basis on which we collect and use your personal data, please
          contact us at <span>contact@playgroundvina.com.</span>
        </span>
        <p>9. Individual Rights</p>
        <span>
          Playground makes certain choices available to you when it comes to
          your personal data. Please review the below information outlining your
          choices and how to exercise them. We will respond to all requests
          within a reasonable timeframe. If our full response will ever take
          more than a month due to complexity or scope, we will notify you of
          this and keep you updated. We may request more information to confirm
          your identity before acting on any request.
          <br />
          <p className="text">Review and update your data:</p>You have the right
          to access and update any personal data that we have collected. To
          review or update this data, please submit a request using the contact
          information at the end of this section.
          <br />
          <p className="text">Delete your data:</p>You also hold the right to
          have your personal data deleted. This is sometimes known as the "right
          to be forgotten." To request that we delete all personal data about
          you, please submit a request using the contact information at the end
          of this section.
          <br />
          After you delete your personal data from our services, we may not
          immediately delete residual copies from our active servers and may not
          immediately remove data from our backup systems for archiving, the
          prevention of fraud and abuse, analytics, the satisfaction of legal
          obligations, or where we otherwise reasonably believe that we have a
          legitimate reason to do so. We may also decline to honor this request
          in certain specific situations, such as if the data is necessary to
          comply with a legal obligation or to exercise/defend a legal claim.
          <br />
          <p className="text">Restrict Processing:</p> You have the right to
          restrict how we process your personal data in certain circumstances.
          This is an alternative to requesting the deletion of your data. Rather
          than requesting we delete all of your personal data, you may request
          that we limit our uses of your personal data to specific purposes. You
          may wish to request we restrict our processing if you contest the
          accuracy of your personal data and we are working to verify this
          information, or if you want us to retain your personal data in
          connection to a legal claim but cease processing it.
          <br />
          <p className="text">Data Portability:</p>You have the right to obtain
          copies of your information in a structured, commonly used format that
          you can move your data between our service and the services of others.
          We may request more information to confirm your identity before
          providing any personal data.
          <br />
          <p className="text">Right to Object: </p>You have the right to object
          to the processing of your personal data for direct marketing purposes
          or when our processing of your data is based on legitimate interests.
          You may request that we stop processing your personal data for direct
          marketing purposes. This is an absolute right and we cannot refuse
          this request. Beyond direct marketing, if you wish to exercise this
          right, you must give specific reasons why you object to our processing
          of your data, based on your particular situation. Even after receiving
          such a request, we may continue processing if it is necessary for the
          exercise/defense of a legal claim or if we can demonstrate a
          compelling legitimate ground for the processing.
          <br />
          If any request made under this section is clearly unfounded or
          excessive, we may reject the request or require a reasonable fee to
          honor the request. If we decide to reject your request, we will inform
          you of the reasons for not taking action and provide information on
          other possible remedies. If we decide that a reasonable fee is
          necessary, we will promptly inform you and will comply with the
          request upon receipt of this fee.
        </span>
        <p>10. Contact for Individual Rights Requests</p>
        <span>
          Please use the below information when submitting a request to exercise
          any of the above rights. Please do not submit requests across multiple
          communication channels. We will make all efforts to respond to your
          request within a reasonable timeframe.
        </span>
        <br />
        Email:
        <span>contact@playgroundvina.com.</span>
        <br />
        <p>11. Revisions to this Privacy Policy</p>
        <span>
          We may change this privacy policy from time to time to accommodate new
          technologies, industry practices, regulatory requirements, or for
          other purposes. If we update this policy, changes will be reflected on
          this page, and we will update the "last updated" date above.
        </span>
        <p>12. Contact information</p>
        <span>
          If you have any questions concerning this Privacy Policy, or data
          collection in particular, please contact us at
          <span>contact@playgroundvina.com</span>
          <br />
          {/* Poki B.V
          <br />
          Spui 10
          <br />
          1012 WZ Amsterdam
          <br />
          The Netherlands
          <br />
          +31 20 2800 870
          <br /> */}
        </span>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
