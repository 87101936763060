import styled from "styled-components";
export const FooterWrapper = styled.section`
  height: 50rem;
  position: relative;
  // margin-left: var(--width_slideBar);
  right: 0;
  bottom: 0;
  background: #00c9a2;
  z-index: 999;
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    bottom: 10rem;
    margin-top: 10rem;
  }
`;


export const ButtomNavigationWrapper = styled.section`
  .nav-text{
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */ 
  }
`;
