import * as React from 'react';
import { footerImg } from "helpers/ExportImg";

export interface ILoadingVerTwoProps extends React.HTMLAttributes<HTMLDivElement> {
}

export default function LoadingVerTwo(props: ILoadingVerTwoProps) {
    const { controlMobile } = footerImg;
    return (
        <div {...props} className="p-40 w-full " >
            <img src={controlMobile} alt="img" className="logo-loading m-auto" />
        </div>
    );
}
