import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Comments from "components/comments";
import GameDescribe from "components/gameDescribe";
import TopFreeGame from "components/home/TopFreeGame";
import InformationsGame from "components/informationsGame";
import WriteComment from "components/writeComment";

import { getComment, getCountStar, getDetailNFTGame } from "./services/api";
import PageNFT from "components/pageNFT";

const GameNFTPage = () => {
  // useSelecter dan den ten store
  const { detailGameNFT } = useSelector((state: any) => state.gamenft);
  const [comments, setComments] = React.useState<any>([]);
  const [star, setStarts] = React.useState<any>([]);
  const [rate, setRate] = useState(0);
  const params = useParams();
  const { rateCount, rateSum } = detailGameNFT;
  const currentID = params.id;

  useEffect(() => {
    getDetailNFTGame(currentID);
    window.scrollTo({ top: 0 });

    const gameId: any = currentID;
    const gameType = "nftGame";
    getComment(gameType, gameId, 3).then((response: any) =>
      setComments(response.slice(0, 3))
    );

    getCountStar(gameType, gameId).then((response: any) => setStarts(response));
  }, [currentID]);

  useEffect(() => {
    const rateGame = rateCount > 0 ? rateSum / rateCount : 5;
    setRate(rateGame);
  }, [rateCount]);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div className="game_page">
      <PageNFT data={detailGameNFT} />
      <InformationsGame data={detailGameNFT} />
      <GameDescribe
        data={detailGameNFT}
        star={star}
        rateSum={rateSum}
        gameType="nftGame"
      />
      <Comments data={comments} />
      <WriteComment data={detailGameNFT} gameType={"nftGame"} />
      {/* <TopFreeGame /> */}
    </div>
  );
};

export default GameNFTPage;
