import { SetStateAction, useEffect, useState } from "react";
import { HomeSearch } from "./styled";

import { getListGenre } from "pages/services/api";
import { useNavigate } from "react-router";

export default function Search() {

    const [showFilter, setShowFilter] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectFilter, setSelectFilter] = useState<any>();
    const onSelectChanged = (e: { currentTarget: { value: SetStateAction<string>; }; }) => {
        const selectId = parseInt(e.currentTarget.value as string)
        setSelectFilter((genres.filter((obj: { id: number; }) => obj.id === selectId)[0]))

    }
    const [genres, setGenres] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState("");
    const handleChangeComment = (event: any) => {
        setQuery(event.target.value);
    };
    useEffect(() => {
        window.scrollTo({ top: 0 });
        getListGenre().then((response: any) => {
            setGenres(response);
            setIsLoading(false);
        });
    }, []);

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            navigate(`all-mini-game/?query=${query}`)
        }
    }

    return (
        <HomeSearch>
            <div className="flex items-center mt-5 p-5 px-10">
                <label htmlFor="simple-search" className="sr-only">Search</label>
                <div className="relative w-full homeSearch ">
                    <div className="absolute ml-6 inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#9E9E9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.5 17.5L13.875 13.875" stroke="#9E9E9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <input type="text" id="simple-search" className="  w-full  ps-10 p-5 " value={query} onChange={handleChangeComment}
                        onKeyDown={handleKeyDown} placeholder="Search by name..." required />
                </div>
                <button type="submit" onClick={() => { navigate(`search-genre`) }} className="ml-5  bth-filter">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className="sr-only">Filter</span>
                </button>
            </div>
            {/* <div className={`${showFilter ? '' : 'invisible'} w-full h-full fixed block top-0 left-0 bg-white z-[1000]`}>
                <nav className=" fixed w-full z-20 top-0 start-0 ">
                    <div className="max-w-screen-xl flex flex-wrap  justify-center mx-auto p-5">
                        <button type="submit" onClick={() => { setShowFilter(false) }} className="">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18L9 12L15 6" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button >
                        <div className="grow text-center">
                            <div className="filter-title">
                                Filter Genre
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="mt-36 p-10">
                    <ul className=" max-w-[1200px] m-auto">
                        {genres.map((filter: any) => (
                            <li key={filter.id} className="flex justify-between gap-x-6 py-5">
                                <div className="flex min-w-0 gap-x-4">
                                    <img src={filter.image} className="w-[25px] h-[25px]" alt="img soft" />
                                    <div className="min-w-0 ml-5 flex-auto">
                                        <p className="filter-name">{filter.name}</p>
                                    </div>
                                </div>
                                <div className=" shrink-0 sm:flex sm:flex-col sm:items-end">
                                    <input type="radio" className="form-radio accent-[green]" name="option" value={filter.id}
                                        onChange={onSelectChanged} />
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="fixed bottom-10 p-4 left-0 z-50 w-full h-30 ">
                        <button onClick={() => { navigate(`all-mini-game/?categoriesId=${selectFilter.id}&&categoriesName=${selectFilter.name}&&iconHeader=1`) }} className="filter-btn mt-5 m-auto w-[100%] max-w-[1200px]">
                            Apply
                        </button>
                    </div>
                </div>
            </div> */}
        </HomeSearch>
    )
}
