import { PopupTag } from "./styled";




const PopupTicket = ({open, setOpen, ticketNumber}: any) => {


  const handlerClosePopup = () => {
    setOpen(false);
  };

;


  return (
    <PopupTag>

      <>
        {open && (
          <>
            <div onClick={handlerClosePopup} className={`backdrop`}></div>
            <div className="popup-img">


              <div className={`popup img-mobile relative min-w-[80%]`}>
                <>
                  {/* <img className=" absolute -top-40 w-80" src={logos.coinPopup}
                    alt="img" /> */}
                  <div className="bg-[#F0F0F0] rounded-3xl p-20 px-32 w-full">

                    <div>
                      <div className={'popup-title text-center  pt-5'} >
                      Your ticket number
                      </div>
                      <div className="ticket-number mt-5">{ticketNumber || '...'}</div>
                      <button onClick={handlerClosePopup} className="popup-btn mt-12 w-full">
                      Got it
                      </button>

                    </div>

                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </PopupTag>
  );
};

export default PopupTicket;
