import { ButtonHome } from "components/button";
import React, { useEffect } from "react";

import { NFTGame } from "./styled";

const PageNFT = ({ data }: any) => {
  return (
    <NFTGame key={data.id}>
      <div className="btn_back">
        <ButtonHome name={data.name} image={data.images[2]} />
      </div>
      <div className="frameGame">
        <img src={data?.images && data?.images[3]} alt="background" />
      </div>
      <div className="frame_video">
        <iframe
          width={408}
          height={235}
          src={data.youtube[0]}
          frameBorder={"0"}
          allowFullScreen
          title="video"
        ></iframe>{" "}
        <iframe
          width={408}
          height={235}
          src={data.youtube[1]}
          frameBorder={"0"}
          allowFullScreen
          title="video"
        ></iframe>{" "}
        <iframe
          width={408}
          height={235}
          src={data.youtube[2]}
          frameBorder={"0"}
          allowFullScreen
          title="video"
        ></iframe>
      </div>
    </NFTGame>
  );
};

export default PageNFT;
