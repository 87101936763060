import React from "react";
import { Progess } from "./styled";
import { AiFillStar } from "react-icons/ai";

const ProgressBar = ({
  bgcolor,
  progress,
  height,
  el,
  rateCount,
  text,
  value,
}: any) => {
  const percent = (el * 100) / rateCount;
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "whitesmoke",
    // borderRadius: 40,
    dislay: "flex",
  };

  const ChildDiv: any = {
    height: "100%",
    width: `${percent}%`,
    backgroundColor: bgcolor,
    // borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "black",
    // fontWeight: 900,
  };

  return (
    <Progess>
      <div className="text">
        <p style={{ fontSize: "22px" }}>{value}</p>
        {/* <AiFillStar width={20} height={20} /> */}
      </div>
      <div className="Parentdiv">
        <div style={ChildDiv}>
          <span style={progresstext}></span>
        </div>
      </div>
    </Progess>
  );
};

export default ProgressBar;
