import { getNetwork } from '@wagmi/core';
import { useAppDispatch } from "index";
import { login } from "pages/services/api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setAuth } from "slices/authSlice";
import { setPopupConnectShowState } from "slices/popupConnectSlice";
import { useAccount } from "wagmi";
import { connectWallet, sign, switchNetwork } from "web3/connector";
import { ButtonConnect } from "./styled";


const ConnectWalletBtn = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [textBtn, setTextBtn] = useState("Loading...");
  const [delay, setDelay] = useState(true);

  // useEffect(() => {
  //   handleConnectWallet()
  // }, []);
  let {address: account, connector} = useAccount()
  const { chain, chains } = getNetwork()


  const dispatchApp = useAppDispatch()

  const handlerPopup = (show: boolean) => {
    dispatchApp(setPopupConnectShowState(show));
  };

  const handleConnectWallet = async () => {
    try {
      setLoading(true);
      setTextBtn("Loading...")
      const time = Date.now()
      const message = "Welcome to Playground Game Center! Timestamp: " + time
      if (!account || !connector) {
        setTextBtn("Connect Wallet...")
        account = await connectWallet()
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
      }

      const token = localStorage.getItem("accessToken");
      const user = JSON.parse(localStorage.getItem("user")!);
      setTextBtn("Switch Network...")
      await switchNetwork();
      if (!user || !token || (account !== user.walletAddress)) {
        setTextBtn("Sign...")
        const signature = await sign(account, message);
        setTextBtn("Login...")
        const res = await login(signature, account, message);
        localStorage.setItem("accessToken", res?.accessToken);
        localStorage.setItem("user", JSON.stringify(res?.user));
        setTextBtn("Successfully")
        dispatch(
          setAuth({
            accessToken: res?.accessToken,
            user: res?.user,
            wallet: account,
          })
        );
      } else {
        setTextBtn("Successfully")
        dispatch(
          setAuth({
            accessToken: token,
            user: user,
            wallet: account,
          })
        );
      }
      handlerPopup(false)
      setLoading(false);
      setTextBtn("Successfully")
      toast.success("Connected successfully")
    } catch (error: any) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Your function to be called after 3 seconds
      delayLoading();
    }, 3000);

    // Cleanup function to clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  const delayLoading = ()=>{
    setTextBtn("Connect Wallet")
    setLoading(false)
    setDelay(false)
  }

  const renderButtonWallet = () => {
    return (
      <ButtonConnect>
        <button
          onClick={handleConnectWallet}
          disabled = { delay }
          className={`popup-btn-connect w-full min-w-[200px] ${delay?' bg-[#cccccc] ':' bg-[#06C270] '}`}
        >
          {textBtn}
        </button>
      </ButtonConnect>
    );
  };
  return <>{renderButtonWallet()}</>;
};

export default ConnectWalletBtn;
