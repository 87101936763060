import { footerImg, homeImg } from "helpers/ExportImg";
import useWindowSize from "hooks/useWindowSize";
import { getHistoryGame, getListGamesRecommend } from "pages/services/api";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListGameOpenSource } from "slices/gameOpenSource";

import GameCardHistory from "components/Card/GameCardHistory";
import { PlayToEarnStyled } from "./styled";
import GameCardRecommend from "components/Card/GameCardRecommend";

const Recommend = ({ openList }: any) => {
  const [page, setPage] = useState(1);
  const [limitGame, setLimitGame] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);

  const rootRef = useRef<any>();
  const [listGame, setListGame] = useState([]);

  const { controlMobile } = footerImg;

  useEffect(() => {
    if (listGame) {
      setLoading(false);
    }
  }, [listGame]);
  const fetchApi = async () => {
    try {
      setLoading(true);
      // const limit = 10;
      const params = { page, limit: limitGame , recommend : true};
      const data = await getListGamesRecommend(params);
      setListGame(data.data)
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchApi();
  }, []);


  const payToEarnRef = useRef<any>(null)


  return (
    <PlayToEarnStyled className="relative">

      {(listGame.length > 0)  &&
        (<div
          className=""
          style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
          ref={rootRef}
        >
          <div
            className=""
            style={{ margin: 0 }}
          >
            <div className="flex justify-between w-full  ">
              <div className="pte-header-left">
                <span>Recommend</span>
              </div>
              {/* <div className="pte-header-right">
                <Link to={"/all-game-pta?iconHeader=2"}>See all</Link>
              </div> */}
            </div>

            <>
              {loading ? (
                <img
                  style={{ padding: "5rem 0" }}
                  src={controlMobile}
                  alt="img"
                  className="logo-loading"
                />
              ) : (

                <div ref={payToEarnRef} className="mt-5 grid grid-rows-2 grid-flow-col gap-10 overflow-x-scroll scroll-p-0 w-[90vw] max-w-[1200px] pb-10 hide-scroll-bar justify-between">
                  {listGame.map((game: any) => (
                    (game) &&
                      (<div
                        key={game?.id}
                      >
                        <GameCardRecommend game={game} />
                      </div>) 
                  ))}
                </div>
              )}
            </>
          </div>
        </div>)
      }
    </PlayToEarnStyled>


  );
};

export default Recommend;