import React, { useState, FC } from "react";
import { MiniGame } from "./styled";
import useWindowSize from "hooks/useWindowSize";
import { ButtonHome } from "components/button";
import { useSelector } from "react-redux";
import { IGame } from "interfaces";
import PopupAds from "components/popupAds";
import useScreenSize from "hooks/useScreenSize";

type GamePageType = {
  isFullScreen?: boolean;
  data: any;
};
const PageMini: FC<GamePageType> = ({ data, isFullScreen }) => {
  // const PageMini = ({ data }: any) => {
  const width: any = useWindowSize();
  const [openPopup, setOpenPopup] = useState(true);
  const screen: any = useScreenSize();
  return (
    // <div className={!fullscreen ? "bg-gamemini" : "bg-gamemini-fullscreen"}>
    <>
      <div
        className={` ${
          !isFullScreen ? "bg-gamemini" : "bg-gamemini-fullscreen"
        }`}
      >
        {/* <PopupAds open={openPopup}  setOpen={setOpenPopup}/> */}
        <script type='text/javascript' src='//pl22341529.toprevenuegate.com/73/e2/98/73e29840027fb6e679c70131afff5810.js'></script>
        <MiniGame></MiniGame>
        {/* <div className="frameGame"> */}
        <div className={isFullScreen ? "full_screenn" : "frameGame"}>
          <iframe
            title="game"
            id="item-direct-container"
            // className="item-direct-container resizable rotate_game_html"
            className={`${
              isFullScreen
                ? (data?.type === "Horizontal" && screen.height > screen.width)
                  ? "rotate_game_html"
                  : ""
                : ""
            } item-direct-container resizable`}
            // className="item-direct-container resizable"
            width="100%"
            height="100%"
            allow="autoplay"
            scrolling="yes"
            data-src={data?.link || undefined}
            src={data?.link || undefined}
            style={{ display: "block", borderStyle: "none" }}
          ></iframe>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default PageMini;
