import { useRef } from "react";

import CardSpecialEvent from "components/Card/CardSpecialEvent";
import { PlayToEarnStyled } from "./styled";
import CardDayEvent from "components/Card/CardDayEvent";

const ListLotteryEvents = ({ eventData }: any) => {



  const payToEarnRef = useRef<any>(null)


  return (
    <PlayToEarnStyled className="relative w-full">

      <div
        className=""
        style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
      >
        <div
          className=""
          style={{ margin: 0 }}
        >
          <>
            <div ref={payToEarnRef} className="mt-5 grid grid-cols-1 gap-10  max-w-[1200px] pb-10  justify-between">
              <div
              >
                <CardDayEvent event={eventData} />
              </div>
            </div>
          </>
        </div>
      </div>
    </PlayToEarnStyled>

  );
};

export default ListLotteryEvents;