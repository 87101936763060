import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AiOutlineFullscreenExit, AiOutlineFullscreen } from "react-icons/ai";
import CropFreeIcon from "@mui/icons-material/CropFree";

import {
  addGameToHistory,
  getComment,
  getCountStar,
  getDetailGame,
  getListGames,
  postPlayGame,
} from "./services/api";
import PageMini from "../components/pageMini";
import WriteComment from "components/writeComment";
import Comments from "components/comments";
import SimilarGame from "components/home/SimilarGame";
import appContext from "appContext";
import InfoGameMini from "components/InfoGameMini";
import GameDescribe from "components/gameDescribe";
import { GameTag } from "./styled";
import {
  setDetailGame,
  setListGame,
  setNextDetailGame,
} from "slices/gameSlice";
import { FullScreen, HomeBtn, NextGameBtn } from "components/buttonScreen";
import { discover } from "constants/path";
import { Button } from "components/button";

const GamePage = () => {
  const { detailGame, games } = useSelector((state: any) => state.games);
  const { categories } = React.useContext(appContext);
  const [change, setChange] = React.useState(false);
  const [comments, setComments] = React.useState<any>([]);
  const [rate, setRate] = useState(0);
  const [star, setStarts] = React.useState<any>([]);

  const params = useParams();
  const currentID = params.id;
  const { rateCount, rateSum } = detailGame;
  const [offset, setOffset] = useState<any>(1);

  const [fullscreen, setFullScrenn] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleScreen = () => {
    setFullScrenn(!fullscreen);
  };

  React.useEffect(() => {
    getDetailGame(currentID);
    window.scrollTo({ top: 0 });

    getListGames({ categories }).then(() => setChange(!change));
    const gameId: any = currentID;
    const gameType = "miniGame";

    getComment(gameType, gameId, 3).then((response: any) =>
      setComments(response)
    );
    getCountStar(gameType, gameId).then((response: any) => setStarts(response));
  }, [currentID, categories]);

  React.useEffect(() => {
    const rateGame = rateCount > 0 ? rateSum / rateCount : 5;
    setRate(rateGame);
  }, [rateCount]);


  const addHistory = async ()=>{
    await addGameToHistory({ gameId: currentID, gameType: "miniGame" })

  }
  React.useEffect( () => {
    addHistory()
  }, []);



 
  
  const listGamesSession = JSON.parse(
    sessionStorage.getItem("listGames") as any
  );
  // const handleNextGame = async () => {
  //   const currentIndex = games.data?.findIndex(
  //     (game: any) => game.id === detailGame.id
  //   );

  //   const nextIndex = (currentIndex + 1) % games.data?.length;
  //   const nextGame = games.data[nextIndex];

  //   dispatch(setNextDetailGame(nextGame));

  //   if (currentIndex === 9) {
  //     const newOffset = offset + 1;
  //     const params = { page: newOffset, limit: 10 };
  //     try {
  //       const response = await getListGames(params);
  //       dispatch(setListGame(response));
  //       setOffset(newOffset);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }

  //   await postPlayGame(detailGame.id);

  // };

  const { accessToken, wallet } = useSelector((state: any) => state.auth);

  const handleNextGame = async () => {
    const currentIndex = listGamesSession?.findIndex(
      (game: any) => game.id === detailGame.id
    );

    const nextIndex = (currentIndex + 1) % listGamesSession?.length;
    const nextGame = listGamesSession[nextIndex];

    dispatch(setNextDetailGame(nextGame));
    navigate(`${discover}/${nextGame.id}`);

    if (currentIndex === 7) {
      const newOffset = offset + 1;
      const params = { page: newOffset, limit: 10, categories };
      try {
        const response = await getListGames(params);
        sessionStorage.setItem("listGames", JSON.stringify(response.data));
        setOffset(newOffset);
      } catch (error) {
        console.log(error);
      }
    }

    if (accessToken && wallet) {
      await postPlayGame(detailGame.id);
    }
  };

  return (
    <GameTag>
      <div className={` ${fullscreen ? "game_page_fullscreen" : "game_page"}`}>
        <PageMini isFullScreen={fullscreen} data={detailGame} />

        {!fullscreen ? (
          <>
            <InfoGameMini data={detailGame} rate={rate} />
            <GameDescribe
              gameType={"miniGame"}
              data={detailGame}
              star={star}
              rateSum={rateSum}
            />
            <Comments data={comments} rate={rate} />
            <WriteComment data={detailGame} rate={rate} gameType={"miniGame"} />
            <SimilarGame data={detailGame} change={change} />
          </>
        ) : (
          ""
        )}
      </div>

      {/* <FullScreen
        isFullScreen={fullscreen}
        className={fullscreen ? "" : "icon_fullscreen_game"}
        onClick={handleToggleScreen}
      />
      {fullscreen ? (
        <NextGameBtn handleNextGame={() => handleNextGame()} />
      ) : (
        ""
      )} */}

      {fullscreen ? <HomeBtn /> : ""}
    </GameTag>
  );
};

export default GamePage;
