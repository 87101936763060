import { PopUpLogin } from "components/button/LoginButton";
import ModalWallet from "components/modals/ModalWallet";
import { logos, slidebarImg } from "helpers/ExportImg";
import {
  getGameReward,
  getUserGameMissionPoint,
  loginWallet
} from "pages/services/api";
import * as React from "react";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { setAuth } from "slices/authSlice";
import { sign } from "web3/connector";
import { PopupTag } from "./styled";
const { logo, pools, coinDes, avatar } = slidebarImg;

export interface IOverLayPlayGameResultProps {
  game: any;
  result: any;
  onClose: () => void;
}

export default function OverLayPlayGameResult({
  game,
  result,
  onClose,
}: IOverLayPlayGameResultProps) {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log({ game });
  const rootRef = useRef<any>(null);

  const [isOpen, setIsOpen] = useState<any>(false);
  const [missionRewards, setMissionRewards] = useState<any>([]);
  const { accessToken, wallet, walletNoSign, user } = useSelector(
    (state: any) => state.auth
  );

  const [dataPoitReward, setDataPointReward] = useState<any>();
  const fetchDataPointReward = async () => {
    try {
      const res = await getUserGameMissionPoint(game?.id);
      setDataPointReward(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchDataPointReward();
  }, []);

  const dispatch = useDispatch();

  const handleSign = async () => {
    const signature = await sign();
    const res = await loginWallet(signature, walletNoSign as string);

    localStorage.setItem("accessToken", res?.accessToken);
    dispatch(
      setAuth({
        accessToken: res?.accessToken,
        user: res?.user,
        wallet: walletNoSign,
      })
    );
  };

  const handleHideOverLay = () => {
    setTimeout(() => {
      rootRef.current.style.display = "none";
    }, 500);
  };



  const handleFetchMissionReward = async () => {
    try {
      const data = await getGameReward();
      setMissionRewards(data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const user_mission = game?.game_mission;
    const missionPass = game?.missionPass || 0;
    const nextMissionReward = user_mission[missionPass];
    handleFetchMissionReward();
  }, []);
  return (
    <>
      <PopupTag>

        <div
          ref={rootRef}
          style={{
            position: "absolute",
            zIndex: "99999",
            inset: "0",
            backdropFilter: "blur(8px)",
            background: "rgba(0,0,0,.6)",
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            className="flex align-center justify-center relative h-full w-full"
          // style={{ width: '100vw', height: '100vh' }}
          >
            <div
              className={`flex flex-col align-center justify-center rounded-[30px]  gap-[40px] absolute w-[360px] mb-[80px] bottom-[0px]`}
              style={{ height: "auto", background: "white" }}
            >
              <div className="relative p-[20px] pt-[40px]">
                <>
                  <div className="inline-block  w-full divide-y divide-[#E0E0E0]">
                    <div className="flex items-center w-[284px] h-[76px]   overflow-hidden rounded-lg  " >
                      <div className="flex-shrink-0 ml-4">
                        <img className="w-[60px] h-[60px] rounded-lg" src={game.image || game.thumbnail} alt="img" />
                      </div>
                      <div className="flex-1 min-w-0 ms-4 ml-6">
                        <p className="game-card-new-title">
                          {game.name}
                        </p>
                        <div className="game-card-new-play-count flex  items-center	">
                          {game && game.categories[0] && game.categories[0].name && (<div className="ml-2 game-card-new-play-count-text flex"> {game.categories[0].name}</div>)}
                        </div>
                      </div>
                    </div>
                    <div className="w-full"></div>
                  </div>
                  <div className="flex-1 mt-5">
                    {game && game.game_mission && (<>

                      <div className="popup-title text-center mt-5 pt-5" >
                        Mission
                      </div>
                      <div className="relative w-full items-center  ">
                        <div className="items-start w-full">
                          <div style={{
                            width: `${(
                              (25 * (result.mission - 1))
                            ).toFixed(0)}%`,
                          }} className="bg-gradient-to-r top-[45%] h-[5px]   left-[45px]  absolute from-yellow-600 to-yellow-400 z-20"></div>

                          <div style={{
                            width: `${(
                              (75)
                            ).toFixed(0)}%`,
                          }} className="bg-[#E1E1E1] top-[45%] h-[5px]   left-[45px]  absolute z-10"></div>
                        </div>
                        <div className="flex ">
                          <span className="mt-5 popup-sc">sc:</span>
                          {game.game_mission?.map((mission: any, index: number) => (
                            <div className=" relative z-20  w-full gap-1"               >
                              <div className=" flex flex-col p-3 items-center text-center gap-2 "    >

                                <div className="popup-text">{mission.pointToPass}</div>
                                <div>
                                  {
                                    (result.mission > index) ? (<svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={26}
                                      height={25}
                                      fill="none"
                                    >
                                      <path
                                        fill="url(#a)"
                                        d="M19.126 24.717c-.29 0-.575-.066-.835-.19L12.56 22.07 6.83 24.52a1.946 1.946 0 0 1-1.636.017 1.937 1.937 0 0 1-1.053-1.2 1.923 1.923 0 0 1-.066-.812l.58-6.224-4.118-4.625a1.926 1.926 0 0 1 1.038-3.228l6.097-1.475 3.186-5.25a1.931 1.931 0 0 1 1.7-1.006 1.94 1.94 0 0 1 1.7 1.005l3.187 5.251 6.099 1.47a1.937 1.937 0 0 1 1.488 1.307 1.92 1.92 0 0 1-.451 1.926l-4.116 4.625.58 6.224a1.922 1.922 0 0 1-1.117 2.02 1.941 1.941 0 0 1-.802.172Z"
                                      />
                                      <g filter="url(#b)">
                                        <path
                                          fill="url(#c)"
                                          d="m18.941 23.676-6.04-2.582a.586.586 0 0 0-.557 0l-6.032 2.582a.543.543 0 0 1-.786-.57l.612-6.556a.597.597 0 0 0-.17-.525l-4.344-4.886a.54.54 0 0 1 .3-.92l6.418-1.546a.591.591 0 0 0 .444-.326l3.355-5.525a.54.54 0 0 1 .775-.222.54.54 0 0 1 .2.222l3.351 5.525a.59.59 0 0 0 .446.325l6.416 1.545a.542.542 0 0 1 .447.663.54.54 0 0 1-.147.257l-4.343 4.886a.595.595 0 0 0-.17.526l.61 6.556a.538.538 0 0 1-.494.63.543.543 0 0 1-.291-.06Z"
                                        />
                                      </g>
                                      <defs>
                                        <linearGradient
                                          id="a"
                                          x1={12.562}
                                          x2={12.562}
                                          y1={0.717}
                                          y2={24.717}
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stopColor="#ECA600" />
                                          <stop offset={1} stopColor="#FFDE01" />
                                        </linearGradient>
                                        <linearGradient
                                          id="c"
                                          x1={2.214}
                                          x2={31.212}
                                          y1={1.625}
                                          y2={25.361}
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stopColor="#ECA600" />
                                          <stop offset={0.443} stopColor="#F4BF00" />
                                          <stop offset={1} stopColor="#FFDE01" />
                                        </linearGradient>
                                        <filter
                                          id="b"
                                          width={28.074}
                                          height={26.962}
                                          x={-1.41}
                                          y={-0.354}
                                          colorInterpolationFilters="sRGB"
                                          filterUnits="userSpaceOnUse"
                                        >
                                          <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                          <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.436} />
                                          <feComposite
                                            in2="SourceAlpha"
                                            operator="in"
                                            result="effect1_backgroundBlur_556_15667"
                                          />
                                          <feBlend
                                            in="SourceGraphic"
                                            in2="effect1_backgroundBlur_556_15667"
                                            result="shape"
                                          />
                                          <feColorMatrix
                                            in="SourceAlpha"
                                            result="hardAlpha"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                          />
                                          <feOffset dy={0.718} />
                                          <feGaussianBlur stdDeviation={0.718} />
                                          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
                                          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                          <feBlend in2="shape" result="effect2_innerShadow_556_15667" />
                                        </filter>
                                      </defs>
                                    </svg>) :
                                      (<svg
                                        width={26}
                                        height={25}
                                        viewBox="0 0 26 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.7099 24.7168C19.4207 24.7164 19.1352 24.6515 18.8745 24.5267L13.1439 22.0712L7.41321 24.5198C7.15245 24.6444 6.86703 24.7094 6.57784 24.7099C6.30196 24.7097 6.02931 24.6508 5.77812 24.5372C5.52694 24.4235 5.30302 24.2577 5.12137 24.0508C4.93971 23.8439 4.80451 23.6008 4.72482 23.3376C4.64512 23.0744 4.62277 22.7973 4.65927 22.5249L5.23846 16.3014L1.12146 11.6762C0.880531 11.4255 0.711983 11.1146 0.633604 10.7764C0.555224 10.4382 0.56992 10.0851 0.67614 9.75456C0.78236 9.42397 0.976165 9.12808 1.23711 8.89811C1.49805 8.66814 1.81645 8.51262 2.15872 8.44795L8.25554 6.97325L11.4425 1.72232C11.6089 1.41804 11.8546 1.16407 12.1536 0.987127C12.4526 0.810181 12.794 0.716797 13.1418 0.716797C13.4896 0.716797 13.8309 0.810181 14.1299 0.987127C14.429 1.16407 14.6746 1.41804 14.8411 1.72232L18.0294 6.97325L24.1276 8.4424C24.4712 8.506 24.7911 8.66121 25.0533 8.89151C25.3155 9.12181 25.5101 9.41859 25.6165 9.75031C25.7229 10.082 25.7371 10.4363 25.6576 10.7754C25.578 11.1145 25.4078 11.4258 25.1649 11.6762L21.0493 16.3014L21.6285 22.5249C21.6663 22.7979 21.6449 23.0758 21.5658 23.34C21.4867 23.6041 21.3517 23.8482 21.1698 24.0559C20.988 24.2637 20.7636 24.4301 20.5118 24.5441C20.2599 24.6581 19.9865 24.717 19.7099 24.7168Z"
                                          fill="#B1B1B1"
                                        />
                                        <g filter="url(#filter0_bi_1031_31120)">
                                          <path
                                            d="M19.3136 23.5872L13.2739 21.0054C13.1884 20.9592 13.0927 20.935 12.9954 20.935C12.8981 20.935 12.8024 20.9592 12.7169 21.0054L6.68417 23.5872C6.59475 23.6339 6.494 23.6548 6.39332 23.6474C6.29263 23.6401 6.196 23.6049 6.11434 23.5457C6.03268 23.4866 5.96924 23.4058 5.93117 23.3127C5.89311 23.2195 5.88194 23.1176 5.89892 23.0184L6.51013 16.462C6.52622 16.3673 6.51918 16.27 6.48962 16.1785C6.46006 16.087 6.40883 16.0039 6.34027 15.9363L1.99634 11.0502C1.92417 10.9798 1.87318 10.8908 1.84911 10.7931C1.82504 10.6954 1.82886 10.5929 1.86014 10.4973C1.89141 10.4016 1.9489 10.3166 2.0261 10.2518C2.10331 10.1869 2.19716 10.1449 2.29707 10.1304L8.71412 8.58496C8.8092 8.57077 8.89936 8.53365 8.97676 8.47683C9.05417 8.42001 9.11647 8.34522 9.15826 8.25895L12.5137 2.73334C12.5581 2.64217 12.6273 2.5653 12.7135 2.51151C12.7997 2.45773 12.8993 2.4292 13.001 2.4292C13.1027 2.4292 13.2023 2.45773 13.2885 2.51151C13.3747 2.5653 13.4439 2.64217 13.4883 2.73334L16.8395 8.25895C16.8818 8.34499 16.9445 8.4195 17.0221 8.47605C17.0997 8.5326 17.1899 8.5695 17.285 8.58358L23.7021 10.129C23.8019 10.1437 23.8956 10.1859 23.9726 10.2508C24.0496 10.3157 24.1069 10.4008 24.138 10.4964C24.1692 10.592 24.1729 10.6944 24.1487 10.792C24.1246 10.8896 24.0736 10.9785 24.0014 11.0488L19.6589 15.9349C19.59 16.0023 19.5386 16.0854 19.509 16.1769C19.4795 16.2685 19.4726 16.3659 19.489 16.4607L20.0989 23.017C20.1161 23.1163 20.1052 23.2184 20.0673 23.3118C20.0293 23.4052 19.9659 23.4861 19.8842 23.5455C19.8025 23.6048 19.7057 23.6402 19.6049 23.6475C19.504 23.6549 19.4031 23.634 19.3136 23.5872Z"
                                            fill="#CFCFCF"
                                          />
                                        </g>
                                        <defs>
                                          <filter
                                            id="filter0_bi_1031_31120"
                                            x={-1.03785}
                                            y={-0.442149}
                                            width={28.0738}
                                            height={26.9624}
                                            filterUnits="userSpaceOnUse"
                                            colorInterpolationFilters="sRGB"
                                          >
                                            <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                            <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.43567} />
                                            <feComposite
                                              in2="SourceAlpha"
                                              operator="in"
                                              result="effect1_backgroundBlur_1031_31120"
                                            />
                                            <feBlend
                                              mode="normal"
                                              in="SourceGraphic"
                                              in2="effect1_backgroundBlur_1031_31120"
                                              result="shape"
                                            />
                                            <feColorMatrix
                                              in="SourceAlpha"
                                              type="matrix"
                                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                              result="hardAlpha"
                                            />
                                            <feOffset dy={0.717837} />
                                            <feGaussianBlur stdDeviation={0.717837} />
                                            <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                                            <feColorMatrix
                                              type="matrix"
                                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                                            />
                                            <feBlend
                                              mode="normal"
                                              in2="shape"
                                              result="effect2_innerShadow_1031_31120"
                                            />
                                          </filter>
                                        </defs>
                                      </svg>)}
                                </div>
                                <div className=" flex items-center" >  <div className="popup-text">{mission.pointReward ? mission.pointReward : '0'}</div><div className="ml-1"> <img className="w-8 h-8 " src={logos.currency}
                                  alt="img" /></div></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>)}
                    {/* <div
                      className="progress rounded-[10px] w-full bg-white h-20  flex mb-[20px]"
                      style={{ overflow: "unset" }}
                    >
                      {game?.game_mission?.map((item: any, index: number) => {
                        return (
                          <div
                            style={{
                              width: `${(
                                100 / (game?.game_mission?.length )
                              ).toFixed(0)}%`,
                            }}
                            key={`game-${index}`}
                            className={`relative value h-[18px]    ${game.missionPass >= index + 1
                              ? "bg-teal-600"
                              : "bg-[#f0f1ff]"
                              }  ${index === 0 && "rounded-l-[10px]"} ${index === game?.game_mission?.length - 1 &&
                              "h-[18px] rounded-r-[10px]"
                              } `}
                          >
                            <p className="text-3xl absolute right-[-5%] top-full">
                              {item.pointToPass}
                            </p>
                            {index === 0 && (
                              <p className="text-3xl absolute left-[0%] top-full">
                                0
                              </p>
                            )}
                          </div>
                        );
                      })}

                      <div className="value w-1/4 bg-white border-r-2  h-full"></div>
                                                <div className="value w-1/4 bg-white border-r-2 h-full"></div>
                                                <div className="value w-1/4 bg-white  h-full"></div>
                    </div> */}
                  </div>
                  <div className="p-5">
                    <div className="flex justify-between text-result mt-5">
                      <div>Scored:</div>
                      <div>{result.score} scrores</div>
                    </div>
                    <div className="flex justify-between text-result">
                      <div>Passed mission:</div>
                      <div>{result.mission} mission</div>
                    </div>
                  </div>
                  <div className=" bg-yellow-100 rounded-xl flex justify-center items-center flex-col p-5">
                    <div className="title-result">
                      Mission Reward
                    </div>
                    <div className="flex mt-5">
                    <div className=" flex items-center" >  <div className="popup-text">{result.realReward  ? result.realReward  : '0'}</div><div className="ml-2"> <img className="w-8 h-8 " src={logos.currency}
                                  alt="img" /></div></div>
                    </div>
                    <p></p>
                  </div>
                  {/* <div className="text-center bg-[#fffacc] p-[20px] font-bold rounded-[8px] mb-[20px] ">
                    <p
                      className={`text-center ${game?.missionPass || 0 !== game?.game_mission?.length
                        ? "mb-8"
                        : ""
                        }  text-[#687180]`}
                    >
                      {game?.missionPass === game?.game_mission?.length
                        ? "You passed all missions today"
                        : "Next mission reward"}
                    </p>
                    {game?.missionPass !== game?.game_mission?.length && (
                      <p className="flex items-center gap-3 justify-center mb-10">
                        {user
                          ? missionRewards?.[game?.missionPass || 0]?.point
                          : missionRewards?.[0]?.point}
                        <img
                          className="w-[20-px] h-[20px]"
                          src={TicketIcon}
                          alt="ticket"
                        />
                      </p>
                    )}

                    {!user?.id && (
                      <p
                        className="text-center  tracking-wide leading-snug font-normal"
                        style={{ fontSize: "16px " }}
                      >
                        Connect wallet to receive points reward!
                      </p>
                    )}
                  </div> */}
                  <div className="flex items-center justify-center gap-6 mb-[12px] mt-20">
                    {!user?.id && (
                      <div
                        onClick={() => {
                          PopUpLogin({ width: 500, height: 500 });
                        }}
                        className="w-full text-3xl text-center hover:text-blue-400 cursor-pointer"
                      >
                        <button
                          className="popup-btn  w-full"
                          style={{ borderRadius: "10px !important" }}
                        >
                          Sign In
                        </button>
                      </div>
                    )}
                    <button onClick={onClose} className="w-full ">
                      <div className="text-3xl text-center hover:text-blue-400 cursor-pointer">
                        <button
                          className="popup-btn  w-full"
                        >
                          Play
                        </button>
                      </div>
                    </button>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <ModalWallet visible={isOpen} onClose={() => setIsOpen(false)} />
        )}
      </PopupTag>

    </>
  );
}
