import { ButtonPointHistory } from "components/button";
import Swap from "components/swap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { PointItem } from "./styled";

function PointHistory() {
  const [isShowSocial, setShowSocial] = useState(false);

  const toggleSocialHandler = () => {
    setShowSocial(!isShowSocial);
  };
  const { user } = useSelector((state: any) => state.auth);

  return (
    <>
      <PointItem status={isShowSocial}>
        <ButtonPointHistory name={"Point history"} />
        <div className="point-title">Point summary</div>
        <div className="point-table">
          <div className="point-table-title">
            This is a detailed summary of Steam Points you’ve earned and
            redeemed.
          </div>
          <div className="point-table-total">
            <div className="point-table-total-title">Total</div>
            <div className="point-table-total-point">
              <span className="point-table-total-point-num">
                {user?.pointPool?.point?.toLocaleString("en-US") || 0}
              </span>
              <span className="point-table-total-point-tit">Points</span>
            </div>
          </div>
          <div className="point-line"></div>
          <div className="point-table-redeemed">
            <div className="point-table-redeemed-title">Total redeemed</div>
            <div className="point-table-redeemed-point">
              <span className="point-table-redeemed-num">
                {user?.pointPool?.redeemPoint?.toLocaleString("en-US") || 0}
              </span>
              <span className="point-table-redeemed-bpool">BPOOL</span>
            </div>
          </div>
          {/* <div className="point-table-bpool">
          <div className="point-table-bpool-title">Current BPOOL</div>
          <div className="point-table-bpool-point">
            <span className="point-table-bpool-num">0</span>
            <span className="point-table-bpool-bpool">BPOOL</span>
          </div>
        </div> */}
        </div>
        <button
          onClick={() => toggleSocialHandler()}
          className="point-btn button_3d"
        >
          SWAP to BPOOL
        </button>
      </PointItem>
      {isShowSocial && <Swap onClick={() => toggleSocialHandler()} />}
    </>
  );
}

export default PointHistory;
