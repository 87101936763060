
import { Link } from "react-router-dom";
import { GameCardNew } from "./styled";

const CardGenre: any = ({ game }: any) => {


  const {id, name } = game;

  const nameRep = name.replaceAll(" ", "-").toLowerCase();
  //const sum = game_mission.reduce((accumulator: number, currentObject: { pointReward: number; }) => accumulator + currentObject.pointReward, 0);

  return (
    <>
      <GameCardNew className="w-[90px]">
        <Link className="" onClick={async () => {  }} to={`/all-mini-game/?categoriesId=${game.id}&&categoriesName=${game.name}`}>
          <div className="flex flex-col  items-center  rounded-[8px]">
              <div className="">
               <img src={game.image} className="w-[48px] h-[48px]" alt="img soft" />
               </div>
              <div className="text-card-genre text-center mt-[5px]">{game.name}</div>
          </div>
        </Link>
      </GameCardNew>
    </>
  );
};

export default CardGenre;