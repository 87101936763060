import { footerImg } from "helpers/ExportImg";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useRef, useState } from "react";

import { getListGenre } from "pages/services/api";
import { Link } from "react-router-dom";
import { PlayToEarnStyled } from "./styled";
import CardGenre from "components/Card/CardGenre";

const FilterGenreFunnyGame = ({ openList, categoriesId, categoriesName }: any) => {
  const [gamesPopular, setGamesPopular] = useState<any>();

  const [loading, setLoading] = useState<boolean>(true);

  const rootRef = useRef<any>();

  const { controlMobile } = footerImg;


  const width: any = useWindowSize();
  const fetchApi = async () => {
    try {
      setLoading(true);
      const data = await getListGenre();
      setLoading(false);
      setGamesPopular(data);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const payToEarnRef = useRef<any>(null)

  return (
    <PlayToEarnStyled className="relative">

      <div
        className=""
        style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
        ref={rootRef}
      >
        <div
          className=""
          style={{ margin: 0 }}
        >
          <div className="flex justify-between w-full  ">
            <div className="pte-header-left">
              <span>{categoriesName || 'Funny game'}</span>
            </div>
            <div className="pte-header-right">
              <Link to={`/all-mini-game?categoriesId=${categoriesId || ''}&&categoriesName=${categoriesName || ''}`}>See all</Link>
            </div>
          </div>

          <>
            {loading ? (
              <img
                style={{ padding: "5rem 0" }}
                src={controlMobile}
                alt="img"
                className="logo-loading"
              />
            ) : (

              <div ref={payToEarnRef} className="mt-24 w-[90vw] max-w-[1200px] pb-10	">
                {gamesPopular?.map((game: any, index: number) => {

                  if (index % 4 === 0) {
                    let list = gamesPopular.slice(index, index + 4);
                    return (<div className="flex justify-between mt-[20px]">
                      {list.map((item: any, i: number) =>
                        (<CardGenre game={item} />)
                      )}
                    </div>)
                  }
                })}
              </div>
            )}
          </>
        </div>
      </div>
    </PlayToEarnStyled>

  );
};

export default FilterGenreFunnyGame;

