import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { typeImplementation } from "@testing-library/user-event/dist/type/typeImplementation";

import { GameNFTState } from "interfaces";

const initialState: GameNFTState = {
  gamesNFT: [],
  isLoading: false,
  detailGameNFT: {
    id: "",
    name: "",
    dowloadLink: "",
    whitePaper: "",
    tokenomic: "",
    images: "",
    description: "",
    category: "",
    version: "",
    youtube: [],
    notifications: [],
  },
};

const gameSlice = createSlice({
  name: "gamesNFT",
  initialState,
  reducers: {
    startLoading: (state: GameNFTState) => {
      state.isLoading = true;
    },
    setListGameNFT: (
      state: GameNFTState,
      action: PayloadAction<GameNFTState["gamesNFT"]>
    ) => {
      state.isLoading = false;
      state.gamesNFT = action.payload;
    },
    setDetailGameNFT: (
      state: GameNFTState,
      action: PayloadAction<GameNFTState["detailGameNFT"]>
    ) => {
      state.isLoading = false;
      state.detailGameNFT = action.payload;
    },
  },
});

export const { setListGameNFT, setDetailGameNFT } = gameSlice.actions;

export const gamenftReducer = gameSlice.reducer;
