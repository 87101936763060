import { useEffect, useRef, useState } from "react";

import { ellipsisMiddle } from "web3/connector";
import { LeaderboardEventWraper } from "./styled";
import { getUsersJoin } from "pages/services/api";
import { handleShowTime } from "helpers/util";

const TableHourEvent = ({ eventData }: any) => {

    const rootRef = useRef<any>();

    return (

        <LeaderboardEventWraper className="relative w-full">
            {/* <PopupInfo /> */}
            <div
                className=""
                style={{ width: "100%", marginBottom: "5rem", scrollMargin: "10rem" }}
                ref={rootRef}
            >
                <div
                    className=""
                    style={{ margin: 0 }}
                >
                    <div className="p-5 flex justify-start space-x-5 items-center w-full  ">
                        <div className="leaderboard-header-left">
                            <span className="header-history">History</span>
                        </div>
                    </div>
                    <>
                        <div className="mt-5 p-5 grid   mb-10  overflow-x-auto">
                            <table className="">
                                <thead className="text-left">
                                    <tr>
                                        <th>Time</th>
                                        <th>Results</th>
                                        <th>Rewards</th>
                                        <th>Pools Voted</th>
                                        <th>My Reward</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-opacity-10">
                                    {(eventData &&  eventData.history && eventData.history.length > 0) && eventData.history?.map((rankInfo: any, index: number) => (
                                        <tr key={rankInfo?.id}>
                                            <td>{handleShowTime((new Date(rankInfo.startedAt)).getHours())}:00</td>
                                            <td>
                                                {(rankInfo.type === 1) && (<div className="flex h-16 w-16  bg-[#FCFF6A]  border-2	border-[#EDF115] rounded-full"></div>)}
                                                {(rankInfo.type === 2) && (<div className="flex h-16 w-16  bg-[#FF7272]  border-2	border-[#FF3535] rounded-full"></div>)}
                                                {(rankInfo.type === 3) && (<div className="flex h-16 w-16  bg-[#69DBFF]  border-2	border-[#1DC9FF] rounded-full"></div>)}

                                            </td>
                                            {/* <td><img className="w-8 h-8 rounded-full"  src={logos.currency}
                                                            alt="img" /></td> */}
                                            <td>{parseFloat(rankInfo.reward || 0).toFixed(2)}</td>
                                            {/* <td>{rankInfo.walletAddress}</td> */}
                                            <td>{parseFloat(rankInfo.userVoted || 0).toFixed(2)}</td>
                                            <td>{parseFloat(rankInfo.userReward || 0).toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {(!eventData ||  !eventData.history || eventData.history.length <= 0) && <div className="text-center info-description mt-10">Empty </div>}
                        </div>
                        {/* <div className="flex flex-col p-8 items-center">
                            <span className={`${total < 1 ? 'hidden' : ''} text-sm text-gray-700`}>
                                Page <span className="font-semibold text-gray-900">{page}</span> of <span className="font-semibold text-gray-900"> {Math.ceil(total / limit)}</span>
                            </span>
                            <div className="inline-flex mt-2 xs:mt-0  bg-[#06C270] rounded-xl">
                                <button onClick={() => { setPage(page - 1) }} className={`${page < 2 ? 'hidden' : ''} flex items-center justify-center px-3 h-8 text-sm font-medium text-white`} >
                                    Prev
                                </button>
                                <button onClick={() => { setPage(page + 1) }} className={`${page >= (total / limit) ? 'hidden' : ''} flex items-center justify-center px-3 h-8 text-sm font-medium text-white border-0 border-s border-gray-700 `}>
                                    Next
                                </button>
                            </div>
                        </div> */}
                    </>
                </div>
            </div>
        </LeaderboardEventWraper>

    );
};

export default TableHourEvent;
