import React, { useEffect, useState } from "react";

import { homeImg } from "helpers/ExportImg";
import { HeaderGames } from "./HeaderGames";
import SecondaryCard from "../games/SecondaryCard";
import { Swiper, SwiperSlide } from "swiper/react";
import btn_next from "../../asset/home/nextbtn.png";
import btn_back from "../../asset/home/prelbtn.png";
import useWindowSize from "hooks/useWindowSize";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { isMetaProperty } from "typescript";
import { nftPath } from "constants/path";
import {
  getBingoVersion,
  getCHTSVersion,
  getCoinHaloVersion,
  getMarbleVersion,
  getPMGVersion,
  getRpsVersion,
} from "pages/services/api";

const NFTGames = ({ data }: any) => {
  const [apkVersion, setApkVersion] = useState<any>({
    Rps: "",
    CoinHalo: "",
    PMG: "",
    Bingo: "",
    CHTS: "",
    Marble: "",
  });

  useEffect(() => {
    const getInfoGame = async () => {
      try {
        const [rpsGame, coinHaloGame, pmGame, bingoGame, CHTSGame, marbleGame] =
          await Promise.all([
            getRpsVersion(),
            getCoinHaloVersion(),
            getPMGVersion(),
            getBingoVersion(),
            getCHTSVersion(),
            getMarbleVersion(),
          ]);

        setApkVersion({
          Rps: rpsGame,
          CoinHalo: coinHaloGame,
          PMG: pmGame,
          Bingo: JSON.parse(bingoGame.trim()).data,
          CHTS: CHTSGame,
          Marble: marbleGame,
        });
      } catch (error) {
        console.log(error);
      }
    };
    getInfoGame();
  }, []);

  const { playGame } = homeImg;
  const width: any = useWindowSize();

  return (
    <div className="component-games ntf-games">
      <HeaderGames
        title="nft games"
        icon={playGame}
        route={`${nftPath}`}
        show={false}
      />

      <div className="wrapper-game-center">
        <Swiper
          navigation={{
            prevEl: ".prevEl_nft",
            nextEl: ".nextEl_nft",
          }}
          pagination={{
            type: "bullets",
          }}
          loop
          modules={[Pagination, Navigation]}
          className="mySwiper"
          breakpoints={{
            1800: {
              spaceBetween: 50,
              slidesPerView: 3,
            },
            1000: {
              spaceBetween: 50,
              slidesPerView: 2,
            },
            700: {
              spaceBetween: 30,
              slidesPerView: 2,
            },

            300: {
              spaceBetween: 40,
              slidesPerView: 1,
            },
          }}
        >
          {[data[0]]?.map((item: any) => (
            <SwiperSlide key={item?.id}>
              <SecondaryCard
                img={width > 768 ? item?.images[2] : item?.images[5]}
                name={item?.name}
                idGame={item?.id}
                item={item}
                description={item?.description}
                whitePaper={item?.whitePaper}
                tokenomic={item?.tokenomic}
                webSite={item?.website}
                download={apkVersion.CoinHalo?.linkDownApk}
                updateAt={item?.updatedAt}
                version={apkVersion.CoinHalo?.version}
              />
            </SwiperSlide>
          ))}
          {[data[1]]?.map((item: any) => (
            <SwiperSlide key={item?.id}>
              <SecondaryCard
                img={width > 768 ? item?.images[2] : item?.images[5]}
                name={item?.name}
                idGame={item?.id}
                item={item}
                description={item?.description}
                whitePaper={item?.whitePaper}
                tokenomic={item?.tokenomic}
                webSite={item?.website}
                download={apkVersion.CHTS?.link_apk}
                updateAt={item?.updatedAt}
                version={apkVersion.CHTS?.version}
              />
            </SwiperSlide>
          ))}
          {[data[2]]?.map((item: any) => (
            <SwiperSlide key={item?.id}>
              <SecondaryCard
                img={width > 768 ? item?.images[2] : item?.images[5]}
                name={item?.name}
                idGame={item?.id}
                item={item}
                description={item?.description}
                whitePaper={item?.whitePaper}
                tokenomic={item?.tokenomic}
                webSite={item?.website}
                download={apkVersion.Bingo?.apk}
                updateAt={item?.updatedAt}
                version={apkVersion.Bingo?.version}
              />
            </SwiperSlide>
          ))}
          {[data[3]]?.map((item: any) => (
            <SwiperSlide key={item?.id}>
              <SecondaryCard
                img={width > 768 ? item?.images[2] : item?.images[5]}
                name={item?.name}
                idGame={item?.id}
                item={item}
                description={item?.description}
                whitePaper={item?.whitePaper}
                tokenomic={item?.tokenomic}
                webSite={item?.website}
                download={apkVersion.PMG?.link}
                updateAt={item?.updatedAt}
                version={apkVersion.PMG?.version}
              />
            </SwiperSlide>
          ))}
          {[data[4]]?.map((item: any) => (
            <SwiperSlide key={item?.id}>
              <SecondaryCard
                img={width > 768 ? item?.images[2] : item?.images[5]}
                name={item?.name}
                idGame={item?.id}
                item={item}
                description={item?.description}
                whitePaper={item?.whitePaper}
                tokenomic={item?.tokenomic}
                webSite={item?.website}
                download={apkVersion.Marble?.link}
                updateAt={item?.updatedAt}
                version={apkVersion.Marble?.version}
              />
            </SwiperSlide>
          ))}
          {[data[5]]?.map((item: any) => (
            <SwiperSlide key={item?.id}>
              <SecondaryCard
                img={width > 768 ? item?.images[2] : item?.images[5]}
                name={item?.name}
                idGame={item?.id}
                item={item}
                description={item?.description}
                whitePaper={item?.whitePaper}
                tokenomic={item?.tokenomic}
                webSite={item?.website}
                download={apkVersion.Rps?.link}
                updateAt={item?.updatedAt}
                version={apkVersion.Rps?.version}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="prevEl_nft">
          <img src={btn_back} alt="btn-back" style={{ cursor: "pointer" }} />
        </div>
        <div className="nextEl_nft">
          <img src={btn_next} alt="btn=next" style={{ cursor: "pointer" }} />
        </div>
      </div>
    </div>
  );
};

export default NFTGames;
